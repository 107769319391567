export const NUMBER='number'
export const BOOLEAN='boolean'
export const TRUE='true'
export const FALSE='false'
export const LOCAL='LOCAL'
export const LOCAL_COLOR='black'
export const OVERRIDE='LOCAL_OVERRIDE'
export const OVERRIDE_COLOR='#9F492D'
export const GLOBAL='GLOBAL'
export const INHERITED_COLOR='#4700CC'
export const PARENT='PARENT'
export const BOLD_FONT=600
export const REGULAR_FONT=401