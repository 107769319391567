import React from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";
import {DifferenceOutlined, FileUploadOutlined, StarBorderOutlined} from "@mui/icons-material";
import Pipe from "../../assets/Pipe.png";
import ChevronRightFilled from "../../assets/ChevronRightFilled.png";
import Exception from "../../assets/Exception.png";


const KeyFeatures=()=>{
    return(
        <>
            <Grid item xs='auto'>
                <Typography sx={{fontSize:'14px',fontWeight:500,color:'#0070F0',fontStyle:'italic'}}>
                    ConfigStore
                </Typography>
                <Typography sx={{fontWeight:500,fontSize:'18px',lineHeight:'160%'}}>
                    Key Features
                </Typography>
            </Grid>
            <Grid item  mt='28px' minHeight='66vh' mb='15px' xs='auto' >
                <Stack direction='row'>
                    <DifferenceOutlined />
                    <Typography>&ensp;Differences</Typography>
                </Stack>
                <Box sx={{pl:'14px'}}>
                    <Typography color='#808080' mt='10px' ml='20px'>
                        View the differences between any versions of configurations and tenants.
                    </Typography>
                </Box>
                <Stack direction='row' mt='24px'>
                    <FileUploadOutlined />
                    <Typography component='div'>&ensp;Upload&nbsp;|&nbsp;<Typography  sx={{float:'right',textDecorationLine:'underline',color:'#0070F0'}}>Template</Typography></Typography>
                </Stack>
                <Box sx={{pl:'14px'}}>
                    <Typography color='#808080' mt='10px' ml='20px'>
                        Upload a file to edit or create a new configuration.
                    </Typography>
                </Box>
                {/*Pipelines and Exceptions are going to implemented in next phase*/}
                {/*<Stack direction='row' mt='24px'>*/}
                {/*    <StarBorderOutlined />*/}
                {/*    <Typography>&ensp;Favourites</Typography>*/}
                {/*</Stack>*/}
                {/*<Box sx={{pl:'14px'}}>*/}
                {/*    <Typography color='#808080' mt='10px' ml='20px'>*/}
                {/*            Access configurations and tenants instantly by saving it in your favorites.*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                {/*<Stack direction='row' mt='24px'>*/}
                {/*    <img src={Exception} width="24px" height="24px" />*/}
                {/*    <Typography>&ensp;Exceptions</Typography>*/}
                {/*</Stack>*/}
                {/*<Box sx={{pl:'14px'}}>*/}
                {/*        <Typography color='#808080' mt='10px' ml='20px'>*/}
                {/*        View list of exceptions set for a tenant.*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
            </Grid>
            {/*PipeLine are implementing in next Phase*/}
            {/*<Grid item  xs='auto' mb='10px' >*/}
            {/*    <Box width='351px' height='105px' paddingLeft='13px' border='1px solid #B3D6FF'  borderRadius='4px'>*/}
            {/*        <Stack direction='row' mt='12px'>*/}
            {/*            <img src={Pipe} width={"24px"} height={"24px"} color={'red'}/>*/}
            {/*            <Typography>&ensp;8 Pipelines</Typography>*/}
            {/*            <img src={ChevronRightFilled} style={{marginLeft:'auto',marginRight:'16px'}} width={"24px"} height={"24px"} color={'red'}/>*/}
            {/*        </Stack>*/}
            {/*        <Box >*/}
            {/*            <Typography color='#808080' mt='10px' ml='30px'>*/}
            {/*                Click on the pipeline icon to directly enter a pipeline.*/}
            {/*            </Typography>*/}
            {/*        </Box>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}
        </>
    )
}
export default KeyFeatures;