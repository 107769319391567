import React, {useState} from 'react';
import {Box, Button, Grid, IconButton, Menu, MenuItem, Stack} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Search from "../../assets/search_white.png";
import Save from "../../assets/save_white.png";
import Cancel from "../../assets/cancel_white.png";
import Edit from "../../assets/edit_white.png";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {LegendIcons} from "./LegendIcons";


const ActionBar = (props) => {
    const {canEdit, isEditable, onEdit, onCancel, onSearch, addObject, onSubmitChanges, configType, selectedIcons, setSelectedIcons} = props
    const [anchorEl, setAnchorEl] = useState(null);


    const theme = createTheme({
        palette: {
            primary:{
                main:"#ffffff"
            }
        },
    });

    const onAddParamsClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const onAddParamsClose = (type) => {
        setAnchorEl(null);
        if  (type) {
            addObject([], type)
        }
    }

    const styles = {
        button:{
            background: '#FFFFFF',
            border: '1px solid #ECECEC',
            borderRadius: '20px',
            fontSize: '16px',
            color: '#000000',
            textAlign: 'center',
            lineHeight: '20px',
            textTransform: 'none',
            fontFamily: 'Amazon Ember',
            fontStyle: 'normal',
            fontWeight: 400,
            paddingTop:'3px',
            paddingBottom:'3px'

        },

        updateButtons:{
            padding: 0,
        }
    }


    return (
        <Grid aria-label={'action-bar-box'} item xs={12} pt={1}>
            <Grid container justifyContent="right" alignItems ="right" sx={{backgroundColor: "#595959"}}>
                <Grid container item xs={7} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                    <Grid item xs={0.2}>
                    </Grid>
                    { isEditable ?
                        configType === "child" ?
                            <Grid item>
                                <Button aria-label='config-table-new-object' variant="text" style={styles.button} onClick={() => addObject([], "object")}>
                                    <AddCircleOutlineOutlinedIcon/>&nbsp;New Object
                                </Button>
                            </Grid>
                            :
                            <Grid item>
                            <Button aria-label='config-table-new-parameter' variant="text" style={styles.button}
                                    open={Boolean(anchorEl)}
                                // aria-controls={openAddParams ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                // aria-expanded={openAddParams ? 'true' : undefined}
                                    onClick={onAddParamsClick}
                            >
                                <AddCircleOutlineOutlinedIcon/>&nbsp;New Parameter
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                onClose={() => onAddParamsClose()}
                                open={Boolean(anchorEl)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => onAddParamsClose("object")}>Object</MenuItem>
                                <MenuItem onClick={() => onAddParamsClose("list")}>List</MenuItem>
                                <MenuItem onClick={() => onAddParamsClose("key-value")}>Key-Value Pair</MenuItem>
                            </Menu>
                            </Grid>
                         : null
                    }
                    <Grid item>

                    <LegendIcons
                        selectedIcons = {selectedIcons}
                        setSelectedIcons = {setSelectedIcons}/>
                    </Grid>
                </Grid>
                <Grid container item xs={5} justifyContent={"flex-end"} alignItems={"center"}>
                <Box pr={1}>
                    <Stack direction="row" paddingRight={1} p={0.5} spacing={2}>
                        <ThemeProvider theme={theme}>
                            <IconButton  style={styles.updateButtons}><Box p={0} onClick={onSearch}>
                                <img src={Search} width={"20px"} height={"20px"} />
                            </Box></IconButton>
                            { isEditable ?
                                <Stack direction="row" spacing={2}>
                                    <IconButton  aria-label='config-table-save-config' style={styles.updateButtons} onClick={onSubmitChanges}>
                                        <Box p={0}>
                                            <img src={Save} width={"20px"} height={"20px"} />
                                        </Box>
                                    </IconButton>
                                    <IconButton  aria-label='config-table-cancel-config'  style={styles.updateButtons} onClick={onCancel}>
                                        <Box p={0}>
                                            <img src={Cancel} width={"20px"} height={"20px"} />
                                        </Box>
                                    </IconButton>
                                </Stack>
                                :   <Stack direction="row">
                                    {canEdit?
                                    <IconButton aria-label='edit-config' style={styles.updateButtons} onClick={onEdit}>
                                        <Box p={0}>
                                            <img src={Edit} width={"20px"} height={"20px"} />
                                        </Box>
                                    </IconButton>:null}
                                </Stack>
                            }
                        </ThemeProvider>
                    </Stack>
                </Box>
                </Grid>
            </Grid>
    </Grid>
    )


}

export default ActionBar;