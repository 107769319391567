import React from 'react';
import {Grid, Tooltip, Typography} from "@mui/material";

const cardTitleStyle={
    fontStyle:'normal',fontSize:'16px',fontWeight:500,px:'8px',alignSelf:'stretch',lineHeight:'150%',
    overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '235px'
}
const CardTitle=(props)=>{
    const {title}=props

    return(
        <Grid item>
                    <Typography variant='h6' className='card_title' sx={cardTitleStyle}>
                        {title}
                    </Typography>
        </Grid>
    )
}
export default CardTitle