import React, {useCallback, useEffect, useState} from 'react';
import SideBar from "../components/app-layout/SideBar";
import {Grid} from "@mui/material";
import DifferencesPanel from "../components/Differences/DifferencesPanel";
import DifferencesLeftDisplay from "../components/Differences/DifferencesLeftDisplay";
import DifferencesRightDisplay from "../components/Differences/DifferencesRightDisplay";
import {fetchAllReviews, getConfigurations, manageReviewer} from "../api/services";
import {DIFFERENCES_PAGE, GLOBAL_CONFIGS, PREVIEW_CHANGES_PAGE, REVIEW_DIFFERENCES} from "../components/constants";
import {SYSTEM_NAME} from "../components/constants/ApiConstants";
import {
    getDiffRowItems,
    getMergedVersionInfo, getObjectKeysList,
    getParentConfig,
    getReviewVersionInfo, keyExists, mergeLists, nestedObj, sizeOfProp
} from "../components/common/SharedFunctions";
import {ADDED, CHANGED, DELETED, UNCHANGED} from "../components/constants/DifferencesConstants";
import {updatedDiff} from "deep-object-diff";
import {CREATE_CONFIG} from "../components/constants/ApprovalStatusConstants";

const ReviewDifferences=(props)=>{
    const {reviewData,onTenantClosed,user,setIsReviewCardSelected,setIsDifferencePageSelected,setOpenTenant,isTenantPageOpened,
        closeReviewPage,selectedConfiguration,previewCurrentVersion,setResponseType,setCreateVersionStatus}=props
    const [currentVersion,setCurrentVersion]=useState(null)
    const [totalMergedVersion,setTotalMergedVersion]=useState({})
    const [prevVersion,setPrevVersion]=useState(null)
    const [configUser,setConfigUser]=useState('')
    const [parentConfig,setParentConfig]=useState('')
    const [mergedVersionInfo,setMergedVersionInfo]=useState({date:'',status:'',version:''})
    const [reviewingVersionInfo,setReviewingVersionInfo]=useState({date:'',status:'',version:0,description:''})
    const [metaData,setMetaData]=useState({})
    const [createType,setCreateType]=useState('')
    const [isVersionLoaded,setIsVersionLoaded]=useState(false)
    const [uniqueRowKey,setUniqueRowKey]=useState(0)
    const [allRows,setAllRows]=useState([])

    useEffect(() => {
        if(isVersionLoaded && reviewData){
            let reviewingVersion=''
            if(reviewData?.totalReview?.reviewJson){
                let configData=JSON?.parse(reviewData?.totalReview?.reviewJson)
                reviewingVersion=configData?.version
                setParentConfig(configData.parentConfig)
                if(configData.configsJson) {
                    setCurrentVersion(JSON?.parse(configData?.configsJson))
                }
            }
            setReviewingVersionInfo(getReviewVersionInfo(reviewData.totalReview,reviewingVersion))
        }
        setConfigUser(reviewData?.totalReview?.requesterId)
        fetchConfigsData().then(r=>   setIsVersionLoaded(true) )
    }, [reviewData]);

    async function fetchConfigsData() {
        let configs=reviewData?.totalReview?.resourceIdentifier.split('-')
        if(reviewData?.totalReview?.reviewJson){
            let reviewJson=JSON?.parse(reviewData?.totalReview?.reviewJson)
            let version=reviewJson?.version
            setCreateType(reviewJson?.api)
            let variables = {
                tenant: configs[0],
                inheritFlag: true,
                configList: {configName:  configs.slice(1).join('-'),version:version}
            }
            await getConfigurations(setTotalMergedVersion, variables);
        }
    }

    useEffect(() => {
       let mergedConfigParent= totalMergedVersion[0]?.parentConfig ?? ''
        if(parentConfig!==mergedConfigParent && createType!==CREATE_CONFIG){
            if(mergedConfigParent==='') mergedConfigParent='None'
            setReviewingVersionInfo({
                ...reviewingVersionInfo,
                description: `Parent changed from ${mergedConfigParent} to ${parentConfig} `
            })
        }
        else {
            setReviewingVersionInfo({
                ...reviewingVersionInfo,
                description: ''
            })
        }
    }, [parentConfig,totalMergedVersion]);

    useEffect(()=>{
        if(isVersionLoaded && totalMergedVersion && totalMergedVersion.length>0){
                setPrevVersion(JSON?.parse(totalMergedVersion[0].configParams))
                setMetaData(JSON?.parse(totalMergedVersion[0].metadata))
                setMergedVersionInfo(getMergedVersionInfo(totalMergedVersion,reviewingVersionInfo))

        }
    },[totalMergedVersion,reviewingVersionInfo])

    const changedRows = updatedDiff(prevVersion, currentVersion)

    const eachDiffRow=useCallback((key,value,isNestedJson,parentKey,level,isDeleting,keyMetaData)=>{
        if(isDeleting){
            if(selectedConfiguration && (keyMetaData==='GLOBAL' || keyMetaData==='PARENT' || (typeof keyMetaData==='object' && (keyMetaData[key]==='PARENT' || keyMetaData[key]==='GLOBAL'))) ){
                return getDiffRowItems(key,value,isNestedJson,parentKey,level,keyMetaData,UNCHANGED)
            }
            return getDiffRowItems(key,value,isNestedJson,parentKey,level,keyMetaData,DELETED)
        }
        else if( !keyExists(prevVersion,parentKey)){
            return getDiffRowItems(key,value,isNestedJson,parentKey,level,keyMetaData,ADDED)
        }
        else if(!isNestedJson &&keyExists(changedRows,parentKey)){
            return getDiffRowItems(key,value,isNestedJson,parentKey,level,keyMetaData,CHANGED)
        }
        else {
            return getDiffRowItems(key,value,isNestedJson,parentKey,level,keyMetaData,UNCHANGED)
        }
    },[selectedConfiguration,changedRows,prevVersion])


    useEffect(()=>{
        if(prevVersion!==null || createType===CREATE_CONFIG){
            let resultRows=diffJsonDisplay(currentVersion,metaData.key_sources);
            setAllRows(resultRows)
        }

    },[prevVersion,currentVersion,metaData])

    const diffJsonDisplay=useCallback(( data ,metaData) =>{
        const differences = [];
        function displayKeyValue(key,value,parentKey,level,isDeleting,keyMetaData) {
            const currentKey=parentKey.length>0 ?[...parentKey,key]:[key]
            let nestedItems=[]
            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    if(sizeOfProp(item)) {
                        nestedItems.push(eachDiffRow(index,sizeOfProp(item),true,currentKey,level+1,isDeleting,keyMetaData))
                    }
                    const nestedDifferences = displayKeyValue(index,item,currentKey,level+1,isDeleting,keyMetaData)
                    nestedItems.push(...nestedDifferences)
                })
                return nestedItems;
            }
            else if (typeof value === 'object' && value !== null) {
                const currentObjKeys = Object.keys(value);
                let prevObjKeys=Object.keys(nestedObj(prevVersion,currentKey))
                const combinedObjKeys=mergeLists(prevObjKeys,currentObjKeys)
                if(selectedConfiguration && keyExists(metaData,currentKey)){
                    keyMetaData=nestedObj(metaData,currentKey)
                }
                combinedObjKeys.forEach((key)=>{
                    if(!(currentObjKeys.includes(key))){
                        let prevValue=nestedObj(prevVersion,[...currentKey,key])
                        if(sizeOfProp(prevValue)){
                            nestedItems.push(eachDiffRow(key,sizeOfProp(prevValue),true,currentKey,level+1,true,keyMetaData))
                        }
                        const nestedDifferences=displayKeyValue(key,prevValue,currentKey,level+1,true,keyMetaData)
                        nestedItems.push(...nestedDifferences)
                    }
                    else {
                        if(sizeOfProp(value[key])){
                            nestedItems.push(eachDiffRow(key,sizeOfProp(value[key]),true,[...currentKey,key],level+1,isDeleting,keyMetaData))
                        }
                        const nestedDifferences=displayKeyValue(key,value[key],currentKey,level+1,isDeleting,keyMetaData)
                        nestedItems.push(...nestedDifferences)
                    }
                })
                return nestedItems;
            }
            if(selectedConfiguration && keyExists(metaData,currentKey)){
                keyMetaData=nestedObj(metaData,currentKey)}
            nestedItems.push(eachDiffRow(key,value,false,currentKey,level,isDeleting,keyMetaData))
            return nestedItems;
        }

        let i=0;

        const json1Keys=getObjectKeysList(prevVersion)
        function displayDeletingKeyValue(i,json1Keys,json1Value,keyMetaData){
            if(sizeOfProp(json1Value)){
                differences.push(eachDiffRow(json1Keys[i],sizeOfProp(json1Value),true,[],0,true,keyMetaData))
            }
            const nestedDifferences=displayKeyValue(json1Keys[i], json1Value, [], 0,true,keyMetaData)
            differences.push(...nestedDifferences)
        }
        for (const key in data) {
            const value = data[key];
            let keyMetaData='LOCAL'
            if(selectedConfiguration && metaData!==undefined && metaData?.hasOwnProperty(key)){
                keyMetaData=metaData[key];
            }
            while (i<json1Keys.length && !(json1Keys[i] in data)) {
                const json1Value=prevVersion[json1Keys[i]]
                if(selectedConfiguration && metaData!==undefined && metaData?.hasOwnProperty(json1Keys[i])){
                    keyMetaData=metaData[json1Keys[i]];
                }
                displayDeletingKeyValue(i,json1Keys,json1Value,keyMetaData)
                i++;
            }
            if(sizeOfProp(value)) {
                differences.push(eachDiffRow(key, sizeOfProp(value), true, [key]  , 0, false,keyMetaData))
            }

            const nestedDifferences=displayKeyValue(key, value, [], 0,false,keyMetaData)
            differences.push(...nestedDifferences)
            i++;
        }
        while (i<json1Keys.length && data && !(json1Keys[i] in data)) {
            const json1Value=prevVersion[json1Keys[i]]
            let keyMetaData='LOCAL'
            if(selectedConfiguration && metaData!==undefined && metaData?.hasOwnProperty(json1Keys[i])){
                keyMetaData=metaData[json1Keys[i]];
            }
            displayDeletingKeyValue(i,json1Keys,json1Value,keyMetaData)
            i++;
        }
        return differences;
    },[prevVersion,selectedConfiguration])

    return(
        <Grid container={true} direction='row'
              sx={{width:'1440px'}}
        >
            <SideBar
                differencesPageType={DIFFERENCES_PAGE}
                onTenantClosed={onTenantClosed}
                selectedTenant={reviewData.cardTenant}
            />
            <Grid container direction='column' sx={{width:'1330px',marginLeft:'32px',mt:'27px'}}>
                <DifferencesPanel
                    user={user}
                    configUser={configUser}
                    selectedTenant={reviewData.cardTenant}
                    selectedConfiguration={reviewData.cardConfiguration?reviewData.cardConfiguration:GLOBAL_CONFIGS}
                    differencesPageType={REVIEW_DIFFERENCES}
                    previewCurrentVersion={previewCurrentVersion}
                    reviewData={reviewData}
                    setIsReviewCardSelected={setIsReviewCardSelected}
                    setIsDifferencePageSelected={setIsDifferencePageSelected}
                    parentConfig={parentConfig}
                    setOpenTenant={setOpenTenant}
                    isTenantPageOpened={isTenantPageOpened}
                    closeReviewPage={closeReviewPage}
                    versionInfo={reviewingVersionInfo}
                    createType={createType}
                    setResponseType={setResponseType}
                    setCreateVersionStatus={setCreateVersionStatus}
                />
                <Grid container direction='row' gap={'32px'} >
                    {isVersionLoaded &&
                    <>
                    {createType!==CREATE_CONFIG &&<DifferencesRightDisplay
                            currentVersion={currentVersion}
                            prevVersion={prevVersion}
                            metaData={metaData}
                            differencesPageType={REVIEW_DIFFERENCES}
                            isCurrentVersion={false}
                            versionInfo={mergedVersionInfo}
                            selectedConfiguration={selectedConfiguration}
                            uniqueRowKey={uniqueRowKey}
                            allRows={allRows}
                        />}
                            <DifferencesLeftDisplay
                                currentVersion={currentVersion}
                                prevVersion={prevVersion}
                                metaData={metaData}
                                isCurrentVersion={true}
                                versionInfo={reviewingVersionInfo}
                                selectedConfiguration={selectedConfiguration}
                                setUniqueRowKey={setUniqueRowKey}
                                uniqueRowKey={uniqueRowKey}
                                allRows={allRows}
                            />
                    </>}
                </Grid>
            </Grid>
        </Grid>
    )
}
export default ReviewDifferences;