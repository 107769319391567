import React, {useEffect, useState} from "react";

import {
    Dialog,
    Box,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControl,
    Grid,
    Select,
    MenuItem,
    TextField, InputLabel, Button, Stack, Typography
} from '@mui/material';
import {JsonEditor} from 'jsoneditor-react';
import styles from '../../styles/CreateTenantModalStyles.module.css';
import FormTextField from './FormTextField';
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import 'jsoneditor-react/es/editor.min.css';
import SwitchRightRoundedIcon from "@mui/icons-material/SwitchRightRounded";
import SwitchLeftRoundedIcon from "@mui/icons-material/SwitchLeftRounded";
import SubmitModal from "../TenantPage/SubmitModal";
import {checkItemInList, isValidJSONObject, updateUsername} from "../common/SharedFunctions";
import {fetchBindleList} from "../../api/services";


function CreateTenantModal(props){
    const {createTenant, open, handleClose, user, setOpen, allTenantsData} = props;
    const [name, setName] = useState("");
    const [environment, setEnvironment] = useState("");
    const [bindleName, setBindleName] = useState("");
    const [bindleJson, setBindleJson] = useState({});
    const [json, setJson] = useState({});
    const [isText, setInputType] = useState(true);
    const [tenantNameExists, setTenantNameExists] = useState(false);
    const [isTextEditorJson, setTextJsonValidation] = useState(true);
    const [textEditorData, setTextEditorData] = useState("");
    const [submitReady, setSubmitReady] = useState(false);
    const [openSubmit, setOpenSubmit] = useState(false);
    const handleSubmitClose = () => setOpenSubmit(false);

    const handleNameChange = (data) => {
        setName(data.replaceAll(' ', ''));
    }

    const handleTextEditorChange = (data) => {
        setTextEditorData(data)
        if (data === '') {
            setJson({});
            setTextJsonValidation(true);
        } else {
            if(isValidJSONObject(data)){
                setJson(JSON.parse(data));
                setTextJsonValidation(true);
            } else {
                setTextJsonValidation(false)
            }
        }
    };

    const onSubmit = () => {
        let jsonBody = {
            tenantName: `${name}`,
            configsJson: isText ? textEditorData : JSON.stringify(json),
            user: `${updateUsername(user)}`,
            bindleID: `${bindleJson[bindleName]}`
        }

        createTenant(name, jsonBody)
        setOpenSubmit(false);
        clearEverything();
    }

    async function fetchBindles () {
        let variables={
            actorID: updateUsername(user)
        }
        let input = {
            input: variables
        }
        await fetchBindleList(setBindleJson,input)
    }

    useEffect( () => {
        fetchBindles()
    }, [user]);

    useEffect(() => {
        if (checkItemInList(Object.keys(allTenantsData),name)){
            setTenantNameExists(true);
        } else {
            setTenantNameExists(false);
        }
        let configsJson = isText ? textEditorData : JSON.stringify(json);
        if (name !== "" && isTextEditorJson && !checkItemInList(Object.keys(allTenantsData),name) && bindleName) {
            setSubmitReady(true);
        } else {
            setSubmitReady(false);
        }
    }, [name, isTextEditorJson, bindleName]);

    const handleSwitchInput = () => {
        setInputType(current => {return !current;});
    };

    useEffect(() => {
        if(!isText){
            if (JSON.stringify(json) !== "{}") {
                setTextEditorData(JSON.stringify(json, null, 2));
            } else {
                setTextEditorData("");
            }
            setTextJsonValidation(true);
        }
    }, [json]);

    const clearEverything = () => {
        setInputType(true);
        setTextEditorData("");
        setJson({});
        setTextJsonValidation(true);
        setName("");
        setEnvironment("");
        setSubmitReady(false);
        setBindleName("")
        props.handleClose();
    };

    const bindleList = () =>{
        const list = [];
        const bindles = Object.keys(bindleJson)
        if (bindles) {
            bindles.forEach((bindle, i) => {
                list.push(<MenuItem
                    key={i}
                    value={bindle}
                >{bindle}</MenuItem>);
            });
        }

        return list;
    };

    return (
        <Box>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle className={styles.dialog__title}>Create Tenant</DialogTitle>
                <DialogContent  sx={{overflowY: "hidden"}}>
                    <Grid container className={styles.dialog__content} rowSpacing={1} columnSpacing={1} pb={1}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label='Name*'
                                value={name}
                                onChange={(e)=>handleNameChange(e.target.value)}
                                hasWordLimit={false}
                                error={tenantNameExists}
                                helperText={tenantNameExists ? "Tenant already Exists" : ""}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Environment</InputLabel>
                                <Select
                                    fullWidth
                                    className={styles.environment}
                                    label="Environment"
                                    value={environment}
                                    onChange={(e) => setEnvironment(e.target.value)}
                                >
                                    <MenuItem value={"Prod"}>Prod</MenuItem>
                                    <MenuItem value={"Test"}>Test</MenuItem>
                                    <MenuItem value={"Dev"}>Dev</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                label={"Bindle*"}
                                value={bindleName}
                                onChange={(e) => setBindleName(e.target.value)}
                                SelectProps={{
                                    MenuProps: {
                                        sx: { height: "400px" },
                                    },
                                }}>
                                {bindleList()}
                            </TextField>
                    </Grid>
                    <Grid className={styles.editor__box} item xs={12}>
                        <Typography className={styles.box__helper_text}>Input configuration (Optional)</Typography>
                        {isText ?
                            <Box style={{overflowY:"auto" , height : "100%"}}>
                                <TextField className={styles.editor}
                                           aria-label='create-tenant-config-json-body-input'
                                           multiline
                                           rows={10}
                                           fullWidth
                                           value={textEditorData}
                                           onChange={(e) => handleTextEditorChange(e.target.value)}
                                           error={!isTextEditorJson}
                                           helperText={!isTextEditorJson ? "Not a valid json" : ""}
                                />
                            </Box>
                            :
                            <JsonEditor mode="tree"
                                        history
                                        value={json}
                                        onChange={setJson}
                                        ace={ace}
                                        theme="ace/theme/github"
                            />
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container className={styles.button__row} justifyContent="space-between">
                        <Button variant="text" className={styles.switch__button} onClick={handleSwitchInput} key="switch-modal">{ (isText) ? <div>&nbsp;<b>Text</b>&nbsp;<SwitchRightRoundedIcon/>&nbsp;Editor&nbsp;</div> :  <div>&nbsp;Text&nbsp;<SwitchLeftRoundedIcon/>&nbsp;<b>Editor</b>&nbsp;</div> }</Button>
                        <Stack direction={"row"} spacing={2}>
                            <Button aria-label='cancel-create-new-config' variant="text" className={styles.button} onClick={clearEverything} key="cancel-modal"> Cancel</Button>
                            {submitReady ?
                                <Button aria-label='submit-create-new-config' variant="text" className={styles.button}
                                        onClick={onSubmit} key="submit-modal"> Submit</Button>
                            :
                                <Button disabled variant="text" className={styles.button__disabled} key="submit-modal"> Submit</Button>
                            }
                        </Stack>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
export default CreateTenantModal;