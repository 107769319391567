import React, {useState} from 'react';
import { Stack, Typography, List, ListItem, ListItemButton } from '@mui/material';
import {InheritedIcon, LocalIcon, OverrideIcon} from "../LegendIcons";

export const LegendIcons = (props) => {
    const { selectedIcons, setSelectedIcons } = props
    const [ hover, setHover ] = React.useState(false);

    const handleIconSelection = (icon) => {
        if (icon === "INHERITED") {
            if (selectedIcons.includes("PARENT")){
                const temp = selectedIcons.filter(icon_ => icon_ != "PARENT")
                const temp2 = temp.filter(icon_ => icon_ != "GLOBAL")
                setSelectedIcons(temp2)
            } else if (selectedIcons.length < 2){
                const temp = [ ...selectedIcons, "GLOBAL", "PARENT" ]
                setSelectedIcons(temp)
            } else {
                setSelectedIcons([])
            }
        } else if (selectedIcons.includes(icon)){
            const temp = selectedIcons.filter(icon_ => icon_ != icon)
            setSelectedIcons(temp)
        } else if(selectedIcons.length < 3) {
            const temp = [ ...selectedIcons, icon ]
            setSelectedIcons(temp)
        } else {
            setSelectedIcons([])
        }
    }

    return <List
        component={ Stack }
        direction={"row"}
        style={{ padding: 0, color: 'white', borderRadius: '150px', border: '1px solid white', overflow: 'hidden' }}
    >
        <ListItem
            style={{
                borderLeft: '1px solid white',
                borderRight: '1px solid white',
                backgroundColor: selectedIcons.includes('LOCAL') || hover === 'LOCAL' ? 'white' : null,
                color: selectedIcons.includes('LOCAL') || hover === 'LOCAL' ? 'black' : 'white',
                padding: '3px'
            }}
            onMouseEnter={() => setHover('LOCAL')}
            onMouseLeave={() => setHover(null)}
            onClick={() => handleIconSelection('LOCAL')}
        >
            <LocalIcon />
            <ListItemButton style={{padding: 0, marginLeft: '5px'}}>
                <Typography>
                    Local
                </Typography>
            </ListItemButton>
        </ListItem>
        <ListItem
            style={{
                borderLeft: '1px solid white',
                borderRight: '1px solid white',
                backgroundColor: selectedIcons.includes('LOCAL_OVERRIDE') || hover === 'OVERRIDE' ? 'white' : null,
                color: selectedIcons.includes('LOCAL_OVERRIDE') || hover === 'OVERRIDE' ? 'black' : 'white',
                padding: '3px'
            }}
            onMouseEnter={() => setHover('OVERRIDE')}
            onMouseLeave={() => setHover(null)}
            onClick={() => handleIconSelection('LOCAL_OVERRIDE')}
        >
            <OverrideIcon />
            <ListItemButton style={{padding: 0, marginLeft: '5px'}}>
                <Typography>
                    Override
                </Typography>
            </ListItemButton>
        </ListItem>
        <ListItem
            style={{
                borderLeft: '1px solid white',
                borderRight: '1px solid white',
                backgroundColor: selectedIcons.includes('GLOBAL') || selectedIcons.includes('PARENT') || hover === 'INHERITED' ? 'white' : null,
                color: selectedIcons.includes('GLOBAL') || selectedIcons.includes('PARENT') || hover === 'INHERITED' ? 'black' : 'white',
                padding: '3px'
            }}
            onMouseEnter={() => setHover('INHERITED')}
            onMouseLeave={() => setHover(null)}
            onClick={() => handleIconSelection('INHERITED')}
        >
            <InheritedIcon />
            <ListItemButton style={{padding: 0, marginLeft: '5px'}}>
                <Typography>
                    Inherited
                </Typography>
            </ListItemButton>
        </ListItem>
    </List>
}