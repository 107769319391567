import {Auth} from "@aws-amplify/auth";
const aws4fetch = require('aws4fetch');

export const getConfigVersion = async(getAWSClient, tenantName, configName, version, configVersionMap) => {
    try {
        // const credentials = await Auth.currentCredentials()
        // const aws = new aws4fetch.AwsClient({
        //     accessKeyId: credentials.accessKeyId,
        //     secretAccessKey: credentials.secretAccessKey,
        //     sessionToken: credentials.sessionToken
        // })
        const aws = await getAWSClient();
        let endPoint = 'prod/tenants/' + tenantName + '/configurations'
        let url = await getUrl() + endPoint;
        const res = await aws.fetch(url,
            {method: 'POST', body: JSON.stringify({inheritFlag: true,  "configList" : [
                        {
                            'configName': configName,
                            'version': parseInt(version)
                        },
                    ]})})
        try {
            res.json()
                .then((json) => {
                        console.log('GET CONFIG VERSION SUCCESSFUL');
                        return json.configsMap;
                    }
                )
                .then((json) => {
                    const newVersionMap = json;
                    configVersionMap(newVersionMap)
                })
                .catch((e) => console.log("getConfigVersion failed", e));
        } catch (e) {
            console.log("getConfigVersion failed", e)
        }
    } catch (e) {
        console.log("getConfigVersion failed", e)
    }
}


const fetchSettings = async () => {
    try {
        const response = await fetch('./settings.json');
        const settingsJson = await response.json();
        return settingsJson
    } catch (e) {
        alert("There was a problem retrieving settings from backend", e);
    }
}

const getUrl = async () => {
    try {
        const settings = await fetchSettings()
        return settings["GFTConfigStoreService"]["APIEndPoint"]
    } catch (e) {
        alert("GetUrl failed")
    }
}
