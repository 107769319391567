import React, {useEffect, useState} from 'react';
import {Button, Grid, IconButton, Menu, MenuItem, Stack, Typography, Tooltip, TextField} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import styles from "../../../../styles/TableStyles.module.css";
import {
    Add,
    CancelOutlined,
    CheckCircleOutlined,
    CompressOutlined,
    EditOutlined,
    ExpandOutlined,
    ModeEdit,
    Search,
    SearchOff,
    StarBorderOutlined
} from "@mui/icons-material";
import {hasRequiredAccess, isPendingState} from "../../../common/SharedFunctions";
import {useSelector} from "react-redux";


const ActionBar = (props) => {
    const {canEdit, isEditable, onEdit, onCancel, onSearch, isSearch, searchValue, setSearchValue, addObject, onSubmitChanges, configType,
        selectedIcons, setSelectedIcons, expandAll, setExpandAll,handlePreviewChanges,selectedTenant,selectedConfig,pendingReviews,selectedVersion} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedLocalButton, setSelectedLocalButton] = useState(false);
    const [selectedOverrideButton, setSelectedOverrideButton] = useState(false);
    const [selectedInheritedButton, setSelectedInheritedButton] = useState(false);
    const [updateAccess,setUpdateAccess]=useState(false);


    const allUpdatableTenants=useSelector(({tenantsReducer})=>({
        updatableTenants:tenantsReducer.updatableTenants
    }))

    useEffect(() => {
        const updateAccess = hasRequiredAccess(allUpdatableTenants.updatableTenants,selectedTenant);
        setUpdateAccess(updateAccess);
    }, [allUpdatableTenants.updatableTenants,selectedTenant])

    useEffect(() => {
        setSelectedLocalButton(false)
        setSelectedOverrideButton(false)
        setSelectedInheritedButton(false)
    }, [selectedVersion])
    const theme = createTheme({
        palette: {
            primary:{
                main:"#ffffff"
            }
        },
    });

    const onAddParamsClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const onAddParamsClose = (type) => {
        setAnchorEl(null);
        if  (type) {
            addObject([], type)
        }
    }

    const handleIconSelection = (icon) => {
        if (icon === "INHERITED") {
            if (selectedIcons.includes("PARENT")){
                const temp = selectedIcons.filter(icon_ => icon_ != "PARENT")
                const temp2 = temp.filter(icon_ => icon_ != "GLOBAL")
                setSelectedIcons(temp2)
            } else if (selectedIcons.length < 2){
                const temp = [ ...selectedIcons, "GLOBAL", "PARENT" ]
                setSelectedIcons(temp)
            } else {
                setSelectedIcons([])
            }
        } else if (selectedIcons.includes(icon)){
            const temp = selectedIcons.filter(icon_ => icon_ != icon)
            setSelectedIcons(temp)
        } else if(selectedIcons.length < 3) {
            const temp = [ ...selectedIcons, icon ]
            setSelectedIcons(temp)
        } else {
            setSelectedIcons([])
        }
    }


    return (
        <Grid container direction='row' justifyContent="space-between" alignItems="center"
              className={styles.config__table_header}>
            <Grid>
                {isEditable ?
                    <>
                        <Button open={Boolean(anchorEl)} aria-haspopup="true" onClick={onAddParamsClick} variant="outlined" startIcon={<Add/>}
                                className={styles.config__button_param}>
                            New Parameter
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            onClose={() => onAddParamsClose()}
                            open={Boolean(anchorEl)}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => onAddParamsClose("object")}>Object</MenuItem>
                            <MenuItem onClick={() => onAddParamsClose("list")}>List</MenuItem>
                            <MenuItem onClick={() => onAddParamsClose("key-value")}>Key-Value Pair</MenuItem>
                        </Menu>
                    </>
                    : null
                }
                <Button variant="outlined" className={selectedIcons.includes('LOCAL') ? `${styles.local__button_config} ${styles.button__selected}`: styles.local__button_config}
                        onClick={() => handleIconSelection('LOCAL')}>
                    <Typography className={styles.local__icon}>L</Typography>&nbsp;Local
                </Button>
                <Button variant="outlined" className={selectedIcons.includes('LOCAL_OVERRIDE') ? `${styles.override__button} ${styles.button__selected}`: styles.override__button}
                        onClick={() => handleIconSelection('LOCAL_OVERRIDE')}>
                    <Typography className={styles.override__icon}>O</Typography>&nbsp;Override
                </Button>
                <Button variant="outlined" className={selectedIcons.includes('GLOBAL') || selectedIcons.includes('PARENT') ? `${styles.inherited__button} ${styles.button__selected}`: styles.inherited__button}
                        onClick={() => handleIconSelection('INHERITED')}>
                    <Typography className={styles.inherited__icon}>I</Typography>&nbsp;Inherited
                </Button>
            </Grid>
            <Stack direction="row" alignItems="center" spacing={0}>
                {isSearch ?
                    <TextField size="small"
                               variant="outlined"
                               value={searchValue}
                               onChange={(e)=>setSearchValue(e.target.value)}
                               inputProps={{
                                   style: {
                                       height: "16px",
                                   },
                               }}>
                    </TextField>
                    : null
                }
                <Tooltip title='Search' placement='top' arrow>
                    <IconButton onClick={()=>onSearch(!isSearch)} size="small">
                        {isSearch ?
                            <SearchOff/>
                        :
                            <Search/>
                        }
                    </IconButton>
                </Tooltip>
                { isEditable ?
                    <Stack direction="row" spacing={0}>
                        <Tooltip title='Submit' placement='top' arrow>
                                <IconButton size="small" aria-label='config-table-save-config'  onClick={handlePreviewChanges}>
                                <CheckCircleOutlined/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Cancel' placement='top' arrow>
                            <IconButton size="small" aria-label='config-table-cancel-config'   onClick={onCancel}>
                                <CancelOutlined/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    :   <Stack direction="row">
                        { canEdit ?
                            <Tooltip title='Edit' placement='top' arrow>
                                <IconButton size="small" aria-label='edit-config' disabled={isPendingState(pendingReviews,selectedTenant,selectedConfig) || !updateAccess} onClick={onEdit}>
                                    <EditOutlined/>
                                </IconButton>
                            </Tooltip>
                            : null
                        }
                    </Stack>
                }
                {expandAll ?
                    <Tooltip title={'Collapse'} placement='top' arrow>
                        <IconButton size="small" onClick={setExpandAll}>
                            <CompressOutlined/>
                        </IconButton>
                    </Tooltip>
                :
                    <Tooltip title={'Expand'} placement='top' arrow>
                        <IconButton size="small" onClick={setExpandAll}>
                            <ExpandOutlined/>
                        </IconButton>
                    </Tooltip>
                }

                {/*
                    <Tooltip title='Favourite' placement='top' arrow>
                    <IconButton size="small">
                        <StarBorderOutlined/>
                    </IconButton>
                </Tooltip>
                */}

            </Stack>
        </Grid>
    )
}

export default ActionBar;