import {FEDERATE, FEDERATE_OIDC} from "../constants/ApiConstants";
import {GLOBAL_CONFIGS, metaData, NO_VERSIONS_AVAILABLE} from "../constants";
import {
    Approved,
    APPROVED, Cancelled, Current,
    Edited,
    MERGED, Pending,
    PENDING,
    Rejected,
    reviewStatus
} from "../constants/ApprovalStatusConstants";

export const updateUsername=(user)=>{
    if(user.startsWith(FEDERATE_OIDC)){
        return user.substring(13)
    }
    else if(user.startsWith(FEDERATE)){
        return user.substring(9)
    }
    return user;
}

export const sizeOfProp=(eachItem)=>{
    if(Array.isArray(eachItem) ){
        return eachItem.length+'props'
    }
    else if(typeof eachItem === 'object' && eachItem !==  null){
        return Object.keys(eachItem).length+'props'
    }
    return null
}

export function nestedObj(obj, keys) {
    for (let i = 0; i < keys.length; i++) {
        if (!obj || !(typeof obj!== "string" && typeof obj!=='number'  && typeof obj!=="boolean" && keys[i] in obj)) {
            return false;
        }
        obj = obj[keys[i]];
    }
    return obj;
}

export function keyExists(obj, keys) {
    for (let i = 0; i < keys.length; i++) {
        if (!obj || !(typeof obj!== "string" && typeof obj!=='number'  && typeof obj!=="boolean" && keys[i] in obj)) {
            return false;
        }
        obj = obj[keys[i]];
    }
    return true;
}

export const getObjectKeysList=(prevVersion)=>{
    if(prevVersion) return Object?.keys(prevVersion)
    return []
}

export function mergeLists(list1, list2) {
    const result = [];
    let i = 0;
    let j = 0;

    while (i < list1.length && j < list2.length) {
        if (list1[i] === list2[j]) {
            result.push(list1[i]);
            i++;
            j++;
        } else {
            result.push(list1[i]);
            i++;
        }
    }

    while (i < list1.length) {
        result.push(list1[i]);
        i++;
    }

    while (j < list2.length) {
        result.push(list2[j]);
        j++;
    }

    return result;
}

export const sortReviews=(totalReviews,versions)=>{
    let filteredTotalReviews=totalReviews.filter((eachReview)=>eachReview.reviewStatus!=='CANCELLED')
    filteredTotalReviews.sort((a, b) => {
        const timestampA = new Date(a.timestamp);
        const timestampB = new Date(b.timestamp);
        return timestampB - timestampA ;
    });
    let totalVersions=versions?.slice().reverse();
    let currentVersionIndex=0
    let updatedReviews=filteredTotalReviews.map((eachReview)=>{
        if(eachReview.reviewStatus===MERGED && totalVersions && totalVersions.length>0){
            let currentReview={...eachReview,version:totalVersions[currentVersionIndex]}
            currentVersionIndex++;
            return currentReview
        }
        else return eachReview
    })
    return updatedReviews
}


export const approvalTimestamp=(eachApproval)=>{
    let version=''
    if(eachApproval.version){
        version=' ('+eachApproval.version+')'
    }
    return convertTimeZone(eachApproval.timestamp)+version
}

export const isPendingState=(pendingReviews,selectedTenant,selectedConfiguration)=>{
    let resourceIdentifier=selectedTenant+'-'+selectedConfiguration
     let currentPendingReviews=pendingReviews?.filter((eachReview)=>{
         return eachReview.resourceIdentifier === resourceIdentifier;
     })
    return currentPendingReviews.length>0
}

export const currentVersionInfo=(metaData)=>{
    if(metaData.hasOwnProperty('created')){
        return convertTimeZone(metaData.created)+' (Current)'
    }
    else return NO_VERSIONS_AVAILABLE
}

export const checkVersionStatus=(reviewStatus)=>{
    return reviewStatus!==Approved
}

export const getDiffRowItems=(key,value,isNestedJson,parentKey,level,keyMetaData,change)=>{
    return {
        rowKey: key,
        value: value,
        isNestedJson: isNestedJson,
        parentKey:parentKey,
        level: level,
        keyMetaData: keyMetaData,
        change:change
    }
}

export const displayReviewStatus=(reviewStatus)=>{
    if(reviewStatus) return  '('+reviewStatus+')'
}

export const getReviewVersionInfo=(review,reviewingVersion)=>{
    let status=reviewStatus[review?.reviewStatus],date=''
    if(review?.timestamp){
        date=convertTimeZone(review?.timestamp)
    }
    if(status===Approved  ){
        let currentReviewVersion=reviewingVersion===1?reviewingVersion:reviewingVersion+1
        if(currentReviewVersion) date+=' (V'+currentReviewVersion+')'
    }
    return {date:date,status:status,version:reviewingVersion}
}

export const getMergedVersionInfo=(mergedVersion,reviewingVersionInfo)=>{
    let date='',status=''
    if(mergedVersion.length && mergedVersion[0]?.metadata){
        let metaData=JSON?.parse(mergedVersion[0]?.metadata)
        date=convertTimeZone(metaData.created)
        if(reviewingVersionInfo.status===Pending || reviewingVersionInfo.status===Edited) status=Current
        else if(reviewingVersionInfo?.version && (reviewingVersionInfo.status===Approved || reviewingVersionInfo.status===Rejected || reviewingVersionInfo.status===Cancelled)){
            date=date+' (V'+reviewingVersionInfo?.version+')'
        }
        return {date:date,status:status}
    }
    return {date:date,status:status}
}

export const getParentConfig =(review,allTenantsData)=>{
    let resourceIdentifier=review?.resourceIdentifier?.split('-')
    let tenant=resourceIdentifier[0],configuration=resourceIdentifier.slice(1).join('-')
    let config=allTenantsData[tenant]
    let currentConfiguration=config?.filter((eachConfig)=>eachConfig?.configName===configuration)
    if(currentConfiguration?.length>0){
        return currentConfiguration[0]?.parentConfig
    }
    else return ''
}

export const latestVersionDateStatus=(metaData)=>{
    let date=''
    if(metaData) date=convertTimeZone(metaData)
    return {date:date,status:Current}
}

export const editedDateStatus=()=>{
    const currentDate = new Date();
    const localTimestamp = currentDate.toLocaleString();
    const [datePart, timePart] = localTimestamp.split(', ');
    const [day, month, year] = datePart.split('/');

    const requiredDate= `${year}-${month}-${day} ${timePart}`
    return {date:requiredDate,status:Edited}
}

export const convertTimeZone=(timeStamp)=>{
    if(!timeStamp) return '';
    const formattedTimestamp = timeStamp?.split(".")[0]+'Z';
    const gmtDate = new Date(formattedTimestamp);
    const localTimestamp = gmtDate?.toLocaleString();
    const [datePart, timePart] = localTimestamp?.split(', ');
    const [day, month, year] = datePart?.split('/');

    return `${year}-${month}-${day} ${timePart}`
}

export const getSubmitters=(reviews)=>{
    return [...new Set(reviews.map((eachReview) => eachReview?.totalReview?.requesterId))]
}

 export const getReviewingConfigurations=(reviews)=>{
     return [...new Set(reviews.map((eachReview) => eachReview?.cardConfiguration))]
 }

export const getReviewingTenants=(reviews)=>{
    return [...new Set(reviews.map((eachReview) => eachReview?.cardTenant))]
}

export const  isDateBetween=(checkDate, startDate, endDate,cardName) =>{
    let formatStartDate=formatDate(startDate)
    let formatEndDate=formatDate(endDate)
    return checkDate >= formatStartDate && checkDate <= formatEndDate;
}

export const formatDate=(inputDate)=>{
    let date = new Date(inputDate);
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based, so we add 1 and pad to 2 digits.
    let day = date.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
}

export const getResourceIdentifier =(tenant,configuration)=>{
    if(configuration) return tenant+'-'+configuration
    else return tenant+'-'+GLOBAL_CONFIGS
}

export const checkItemInList=(totalList,itemName)=>{
    const index = totalList.findIndex(element => {
        return element.toLowerCase() === itemName.toLowerCase();
    });
    return index!==-1
}

export const currentDiffRowFromTop=(inputString)=>{
    let match = inputString.match(/\d+$/);

    if (match) {
        return parseInt(match[0], 10);

    } else {
       return 0;
    }

}

export const paddingLeftDiffRow=(level,isNestedJson)=>{
    if(!isNestedJson) return `${level * 26 + 23}px`
    return `${level * 26 + 14}px`
}

export const isValidJSONObject = (str) => {
    try {
        const parsed = JSON.parse(str);

        return parsed !== null && typeof parsed === 'object';
    } catch (error) {
        return false;
    }
}

export const hasRequiredAccess=(TenantsList,selectedTenant)=>{
    if(!selectedTenant || !TenantsList){
        return false;
    }
    return TenantsList.includes(selectedTenant);
}