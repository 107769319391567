import React from 'react';
import { TextField, Box, InputAdornment } from '@mui/material';

const SearchField = (props) => {
    const [ query, setQuery ] = React.useState('');
    const { startIcon, endIcon, variantStyle, placeholder, handleSearchQuery  } = props;

    const variants = {
        actionBar: {
            backgroundColor: 'white',
            color: 'black',
            border: '1px solid black',
            padding: 0,
            width: '100%',
            height: '100%' 
        },
        menuItem: {
            backgroundColor: '#EAEAEA',
            color: 'black',
            border: '1px solid grey',
            padding: 0,
            width: '100%',
            height: '100%',
            borderRadius: '15px'
        }
    }
    
    const handleQuery = (e) => {
        setQuery(e.target.value)
        handleSearchQuery(e.target.value)
    };

    const handleKeyDown = (e) => {
        e.stopPropagation()
    };
    
    return <Box aria-label='search-field-box' component="form" sx={{'& > :not(style': { m: 1 }}} data-testid="search-box-field-container">
            <TextField
                aria-label='search-field-text'
                style={ variants[ variantStyle ] }
                onChange={ (e) => handleQuery(e) } 
                id="search-box-field" 
                data-testid="search-box-field"
                placeholder={ placeholder }
                value={ query }
                onKeyDown={(e) => handleKeyDown(e)}
                size="small"
                InputProps={{
                    startAdornment: Boolean(startIcon) ? <InputAdornment aria-label='start-icon' data-testid="start-icon" position="start"> { startIcon } </InputAdornment> : null,
                    endAdornment: Boolean(endIcon) ? <InputAdornment data-testid="end-icon" position="end"> { endIcon } </InputAdornment> : null,
                    inputProps: {
                        style: { 
                            borderLeft: Boolean(startIcon) && variantStyle !== 'menuItem' ? '1px solid grey' : null,
                            borderRight: Boolean(endIcon) ? '1px solid grey' : null, 
                            paddingLeft: '5px',
                            paddingRight: '5px'
                        }
                    }
                }}
            />
    </Box>
};

export default SearchField;