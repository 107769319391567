export const CANCEL_REVIEW='CANCEL_REVIEW'
export const APPROVED='APPROVED'
export const DENIED='DENIED'
export const MERGED='MERGED'
export const CANCELLED='CANCELLED'
export const PENDING='PENDING'
export const CURRENT='CURRENT'

//versionInfoStatus
export const Approved='Approved'
export const Rejected='Rejected'
export const Cancelled='Cancelled'
export const Pending='Pending'
export const Edited='Edited'
export const Current='Current'


export const ACTIVE='ACTIVE'
export const CLOSED='CLOSED'
export const ALL='ALL'

export const CREATE_CONFIGURATION='Create Configuration'
export const CREATE_CONFIG='createConfiguration'
export const DIFFERENCES='Differences'
export const PREVIEW_CHANGES='Preview Changes'

export const WARNING_SEVERITY='warning'
export const ERROR_SEVERITY="error"
export const SUCCESS_SEVERITY="success"
export const INFO_SEVERITY='info'

//Toast Message
export const UPDATED_CONFIG='Configuration Update Successful'
export const CREATED_REVIEW='Review Submission Successful'
export const APPROVED_REVIEW='Review Approved'
export const REJECTED_REVIEW='Review Rejected'
export const CANCELLED_REVIEW='Review Cancelled'
export const TENANT_UPDATED='Successfuly Created Tenant '
export const UPLOAD_CONFIG='Upload Configuration Successful'

export const FAILED_UPDATE_CONFIG='Configuration Update Failed'
export const FAILED_APPROVED_REVIEW='Review Approval Failed'
export const FAILED_REJECTED_REVIEW='Review Rejected Failed'
export const FAILED_CANCELLED_REVIEW='Review Cancelled Failed'
export const FAILED_CREATE_TENANT='Tenant Creation Failed'
export const FAILED_UPLOAD_CONFIG='Upload Configuration Failed'

//responseType
export const SUBMIT_CONFIG='Submit Configuration'
export const APPROVE_CONFIG='Approve'
export const REJECT_REVIEW='Reject'
export const DISCARD_CONFIG='Discard'
export const CREATE_TENANT='Create Tenant'

//response Status
export const SUCCESS='SUCCESS'
export const STATUS_SUCCESS='Success'
export const REVIEW_NOT_REQUIRED='FAILED-ResourceDoesNotRequireReview'
export const PENDING_REVIEW_STATUS="Failed creation due to reviewStatus issue when creating a review. Review status is: SUCCESS"
export const REJECT_REVIEW_CONFIG_STATUS="Failed creation because reviewStatus is not Merged"


export const statusColor={
    Pending: {color:'#FF9900'},
    Approved:{color:'#1F7A1F'},
    Rejected:{color:'#E60000'},
    MERGED:{color:'#00008B'},
    Cancelled:{color:'black'},
    Edited:{color:'#0070F0'}
}

export const diffBorderColor={
    Pending: '1px solid #FF9900',
    Approved:'1px solid green',
    Rejected:'1px solid #E60000',
    Cancelled:'1px solid black',
    Current:'1px solid #E6E6E6',
    Edited:'1px solid #0070F0'
}
export const statusStyle={
    Pending: {color:'#FF9900',fontSize:'12px',fontStyle:'Italic',lineHeight:'20.02px',pt:'1.8px'},
    Rejected:{color:'#E60000',fontSize: '12px',pt:'1.8px'},
    Approved:{color:'green',fontSize: '12px',pt:'1.8px'},
    Cancelled:{fontSize:'12px',pt:'1.8px'}
}

export const reviewStatus={
    MERGED:'Approved',
    DENIED:'Rejected',
    CANCELLED:'Cancelled',
    PENDING:'Pending'
}

export const REVIEW_DESCRIPTION_ERROR='The description length should not exceed 128 characters'