import React, {useCallback, useEffect, useState} from 'react';
import SideBar from "../components/app-layout/SideBar";
import {Grid} from "@mui/material";
import DifferencesPanel from "../components/Differences/DifferencesPanel";
import DifferencesLeftDisplay from "../components/Differences/DifferencesLeftDisplay";
import DifferencesRightDisplay from "../components/Differences/DifferencesRightDisplay";
import {getConfigurations} from "../api/services";
import {DIFFERENCES_PAGE, GLOBAL_CONFIGS, NO_VERSIONS_AVAILABLE, PREVIEW_CHANGES_PAGE} from "../components/constants";
import {
    convertTimeZone,
    editedDateStatus,
    getDiffRowItems, getObjectKeysList, keyExists,
    latestVersionDateStatus, mergeLists, nestedObj, sizeOfProp
} from "../components/common/SharedFunctions";
import {updatedDiff} from "deep-object-diff";
import {ADDED, CHANGED, DELETED, UNCHANGED} from "../components/constants/DifferencesConstants";


const VersionDifferences=(props)=>{
    const {handleDifferencePage, onTenantClosed,selectedTenant,selectedConfiguration,differencesPageType,closePreviewChangesPage,setOpenTenant,
        previewCurrentVersion,previewEditedVersion,pendingVersionJsonBody,setIsDifferencePageSelected,setIsReviewCardSelected,
        user,setCreateVersionStatus,openTenant,isTenantPageOpened,setResponseType,
        setIsTenantConfigMap, setIsEditableTenant, setIsEditableConfig, setIsConfigConfigMap}=props
    const [leftDisplayVersion,setLeftDisplayVersion]=useState({})
    const [totalVersions,setTotalVersions]=useState('')
    const [versionList,setVersionList]=useState([])
    const [selectedVersion,setSelectedVersion]=useState('Current')
    const [rightDisplayVersion,setRightDisplayVersion]=useState({})
    const [prevVersion,setPrevVersion]=useState(null)
    const [currentVersion,setCurrentVersion]=useState(null)
    const[metaData,setMetaData]=useState({})
    const[parentConfig,setParentConfig]=useState('')
    const [configUser,setConfigUser]=useState('')
    const [latestVersionInfo,setLatestVersionInfo]=useState({date:'',status:'',version:''})
    const [uniqueRowKey,setUniqueRowKey]=useState(0)
    const [allRows,setAllRows]=useState([])

    useEffect(() => {
        if(differencesPageType===DIFFERENCES_PAGE){
            fetchConfigsData(selectedTenant)
        }
        else {
            setLeftDisplayVersion(previewEditedVersion)
            setRightDisplayVersion(previewCurrentVersion)
        }
    }, []);

    async function fetchConfigsData(tenant) {
        let variables = {
            tenant: `${tenant}`,
            inheritFlag: true,
            configList: {configName: selectedConfiguration?selectedConfiguration:GLOBAL_CONFIGS}
        }
        await getConfigurations(setLeftDisplayVersion,variables);
    }

    useEffect(()=>{
        if(differencesPageType===DIFFERENCES_PAGE && leftDisplayVersion && leftDisplayVersion.length>0){
            setCurrentVersion(JSON?.parse(leftDisplayVersion[0].configParams))
            let metaData=JSON?.parse(leftDisplayVersion[0].metadata)
            setMetaData(metaData)
            setLatestVersionInfo(latestVersionDateStatus(metaData?.created))
        }
        else if(differencesPageType===PREVIEW_CHANGES_PAGE && leftDisplayVersion!==undefined && leftDisplayVersion.configsJson!==undefined){
            setCurrentVersion(JSON?.parse(leftDisplayVersion.configsJson))
            setLatestVersionInfo(editedDateStatus())
        }
    },[leftDisplayVersion])

    useEffect(() => {
        if(differencesPageType ===DIFFERENCES_PAGE) {
            let versionCount=totalVersions?.slice(1, totalVersions.length)-1
            if(versionCount === 0){
                versionCount=1
            }
            if(versionCount===-1){
                setSelectedVersion('No Versions Available')
            }
            else {
                setSelectedVersion('V'+(versionCount).toString())
            }
        }
    }, [totalVersions]);

    useEffect(() => {
        if(differencesPageType===DIFFERENCES_PAGE){
            fetchRightConfigsData(selectedTenant)
        }
    }, [selectedVersion]);

    async function fetchRightConfigsData(tenant) {
        let versionCount=selectedVersion?.slice(1, selectedVersion.length)
        if(versionCount === 0){
            versionCount=1
        }
        let inheritFlag=false
        if(selectedConfiguration) inheritFlag=true
        let variables = {
            tenant: `${tenant}`,
            inheritFlag: `${inheritFlag}`,
            configList: {configName: selectedConfiguration?selectedConfiguration:GLOBAL_CONFIGS,version: `${versionCount}`}
        }
        await getConfigurations(setRightDisplayVersion,variables);
    }

    useEffect(()=>{
        if(differencesPageType===DIFFERENCES_PAGE && rightDisplayVersion && rightDisplayVersion.length>0){
            setPrevVersion(JSON?.parse(rightDisplayVersion[0].configParams))
            setParentConfig(rightDisplayVersion[0]?.parentConfig)
            if(leftDisplayVersion?.length>0) setConfigUser(leftDisplayVersion[0].user)
        }
        if(differencesPageType===PREVIEW_CHANGES_PAGE && rightDisplayVersion && rightDisplayVersion.configParams!==undefined){
            setPrevVersion(JSON?.parse(rightDisplayVersion?.configParams))
            setTotalVersions(rightDisplayVersion["versionDescription"] != null ? rightDisplayVersion["versionDescription"] : null)
            setSelectedVersion( rightDisplayVersion["versionDescription"] != null ? rightDisplayVersion["versionDescription"] : null)
            setParentConfig(rightDisplayVersion?.parentConfig)
            setConfigUser(user)
            if(rightDisplayVersion.metadata!==undefined){
                setMetaData(JSON?.parse(rightDisplayVersion.metadata))
            }
        }

    },[rightDisplayVersion])

    useEffect(()=>{

        if(differencesPageType===PREVIEW_CHANGES_PAGE){
            let resultRows=[]
            resultRows=diffJsonDisplay(currentVersion,metaData.key_sources)
            setAllRows(resultRows)
        }
        else if(differencesPageType===DIFFERENCES_PAGE ){
            if(prevVersion!==null){
                let resultRows=[]
                resultRows=diffJsonDisplay(currentVersion,metaData.key_sources)
                setAllRows(resultRows)
            }

        }
    },[prevVersion,currentVersion,metaData])

    useEffect(()=>{
        if(differencesPageType===DIFFERENCES_PAGE && leftDisplayVersion && leftDisplayVersion.length>0){
            setTotalVersions(leftDisplayVersion[0].versionDescription)
        }
    },[leftDisplayVersion])

    const handleVersion=(version)=>{
        setSelectedVersion(version)
    }
    useEffect(()=>{
        let currentVersionList=[]
        let totalversioncount=parseInt(totalVersions?.slice(1, totalVersions.length))
        if(totalVersions){
            Array.from({length:totalversioncount},(_,index)=>{
                let versionNumber=index+1
                currentVersionList.push('V'+versionNumber.toString())
            })
            currentVersionList.reverse();
        }
        else {
            currentVersionList.push(NO_VERSIONS_AVAILABLE)
        }
        setVersionList(currentVersionList)
    },[totalVersions])

    const changedRows = updatedDiff(prevVersion, currentVersion)

    const eachDiffRow=useCallback((key,value,isNestedJson,parentKey,level,isDeleting,keyMetaData)=>{
        if(isDeleting){
            if(selectedConfiguration && (keyMetaData==='GLOBAL' || keyMetaData==='PARENT' || (typeof keyMetaData==='object' && (keyMetaData[key]==='PARENT' || keyMetaData[key]==='GLOBAL'))) ){
                return getDiffRowItems(key,value,isNestedJson,parentKey,level,keyMetaData,UNCHANGED)
            }
            return getDiffRowItems(key,value,isNestedJson,parentKey,level,keyMetaData,DELETED)
        }
        else if( !keyExists(prevVersion,parentKey)){
            return getDiffRowItems(key,value,isNestedJson,parentKey,level,keyMetaData,ADDED)
        }
        else if(!isNestedJson &&keyExists(changedRows,parentKey)){
            return getDiffRowItems(key,value,isNestedJson,parentKey,level,keyMetaData,CHANGED)
        }
        else {
            return getDiffRowItems(key,value,isNestedJson,parentKey,level,keyMetaData,UNCHANGED)
        }
    },[selectedConfiguration,changedRows,prevVersion])

    const diffJsonDisplay=useCallback(( data ,metaData) =>{
        const differences = [];
        function displayKeyValue(key,value,parentKey,level,isDeleting,keyMetaData) {
            const currentKey=parentKey.length>0 ?[...parentKey,key]:[key]
            let nestedItems=[]
            if (Array.isArray(value) ) {
                value.forEach((item, index) => {
                    if(sizeOfProp(item)) {
                        nestedItems.push(eachDiffRow(index,sizeOfProp(item),true,currentKey,level+1,isDeleting,keyMetaData))
                    }
                    const nestedDifferences = displayKeyValue(index,item,currentKey,level+1,isDeleting,keyMetaData)
                    nestedItems.push(...nestedDifferences)
                })
                return nestedItems;
            }
            else if (typeof value === 'object' && value !== null) {
                const currentObjKeys = Object.keys(value);
                let prevObjKeys=Object.keys(nestedObj(prevVersion,currentKey))
                const combinedObjKeys=mergeLists(prevObjKeys,currentObjKeys)
                if(selectedConfiguration && keyExists(metaData,currentKey)){
                    keyMetaData=nestedObj(metaData,currentKey)
                }
                combinedObjKeys.forEach((key)=>{
                    if(!(currentObjKeys.includes(key))){
                        let prevValue=nestedObj(prevVersion,[...currentKey,key])
                        if(sizeOfProp(prevValue)){
                            nestedItems.push(eachDiffRow(key,sizeOfProp(prevValue),true,currentKey,level+1,true,keyMetaData))
                        }
                        const nestedDifferences=displayKeyValue(key,prevValue,currentKey,level+1,true,keyMetaData)
                        nestedItems.push(...nestedDifferences)
                    }
                    else {
                        if(sizeOfProp(value[key])){
                            nestedItems.push(eachDiffRow(key,sizeOfProp(value[key]),true,[...currentKey,key],level+1,isDeleting,keyMetaData))
                        }
                        const nestedDifferences=displayKeyValue(key,value[key],currentKey,level+1,isDeleting,keyMetaData)
                        nestedItems.push(...nestedDifferences)
                    }
                })
                return nestedItems;
            }
            if(selectedConfiguration && keyExists(metaData,currentKey)){
                keyMetaData=nestedObj(metaData,currentKey)}
            nestedItems.push(eachDiffRow(key,value,false,currentKey,level,isDeleting,keyMetaData))
            return nestedItems;
        }

        let i=0;

        const json1Keys=getObjectKeysList(prevVersion)
        function displayDeletingKeyValue(i,json1Keys,json1Value,keyMetaData){
            if(sizeOfProp(json1Value)){
                differences.push(eachDiffRow(json1Keys[i],sizeOfProp(json1Value),true,[],0,true,keyMetaData))
            }
            const nestedDifferences=displayKeyValue(json1Keys[i], json1Value, [], 0,true,keyMetaData)
            differences.push(...nestedDifferences)
        }
        for (const key in data) {
            const value = data[key];
            let keyMetaData='LOCAL'
            if(selectedConfiguration && metaData!==undefined && metaData?.hasOwnProperty(key)){
                keyMetaData=metaData[key];
            }
            while (i<json1Keys.length && !(json1Keys[i] in data)) {
                const json1Value=prevVersion[json1Keys[i]]
                if(selectedConfiguration && metaData!==undefined && metaData?.hasOwnProperty(json1Keys[i])){
                    keyMetaData=metaData[json1Keys[i]];
                }
                displayDeletingKeyValue(i,json1Keys,json1Value,keyMetaData)
                i++;
            }
            if(sizeOfProp(value)) {
                differences.push(eachDiffRow(key, sizeOfProp(value), true, [key]  , 0, false,keyMetaData))
            }

            const nestedDifferences=displayKeyValue(key, value, [], 0,false,keyMetaData)
            differences.push(...nestedDifferences)
            i++;
        }
        while (i<json1Keys.length && data && !(json1Keys[i] in data)) {
            const json1Value=prevVersion[json1Keys[i]]
            let keyMetaData='LOCAL'
            if(selectedConfiguration && metaData!==undefined && metaData?.hasOwnProperty(json1Keys[i])){
                keyMetaData=metaData[json1Keys[i]];
            }
            displayDeletingKeyValue(i,json1Keys,json1Value,keyMetaData)
            i++;
        }
        return differences;
    },[prevVersion,metaData])

    return(
        <Grid container={true} direction='row'
              sx={{width:'1440px'}}
        >
            <SideBar
                differencesPageType={differencesPageType}
                onTenantClosed={onTenantClosed}
                selectedTenant={selectedTenant}
            />
            <Grid container direction='column' sx={{width:'1330px',marginLeft:'32px',mt:'27px'}}>
                <DifferencesPanel
                    previewCurrentVersion={previewCurrentVersion}
                    handleDifferencePage={handleDifferencePage}
                    selectedTenant={selectedTenant}
                    selectedConfiguration={selectedConfiguration}
                    differencesPageType={differencesPageType}
                    closePreviewChangesPage={closePreviewChangesPage}
                    pendingVersionJsonBody={pendingVersionJsonBody}
                    setIsReviewCardSelected={setIsReviewCardSelected}
                    setIsDifferencePageSelected={setIsDifferencePageSelected}
                    openTenant={openTenant}
                    user={user}
                    configUser={configUser}
                    parentConfig={parentConfig}
                    setCreateVersionStatus={setCreateVersionStatus}
                    setOpenTenant={setOpenTenant}
                    isTenantPageOpened={isTenantPageOpened}
                    setResponseType={setResponseType}
                    setIsTenantConfigMap = {setIsTenantConfigMap}
                    setIsEditableTenant = {setIsEditableTenant}
                    setIsEditableConfig ={setIsEditableConfig}
                    setIsConfigConfigMap = {setIsConfigConfigMap}
                />
                <Grid container direction='row' gap={'32px'} >
                    <DifferencesRightDisplay
                        currentVersion={currentVersion}
                        prevVersion={prevVersion}
                        selectedTenant={selectedTenant}
                        selectedConfiguration={selectedConfiguration}
                        versionList={versionList}
                        handleVersion={handleVersion}
                        selectedVersion={selectedVersion}
                        differencesPageType={differencesPageType}
                        metaData={metaData}
                        rightDisplayVersion={rightDisplayVersion}
                        isCurrentVersion={false}
                        uniqueRowKey={uniqueRowKey}
                        allRows={allRows}
                    />
                    <DifferencesLeftDisplay
                        currentVersion={currentVersion}
                        prevVersion={prevVersion}
                        selectedTenant={selectedTenant}
                        selectedConfiguration={selectedConfiguration}
                        differencesPageType={differencesPageType}
                        metaData={metaData}
                        isCurrentVersion={true}
                        versionInfo={latestVersionInfo}
                        setUniqueRowKey={setUniqueRowKey}
                        uniqueRowKey={uniqueRowKey}
                        allRows={allRows}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default VersionDifferences;