import React from "react";
import {Divider, Grid} from "@mui/material";

const dividerStyle={width:'1px',height:'40px',border:'1px solid #B3D6FF'}

const CardData=(props)=>{
    const {cardData}=props

    return(
        <Grid container  item justifyContent='center' marginY='14px'  sx={{gap:'16px',textAlign:'center'}}>
            {cardData?.map((eachItem,index)=>{
                let dataName=Object.keys(eachItem)[0]
                return <React.Fragment key={index}>
                            <Grid item >
                                <div className='card__data_count'>{eachItem[dataName]}</div>
                                <div className='card_data'>{dataName}</div>
                            </Grid>
                            {index!==cardData.length-1&&<Divider orientation="vertical"  sx={dividerStyle} />}
                        </React.Fragment>
            })}
        </Grid>
    )
}
export default CardData;

