import React from "react";
import {Button, ButtonGroup, Typography} from "@mui/material";

const GroupedButtons=(props)=>{
    const {toggle,handleTenants,handleConfigurations}=props

    return(
        <ButtonGroup
            disableElevation
            sx={{width:'240px',height:'30px'}}
        >
            <Button onClick={handleTenants} variant={toggle?"contained":"outlined"} sx={{height:'30px',width:'111px',border:'1px solid #0070F0',borderRadius:'4px',textTransform: "capitalize"}}>
                <Typography variant='body2' sx={{color:toggle?'#FFF':'#D9D9D9',fontSize:'14px',lineHeight:'18px',letterSpacing:'0.16px'}}>Tenants</Typography>
            </Button>
            <Button  onClick={handleConfigurations} variant={toggle?"outlined":"contained"} sx={{width:'129px',height:'30px',border:'1px solid #0070F0',borderRadius:'4px',textTransform: "capitalize"}}>
                <Typography variant='body2' sx={{color:toggle?'#D9D9D9':'#FFF',fontSize:'14px',lineHeight:'18px',letterSpacing:'0.16px'}}>Configurations</Typography>
            </Button>
        </ButtonGroup>
    )
}
export default GroupedButtons;