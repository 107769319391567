import React, {useEffect, useState} from 'react';
import {Box, Grid, IconButton, MenuItem, Stack, Table, TableBody, TableRow, TextField, Typography} from "@mui/material";
import './configTableRow.css';
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {InheritedIcon, LocalIcon, OverrideIcon} from "../LegendIcons";
import {Input} from "reactstrap";
import ConfigTableKeyValueType from "./ConfigTableKeyValueType";
import Delete from "../../assets/Delete.png";
import Undo from "../../assets/undo-button.png";
import Redo from "../../assets/redo-button.png";
import RemoveOverrideImg from '../../assets/Override Icon.png';


const ConfigTableKeyValue = (props) => {
    const { k, v, path, isEditable, updateKey, updateValue, updateType, getOverridenValue, onDeleteRow, undoEverything } = props;
    const [focused, setFocused] = React.useState(false)
    const [isHover, setHover] = useState(false);
    const [keyField, setKeyField] = useState(v[0]);
    const [valueField, setValueField] = useState(v[1]);
    const [isDeleted, setDeleted] = useState(v[4][0] === 0? false : true);
    const [isRowEdited, setIsRowEdited] = useState(false);
    const [isUndone, setIsUndone] = useState(false);
    const [undoSaved, setUndoSaved] = useState(new Map());
    const [dataType, setDataType] = useState(v[2]);
    const [type, setType] = useState([v[3]])
    const [overriddenValue, setOverriddenValue] = useState(getOverridenValue(path))

    const onClick = () => {
        setFocused(!focused)
    }

    const onUndoFunction = () => {
        let setUndoMap = new Map()
        setUndoMap.set("key", keyField)
        setUndoMap.set("value", valueField)
        setUndoMap.set("dataType", dataType)
        setUndoMap.set("type", type)
        setUndoMap.set("deleteFlag", isDeleted)
        setUndoSaved(setUndoMap)
        undoEverything(path, setKeyField, setValueField, setDataType, setType)
        setDeleted(false)
        setIsUndone(true)
        setIsRowEdited(false)
    }

    const onRedoFunction = () => {
        setIsRowEdited(true)
        setValueField(undoSaved.get('value'))
        setKeyField(undoSaved.get('key'))
        setDataType(undoSaved.get('dataType'))
        setDeleted(undoSaved.get('deleteFlag'))

        updateKey(path,undoSaved.get('key'))
        updateValue(path,undoSaved.get('value'))
        updateType(path,undoSaved.get('dataType'))
        if(undoSaved.get('deleteFlag')) {
            onDeleteRow(path)
        }
    }

    const onDeleted = () => {
        setDeleted(true)
        onDeleteRow(path)
        setIsRowEdited(true)
    }

    useEffect(() => {
        setKeyField(v[0])
        setValueField(v[1])
        setFocused(false)
        setHover(false)
        setDeleted(v[4][0] === 0? false : true)
        setIsRowEdited(false)
        setIsUndone(false)
        setUndoSaved(new Map())
        setDataType(v[2])
        setType(v[3])
        setOverriddenValue(getOverridenValue(path))
    },[v]);


    const onChangeKeyData = (value) => {
        setKeyField(value)
        updateKey(path, value)
        setIsRowEdited(true)
    }
    const onChangeValueData = (value) => {
        setValueField(value)
        updateValue(path, value)
        setIsRowEdited(true)
    }

    const fetchIcon = (text) => {
        switch(text) {
            case 'LOCAL':
                return <LocalIcon/>;
            case 'LOCAL_OVERRIDE':
                return <OverrideIcon/>;
            case 'GLOBAL' :
                return <InheritedIcon/>;
            case 'PARENT' :
                return <InheritedIcon/>;
            default:
                return <LocalIcon/>;
        }
    };

    const isKeyNotEditable = (text) => {
        if (text === "GLOBAL" || text === "PARENT" || text === 'LOCAL_OVERRIDE') {
            return true
        }
        return false
    };
    const isInherited = (text) => {
        if (text === "GLOBAL" || text === "PARENT" ) {
            return true
        }
        return false
    };

    return(
        <Grid container id={`${path}-table-item`} key={`${path}-table-item`}  justifyContent={"flex-start"} alignItems={"center"}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}>
            <Grid item xs={3} id={`${path}-table-item-key`} key={`${path}-table-item-key`} pl={2}>
                {   isEditable && !props.isArrayRow && !isKeyNotEditable(v[3])?
                        <Input
                            aria-label={`config-table-row-key-${path}`}
                            id={`${path}-table-item-header-3`}
                            key={`${path}-table-item-header-3`}
                            className="textField"
                            value = {keyField}
                            onChange={(e) => onChangeKeyData(e.target.value)}
                            style={{border: isHover ? '1.5px solid #9747FF' : '0.5px solid #979797', textDecoration: isDeleted ?"line-through":"none"}}
                        /> :
                        <Typography p={0}
                                    onClick={onClick}
                                    fontSize={"0.8rem"}
                                    fontWeight={"401"}
                                    fontFamily={'Amazon Ember'}
                                    style={focused ?
                                        {wordWrap: "break-word"} :
                                        {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}}>
                            {v[0]}
                        </Typography>
                }

            </Grid>
            <Grid item container xs={5} id={`${path}-table-item-value`} key={`${path}-table-item-value`}
                  justifyContent={"flex-start"} alignItems={"center"} pl={1}
                direction={"row"}>
               <ConfigTableKeyValueType
                   isEditable = {isEditable}
                   typeValue = {dataType}
                   path = {path}
                   setDataType={setDataType}
                   updateType ={updateType}
                   isEditable = {isEditable && !isInherited(v[3])}
                   setIsRowEdited={setIsRowEdited}
               ></ConfigTableKeyValueType>

                <Grid item xs={7.5} pl={1}>
                    {   isEditable ?
                            <Input
                                aria-label={`config-table-row-value-${path}`}
                                id={`${path}-table-item-header-3`}
                                key={`${path}-table-item-header-3`}
                                className="textField"
                                value = {valueField}
                                onChange={(e) => onChangeValueData(e.target.value)}
                                style={{border: isHover ? '1.5px solid #9747FF' : '0.5px solid #979797' , textDecoration: isDeleted ?"line-through":"none"}}
                            /> :
                            <Typography p={0}
                                        onClick={onClick}
                                        fontSize={"0.8rem"}
                                        fontWeight={"401"}
                                        fontFamily={'Amazon Ember'}
                                        style={focused ?
                                            {wordWrap: "break-word"} :
                                            {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}
                                           }>
                                {v[1]}
                            </Typography>
                    }
                </Grid>
            </Grid>
            <Grid item container xs={4} id={`${path}-table-item-type`} key={`${path}-table-item-type`} justifyContent={"flex-start"} alignItems={"center"}>
                <Grid item xs={1.5} alignItems={"center"}>
                    {   isRowEdited && !v[4][1]?
                            <IconButton aria-label={`config-table-item-undo-${path}`} style={{padding: 0}} key={`${path}-table-item-undo`} fontSize={"small"}
                                        onClick={() => onUndoFunction()}>
                                <img src={Undo} width={"13px"} height={"15px"}/>
                            </IconButton>
                        : null
                    }
                    {   !isRowEdited && isUndone?
                        <IconButton aria-label={`config-table-item-redo-${path}`}  style={{padding: 0}} key={`${path}-table-item-redo`} fontSize={"small"}
                                    onClick={() => onRedoFunction()}>
                            <img src={Redo} width={"13px"} height={"15px"}/>
                        </IconButton>
                        : null
                    }
                </Grid>
                <Grid item xs={1.5} >
                    {
                        isHover && isEditable && v[3]==="LOCAL"?
                            <IconButton  style={{padding:0}} key={`${path}-table-item-delete-button`} onClick={() => onDeleted()}>
                                <img src={Delete} width={"17px"} height={"17px"} />
                            </IconButton>
                            : null
                    }
                    {
                        isHover && isEditable && v[3]==="LOCAL_OVERRIDE"?
                            <IconButton aria-label={`config-table-delete-local-override-object-${path}`}  style={{padding:0}} key={`${path}-table-item-delete-button`} onClick={() => onDeleted()}>
                                <img src={RemoveOverrideImg} width={"17px"} height={"17px"}/>
                            </IconButton>
                            : null
                    }

                </Grid>
                <Grid item xs={2}>
                { fetchIcon(v[3]) }
                </Grid>
                <Grid item xs={6.5}>
                    <Typography p={0}
                                onClick={onClick}
                                color={"green"}
                                fontSize={"0.8rem"}
                                fontWeight={"401"}
                                fontFamily={'Amazon Ember'}
                                style={focused ?
                                    {wordWrap: "break-word"} :
                                    {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}}>
                { v[3] === "LOCAL_OVERRIDE" ? "(Overriden:" + overriddenValue +")": null}
                    </Typography>
                </Grid>
            </Grid>

        </Grid>
    )

}

export default ConfigTableKeyValue;