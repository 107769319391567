export const DIFFERENCES_PAGE='Differences_Page'
export const PREVIEW_CHANGES_PAGE='Preview_Page'
export const REVIEW_DIFFERENCES='Review_Differences'
export const PARENT_CHANGE='Parent Change'
export const SELECTING_REVIEW_DIFF='Selecting_Review_Diff'
export const CREATE_CONFIG_DIFF='Create Configuration'

export const PipStyle={
    "Prod": {
        color:"#9A6632",
        text:"Prod"
    },
    "Test": {
        color: "#8500CC",
        text:"Test"
    },
    "Dev": {
        color:"#CC003D",
        text:"Dev"
    }
};
export const DiffRowColor={
    addedRow:'#EBFAEB',
    deletedRow:'#FFE6E6',
    changedRow:'#F9F2EC'
}
export const metaData={
    "LOCAL":'#000000',
    "GLOBAL":'#4700CC',
    "PARENT":'#4700CC',
    "LOCAL_OVERRIDE":'#9F492D'
}
export const GLOBAL_CONFIGS='global_configs'
export const NO_VERSIONS_AVAILABLE='No versions available'