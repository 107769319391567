import React, { useState, useEffect } from 'react';
import {
    TableRow,
    TextField,
    Stack,
    Box,
    Grid,
    MenuItem, Typography, IconButton, TableCell
} from "@mui/material";
import '../../styles/tableRow.css';
import LocalIcon from "../LegendIcons/legendIcons";
import {Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import Delete from "../../assets/Delete.png";
import Undo from "../../assets/undo-button.png";
import Redo from "../../assets/redo-button.png";


function TenantTableRow(props) {
    const { k, v, isEditable,onTableRowKeyEdit, onTableRowValueEdit, setCreateParamRowType, onDelete, onUndo, onTableRowEditedFlag } = props;
    const [keyField, setKeyField] = useState(v[0]);
    const [valueField, setValueField] = useState(v[1]);
    const [dataType, setDataType] = useState(v[2]);
    const [isDeleted, setIsDeleted] = useState(v[3]);
    const [isHover, setHover] = useState(false);
    const [isEditFlag, setIsEditedFlag] = useState(v[5]);
    let undoValues = new Map();
    undoValues["key"] = v[0]
    undoValues["value"] = v[1]
    undoValues["dataType"] = v[2]
    undoValues["deleted"] = v[3]
    const [undoSaved, setUndoSaved] = useState(undoValues);

    const [focused, setFocused] = React.useState(false)
    const [focusedKeyValue, setFocusedKeyValue] = React.useState(false)
    const [focusedValueValue, setFocusedValueValue] = React.useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const onFocus = () => setFocused(true)
    const onBlur = () => setFocused(false)

    const onFocusTypography = (setFunction, value) => setFunction(!value)
    const onUndoFunction = () => {
        let undoValues = new Map();
        undoValues["key"] = keyField
        undoValues["value"] = valueField
        undoValues["dataType"] = dataType
        undoValues["deleted"] = isDeleted
        setUndoSaved(undoValues)
        onUndo(k, setValueField, setKeyField, setDataType, setIsDeleted, setIsEditedFlag)
    }

    const onRedoFunction = () => {
        setIsEditedFlag(true)
        setValueField(undoSaved['value'])
        setKeyField(undoSaved['key'])
        setDataType(undoSaved['dataType'])
        setIsDeleted(undoSaved["deleted"])

        onTableRowKeyEdit(k, undoSaved['key'])
        onTableRowValueEdit(k, undoSaved['value'])
        setCreateParamRowType(k, undoSaved['dataType'])
        onDelete(k, undoSaved["deleted"])
        onTableRowEditedFlag(k, 1)
    }

    const onChangeValueData = (value) => {
        setValueField(value);
        onTableRowValueEdit(k, value)
        setIsEditedFlag(1)
    }
    const onChangeKeyData = (value) => {
        setKeyField(value);
        onTableRowKeyEdit(k, value)
        setIsEditedFlag(1)
    }

    const onDeleteIconClick = () => {
        setIsDeleted(1);
        onDelete(k, 1)
        setIsEditedFlag(1)
    }

    const handleChangeType = (value) => {
        setDataType(value)
        setCreateParamRowType(k, value)
        setIsEditedFlag(1)
    }

    const getDataTypeDescription = (value) => {
        if (value === 'string') return 'abc';
        if (value === 'boolean') return 'T/F';
        return '123';
    }

    useEffect(() => {
        if (!isEditable) {
            setValueField(v[1])
            setKeyField(v[0])
            setDataType(v[2])
            setHover(false)
            setIsEditedFlag(v[5])
            let undoValues = new Map();
            undoValues["key"] = v[0]
            undoValues["value"] = v[1]
            undoValues["dataType"] = v[2]
            undoValues["deleted"] = v[3]
            setUndoSaved(undoValues);
            setFocused(false)
            setFocusedKeyValue(false)
            setFocusedValueValue(false)
            setIsDeleted(false)
        }
    },[isEditable]);

    function checkValueCorrect() {
        if (focused) {
            if (dataType==="number" && isNaN(valueField)) {
                return  "1.5px solid red"
            } else if (dataType==="boolean" && !(valueField === "true" || valueField === "false")) {
                return  "1.5px solid red"
            }
            return "1.5px solid green"
        }
        return isHover? '1px solid #9747FF' : '0.5px solid #979797'

    }
    
    return (
        <TableRow key ={k +'-data-tableRow'}
                  id={`${k}-table-item`}
                  style = {styles.tableRow}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
        >
            <TableCell style={{padding:0}}>
                <div data-testid={`table-tenant-row-${k}`}>
                <Grid container  alignItems="center"
                      justifyContent="center">
                    <Grid item xs={2.5} pl={2} pr={2}>
                        {   isEditable ?

                            <Input
                                aria-label={`${k}_tenantRow_key`}
                                id={`${k}_tenantRow_key`}
                                className="textField"
                                value={keyField}
                                onChange={(e) => onChangeKeyData(e.target.value)}
                                style ={{border: isHover? '1px solid #9747FF' : '0.5px solid #979797', textDecoration: isDeleted ? "line-through":"none"}}
                            />
                            :
                            <Typography p={0.5}
                                fontSize={"0.8rem"}
                                fontWeight={"401"}
                                fontFamily = {'Amazon Ember'}
                                onClick={() => onFocusTypography(setFocusedKeyValue, focusedKeyValue)}                             // textOverflow={'ellipsis'}
                                style={focusedKeyValue?
                                    { wordWrap: "break-word" } :
                                    { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow:'hidden' }}>
                                {v[0]}
                            </Typography>
                    }
                </Grid>
                {props.isEditable ?
                    <Grid item xs={5.5}>
                        <Grid container pt={0} spacing={0.5} justifyContent={"center"} alignItems={"center"}>
                            <Grid item xs={0.6}>
                                :&ensp;&ensp;
                            </Grid>
                            <Grid item xs ={2.1}
                                id={`${props.k}_tenantRow_stack`} justifyContent={"center"} alignItems={"center"}
                                  style={{maxWidth: "min-content"}}
                            >
                                <Dropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggle}
                                    direction="down"
                                    data-testid={`tenant-table-data-type-${k}`}
                                    id={`${props.k}_tenantRow_type`}
                                    group
                                    style={{fontFamily:'Amazon Ember'}}
                                >
                                    <DropdownToggle
                                        caret
                                        style={{
                                            height: '30px',
                                            backgroundColor: '#979797',
                                            color: 'white', border: '0.5px solid #979797',
                                            borderRadius: '5px',
                                            width: '17px',
                                            padding: '0px',
                                            paddingRight:'17px',
                                            margin: '0px',
                                            textAlign: 'center',
                                            borderTopRightRadius: '0px',
                                            borderBottomRightRadius: '0px',
                                            fontFamily:'Amazon Ember'
                                        }}
                                    >
                                    </DropdownToggle>
                                    <Button
                                        style={{
                                            height: '30px',
                                            backgroundColor: 'white',
                                            color: '#444', border: '0.5px solid #979797',
                                            borderRadius: '5px',
                                            fontSize: '0.8rem',
                                            borderTopLeftRadius: '0px',
                                            borderBottomLeftRadius: '0px',
                                            fontFamily:'Amazon Ember',
                                            width: '50px'
                                        }}
                                        aria-label={`tenant-table-data-type-button-${k}`}
                                        onClick={toggle}
                                    >{getDataTypeDescription(dataType)}</Button>
                                    <DropdownMenu>
                                        <DropdownItem
                                            aria-label={`tenant-table-data-type-string-${k}`}
                                            value="string"
                                            onClick={(e) => handleChangeType(e.target.value)}
                                            style={{
                                                fontWeight: '400',
                                                fontSize: '0.8rem',
                                                paddingTop: '0px',
                                                paddingBottom: '0px',
                                                backgroundColor: 'white',
                                                fontFamily:'Amazon Ember',
                                            }}
                                            key={props.k + "String-datatype"}
                                        >
                                            abc (string)
                                        </DropdownItem>
                                            <DropdownItem divider />
                                        <DropdownItem
                                            aria-label={`tenant-table-data-type-number-${k}`}
                                            value="number"
                                            onClick={(e) => handleChangeType(e.target.value)}
                                            style={{
                                                fontWeight: '400',
                                                fontSize: '0.8rem',
                                                paddingTop: '0px',
                                                paddingBottom: '0px',
                                                backgroundColor: 'white',
                                                fontFamily:'Amazon Ember'
                                            }}
                                            key={props.k + "Number-datatype"}
                                        >
                                            123 (number)
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem
                                            aria-label={`tenant-table-data-type-boolean-${k}`}
                                            value="boolean"
                                            onClick={(e) => handleChangeType(e.target.value)}
                                            style={{
                                                fontWeight: '400',
                                                fontSize: '0.8rem',
                                                paddingTop: '0px',
                                                paddingBottom: '0px',
                                                backgroundColor: 'white',
                                            }}
                                            key={props.k + "Boolean-datatype"}
                                        >
                                            T/F
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Grid>
                            <Grid item xs={9.3}>
                                    <Input
                                        aria-label={`${k}_tenantRow_value`}
                                        id={`${k}_tenantRow_value `}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        className="textField"
                                        value={valueField}
                                        style={{border: checkValueCorrect(), textDecoration: isDeleted ?"line-through":"none"}}
                                        onChange={(e) => onChangeValueData(e.target.value)}
                                    />

                                </Grid>
                            </Grid>
                        </Grid> :
                        <Grid container item xs={5.5} direction={"row"} alignItems={"center"}>
                            <Grid item xs={0.5}>
                                :&ensp;&ensp;
                            </Grid>
                            <Grid item xs={0.8}>
                                <Box backgroundColor={'#D9D9D9'} borderRadius={'5px'}>
                                    <Typography
                                        p={0.5}
                                        fontSize={"0.8rem"}
                                        fontWeight={"400"}
                                        fontFamily={'Amazon Ember'}
                                        textOverflow={'ellipsis'}
                                    >
                                        {v[2] === "number" ? "123" : (v[2] === "string" ? "abc" : "T/F")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={10.7} pl={1}>
                                <Typography
                                    p={0.5}
                                    onClick={() => onFocusTypography(setFocusedValueValue, focusedValueValue)}
                                    fontSize={"0.8rem"}
                                    fontWeight={"401"}
                                    fontFamily={'Amazon Ember'}
                                    style={focusedValueValue?
                                        { wordWrap: "break-word" } :
                                        { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow:'hidden' }}
                                >{v[1]}</Typography>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={4} >
                        { isEditable ?
                            (
                                <Grid container flex={"flex-start"} alignItems={"center"} spacing={1} paddingLeft={2}>
                                    <Grid item xs={1.5}>
                                        {
                                            isEditFlag==1 && !v[4] ?
                                                <IconButton aria-label={`undo-tenant-table-row-${k}`} style={styles.updateButtons} key={v[0] + '-data-tableCell5'} fontSize={"small"}
                                                             onClick={() => onUndoFunction()}>
                                                    <img src={Undo} width={"13px"} height={"15px"} />
                                                </IconButton>
                                                : null
                                        }
                                        {
                                            isEditFlag==2 ?
                                                <IconButton aria-label={`redo-tenant-table-row-${k}`} style={styles.updateButtons} key={v[0] + '-data-tableCellRedo'} fontSize={"small"}
                                                             onClick={() => onRedoFunction()}>
                                                    <img src={Redo} width={"13px"} height={"15px"} />
                                                </IconButton>
                                                : null
                                        }
                                    </Grid>
                                    <Grid item xs={1.5} alignItems={"center"}>
                                        {
                                            isHover?
                                                <IconButton aria-label={`delete-tenant-table-row-${k}`} style={styles.updateButtons} key={v[0] + '-data-tableCell6'}
                                                             onClick={() => onDeleteIconClick()}>
                                                    <img src={Delete} width={"15px"} height={"15px"} />
                                                </IconButton>
                                                : null
                                        }

                                    </Grid>
                                    <Grid item xs={1.5} justifyContent={"center"}>
                                        <LocalIcon key={v[0] + '-data-tableCell4'}/>
                                    </Grid>
                                </Grid>
                            ) :
                            <Grid container flex={"flex-start"} alignItems={"center"} spacing={1} paddingLeft={2}>
                                <Grid item xs={1.5}>
                                </Grid>
                                <Grid item xs={1.5}>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <LocalIcon key={v[0] + '-data-tableCell4'}/>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
        </div>
            </TableCell>
        </TableRow>
    )
}
const styles = {
    button:{
        background: '#FFFFFF',
        border: '1px solid #ECECEC',
        borderRadius: '20px',
        fontSize: '12px',
        color: '#000000',
        textAlign: 'center',
        lineHeight: '20px',
        textTransform: 'none',
        fontWeight: 'normal'
    },
    tableRow: {
        backgroundColor: '#E5E5E5'
    },
    updateButtons:{
        padding: 0,
    }
}
export default TenantTableRow;