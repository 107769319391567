import React, {useState, useEffect, useRef} from 'react';
import {
    TableRow,
    TextField,
    Box,
    Grid,
    MenuItem, Typography, IconButton, TableCell
} from "@mui/material";
import '../../../../styles/tableRow.css';
import styles from '../../../../styles/TableStyles.module.css'
import Delete from "../../../../assets/Delete.png";
import Undo from "../../../../assets/undo-button.png";
import Redo from "../../../../assets/redo-button.png";
import {BOOLEAN, FALSE, NUMBER, TRUE} from "../../../constants/TableConstants";


function TenantTableRow(props) {
    const { index, row, isEditable,onTableRowKeyEdit, onTableRowValueEdit, setCreateParamRowType, onDelete, onUndo, onTableRowEditedFlag } = props;
    const [keyField, setKeyField] = useState(row[0]);
    const [valueField, setValueField] = useState(row[1]);
    const [dataType, setDataType] = useState(row[2]);
    const [editedDataType, setEditedDatatype] = useState(null);
    const [isDeleted, setIsDeleted] = useState(row[3]);
    const [isHover, setHover] = useState(false);
    const [isEditFlag, setIsEditedFlag] = useState(row[5]);
    const inputReference = useRef(null);

    let undoValues = new Map();
    undoValues["key"] = row[0]
    undoValues["value"] = row[1]
    undoValues["dataType"] = row[2]
    undoValues["deleted"] = row[3]
    const [undoSaved, setUndoSaved] = useState(undoValues);

    const [focused, setFocused] = React.useState(false)
    const [focusedKeyValue, setFocusedKeyValue] = React.useState(false)
    const [focusedValueValue, setFocusedValueValue] = React.useState(false)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dataTypeMismatch, setDataTypeMismatch] = useState(false);

    const onFocusTypography = (setFunction, value) => setFunction(!value)
    const onUndoFunction = () => {
        let undoValues = new Map();
        undoValues["key"] = keyField
        undoValues["value"] = valueField
        undoValues["dataType"] = dataType
        undoValues["deleted"] = isDeleted
        setUndoSaved(undoValues)
        onUndo(index, setValueField, setKeyField, setDataType, setIsDeleted, setIsEditedFlag)
    }

    const onRedoFunction = () => {
        setIsEditedFlag(true)
        setValueField(undoSaved['value'])
        setKeyField(undoSaved['key'])
        setDataType(undoSaved['dataType'])
        setIsDeleted(undoSaved["deleted"])

        onTableRowKeyEdit(index, undoSaved['key'])
        onTableRowValueEdit(index, undoSaved['value'])
        setCreateParamRowType(index, undoSaved['dataType'])
        onDelete(index, undoSaved["deleted"])
        onTableRowEditedFlag(index, 1)
    }

    const onChangeValueData = (value) => {
        setValueField(value);
        onTableRowValueEdit(index, value)
        setIsEditedFlag(1)
    }
    const onChangeKeyData = (value) => {
        setKeyField(value);
        onTableRowKeyEdit(index, value)
        setIsEditedFlag(1)
    }

    const onDeleteIconClick = () => {
        setIsDeleted(1);
        onDelete(index, 1)
        setIsEditedFlag(1)
    }

    const handleChangeType = (value) => {
        setDataType(value)
        setCreateParamRowType(index, value)
        setIsEditedFlag(1)
    }

    const getDataTypeDescription = (value) => {
        if (value === 'string') return 'abc';
        if (value === 'boolean') return 'T/F';
        return '123';
    }
    useEffect(() => {
        setEditedDatatype(dataType);
    },[dataType])

    useEffect(() => {
        if (dataType === NUMBER && !Number(valueField)) {
            setDataTypeMismatch(true);
        } else if (dataType === BOOLEAN && valueField !== TRUE && valueField !== FALSE) {
            setDataTypeMismatch(true);
        } else {
            setDataTypeMismatch(false);
        }
    },[valueField, dataType])

    useEffect(() => {
        if (!isEditable) {
            setValueField(row[1])
            setKeyField(row[0])
            setDataType(row[2])
            setHover(false)
            setIsEditedFlag(row[5])
            let undoValues = new Map();
            undoValues["key"] = row[0]
            undoValues["value"] = row[1]
            undoValues["dataType"] = row[2]
            undoValues["deleted"] = row[3]
            setUndoSaved(undoValues);
            setFocused(false)
            setFocusedKeyValue(false)
            setFocusedValueValue(false)
            setIsDeleted(false)
            setDataTypeMismatch(false)
        }
    },[isEditable]);

    useEffect(() => {
        if(inputReference && inputReference.current && keyField==='') inputReference.current.focus();
    }, [isEditable]);


    return (
        <TableRow key ={index +'-data-tableRow'}
                  id={`${index}-table-item`}
                  className = {styles.tableRow}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
        >
            <TableCell style={{height: '48px',padding:0,borderBottom:'1px solid #EFEFEF'}}>
                <div data-testid={`table-tenant-row-${index}`}>
                    <Grid container  alignItems="center"
                          justifyContent="center">
                        <Grid item xs={4.5} pl={2} pr={2}>
                            {   isEditable ?
                                <TextField
                                    fullWidth
                                    size="small"
                                    inputRef={inputReference}
                                    aria-label={`${index}_tenantRow_key`}
                                    id={`${index}_tenantRow_key`}
                                    value={keyField}
                                    style={{textDecoration: isDeleted ?"line-through":"none"}}
                                    onChange={(e) => onChangeKeyData(e.target.value)}>
                                </TextField>
                                :
                                <Typography p={1}
                                            fontSize={"16px"}
                                            fontWeight={"401"}
                                            fontFamily = {'Amazon Ember'}
                                            onClick={() => onFocusTypography(setFocusedKeyValue, focusedKeyValue)}
                                            style={focusedKeyValue?
                                                { wordWrap: "break-word" } :
                                                { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow:'hidden' }}>
                                    {row[0]}
                                </Typography>
                            }
                        </Grid>
                        {props.isEditable ?
                            <Grid item xs={5}>
                                <Grid container pt={0} spacing={0.5} justifyContent={"left"} alignItems={"center"}>
                                    <Grid item xs={0.5}>
                                        :&ensp;
                                    </Grid>
                                    <Grid item xs={2.5}
                                          id={`${props.k}_tenantRow_stack`} justifyContent={"center"} alignItems={"center"}
                                    >
                                        <TextField
                                            fullWidth
                                            className={styles.dropdown}
                                            size="small"
                                            select
                                            placeholder={""}
                                            value={editedDataType ? editedDataType : ""}
                                            onChange={(e) => handleChangeType(e.target.value)}
                                        >
                                            <MenuItem className={styles.dropdown} value={"number"}>123</MenuItem>
                                            <MenuItem className={styles.dropdown} value={"string"}>abc</MenuItem>
                                            <MenuItem className={styles.dropdown} value={"boolean"}>T/F</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={9}>
                                        {
                                            editedDataType === BOOLEAN ?
                                                <TextField
                                                    fullWidth
                                                    select
                                                    size="small"
                                                    aria-label={`${index}_tenantRow_value`}
                                                    id={`${index}_tenantRow_value `}
                                                    error={dataTypeMismatch}
                                                    className={!(editedDataType && editedDataType !== "") ? `${styles.textfield__disabled} "textField"`: "textField"}
                                                    value={valueField === TRUE || valueField === FALSE ? valueField : ""}
                                                    disabled={!(editedDataType && editedDataType !== "")}
                                                    style={{textDecoration: isDeleted ?"line-through":"none"}}
                                                    onChange={(e) => onChangeValueData(e.target.value)}
                                                >
                                                    <MenuItem className={styles.dropdown} value={"true"}>true</MenuItem>
                                                    <MenuItem className={styles.dropdown} value={"false"}>false</MenuItem>
                                                </TextField>
                                                :
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    aria-label={`${index}_tenantRow_value`}
                                                    id={`${index}_tenantRow_value `}
                                                    error={dataTypeMismatch}
                                                    className={!(editedDataType && editedDataType !== "") ? `${styles.textfield__disabled} "textField"`: "textField"}
                                                    value={valueField}
                                                    disabled={!(editedDataType && editedDataType !== "")}
                                                    style={{textDecoration: isDeleted ?"line-through":"none"}}
                                                    onChange={(e) => onChangeValueData(e.target.value)}
                                                >

                                                </TextField>
                                        }


                                    </Grid>
                                </Grid>
                            </Grid> :
                            <Grid container item xs={5} direction={"row"} alignItems={"center"}>
                                <Grid item xs={0.5}>
                                    :&ensp;&ensp;
                                </Grid>
                                <Grid item xs={1.2}>
                                    <Box backgroundColor={'#F2F2F2'} borderRadius={'5px'}>
                                        <Typography
                                            p={0.5}
                                            textAlign={"center"}
                                            fontSize={"0.8rem"}
                                            fontWeight={"400"}
                                            fontFamily={'Amazon Ember'}
                                            textOverflow={'ellipsis'}
                                        >
                                            {row[2] === "number" ? "123" : (row[2] === "string" ? "abc" : "T/F")}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10.3} pl={1}>
                                    <Typography
                                        p={0.5}
                                        onClick={() => onFocusTypography(setFocusedValueValue, focusedValueValue)}
                                        fontSize={"16px"}
                                        fontWeight={"401"}
                                        fontFamily={'Amazon Ember'}
                                        style={focusedValueValue?
                                            { wordWrap: "break-word" } :
                                            { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow:'hidden' }}
                                    >{row[1]}</Typography>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={2.5} >
                            { isEditable ?
                                (
                                    <Grid container flex={"flex-start"} alignItems={"center"} spacing={1} paddingLeft={2}>
                                        <Grid item xs={1}>
                                        {
                                            isEditFlag==1 && !row[4] ?
                                                <IconButton aria-label={`undo-tenant-table-row-${index}`} className={styles.updateButtons} key={row[0] + '-data-tableCell5'} fontSize={"small"}
                                                            onClick={() => onUndoFunction()}>
                                                    <img src={Undo} width={"13px"} height={"15px"} />
                                                </IconButton>
                                                : null
                                        }
                                        {
                                            isEditFlag==2 ?
                                                <IconButton aria-label={`redo-tenant-table-row-${index}`} className={styles.updateButtons} key={row[0] + '-data-tableCellRedo'} fontSize={"small"}
                                                            onClick={() => onRedoFunction()}>
                                                    <img src={Redo} width={"13px"} height={"15px"} />
                                                </IconButton>
                                                : null
                                        }
                                        </Grid>
                                        <Grid item xs={3.5}>
                                        </Grid>
                                        <Grid item xs={2} justifyContent={"center"}>
                                            <Typography className={styles.local__icon}>L</Typography>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={1.5}>
                                            {
                                                isHover?
                                                    <IconButton aria-label={`delete-tenant-table-row-${index}`} className={styles.updateButtons} key={row[0] + '-data-tableCell6'}
                                                                onClick={() => onDeleteIconClick()}>
                                                        <img src={Delete} width={"15px"} height={"15px"} />
                                                    </IconButton>
                                                    : null
                                            }
                                        </Grid>
                                        <Grid item xs={0.5}></Grid>
                                    </Grid>
                                ) :
                                <Grid container flex={"flex-start"} alignItems={"center"} spacing={1} paddingLeft={2}>
                                    <Grid item xs={4.5}></Grid>
                                    <Grid item xs={2}>
                                        <Typography className={styles.local__icon}>L</Typography>
                                    </Grid>
                                    <Grid item xs={5.5}>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </div>
            </TableCell>
        </TableRow>
    )
}

export default TenantTableRow;