/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createConfigurationInDataStore = /* GraphQL */ `
  mutation CreateConfigurationInDataStore($input: CreateConfigurationRequest!) {
    createConfigurationInDataStore(input: $input) {
      string
      __typename
    }
  }
`;

export const createConfiguration = /* GraphQL */ `
  mutation CreateConfiguration($input: CreateConfigWithReviewInput!) {
    createConfiguration(input: $input) {
      reviewStatus
      status
      __typename
    }
  }
`;

export const createReview = /* GraphQL */ `
  mutation CreateReview($input: CreateReviewInput!) {
    createReview(input: $input) {
      status
      __typename
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant($input: CreateTenantRequest!) {
    createTenant(input: $input) {
      string
      __typename
    }
  }
`;

export const createSystem = /* GraphQL */ `
  mutation CreateSystem($input: CreateSystemInput!) {
    createSystem(input: $input) {
      status
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      status
      __typename
    }
  }
`;
export const addComment = /* GraphQL */ `
  mutation AddComment($input: AddCommentInput!) {
    addComment(input: $input) {
      status
      __typename
    }
  }
`;
export const manageReview = /* GraphQL */ `
  mutation ManageReview($input: ManageReviewRequest!) {
    manageReview(input: $input) {
      status
      __typename
    }
  }
`;
export const manageReviewerReview = /* GraphQL */ `
  mutation ManageReviewerReview($input: ManageReviewerReviewRequest!) {
    manageReviewerReview(input: $input) {
      status
      review {
        reviewId
        reviewStatus
        reviewJson
        system
        timestamp
        requesterId
        resourceIdentifier
        reviewers
        reviewDescription
        __typename
      }
      reviewStatus
      configStatus
      __typename
    }
  }
`;

export const createVersion = /* GraphQL */ `
  mutation CreateVersion($input: CreateVersionWithReviewInput!) {
    createVersion(input: $input) {
      reviewStatus
      status
      __typename
    }
  }
`;
export const createVersionInDataStore = /* GraphQL */ `
  mutation CreateVersionInDataStore($input: CreateConfigurationRequest!) {
    createVersionInDataStore(input: $input) {
      string
      __typename
    }
  }
`;

