import {Box, Grid, MenuItem, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import styles from "../../../../styles/TableStyles.module.css";

const ConfigTableKeyValueType = (props) => {
    const {isEditable, typeValue, path, updateType, setDataType, setIsRowEdited} = props

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleChangeType = (value) => {
        setDataType(value)
        updateType(path, value)
        setIsRowEdited(true)
    }

    const getDataTypeDescription = (value) => {
        if (value === 'string') return 'abc';
        if (value === 'boolean') return 'T/F' ;
        return '123';
    }


    return (
        <>
            {   isEditable?
                <TextField
                    fullWidth
                    className={styles.dropdown}
                    id={`${props.k}_tenantRow_type`}
                    data-testid={`config-table-data-type-${path}`}
                    size="small"
                    select
                    placeholder={""}
                    value={typeValue ?? ""}
                    onChange={(e) => handleChangeType(e.target.value)}
                >
                    <MenuItem className={styles.dropdown} value={"number"}>123</MenuItem>
                    <MenuItem className={styles.dropdown} value={"string"}>abc</MenuItem>
                    <MenuItem className={styles.dropdown} value={"boolean"}>T/F</MenuItem>
                </TextField>
                :
                <>
                <Box backgroundColor={'#F2F2F2'} borderRadius={'5px'} maxWidth={'36px'}>
                    <Typography
                        p={0.5}
                        textAlign={"center"}
                        fontSize={"0.8rem"}
                        fontWeight={"400"}
                        fontFamily={'Amazon Ember'}
                        textOverflow={'ellipsis'}
                    >
                        {typeValue === "number" ? "123" : (typeValue === "string" ? "abc" : "T/F")}
                    </Typography>
                </Box>
                </>
            }
        </>
    )
}
export default ConfigTableKeyValueType;