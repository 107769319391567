import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions
} from "@mui/material";
import styles from "../../styles/CreateConfigModalStyles.module.css";

function SubmitModal(props){

    return(
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-label='modal-submit-modal'
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {
                props.isReview?
                    <DialogTitle id="alert-dialog-title">
                        {"Cancel Review"}
                    </DialogTitle>:
                props.isCancel ?
                    <DialogTitle id="alert-dialog-title">
                        {"Cancel Changes"}
                    </DialogTitle>
                    :
                    <DialogTitle id="alert-dialog-title">
                        {"Submit Changes"}
                    </DialogTitle>
            }
            <DialogContent>
                {props.isReview?
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to cancel Review?
                    </DialogContentText>:
                    props.isCancel ?
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to cancel all changes?
                        </DialogContentText>
                        :
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to submit changes?
                        </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button className={styles.button} onClick={props.handleClose}>No</Button>
                <Button className={styles.button} onClick={props.onSubmit} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SubmitModal;