import { Auth } from '@aws-amplify/auth';
const aws4fetch = require('aws4fetch');

const getCurrentUser = async ()=>  {
    try {
        authConfigure()
        return await Auth.currentAuthenticatedUser();
    } catch {
        Auth.federatedSignIn({customProvider: 'FederateOIDC'})
            .then((credentials) => console.log("SignIn successful"))
            .catch((e) => console.log(e))
        return null;
    }
};

const authConfigure = () => {
    Auth.configure({
        Auth: {
            userPoolId: "us-west-2_0AWJRTHP2",
            userPoolWebClientId: "5c6dl0526kfj6qapf81sbgf7cm",
            identityPoolId: "us-west-2:417c1ab5-b46a-4cb4-bb15-08a509e8b494",
            region: "us-west-2"
        },
        oauth: {
            domain: "configstoreui-test.auth.us-west-2.amazoncognito.com",
            scope: ['openid'],
            responseType: 'code',
            redirectSignIn: window.location.protocol + '//' + window.location.host,
            redirectSignOut: window.location.protocol + '//' + window.location.host,
        }
    });
}
//Implement authentication for Appsync queries
// const authConfigure = (settings) => {
//     Amplify.configure({
//         Auth: {
//             // REQUIRED - Amazon Cognito Identity Pool ID
//             userPoolId: 'us-west-2_0AWJRTHP2',
//             // REQUIRED - Amazon Cognito Region
//             region: 'us-west-2',
//             // OPTIONAL - Amazon Cognito User Pool ID
//             identityPoolId: 'us-west-2:417c1ab5-b46a-4cb4-bb15-08a509e8b494',
//             // OPTIONAL - Amazon Cognito Web Client ID
//             userPoolWebClientId: '5c6dl0526kfj6qapf81sbgf7cm',
//         },
//         API: {
//             'aws_appsync_graphqlEndpoint': 'https://qyiabjqh3bawpbhumby5yzgyji.appsync-api.us-west-2.amazonaws.com/graphql',
//             'aws_appsync_region': 'us-west-2',
//             'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
//         },
//     });
// }

const getAWSClient = async() => {
    const credentials = await Auth.currentCredentials();
    const aws = new aws4fetch.AwsClient({
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken
    });
    return aws;
}

export { getCurrentUser, getAWSClient };