import {Auth} from "@aws-amplify/auth";
const aws4fetch = require('aws4fetch');

export const getTenantConfigs = async(getAWSClient, tenantName, setTenantConfigs) => {
    try {
        // const credentials = await Auth.currentCredentials()
        // const aws = new aws4fetch.AwsClient({
        //     accessKeyId: credentials.accessKeyId,
        //     secretAccessKey: credentials.secretAccessKey,
        //     sessionToken: credentials.sessionToken
        // })
        const aws = await getAWSClient();
        let endPoint = 'prod/tenants/' + tenantName + '/configurations'
        let url = await getUrl() + endPoint;
        const res = await aws.fetch(url,
            {method: 'GET'})
        try {
            res.json()
                .then((json) => {
                        return json.configs;
                    }
                )
                .then((json) => {
                    const configs = json;
                    setTenantConfigs(configs)
                })
                .catch((e) => console.log("getTenantConfigs failed", e));
        } catch (e) {
            console.log("getTenantConfigs failed", e)
        }
    } catch (e) {
        console.log("getTenantConfigs failed", e)
    }
}


const fetchSettings = async () => {
    try {
        const response = await fetch('./settings.json');
        const settingsJson = await response.json();
        return settingsJson
    } catch (e) {
        alert("There was a problem retrieving settings from backend", e);
    }
}

const getUrl = async () => {
    try {
        const settings = await fetchSettings()
        return settings["GFTConfigStoreService"]["APIEndPoint"]
    } catch (e) {
        alert("GetUrl failed")
    }
}
