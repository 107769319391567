import React, {useEffect,useState} from 'react';
import {Grid, Tooltip} from "@mui/material";
import CardTitle from "./CardTitle";
import CardData from "./CardData";
import CardActionPopUp from "./CardActionPopUp";
import {
    CHILD_CONFIG_TYPE,
    DATA_CARD,
    PARENT_CONFIG_TYPE,
    REVIEW_CARD,
    TENANT_CARD
} from "../constants/HomePageConstants";
import {GLOBAL_CONFIGS} from "../constants";


const  TenantCard =(props)=>{
    const {cardTenant,onTenantSelected,cardFunctionality,cardData,handleReviewDifferences,setOpenConfigModal,setOpenUploadModal,openDifferencePage}=props
    const [dataCount,setDataCount]=useState([])

    useEffect(() => {
        if(cardFunctionality===DATA_CARD) {
            let parentCount=0,configCount=0,reviewCount=0

            if(cardData ){
                parentCount=cardData.parentCount
                configCount=cardData.configCount
                reviewCount=cardData.reviewCount
            }
            setDataCount([{'Parents':parentCount}, {'Configs': configCount}, {'Reviews': reviewCount}])
        }
        else if(cardFunctionality===REVIEW_CARD){
            setDataCount([{'Submitter':cardData.Submitter},{'Submission':cardData.Submission}])
        }
    }, [cardData]);

    const handleTenantSelected =()=>{
        if(cardFunctionality===DATA_CARD){
            onTenantSelected(cardTenant)
        }
        else if(cardFunctionality===REVIEW_CARD){
            handleReviewDifferences(cardData)
        }
     }

    return (
        <>
            <div className='card_wrapper'>
                <Tooltip title={cardTenant?.length>26&&cardTenant} placement='top' arrow>
                <Grid container className='card_container' onClick={handleTenantSelected} sx={{width:'240px',height:'140px',pt:'8px',marginY:'8px',border: '1px solid #CCE4FF',borderRadius: '4px'}}>
                    <CardTitle title={cardTenant} cardData={cardData} cardFunctionality={cardFunctionality}/>
                    <CardData cardData={dataCount} cardTenant={cardTenant}/>
                    <CardActionPopUp
                        cardTenant={cardTenant}
                        cardType={TENANT_CARD}
                        cardFunctionality={cardFunctionality}
                        openDifferencePage={openDifferencePage}
                        setOpenConfigModal={setOpenConfigModal}
                        setOpenUploadModal={setOpenUploadModal}
                        cardConfiguration={GLOBAL_CONFIGS}/>
                </Grid>
                </Tooltip>
            </div>
        </>
    )
}
export default TenantCard;