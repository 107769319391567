import React, {useEffect, useState} from 'react';
import {
    Button,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";
import {DIFFERENCES_PAGE, GLOBAL_CONFIGS, PARENT_CHANGE, PREVIEW_CHANGES_PAGE, REVIEW_DIFFERENCES} from "../constants";
import styles from '../../styles/Differences.module.css'
import {
    createNewConfiguration,
    getConfigurations,
    manageReviewer,
    reviewCanceled,
    updateConfiguration, userAccess
} from "../../api/services";
import {
    APPROVE_CONFIG,
    APPROVED,
    CANCEL_REVIEW,
    CANCELLED,
    CREATE_CONFIG,
    CREATE_CONFIGURATION,
    CREATED_REVIEW,
    DENIED,
    DIFFERENCES,
    DISCARD_CONFIG,
    MERGED,
    PENDING,
    PREVIEW_CHANGES,
    REJECT_REVIEW, REVIEW_DESCRIPTION_ERROR,
    SUBMIT_CONFIG,
} from "../constants/ApprovalStatusConstants";
import {SYSTEM_NAME} from "../constants/ApiConstants";
import SubmitModal from "../TenantPage/SubmitModal";
import {updateUsername} from "../common/SharedFunctions";
import {useSelector} from "react-redux";
import {hasRequiredAccess} from "../common/SharedFunctions";


const submitButtonStyle={
    "&.Mui-disabled": {
        background: "#F2F2F2",
        color: "#D9D9D9"
    },
    '&:hover': {
        color: '#1F7A1F',
    },lineHeight:'24px',marginTop:'4px',marginLeft:'105px',textTransform: "capitalize",border:"none",borderRadius:'4px',backgroundColor:'#1F7A1F',width:'75px',marginRight:'4px',color:'#FFF'
}
const cancelButtonStyle={
    '&:hover': {
        color: '#E60000',
    },lineHeight:'24px',marginTop:'4px',marginLeft:'5px',textTransform:'capitalize',border:"none",backgroundColor:'#E60000',borderRadius:'4px',fontSize:'14px',width:'75px',color:'#FFF'
}
const discardButtonStyle={
    '&:hover': {
        color: '#E60000',
    },lineHeight:'24px',marginTop:'4px',marginLeft:'193px',textTransform:'capitalize',border:"none",backgroundColor:'#E60000',borderRadius:'4px',fontSize:'14px',width:'75px',color:'#FFF'
}


const DifferencesPanel=(props)=>{
    const {handleDifferencePage,selectedTenant,selectedConfiguration,differencesPageType,closePreviewChangesPage,
        pendingVersionJsonBody,reviewData,user,setOpenTenant,parentConfig,
        setIsDifferencePageSelected,setIsReviewCardSelected,setCreateVersionStatus,isTenantPageOpened,configUser,closeReviewPage,previewCurrentVersion,createType
        ,setResponseType, setIsTenantConfigMap, setIsEditableTenant, setIsEditableConfig, setIsConfigConfigMap,versionInfo}=props
    const [description,setDescription]=useState('')
    const [userPermission,setUserPermission]=useState({hasAccess:false})
    const [cancelReview,setCancelReview]=useState(false)


    const allUpdatableTenants=useSelector(({tenantsReducer})=>({
        reviewableTenants:tenantsReducer.reviewableTenants
    }))

    async function IsUserHavePermission () {
        let variables={
            systemId: SYSTEM_NAME,
            userId: updateUsername(user)
        }
        let input = {
            input: variables
        }
        await userAccess(setUserPermission,input)
    }

    useEffect(()=>{
        if(selectedTenant){
            if(differencesPageType===REVIEW_DIFFERENCES){
                const reviewAccess = hasRequiredAccess(allUpdatableTenants.reviewableTenants,selectedTenant);
                setUserPermission({hasAccess:reviewAccess});
            }
        }
    },[allUpdatableTenants.reviewableTenants,selectedTenant])

    const handleDiscardReview=()=> {
        discardReview().then(r =>  setResponseType(DISCARD_CONFIG))
        if (isTenantPageOpened) setOpenTenant(true)
        setIsDifferencePageSelected(false)
        setIsReviewCardSelected(false)
        setCancelReview(false)
    }

    async function discardReview() {
        let variables = {
            action: CANCEL_REVIEW,
            reviewId: `${reviewData.reviewId}`
        }
        let input = {
            input: variables
        }
        await reviewCanceled(setCreateVersionStatus, input)
    }
    const handleSubmitReview=()=>{
        if (!previewCurrentVersion){
            createConfig(selectedTenant, {...pendingVersionJsonBody, reviewDescription: description,version:0}).then(r  =>setResponseType(CREATE_CONFIG))
        } else {
            updateConfig(selectedTenant, {...pendingVersionJsonBody, reviewDescription: description}).then(r => setResponseType(SUBMIT_CONFIG))
        }
        setIsDifferencePageSelected(false)
        setIsReviewCardSelected(false)
        setOpenTenant(true)

        // Reset tenant and config maps and edit flags so that last saved values and edit mode are closed after config is submitted.
        setIsTenantConfigMap(null)
        setIsEditableTenant(false)
        setIsEditableConfig(false)
        setIsConfigConfigMap(null)
    }

    async function createConfig(selectedTenant,variables) {
        let input = {
            input: variables
        }
        await createNewConfiguration(selectedTenant, input, setCreateVersionStatus);
    }

    async function updateConfig(selectedTenant,variables) {
        let input = {
            input: variables
        }
        await updateConfiguration(selectedTenant, input, setCreateVersionStatus);
    }

    const handleApproveReview=()=>{
        approveReview().then(r => setResponseType(APPROVE_CONFIG))
        if(isTenantPageOpened) setOpenTenant(true)
        setIsDifferencePageSelected(false)
        setIsReviewCardSelected(false)

    }
    async function approveReview() {
        let variables = {
            reviewerStatus: APPROVED,
            reviewId:reviewData.totalReview.reviewId,
             userId:`${user}`
        }
        let input = {
            input: variables
        }
        await manageReviewer(setCreateVersionStatus,input)
    }

    const handleRejectReview=()=>{
        rejectReview().then(r => setResponseType(REJECT_REVIEW))
        if(isTenantPageOpened) setOpenTenant(true)
        setIsDifferencePageSelected(false)
        setIsReviewCardSelected(false)
    }

    async function rejectReview() {
        let variables = {
            reviewerStatus: DENIED,
            reviewId:reviewData.totalReview.reviewId,
            userId:`${user}`
        }
        let input = {
            input: variables
        }
        await manageReviewer(setCreateVersionStatus,input)
    }

    const handleDescription=(e)=>{
        setDescription(e.target.value)
    }
    const isDescriptionEmpty=description.length===0

    const handleCancelReview=()=>{
        setCancelReview(false)
    }

    return(
        <Grid container direction='column'
              sx={{width:'1328px',height:'148px',borderRadius:'4px',bgcolor:differencesPageType!==PREVIEW_CHANGES_PAGE?'#FFF9EF':'#F5F9FF',pl:'16px',lineHeight:'20.02px',fontSize:'14px',letterSpacing:'0.17px'}}>

            <Grid container >
                <Grid item xs sx={{pt:'16px'}}>
                    <Typography sx={{fontSize:'20px',fontWeight:500,lineHeight:'32px'}}>{versionInfo?.description?PARENT_CHANGE :differencesPageType!==PREVIEW_CHANGES_PAGE ?createType===CREATE_CONFIG?CREATE_CONFIGURATION:DIFFERENCES:PREVIEW_CHANGES}</Typography>
                </Grid>
                {differencesPageType===DIFFERENCES_PAGE &&<Grid item>
                    <IconButton sx={{width:'48px',height:'48px',borderRadius:'0'}} onClick={(e)=>handleDifferencePage(e,isTenantPageOpened,selectedTenant,selectedConfiguration)}>
                        <CloseOutlined sx={{height:'20px',width:'20px'}}/>
                    </IconButton>
                </Grid>}
                {differencesPageType===REVIEW_DIFFERENCES &&<Grid item>
                    <IconButton sx={{width:'48px',height:'48px',borderRadius:'0'}} onClick={(e)=>closeReviewPage(e,isTenantPageOpened,selectedTenant,selectedConfiguration)}>
                        <CloseOutlined sx={{height:'20px',width:'20px'}}/>
                    </IconButton>
                </Grid>}

            </Grid>
            <Grid item sx={{pt:'10px'}}>
                <Stack direction='row' className={styles.differences__panel} gap='40px'>
                    <div ><span>Tenant:&nbsp;</span>{selectedTenant}</div>
                    <div><span>Configurations:&nbsp;</span>{selectedConfiguration?selectedConfiguration:GLOBAL_CONFIGS}</div>
                    <div><span>Parent:&nbsp;</span>{parentConfig} </div>
                    <div><span>Submitter:&nbsp;</span>{configUser} </div>
                </Stack>
            </Grid>
            <Grid item sx={{pt:'5px'}}>
                {differencesPageType===REVIEW_DIFFERENCES?
                    <Stack direction={'row'}>
                        <div style={{width:'1031px'}}><span style={{color:'#808080'}}>Description:&nbsp;</span>
                            <span >{versionInfo?.description?versionInfo.description :reviewData.totalReview?.reviewDescription}</span>
                        </div>
                        {reviewData.reviewStatus===PENDING ?
                            reviewData.Submitter===user ?
                                <>
                                    <Button sx={discardButtonStyle}  onClick={()=>setCancelReview(true)}>Discard</Button>
                                </>:
                                userPermission.hasAccess?
                                    <>
                                        <Button sx={submitButtonStyle}  onClick={handleApproveReview}>Approve</Button>
                                        <Button sx={cancelButtonStyle}  onClick={handleRejectReview}>Reject</Button>
                                    </>
                                    : null
                            : null}
                    </Stack>:null
                }
                {differencesPageType===DIFFERENCES_PAGE ?
                    <div style={{width:'1031px'}}>
                        <span style={{color:'#808080'}}>Description:&nbsp;</span>
                    </div>:differencesPageType===PREVIEW_CHANGES_PAGE&&
                    <Stack direction={'row'}>
                        <TextField value={description} label={'Add Description*'} onChange={handleDescription}   inputProps={{
                            maxLength: 128
                        }} helperText={description.length+'/'+128} size={"small"} sx={{mr:'30px',width:'990px',height:'40px',bgcolor:'#FFF',border:'1px solid #F2F2F2',borderRadius:'4px'}}/>
                        <Button sx={submitButtonStyle}  disabled={isDescriptionEmpty}  onClick={handleSubmitReview}>Submit</Button>
                        <Button sx={cancelButtonStyle}  onClick={closePreviewChangesPage}>Cancel</Button>
                    </Stack>
                }
            </Grid>
            <SubmitModal open={cancelReview} handleClose={handleCancelReview} onSubmit={handleDiscardReview} isReview={true}/>
        </Grid>
    )
}
export default DifferencesPanel;