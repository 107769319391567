import React, {useCallback, useEffect, useState} from "react";
import {
    Checkbox,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Tooltip,
    Typography
} from "@mui/material";
import GroupedButtons from "./GroupedButtons";
import TenantCard from "../Cards/TenantCard";
import { REVIEW_CARD} from "../constants/HomePageConstants";
import ConfigurationCard from "../Cards/ConfigurationCard";
import {DateRangeOutlined, FilterListOutlined} from "@mui/icons-material";
import CancelFilled from "../../assets/CancelFilled.png";
import {
    CONFIGURATION_FILTER,
    CONFIG_DATE_RANGE,
    CONFIG_SUBMITTERS,
    TENANT_FILTER,
    TENANT_SUBMITTERS, TENANT_DATE_RANGE
} from "../constants/ReviewsFilterItemConstants";
import '../../styles/FilterReviews.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {DateRangePicker} from "react-date-range";
import {GLOBAL_CONFIGS} from "../constants";
import {
    convertTimeZone,
    getReviewingConfigurations,
    getReviewingTenants,
    getSubmitters,
    isDateBetween,
    updateUsername
} from "../common/SharedFunctions";

const titleStyle={
    fontSize: '18px',lineHeight:'160%',fontWeight:'500'
}

const iconStyle={
    width:'20px',height:'20px',color:'#808080','&:hover':{
        cursor: 'pointer'
    }
}

const filterOptions={
    tenant_Date_Range:[],
    tenantSubmitters:[],
    tenants:[],
    configuration_Date_Range:[],
    configSubmitters:[],
    configurations:[]
}

const Reviews=(props)=>{
    const {handleReviewDifferences,pendingReviews}=props
    const [toggle,setToggle]=useState(false)
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [currentFilter,setCurrentFilter]=useState('')
    const [currentFilterOptions,setCurrentFilterOptions]=useState([])
    const [isDateFilterSelect,setIsDateFilterSelect]=useState(false)
    const [isDateRangeSelect,setIsDateRangeSelect]=useState({tenant:false,configuration:false})
    const [tenantReviews,setTenantReviews]=useState([])
    const [configReviews,setConfigReviews]=useState([])
    const [noOfFilters,setNoOfFilters]=useState(0)
    const [allFilterOptions,setAllFilterOptions]=useState(filterOptions)
    const [selectedFilters,setSelectedFilters]=useState(filterOptions)
    const [selectedReviews,setSelectedReviews]=useState({tenants:[],configurations:[]})
    const [configDateRange, setConfigDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ])
    const [tenantDateRange, setTenantDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ])
    const open = Boolean(anchorEl);

    useEffect(() => {
        let isDateSelected=0,submitters=0,updatedNoOfFilters=0,noOfTenants=0,noOfConfigurations=0
        if(toggle){
            noOfConfigurations=selectedFilters?.tenants?.length>0?1:0
            submitters=selectedFilters?.tenantSubmitters?.length>0?1:0
            isDateSelected=isDateRangeSelect.tenant?1:0
            updatedNoOfFilters= noOfConfigurations+submitters + isDateSelected
        }
        else {
            noOfTenants=selectedFilters?.configurations?.length>0?1:0
            submitters=selectedFilters?.configSubmitters?.length>0?1:0
            isDateSelected=isDateRangeSelect.configuration?1:0
            updatedNoOfFilters= noOfTenants+submitters + isDateSelected
        }
        setNoOfFilters(updatedNoOfFilters)

    }, [selectedFilters,toggle,isDateFilterSelect,configDateRange,tenantDateRange]);

    useEffect(() => {
        let changedTenantReviews={},changedConfigReviews={}
        if(toggle){
            if(!isDateRangeSelect.tenant &&!selectedFilters.tenantSubmitters.length && !selectedFilters.tenants.length){
                setSelectedReviews({
                    ...selectedReviews,
                    tenants: tenantReviews
                })
            }
            else {
                changedTenantReviews = tenantReviews.filter((eachReview) => {
                    if ((isDateRangeSelect.tenant && isDateBetween(eachReview.Submission,tenantDateRange[0].startDate,tenantDateRange[0].endDate)) || selectedFilters.tenantSubmitters.includes(eachReview.Submitter) || selectedFilters?.tenants.includes(eachReview.cardTenant) ) {
                        return true
                    }
                })
                setSelectedReviews({
                    ...selectedReviews,
                    tenants: changedTenantReviews
                })
            }
        }
        else{
            if(!isDateRangeSelect.configuration &&!selectedFilters.configSubmitters.length && !selectedFilters.configurations.length){
                setSelectedReviews({
                    ...selectedReviews,
                    configurations: configReviews
                })
            }
            else {
                changedConfigReviews = configReviews.filter((eachReview) => {
                    if ((isDateRangeSelect.configuration && isDateBetween(eachReview.Submission,configDateRange[0].startDate,configDateRange[0].endDate)) || selectedFilters.configSubmitters.includes(eachReview.Submitter) || selectedFilters?.configurations.includes(eachReview.cardConfiguration) ) {
                        return true
                    }
                })
                setSelectedReviews({
                    ...selectedReviews,
                    configurations: changedConfigReviews
                })
            }
        }
    }, [selectedFilters,tenantReviews,configReviews,configDateRange,tenantDateRange,toggle]);


    useEffect(() => {
        let allTenantReviews=[],allConfigReviews=[]
        pendingReviews?.map((eachReview)=>{
            let Submitter=updateUsername(eachReview.requesterId)
            let resourceIdentifier=eachReview.resourceIdentifier.split('-')

            if(resourceIdentifier.slice(1).join('-')===GLOBAL_CONFIGS){
                allTenantReviews.push({reviewId:eachReview.reviewId,reviewStatus:eachReview.reviewStatus,cardTenant:resourceIdentifier[0],Submitter:Submitter,Submission:convertTimeZone(eachReview.timestamp).slice(0,10),totalReview:eachReview})
            }
            else {
                allConfigReviews.push({reviewId:eachReview.reviewId,reviewStatus:eachReview.reviewStatus,cardTenant:resourceIdentifier[0],cardConfiguration:resourceIdentifier.slice(1).join('-'),Submitter:Submitter,Submission:convertTimeZone(eachReview.timestamp).slice(0,10),totalReview:eachReview})
            }
        })
        setTenantReviews(allTenantReviews)
        setConfigReviews(allConfigReviews)
        let updatedFilterOptions={
            tenant_Date_Range:[],
            tenantSubmitters:getSubmitters(allTenantReviews),
            tenants:getReviewingTenants(allTenantReviews),
            configuration_Date_Range:[],
            configSubmitters:getSubmitters(allConfigReviews),
            configurations:getReviewingConfigurations(allConfigReviews)
        }
        setAllFilterOptions(updatedFilterOptions)
    }, [pendingReviews]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setCurrentFilter('')
    };


    const handleMenuItem =useCallback( (itemName) => {
        setCurrentFilter(itemName)
        setIsDateFilterSelect(false)
        switch (itemName) {
            case TENANT_DATE_RANGE:
                setIsDateFilterSelect(true)
                break
            case TENANT_SUBMITTERS:
                setCurrentFilterOptions(allFilterOptions?.tenantSubmitters)
                break;
            case TENANT_FILTER:
                setCurrentFilterOptions(allFilterOptions.tenants)
                break;
            case CONFIG_DATE_RANGE:
                setIsDateFilterSelect(true)
                break;
            case CONFIG_SUBMITTERS:
                setCurrentFilterOptions(allFilterOptions.configSubmitters)
                break;
            case CONFIGURATION_FILTER:
                setCurrentFilterOptions(allFilterOptions.configurations)
                break;
        }
    },[allFilterOptions]);


    const handleCancelSelected =(itemName)=>{
        if(itemName===CONFIG_DATE_RANGE){
            setIsDateRangeSelect({
                ...isDateRangeSelect,
                configuration:false
            })
            setConfigDateRange([
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection'
                }
            ])
        }
        else if(itemName===TENANT_DATE_RANGE){
            setIsDateRangeSelect({
                ...isDateRangeSelect,
                tenant:false
            })
            setTenantDateRange([
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection'
                }
            ])
        }
        else {
            setSelectedFilters({
                ...selectedFilters,
                [itemName]:[]
            })
        }

    }

    const handleCurrentFilterItem=(currentFilter,currentFilterOption)=> {
            if (!selectedFilters[currentFilter].includes(currentFilterOption)) {
                setSelectedFilters({
                    ...selectedFilters,
                    [currentFilter]: [...selectedFilters[currentFilter], currentFilterOption]
                })
            }
            else {
                const updatedCurrentFilters=selectedFilters[currentFilter].filter((eachFilter)=>eachFilter!==currentFilterOption)
                setSelectedFilters({
                    ...selectedFilters,
                    [currentFilter]:updatedCurrentFilters
                })
            }
    }

    const handleTenants=()=>{
        setToggle(true)
    }

    const handleConfigurations=()=>{
        setToggle(false)
    }

    const handleRangeChange=(item) =>{
        if(toggle){
            setIsDateRangeSelect({
                ...isDateRangeSelect,
                tenant: true
            })
            setTenantDateRange([item.selection])
        }
        else {
            setIsDateRangeSelect({
                ...isDateRangeSelect,
                configuration: true
            })
            setConfigDateRange([item.selection])
        }

    }
    return (
        <>
            <Grid container item minWidth='240px' minHeight='69px'>
                <Grid  item>
                    <Grid container item  width='240px'>
                        <Grid item xs>
                            <Typography sx={titleStyle}>
                                Reviews
                            </Typography>
                        </Grid>
                        <Grid item>
                           <span style={{color:'#808080',fontWeight:'500',fontSize:'14px',lineHeight:'150%',letterSpacing:'0.15px'}}>
                                    {noOfFilters>0&&noOfFilters}
                           </span>
                        </Grid>
                        <Grid item>
                            <FilterListOutlined  onClick={handleClick}  sx={iconStyle}/>
                            <div style={{position:"relative"}}>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    sx={{boxShadow:2,zIndex:50,py:'0px'}}
                                >
                                    {toggle?<MenuItem onClick={()=>handleMenuItem(TENANT_DATE_RANGE)} className={'menu-item'} sx={{bgcolor:isDateRangeSelect.tenant?'#E6F1FF':'#FFF',width:toggle?'136px':'160px'}}>
                                        Date
                                        {isDateRangeSelect.tenant&&<img src={CancelFilled} className={'Cancel_Filter'} onClick={()=>handleCancelSelected(TENANT_DATE_RANGE)} alt={'CancelFilled'}/>}
                                    </MenuItem>:
                                    <MenuItem onClick={()=>handleMenuItem(CONFIG_DATE_RANGE)} className={'menu-item'} sx={{bgcolor:isDateRangeSelect.configuration?'#E6F1FF':'#FFF',width:toggle?'136px':'160px'}}>
                                        Date
                                        {isDateRangeSelect.configuration&&<img src={CancelFilled} className={'Cancel_Filter'} onClick={()=>handleCancelSelected(CONFIG_DATE_RANGE)} alt={'CancelFilled'}/>}
                                    </MenuItem>}
                                    {toggle?<MenuItem onClick={()=>handleMenuItem(TENANT_SUBMITTERS)} className={'menu-item'} sx={{bgcolor:selectedFilters?.tenantSubmitters?.length>0?'#E6F1FF':'#FFF'}}>
                                        Submitter
                                        {selectedFilters.tenantSubmitters?.length>0 && <img src={CancelFilled} className={'Cancel_Filter'} onClick={()=>handleCancelSelected(TENANT_SUBMITTERS)} alt={'CancelFilled'}/>}
                                    </MenuItem>:<MenuItem onClick={()=>handleMenuItem(CONFIG_SUBMITTERS)} className={'menu-item'} sx={{bgcolor:selectedFilters?.configSubmitters?.length>0?'#E6F1FF':'#FFF'}}>
                                        Submitter
                                        {selectedFilters.configSubmitters?.length>0 && <img src={CancelFilled} className={'Cancel_Filter'} onClick={()=>handleCancelSelected(CONFIG_SUBMITTERS)} alt={'CancelFilled'}/>}
                                    </MenuItem>}
                                    {toggle?<MenuItem onClick={()=>handleMenuItem(TENANT_FILTER)} className={'menu-item'} sx={{bgcolor:selectedFilters?.tenants?.length>0?'#E6F1FF':'#FFF'}}>
                                            Tenant
                                            {selectedFilters.tenants?.length>0 && <img src={CancelFilled} className={'Cancel_Filter'} onClick={()=>handleCancelSelected(TENANT_FILTER)} alt={'CancelFilled'}/>}
                                        </MenuItem>:
                                        <MenuItem onClick={()=>handleMenuItem(CONFIGURATION_FILTER)} className={'menu-item'} sx={{bgcolor:selectedFilters?.configurations?.length>0?'#E6F1FF':'#FFF'}}>
                                            Configurations
                                            {selectedFilters.configurations?.length>0 && <img src={CancelFilled} className={'Cancel_Filter'} onClick={()=>handleCancelSelected(CONFIGURATION_FILTER)} alt={'CancelFilled'}/>}
                                        </MenuItem>}
                                </Menu>
                                <div style={{position:"absolute",top:0,left:'110%',zIndex:100}}>
                                    {currentFilter&&
                                        <Paper className='reviews_filter' sx={{boxShadow:5,width:'332px',maxHeight:'434px',py:'7px' }}>
                                            {isDateFilterSelect?
                                                <DateRangePicker
                                                    onChange={handleRangeChange}
                                                    editableDateInputs={true}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={toggle?tenantDateRange:configDateRange}
                                                    months={1}
                                                    direction="vertical"
                                                    className='date_range_calendar'
                                                    rangeColors={["#0070F0"]}
                                                    showMonthAndYearPickers={false}
                                                />
                                                :
                                                currentFilterOptions.map((currentFilterOption)=>{
                                                    return <MenuItem key={currentFilterOption} sx={{height:'36px',fontSize:'16px',pl:'3px',py:'0px',bgcolor:selectedFilters.hasOwnProperty(currentFilter)&& selectedFilters[currentFilter].includes(currentFilterOption)?'#E6F1FF !important':'#FFF'}} >
                                                        <Checkbox
                                                            checked={selectedFilters.hasOwnProperty(currentFilter)&& selectedFilters[currentFilter].includes(currentFilterOption)}
                                                            onChange={()=>handleCurrentFilterItem(currentFilter,currentFilterOption)} />
                                                        {currentFilterOption.length>34?
                                                            <Tooltip title={currentFilterOption} placement='top' arrow><span>{currentFilterOption.slice(0,34)+'...'}</span></Tooltip>:
                                                            <span>{currentFilterOption}</span>}
                                                    </MenuItem>})
                                            }
                                        </Paper>
                                    }

                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid  container item  marginTop={1}>
                    <GroupedButtons
                        toggle={toggle}
                        handleTenants={handleTenants}
                        handleConfigurations={handleConfigurations}
                    />
                </Grid>
                <Grid container item width='240px'  maxHeight='80vh' className='grid_container'  mt='12px'>
                    {toggle?
                        selectedReviews.tenants && selectedReviews.tenants.map((eachTenantReview)=>{
                            return <TenantCard
                                key={eachTenantReview.reviewId}
                                cardFunctionality={REVIEW_CARD}
                                cardTenant={eachTenantReview.cardTenant}
                                cardData={eachTenantReview}
                                handleReviewDifferences={handleReviewDifferences}
                            />
                        })
                        :
                        selectedReviews.configurations && selectedReviews?.configurations?.map((eachConfigReview)=>{
                            return <ConfigurationCard
                                key={eachConfigReview.reviewId}
                                cardFunctionality={REVIEW_CARD}
                                cardConfiguration={eachConfigReview.cardConfiguration}
                                cardTenant={eachConfigReview.cardTenant}
                                cardData={eachConfigReview}
                                handleReviewDifferences={handleReviewDifferences}
                            />
                        })
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default Reviews;