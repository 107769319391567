import {
    checkUserPermission,
    fetchReviewByReviewId,
    fetchReviews, getListOfConfigurations,
    getListOfTenants, getConfigType,
    retrieveConfigurations, fetchAccessibleReviews, getListOfAccessibleTenants, listBindleByOwner
} from "../graphql/queries";
import {
    createTenant,
    createConfiguration,
    createVersion,
    manageReviewerReview,
    manageReview
} from "../graphql/mutations";
import config from "../aws-exports";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import {CREATE_TENANT} from "../components/constants/ApprovalStatusConstants";
import {
    addReviewableTenants,
    addTenants,
    addUpdatableTenants,
    tenantsFailed,
    tenantsLoading
} from "../redux/actions/tenants-actions";
import {
    CAN_READ_CONFIG_OPERATION,
    CAN_REVIEW_CONFIG_OPERATION,
    CAN_UPDATE_CONFIG_OPERATION, IS_OWNER_OF_OPERATION
} from "../components/constants/PermissionConstants";


const fetchSettings = async () => {
    try {
        let settingsJson = JSON.parse(localStorage.getItem("settingsJson"));
        if (!settingsJson){
            const response = await fetch('./settings.json');
            settingsJson = await response.json();
            localStorage.setItem("settingsJson", JSON.stringify(settingsJson));
        }
        return settingsJson
    } catch (e) {
        alert("There was a problem retrieving settings from backend", e);
    }
}

const getStageForConfig = async() => {
    const settings = await fetchSettings()
    await API.configure(config[settings["stage"]])
}

export const  loadTenants= (currentUser) => async (dispatch) => {
    dispatch(tenantsLoading(true));
    try {
        await getStageForConfig()
        try {
            let variables = {
                user:currentUser
            }
            let input = {
                input: variables
            }
            await API.graphql({
                query: getListOfAccessibleTenants,
                variables:input
            })
                .then((json) => {
                    console.log("GET APPSYNC TENANTS SUCCESSFUL")
                    return json.data
                })
                .then((json)=>{
                    const tenantInfoList = json.getListOfAccessibleTenants.tenants;
                    let readableTenantSet = new Set(),updatableTenantSet = new Set(),reviewableTenantSet = new Set();

                    tenantInfoList.forEach(tenantInfo => {
                        switch(tenantInfo.operation){
                            case CAN_READ_CONFIG_OPERATION:
                                readableTenantSet.add(tenantInfo.tenantName)
                                break;

                            case CAN_UPDATE_CONFIG_OPERATION:
                                updatableTenantSet.add(tenantInfo.tenantName)

                                break;

                            case CAN_REVIEW_CONFIG_OPERATION:
                                reviewableTenantSet.add(tenantInfo.tenantName)

                                break;

                            case IS_OWNER_OF_OPERATION:
                                readableTenantSet.add(tenantInfo.tenantName)
                                break;

                        }
                    })

                    const readableTenantList = Array.from(readableTenantSet);
                    const updatableTenantList = Array.from(updatableTenantSet);
                    const reviewableTenantList = Array.from(reviewableTenantSet);

                    dispatch(addTenants(readableTenantList))
                    dispatch(addUpdatableTenants(updatableTenantList))
                    dispatch(addReviewableTenants(reviewableTenantList))
                })
        }
        catch (e) {
            console.log("loadTenants failed", e)
            dispatch(tenantsFailed(e.message));
        }
    }
    catch (e){
        console.log("getAwsExports Failed",e)
    }
}

export const  getTenants=async (setTenants)=> {
    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: getListOfTenants,
            })
                .then((json) => {
                    console.log("GET TENANTS SUCCESSFUL")
                    return json.data
                })
                .then((json) => {
                    setTenants(json.getListOfTenants.tenants)
                })
        } catch (e) {
            console.log("getTenants Failed", e)
        }
    }catch (e){
        console.log("getTenants AwsExports Failed",e)
    }
}
export const  getConfigurations=async (setConfigs,variables)=> {
    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: retrieveConfigurations,
                variables:variables
            })
                .then((json) => {
                    return json.data
                })
                .then((json)=>{
                    setConfigs(json.retrieveConfigurations.configsMap)
                })
        }
        catch (e) {
            console.log("getConfigurations Failed",e)
        }
    }catch (e){
        console.log("getConfigurations AwsExports Failed",e)
    }
}

export const  getConfigurationsList=async (setConfig_List,variables)=> {

    try {
        await getStageForConfig()
        try {
            let configs = await API.graphql({
                query: getListOfConfigurations,
                variables:variables
            })
                .then((json) => {
                    return json.data
                })
                .then((json)=>{
                    setConfig_List(json.getListOfConfigurations.configs)
                    return json.getListOfConfigurations.configs;
                })
            return configs;
        }
        catch (e) {
            console.log("getConfigurationsList Failed",e)
        }
    }catch (e){
        console.log("getConfigurationsList AwsExports Failed",e)
    }
}

export const  fetchAllReviews = async (  setTotalReviews,variables) => {
    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: fetchAccessibleReviews,
                variables:variables
            })
                .then((json) => {
                    setTotalReviews(json.data?.fetchAccessibleReviews?.reviews);
                })
        }
        catch (e) {
            console.log("fetchAllReviews Failed",e)
        }
    }
    catch (e){
        console.log("fetchAllReviews AwsExports Failed",e)
    }
}

export const  fetchReviewById = async (  setTotalReviews,variables) => {
    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: fetchReviewByReviewId,
                variables:variables
            })
                .then((json) => {
                    setTotalReviews(json.data?.fetchReviewById?.review);
                })
        }
        catch (e) {
            console.log("fetchReviewById Failed",e)
        }
    }
    catch (e){
        console.log("fetchReviewById AwsExports Failed",e)
    }
}

export const  manageReviewer = async (setResponse, variables) => {
    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: manageReviewerReview,
                variables:variables
            })
                .then((json) => {
                    setResponse(json.data?.manageReviewerReview);
                })
        }
        catch (e) {
            console.log("Review Approval Failed",e)
            setResponse({status: "Failed"})
        }
    }
    catch (e){
        console.log("Review Approval  AwsExports Failed",e)
        setResponse({status: "Failed"})
    }
}

export const  reviewCanceled = async (  setResponse,variables) => {
    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: manageReview,
                variables:variables
            })
                .then((json) => {
                    setResponse(json.data.manageReview);
                })
        }
        catch (e) {
            console.log("reviewCanceled Failed",e)
            setResponse({status: "Failed"})

        }
    }
    catch (e){
        console.log("Review Approval  AwsExports Failed",e)
        setResponse({status: "Failed"})

    }
}

export const  getAllTenantsData=async (tenant, setConfigs, variables)=> {

    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: getConfigType,
                variables:variables
            })
                .then((json) => {
                    return json.data.getListOfConfigurations.configs
                })
                .then((json)=>{
                    setConfigs((prevConfigs)=>({
                        ...prevConfigs,
                        [tenant]: json
                    }))
                })
        }
        catch (e) {
            console.log("getAllTenantsData Failed",e)
        }
    }
    catch (e){
        console.log("getAllTenantsDataAwsExports Failed",e)
    }
}

export const  createNewTenant = async (tenant, variables, setResponse,setResponseType) => {

    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: createTenant,
                variables:variables
            })
                .then((json) => {

                    setResponse({
                        tenantName: tenant,
                        status: json.data.createTenant.string
                    })
                }).then(()=>{
                    setResponseType(CREATE_TENANT)
                })
        }
        catch (e) {
            console.log("createNewTenant Failed",e)
            setResponse({status: "Failed"})
            setResponseType(CREATE_TENANT)
        }
    }
    catch (e){
        console.log("createNewTenantAwsExports Failed",e)
    }
}

export const  createNewConfiguration = async (tenant, variables, setResponse) => {
    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: createConfiguration,
                variables:variables
            })
                .then((json) => {
                    setResponse({status: json.data.createConfiguration.status, reviewStatus: json.data.createConfiguration.reviewStatus});
                    return json.data
                })
        }
        catch (e) {
            console.log("Create Configuration Failed",e)
            setResponse({status: "Failed"})
        }
    }
    catch (e){
        console.log("Create Configuration AwsExports Failed",e)
        setResponse({status: "Failed"})
    }
}

export const  updateConfiguration = async (tenant, variables, setResponse) => {
    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: createVersion,
                variables:variables
            })
                .then((json) => {
                    setResponse({status: json.data.createVersion.status, reviewStatus: json.data.createVersion.reviewStatus});
                })
        }
        catch (e) {
            console.log("Update Configuration Failed",e)
            setResponse({status: "Failed"})
        }
    }
    catch (e){
        console.log("updateConfiguration AwsExports Failed",e)
        setResponse({status: "Failed"})
    }
}

export const userAccess= async (setUserPermission,variables)=>{
    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: checkUserPermission,
                variables:variables
            })
                .then((json) => {
                    setUserPermission(json.data.checkSystemPermission);
                })
        }
        catch (e) {
            console.log("userPermission Failed",e)
            setUserPermission({hasAccess: false})
        }
    }
    catch (e){
        console.log("userPermission AwsExports Failed",e)
        setUserPermission({hasAccess: false})
    }
}

export const fetchBindleList= async (setBindleJson, variables)=>{
    try {
        await getStageForConfig()
        try {
            await API.graphql({
                query: listBindleByOwner,
                variables:variables
            })
                .then((json) => {
                    return json.data;
                })
                .then((json) => {

                    const bindles = json.listBindleByOwner.listOfBindles;

                    let bindleJson={}
                    bindles.forEach(({bindleName, bindleID}) => {
                        bindleJson[bindleName]=bindleID

                    })
                    setBindleJson(bindleJson)
                })
        }
        catch (e) {
            console.log("Fetching Bindles Failed",e)
        }
    }
    catch (e){
        console.log("Fetching Bindles Failed",e)
    }
}
