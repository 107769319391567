import React, {useState} from 'react';
import {Grid, Table, TableBody, TableRow, Typography} from "@mui/material";
import ConfigTableObject from "./ConfigTableObject";
import ConfigTableKeyValue from "./ConfigTableKeyValue";
import ConfigTableArray from "./ConfigTableArray";
import styles from "../../../../styles/TableStyles.module.css";

const ConfigTableRow = (props) => {
    const { index, row, path, isEditable, updateKey, updateValue, updateType, getOverridenValue, onDeleteRow,
        undoEverything, onRemoveOverride, selectedIcons, expandAll, onExpandRow, onEditRow } = props;

    const getRows= (metaMap) => {
        const comps = [];
        if (selectedIcons.length === 0) {
            metaMap.forEach((row, index) => {
                let path_value = [...path, index]
                comps.push(
                    <ConfigTableRow
                        index={index}
                        row={row}
                        path={path_value}
                        key={path_value + "-configTableRow"}
                        isEditable={isEditable}
                        updateKey={updateKey}
                        updateValue={updateValue}
                        addRowItem={props.addRowItem}
                        updateType={updateType}
                        getOverridenValue={getOverridenValue}
                        onDeleteRow={onDeleteRow}
                        onExpandRow={onExpandRow}
                        onEditRow={onEditRow}
                        undoEverything={undoEverything}
                        onRemoveOverride={onRemoveOverride}
                        selectedIcons={selectedIcons}
                        expandAll={expandAll}
                    />
                )
            });
        } else {
            metaMap.forEach((row, index) => {
                if (row[3] === "" || selectedIcons.includes(row[3])) {
                    let path_value = [...path, index]
                    comps.push(
                        <ConfigTableRow
                            index={index}
                            row={row}
                            path={path_value}
                            key={path_value + "-configTableRow"}
                            isEditable={isEditable}
                            updateKey={updateKey}
                            updateValue={updateValue}
                            addRowItem={props.addRowItem}
                            onExpandRow={onExpandRow}
                            onEditRow={onEditRow}
                            updateType={updateType}
                            getOverridenValue={getOverridenValue}
                            onDeleteRow={onDeleteRow}
                            undoEverything={undoEverything}
                            onRemoveOverride={onRemoveOverride}
                            selectedIcons={selectedIcons}
                            expandAll={expandAll}
                        />
                    )
                }
            });
        }
        return comps;
    }

    const getArrayRows= (metaMap) => {
        const comps = [];
        if (selectedIcons.length === 0) {
            metaMap.forEach((row, index) => {
                let path_value = [...path, index]
                comps.push(
                    <ConfigTableRow
                        index={index}
                        row={row}
                        path={path_value}
                        key={path_value + "-configTableRow"}
                        isEditable={isEditable}
                        updateKey={updateKey}
                        updateValue={updateValue}
                        isArrayRow={true}
                        onExpandRow={onExpandRow}
                        onEditRow={onEditRow}
                        addRowItem={props.addRowItem}
                        updateType={updateType}
                        getOverridenValue={getOverridenValue}
                        onDeleteRow={onDeleteRow}
                        undoEverything={undoEverything}
                        onRemoveOverride={onRemoveOverride}
                        selectedIcons={selectedIcons}
                        expandAll={expandAll}
                    />
                )
            });
        } else {
            metaMap.forEach((row, index) => {
                if (row[3] === "" || selectedIcons.includes(row[3])) {
                    let path_value = [...path, index]
                    comps.push(
                        <ConfigTableRow
                            index={index}
                            row={row}
                            path={path_value}
                            key={path_value + "-configTableRow"}
                            isEditable={isEditable}
                            updateKey={updateKey}
                            updateValue={updateValue}
                            isArrayRow={true}
                            addRowItem={props.addRowItem}
                            updateType={updateType}
                            getOverridenValue={getOverridenValue}
                            onDeleteRow={onDeleteRow}
                            onExpandRow={onExpandRow}
                            onEditRow={onEditRow}
                            undoEverything={undoEverything}
                            onRemoveOverride={onRemoveOverride}
                            selectedIcons={selectedIcons}
                            expandAll={expandAll}
                        />
                    )
                }
            });
        }

        return comps;

    }



    return (
        <Table
            className={styles.mainRowDataTable}
            key={`${path}-table`}
            size="small">
            <TableBody className={styles.mainRowDataTableBody} key={`${path}-tableBody`}>
                <TableRow key={`${path}-table-row`}>
                    {
                        row[2] === "object" ?
                            <ConfigTableObject
                                index ={index}
                                row ={row}
                                path = {path}
                                getRows = {getRows}
                                isEditable = {isEditable}
                                updateKey = {updateKey}
                                isArrayRow = {props.isArrayRow}
                                addRowItem = {props.addRowItem}
                                onDeleteRow = {onDeleteRow}
                                onExpandRow={onExpandRow}
                                onEditRow={onEditRow}
                                undoEverything={undoEverything}
                                expandAll={expandAll}
                            />

                            :
                            ( row[2]==="array"?  (
                                        <ConfigTableArray
                                            index ={index}
                                            row ={row}
                                            path = {path}
                                            getRows = {getArrayRows}
                                            isEditable = {isEditable}
                                            updateKey = {updateKey}
                                            isArrayRow = {props.isArrayRow}
                                            addRowItem = {props.addRowItem}
                                            onDeleteRow = {onDeleteRow}
                                            onExpandRow={onExpandRow}
                                            onEditRow={onEditRow}
                                            undoEverything={undoEverything}
                                            expandAll={expandAll}
                                        />
                                    ) :

                                    <ConfigTableKeyValue
                                        index ={index}
                                        row ={row}
                                        path = {path}
                                        isEditable = {isEditable}
                                        updateKey = {updateKey}
                                        updateValue = {updateValue}
                                        isArrayRow = {props.isArrayRow}
                                        updateType = {updateType}
                                        getOverridenValue = {getOverridenValue}
                                        onDeleteRow = {onDeleteRow}
                                        onEditRow={onEditRow}
                                        undoEverything={undoEverything}
                                        onRemoveOverride={onRemoveOverride}
                                    />

                            )
                    }
                </TableRow>
            </TableBody>

        </Table>
    )
}
export default ConfigTableRow;