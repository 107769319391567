import React, {useEffect, useState} from "react";
import TenantCard from "../Cards/TenantCard";
import {Grid} from "@mui/material";
import {useSelector} from "react-redux";

const TenantsList=(props)=>{

    const {onTenantSelected,cardFunctionality,tenantCardSelect,setOpenConfigModal,setOpenUploadModal,openDifferencePage,tenantCardInfo}=props
    const [options,setOptions]=useState([])
    const allTenants=useSelector(({tenantsReducer})=>({
        tenants:tenantsReducer.tenants
    }))

    useEffect(()=>{
        if(tenantCardSelect){
            setOptions([tenantCardSelect])
        }
        else if(!tenantCardSelect){
            setOptions(allTenants.tenants)
        }
    },[tenantCardSelect])

    useEffect(() => {
        setOptions(allTenants.tenants)
    }, [allTenants.tenants]);


    return(
        <Grid container item minWidth='248px' maxHeight='80vh' className='grid_container' >
            {options?.map((option,index)=>{
                return <TenantCard key={option}
                                   cardTenant={option}
                                   onTenantSelected={onTenantSelected}
                                   cardFunctionality={cardFunctionality}
                                   cardData={tenantCardInfo[option]}
                                   openDifferencePage={openDifferencePage}
                                   setOpenConfigModal={setOpenConfigModal}
                                   setOpenUploadModal={setOpenUploadModal}
                />
            })}
        </Grid>
    )
}
export default TenantsList;