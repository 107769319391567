import React, {useEffect, useRef, useState} from 'react';
import {
    Grid,
    IconButton,
    TableCell,
    TextField,
    Typography,
    Tooltip, MenuItem
} from "@mui/material";
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {InheritedIcon, LocalIcon, OverrideIcon} from "../../../LegendIcons";
import {Input} from "reactstrap";
import ConfigTableKeyValueType from "./ConfigTableKeyValueType";
import Delete from "../../../../assets/Delete.png";
import Undo from "../../../../assets/undo-button.png";
import Redo from "../../../../assets/redo-button.png";
import RemoveOverrideImg from '../../../../assets/Override Icon.png';
import styles from "../../../../styles/TableStyles.module.css";
import {
    NUMBER,
    BOOLEAN,
    TRUE,
    FALSE,
    LOCAL,
    OVERRIDE,
    GLOBAL,
    OVERRIDE_COLOR,
    INHERITED_COLOR,
    LOCAL_COLOR,
    PARENT,
    BOLD_FONT,
    REGULAR_FONT
} from "../../../constants/TableConstants"


const ConfigTableKeyValue = (props) => {
    const { index, row, path, isEditable, updateKey, updateValue, updateType,
        getOverridenValue, onDeleteRow, undoEverything, onEditRow } = props;
    const [focused, setFocused] = React.useState(false)
    const [isHover, setHover] = useState(false);
    const [keyField, setKeyField] = useState(row[0]);
    const [valueField, setValueField] = useState(row[1]);
    const [isDeleted, setDeleted] = useState(row[4][0] === 0? false : true);
    const [isRowEdited, setIsRowEdited] = useState(row[4][3]===1);
    const [isUndone, setIsUndone] = useState(false);
    const [undoSaved, setUndoSaved] = useState(new Map());
    const [dataType, setDataType] = useState(row[2]);
    const [type, setType] = useState([row[3]])
    const [overriddenValue, setOverriddenValue] = useState(getOverridenValue(path))
    const [dataTypeMismatch, setDataTypeMismatch] = useState(false);
    const inputReference = useRef(null);

    const topLevelBoldFont = path.length > 1 ? REGULAR_FONT : BOLD_FONT;

    const onClick = () => {
        setFocused(!focused)
    }

    const onUndoFunction = () => {
        let setUndoMap = new Map()
        setUndoMap.set("key", keyField)
        setUndoMap.set("value", valueField)
        setUndoMap.set("dataType", dataType)
        setUndoMap.set("type", type)
        setUndoMap.set("deleteFlag", isDeleted)
        setUndoSaved(setUndoMap)
        undoEverything(path, setKeyField, setValueField, setDataType, setType)
        setDeleted(false)
        setIsUndone(true)
        setIsRowEdited(false)
        onEditRow(path, 0)
    }

    const onRedoFunction = () => {
        setIsRowEdited(true)
        onEditRow(path, 1)
        setValueField(undoSaved.get('value'))
        setKeyField(undoSaved.get('key'))
        setDataType(undoSaved.get('dataType'))
        setDeleted(undoSaved.get('deleteFlag'))

        updateKey(path,undoSaved.get('key'))
        updateValue(path,undoSaved.get('value'))
        updateType(path,undoSaved.get('dataType'))
        if(undoSaved.get('deleteFlag')) {
            onDeleteRow(path)
        }
    }

    const onDeleted = () => {
        setDeleted(true)
        onDeleteRow(path)
        setIsRowEdited(true)
        onEditRow(path, 1)
    }

    useEffect(() => {
        if (dataType === NUMBER && !Number(valueField)) {
            setDataTypeMismatch(true);
        } else if (dataType === BOOLEAN && valueField !== TRUE && valueField !== FALSE) {
            setDataTypeMismatch(true);
        } else {
            setDataTypeMismatch(false);
        }
    },[valueField, dataType])

    useEffect(() => {
        setKeyField(row[0])
        setValueField(row[1])
        setFocused(false)
        setHover(false)
        setDeleted(row[4][0] === 0? false : true)
        setIsRowEdited(row[4][3]===1)
        setIsUndone(false)
        setUndoSaved(new Map())
        setDataType(row[2])
        setType(row[3])
        setOverriddenValue(getOverridenValue(path))
        setDataTypeMismatch(false)
    },[row]);


    const onChangeKeyData = (value) => {
        setKeyField(value)
        updateKey(path, value)
        setIsRowEdited(true)
        onEditRow(path, 1)
    }
    const onChangeValueData = (value) => {
        setValueField(value)
        updateValue(path, value)
        setIsRowEdited(true)
        onEditRow(path, 1)
    }

    const fontColor = (text) => {
        switch(text) {
            case LOCAL:
                return LOCAL_COLOR;
            case OVERRIDE:
                return OVERRIDE_COLOR;
            case GLOBAL :
                return INHERITED_COLOR;
            case PARENT :
                return INHERITED_COLOR;
            default:
                return LOCAL_COLOR;
        }
    };

    const fetchIcon = (text) => {
        switch(text) {
            case LOCAL:
                return <Typography className={styles.local__icon}>L</Typography>;
            case OVERRIDE:
                return <Typography className={styles.override__icon}>O</Typography>;
            case GLOBAL :
                return <Typography className={styles.inherited__icon}>I</Typography>;
            case PARENT :
                return <Typography className={styles.inherited__icon}>I</Typography>;
            default:
                return <Typography className={styles.local__icon}>L</Typography>;
        }
    };

    const isKeyNotEditable = (text) => {
        if (text === GLOBAL || text === PARENT || text === OVERRIDE) {
            return true
        }
        return false
    };
    const isInherited = (text) => {
        if (text === GLOBAL || text === PARENT ) {
            return true
        }
        return false
    };

    useEffect(() => {
        if(inputReference && inputReference.current && keyField==='') inputReference.current.focus();
    }, [isEditable,props.isArrayRow]);

    return(
        <Grid container key={`${path}-table-item`} justifyContent={"flex-start"} alignItems={"center"}
                   id={`${path}-table-item`}
              className={`${fontColor}`}
                   style={{minHeight: '48px',padding:0,borderBottom:'1px solid #EFEFEF'}}
                   onMouseEnter={() => setHover(true)}
                   onMouseLeave={() => setHover(false)}>
            <Grid container item xs>
                <Grid item xs={5.5} id={`${path}-table-item-key`} key={`${path}-table-item-key`} pl={2} pr={2}>
                    {   isEditable && !props.isArrayRow && !isKeyNotEditable(row[3])?
                        <TextField
                            fullWidth
                            size="small"
                            inputRef={inputReference}
                            aria-label={`config-table-row-key-${path}`}
                            id={`${path}-table-item-header-3`}
                            key={`${path}-table-item-header-3`}
                            className="textField"
                            value = {keyField}
                            onChange={(e) => onChangeKeyData(e.target.value)}
                            style={{textDecoration: isDeleted ?"line-through":"none"}}
                            sx={{ input: { color:fontColor(row[3]),  fontWeight: topLevelBoldFont}}}>
                        </TextField> :
                        <Typography p={1}
                                    onClick={onClick}
                                    fontSize={"16px"}
                                    fontWeight={"401"}
                                    fontFamily={'Amazon Ember'}
                                    style={focused ?
                                        {wordWrap: "break-word", wordBreak: "break-all", color:fontColor(row[3]),  fontWeight: topLevelBoldFont} :
                                        {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden', color:fontColor(row[3]), fontWeight: topLevelBoldFont}}>
                            {focused ? row[0] : row[0].toString().substring(0,50)}
                        </Typography>
                    }

                </Grid>
                { isEditable ?
                    <Grid item xs={6.5} id={`${path}-table-item-value`} key={`${path}-table-item-value`}>
                        <Grid container pt={0} spacing={0.5} justifyContent={"left"} alignItems={"center"}>
                            <Grid item xs={0.5}>
                                :&ensp;
                            </Grid>
                            <Grid item xs={2.5}>
                                <ConfigTableKeyValueType
                                    typeValue = {dataType}
                                    path = {path}
                                    setDataType={setDataType}
                                    updateType ={updateType}
                                    isEditable = {isEditable && !isInherited(row[3])}
                                    setIsRowEdited={setIsRowEdited}
                                ></ConfigTableKeyValueType>
                            </Grid>
                            <Grid item xs={9.0} pl={2}>
                                {dataType === BOOLEAN ?
                                    <TextField
                                        fullWidth
                                        size="small"
                                        select
                                        aria-label={`config-table-row-value-${path}`}
                                        id={`${path}-table-item-header-3`}
                                        key={`${path}-table-item-header-3`}
                                        className={dataType === "" ? `${styles.textfield__disabled} "textField"}`: "textField"}
                                        disabled={dataType === ""}
                                        error={dataTypeMismatch}
                                        value={valueField === TRUE || valueField === FALSE ? valueField : ""}
                                        onChange={(e) => onChangeValueData(e.target.value)}
                                        style={{ textDecoration: isDeleted ?"line-through":"none"}}
                                        sx={{ input: { color:fontColor(row[3]), fontWeight: topLevelBoldFont}}}
                                    >
                                        <MenuItem className={styles.dropdown} value={"true"}>true</MenuItem>
                                        <MenuItem className={styles.dropdown} value={"false"}>false</MenuItem>
                                    </TextField>
                                :
                                    <TextField
                                        fullWidth
                                        size="small"
                                        aria-label={`config-table-row-value-${path}`}
                                        id={`${path}-table-item-header-3`}
                                        key={`${path}-table-item-header-3`}
                                        className={dataType === "" ? `${styles.textfield__disabled} "textField"}`: "textField"}
                                        disabled={dataType === ""}
                                        error={dataTypeMismatch}
                                        value = {valueField}
                                        onChange={(e) => onChangeValueData(e.target.value)}
                                        style={{ textDecoration: isDeleted ?"line-through":"none"}}
                                        sx={{ input: { color:fontColor(row[3]), fontWeight: topLevelBoldFont}}}>
                                    </TextField>
                                }
                            </Grid>
                        </Grid>
                    </Grid> :
                    <Grid container item xs={6.5} id={`${path}-table-item-value`} key={`${path}-table-item-value`} direction={"row"} alignItems={"center"}>
                        <Grid item xs={0.5}>
                            :&ensp;&ensp;
                        </Grid>
                        <Grid item xs={1.2}>
                            <ConfigTableKeyValueType
                                typeValue = {dataType}
                                path = {path}
                                setDataType={setDataType}
                                updateType ={updateType}
                                isEditable = {isEditable && !isInherited(row[3])}
                                setIsRowEdited={setIsRowEdited}
                            ></ConfigTableKeyValueType>
                        </Grid>
                        <Grid item xs={10.3} pl={1}>
                            <Typography p={0}
                                        onClick={onClick}
                                        fontSize={"16px"}
                                        fontWeight={"401"}
                                        fontFamily={'Amazon Ember'}
                                        style={focused ?
                                            {wordWrap: "break-word", wordBreak: "break-all", color:fontColor(row[3]), fontWeight: topLevelBoldFont} :
                                            {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden', color:fontColor(row[3]), fontWeight: topLevelBoldFont}
                                        }>
                                {focused ? row[1] : row[1].toString().substring(0,50)}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid container item width={'180px'} id={`${path}-table-item-type`} key={`${path}-table-item-type`} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                <Grid item xs={2} justifyContent={"center"} alignItems={"center"}>
                    {   isRowEdited && !row[4][1]?
                        <IconButton aria-label={`config-table-item-undo-${path}`}  key={`${path}-table-item-undo`} fontSize={"small"}
                                    onClick={() => onUndoFunction()}>
                            <img src={Undo} width={"13px"} height={"15px"}/>
                        </IconButton>
                        : null
                    }
                    {   !isRowEdited && isUndone?
                        <IconButton aria-label={`config-table-item-redo-${path}`}  key={`${path}-table-item-redo`} fontSize={"small"}
                                    onClick={() => onRedoFunction()}>
                            <img src={Redo} width={"13px"} height={"15px"}/>
                        </IconButton>
                        : null
                    }
                </Grid>
                <Grid item xs={2}>
                        { fetchIcon(row[3]) }
                </Grid>
                <Grid item xs={5} >
                    {row[3] === "LOCAL_OVERRIDE" ?
                        <Tooltip title={ row[3] === "LOCAL_OVERRIDE" ? overriddenValue : null} placement='top' arrow>
                            <Typography p={0}
                                        fontSize={"16px"}
                                        fontWeight={"400"}
                                        fontFamily={'Amazon Ember'}
                                        style={{
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                overflow: 'hidden',
                                                color: '#D27C60'
                                        }}>
                                {overriddenValue}
                            </Typography>
                        </Tooltip>: null
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        isHover && isEditable && row[3]==="LOCAL"?
                            <IconButton key={`${path}-table-item-delete-button`} onClick={() => onDeleted()}>
                                <img src={Delete} width={"17px"} height={"17px"} />
                            </IconButton>
                            : null
                    }
                    {
                        isHover && isEditable && row[3]==="LOCAL_OVERRIDE"?
                            <IconButton aria-label={`config-table-delete-local-override-object-${path}`} key={`${path}-table-item-delete-button`} onClick={() => onDeleted()}>
                                <img src={RemoveOverrideImg} width={"17px"} height={"17px"}/>
                            </IconButton>
                            : null
                    }
                </Grid>
                <Grid item xs={1}>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default ConfigTableKeyValue;