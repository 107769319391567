import React, { useEffect, useState } from "react";
import { Box, Grid, makeStyles, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Topbar from "./components/Shared/Topbar";
import MenuBar from "./components/Shared/MenuBar";
import TenantTable from "./components/TenantTable/TenantTable";
import {
  fetchTenants,
  fetchConfigs,
  createConfig,
  updateTenantConfig,
  createVersion,
  addTenants,
  tenantsFailed,
} from "./redux/ActionCreators";
// import { getCurrentUser } from "./authentication";
import ConfigTable from "./components/ConfigTable/ConfigTable";
import { getTenantConfigs } from "./logic/getTenantConfigs";
import MainPage from "./screens/MainPage";
import { getCurrentUser } from "./authentication";
import { loadTenants } from "./api/services";
import { updateUsername } from "./components/common/SharedFunctions";

const mapStateToProps = (state) => {
  return {
    tenants: state.tenants,
    configs: state.configs,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchTenants: (getAWSClient) => {
    dispatch(fetchTenants(getAWSClient));
  },
  fetchConfigs: (getAWSClient, tenantName, message, selectedConfig) => {
    dispatch(fetchConfigs(getAWSClient, tenantName, message, selectedConfig));
  },
  updateTenantConfig: (
    getAWSClient,
    tenantName,
    tenantConfigs,
    configName,
    latestConfigVersion
  ) => {
    dispatch(
      updateTenantConfig(
        getAWSClient,
        tenantName,
        tenantConfigs,
        configName,
        latestConfigVersion
      )
    );
  },
  createConfig: (getAWSClient, tenantName, tenantConfigs, configName) => {
    dispatch(createConfig(getAWSClient, tenantName, tenantConfigs, configName));
  },
  createVersion: (
    getAWSClient,
    tenantName,
    tenantConfigs,
    configName,
    latestConfigVersion
  ) => {
    dispatch(
      createVersion(
        getAWSClient,
        tenantName,
        tenantConfigs,
        configName,
        latestConfigVersion
      )
    );
  },
  loadTenants: (currentUser) => {
    dispatch(loadTenants(currentUser));
  },
});

const App = (props) => {
  const {
    tenants,
    configs,
    fetchConfigs,
    fetchTenants,
    updateTenantConfig,
    createConfig,
    createVersion,
    loadTenants,
  } = props;
  const [selectedConfig, setSelectedConfig] = React.useState(null);
  const [selectedTenant, setSelectedTenant] = React.useState(null);
  const [selectedVersion, setSelectedVersion] = React.useState(null);
  const [selectedParentConfig, onSelectedParentConfig] = React.useState(null);
  const [cognitoUser, setUser] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const [editParentConfig, setEditParentConfig] = React.useState(false);
  const [latestConfigVersion, setLatestConfigVersion] = React.useState(null);
  const [oldConfigVersion, setOldConfigVersion] = React.useState(null);
  const [isConfigInvalid, setIsConfigInvalid] = React.useState(false);
  const [newUI, setNewUI] = React.useState(true);

  const onSetOldConfigVersion = (value) => {
    setOldConfigVersion(value);
  };
  const onTenantSelected = (tenant) => {
    fetchConfigs(props.getAWSClient, tenant, "", []);
    setSelectedTenant(tenant);
    setSelectedConfig(null);
  };

  const onConfigSelected = (config, parentConfig) => {
    fetchConfigs(
      props.getAWSClient,
      selectedTenant,
      "",
      Array(config, parentConfig ? parentConfig : "")
    );
    setSelectedConfig(config);
  };

  const onVersionSelected = (version) => {
    setSelectedVersion(version);
  };

  const onParentConfigSelected = (parentConfig) => {
    onSelectedParentConfig(parentConfig);
  };

  const onEditParentConfig = (value) => {
    setEditParentConfig(value);
  };

  const onLogoClick = () => {
    setNewUI(false);
    setSelectedConfig(null);
    setSelectedTenant(null);
  };
  const onNewUiClick = () => {
    setNewUI(true);
  };

  const onChangeIsConfigInvalid = (value) => {
    setIsConfigInvalid(value);
  };

  React.useEffect(() => {
    setMessage("");
  }, [selectedConfig, selectedTenant, isConfigInvalid]); // runs once on mount

  React.useEffect(() => {
    setMessage(configs.errMess);
  }, [configs.errMess]);

  React.useEffect(() => {
    setUser(updateUsername(props.username));
    loadTenants(updateUsername(props.username)); //fetch all tenants from appsync
    // getCurrentUser().then((user) =>
    //  {
    //      setUser(updateUsername(user.username));
    // //     fetchTenants(props.getAWSClient);
    //  //    loadTenants()
    //  }).catch((e) => console.log("Failed getting current user"))
  }, [props.username]); // runs once on mount

  const loggedInUser = () => {
    return (
      <Stack>
        {/* Uncomment below line to see react router working */}
        {/* <Link to="/testing"> Go to testing route </Link> */}
        {newUI ? (
          <MainPage
            user={cognitoUser}
            tenants={tenants.tenants}
            fetchConfigs={fetchConfigs}
            getTenantConfigs={getTenantConfigs}
            updateTenantConfig={updateTenantConfig}
            createConfig={createConfig}
            createVersion={createVersion}
            onEditParentConfig={onEditParentConfig}
            getAWSClient={props.getAWSClient}
            configs={configs}
            loadTenants={loadTenants}
          />
        ) : (
          <Grid container aria-label="app" spacing={1}>
            <Grid item xs={8}>
              <MenuBar
                tenants={tenants.tenants}
                configs={configs.configs.configsMap}
                selectedTenant={selectedTenant}
                selectedConfig={selectedConfig}
                onTenantSelected={onTenantSelected}
                onConfigSelected={onConfigSelected}
                onVersionSelected={onVersionSelected}
                selectedVersion={selectedVersion}
                selectedParentConfig={selectedParentConfig}
                onParentConfigSelected={onParentConfigSelected}
                onEditParentConfig={onEditParentConfig}
                editParentConfig={editParentConfig}
                createVersion={createVersion}
                user={cognitoUser}
                latestConfigVersion={latestConfigVersion}
                setLatestConfigVersion={setLatestConfigVersion}
                oldConfigVersion={oldConfigVersion}
                getTenantConfigs={getTenantConfigs}
                onChangeIsConfigInvalid={onChangeIsConfigInvalid}
                getAWSClient={props.getAWSClient}
              />
              <Box style={{ height: "75vh", overflow: "auto" }}>
                {selectedTenant === null ? (
                  <Box height={"100%"} pt={1}>
                    <Box
                      height={"100%"}
                      border={"1px solid #595959"}
                      pt={2}
                      pl={1}
                      pr={1}
                    >
                      <Typography
                        variant="h6"
                        fontFamily={"Amazon Ember"}
                        fontWeight={"bold"}
                      >
                        {" "}
                        "ConfigStore"
                      </Typography>
                      <Typography variant="h7" fontFamily={"Amazon Ember"}>
                        is a centralized storage of configurations for different
                        flows/jobs in airflow. It stores the configurations in a
                        hierarchial structure enabling parent/child
                        relationship. ConfigStore UI enables users to Add,
                        Modify and view configurations based on their roles.
                      </Typography>
                    </Box>
                  </Box>
                ) : null}

                {selectedConfig === null && configs.configs.configsMap ? (
                  selectedTenant != null ? (
                    <TenantTable
                      config={configs.configs.configsMap}
                      updateTenantConfig={updateTenantConfig}
                      selectedConfig={selectedConfig}
                      selectedTenant={selectedTenant}
                      createConfig={createConfig}
                      message={message}
                      setMessage={setMessage}
                      user={cognitoUser}
                      getAWSClient={props.getAWSClient}
                    />
                  ) : null
                ) : (
                  <ConfigTable
                    message={message}
                    selectedVersion={selectedVersion}
                    configs={configs.configs.configsMap}
                    selectedConfig={selectedConfig}
                    selectedTenant={selectedTenant}
                    createVersion={createVersion}
                    setMessage={setMessage}
                    onEditParentConfig={onEditParentConfig}
                    user={cognitoUser}
                    latestConfigVersion={latestConfigVersion}
                    oldConfigVersion={oldConfigVersion}
                    setOldConfigVersion={onSetOldConfigVersion}
                    isConfigInvalid={isConfigInvalid}
                    isLoading={configs.isLoading}
                    getAWSClient={props.getAWSClient}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                height="82vh"
                sx={{
                  flex: 1,
                  border: "1px solid #595959",
                  outline: "1px solid black",
                  outlineOffset: "8px",
                }}
              >
                <Stack height={"100%"} spacing={1}>
                  <Box
                    height={"50%"}
                    sx={{ flex: 1, borderBottom: "1px solid #595959" }}
                  ></Box>
                  <Box
                    height={"50%"}
                    sx={{ flex: 1, borderTop: "1px solid #595959" }}
                  ></Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        )}
      </Stack>
    );
  };
  return cognitoUser ? loggedInUser() : <div> Logging in.... </div>;
  // return true ? loggedInUser() : <div> Logging in.... </div>;
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
// export default App;
