import {Box} from "@mui/material";
import React from "react";
import chevronRight from '../../assets/Chevron Right.png';

const styles = {
    local:{
        background: '#000000',
        borderRadius: '4px',
        fontSize: '12px',
        height: '20px',
        width: '20px',
        color: '#FFFFFF',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontWeight: 'bold',
        padding: '8px'
    },
    override: {
        background: 'green',
        borderRadius: '4px',
        fontSize: '12px',
        height: '20px',
        width: '20px',
        color: '#FFFFFF',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontWeight: 'bold',
        padding: '8px'
    },
    inherited: {
        background: 'blue',
        borderRadius: '4px',
        fontSize: '12px',
        height: '20px',
        width: '20px',
        color: '#FFFFFF',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontWeight: 'bold',
        padding: '8px'
    }
}
const LocalIcon = () => (<Box component="span" style = {styles.local}>L</Box>);
const OverrideIcon = () => (<Box component="span" style = {styles.override}>O</Box>);
const InheritedIcon = () => (<Box component="span" style = {styles.inherited}>I</Box>);


const ChevronRight = () => {
    return <Box>
        <img src={chevronRight} />
    </Box> 
};
const ChevronUp = () => {};
const Chevron = () => {};
const Close = () => {};
const CrossCircle = () => {};
const CrossFilled = () => {};
const Delete = () => {};
const DropdownTriangle = () => {};
const Dropdown = () => {};
const ExpandIcon = () => {};
const Inherited = () => {};
const Local = () => {};
const Override = () => {};
const PlusCircle = () => {};
const Plus = () => {};
const Save = () => {};
const Search = () => {};
const Undo = () => {}; 

export { LocalIcon, OverrideIcon, InheritedIcon};