import React from 'react';
import { MenuItem, Button, Menu } from "@mui/material";
import '../../styles/sidebar.css';
import { KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';
import SearchField from './SearchField';

const DropDownMenu = (props) => {
    const { category, items, handleSelection, stylingType } = props;
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [ menuItems, setMenuItems ] = React.useState(null);

    const stylingTypes = {
        tenantsAndConfigs: {
            backgroundColor: "#595959",
            color: "white",
            borderRadius: 0,
            padding: 8,
            fontWeight: "bold",
            width: "100%",
            fontSize: "1rem"
        },
        versionControl: {
            backgroundColor: "white",
            color: "black",
            border: "1px solid grey",
            borderRadius: 0,
            textTransform: "none",
            width: "100%",
            height: '100%',
            justifyContent: 'space-between'
        }
    }

    const open = Boolean(anchorEl);
    const handleOpen = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const passUpSelection = (value) => {
        handleSelection({category: value.category, item: value.item});
        handleSearchQuery('')
        handleClose();
    };

    const handleSearchQuery = (query) => {
        if(query === ''){ 
            setMenuItems(items)
        } else {
            const temp_items = items.filter(item => item.substring(0,query.length).toLowerCase() === query.toLowerCase())
            setMenuItems(temp_items)
        }
    };
    
    // eslint-disable-next-line
    React.useEffect(() => {
        handleSearchQuery('')
    }, [ items ])

    return <>
        <Button aria-label={`dropdown-menu-button-${category}`} data-testid={`${category}-dropdown-menu`} id={`${category}-dropdown-menu`} onClick={(e) => handleOpen(e)}  style={stylingTypes[stylingType]} >
            { category } { open ? <KeyboardArrowUp /> : <KeyboardArrowDown /> }
        </Button>
        <Menu aria-label={`dropdown-menu-${category}`}  data-testid={category+'-menu'} id={category+'-menu'} anchorEl={anchorEl} open={open} onClose={handleClose}>
            <div style={{paddingLeft: '15px', paddingRight: '15px', paddingBottom: '5px', paddingTop: '5px'}}>
                <SearchField placeholder="" handleSearchQuery={handleSearchQuery} variantStyle="menuItem" startIcon={<Search/>}/>
            </div>
            {
                menuItems != null && menuItems ? menuItems.map( item => { 
                    return <MenuItem aria-label={`dropdown-option-${category}-${item}`} style={{color:(item === "EMPTY PARENT")?'crimson':'black'}} data-testid={`${category}-dropdown-menu-item-${item}`} id={`${category}-dropdown-menu-item-${item}`} key={item} onClick={() => passUpSelection({category: category, item: item})}>{item}</MenuItem> })
                : null
            }
        </Menu>
    </>
};

export default DropDownMenu;