import React, {useEffect, useState} from "react";
import {Box, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {AddOutlined, DifferenceOutlined, FileUploadOutlined, StarBorderOutlined,StarOutlined} from "@mui/icons-material";
import Pipe from "../../assets/PipeIcon.png";
import {CONFIG_CARD, DATA_CARD, TENANT_CARD} from "../constants/HomePageConstants";
import {DIFFERENCES_PAGE, PipStyle} from "../constants";
import {hasRequiredAccess} from "../common/SharedFunctions";
import styles from "../../styles/TableStyles.module.css";
import {useSelector} from "react-redux";

const IconStyle={width:'16px',height:'16px',color:'#808080',borderRadius:'0'}
const IconStyleDisabled={width:'16px',height:'16px',color:'#bfbfbf',borderRadius:'0'}


const CardActionPopUp=(props)=>{

    const {cardTenant,cardType,cardConfiguration,cardFunctionality,setOpenConfigModal, setOpenUploadModal,openDifferencePage}=props
    const [favourite,setFavourite]=useState(false)
    const [currentPipStyle,setCurrentPipStyle]=useState(PipStyle.Dev)
    const handleClickFavourite=(e)=>{
        e.stopPropagation()
        setFavourite(!favourite)
    }
    useEffect(() => {
        if(cardTenant==="ProdConfigStore"){
            setCurrentPipStyle(PipStyle.Prod)
        }
        else if(cardTenant==="DemoConfigStore"){
            setCurrentPipStyle(PipStyle.Test)
        }
    }, []);

    const allUpdatableTenants=useSelector(({tenantsReducer})=>({
        updatableTenants:tenantsReducer.updatableTenants,
        reviewableTenants:tenantsReducer.reviewableTenants
    }))

    const [updateAccess,setUpdateAccess]=useState(false);
    const [reviewAccess,setReviewAccess]=useState(false);


    useEffect(() => {
        if(cardType===TENANT_CARD && cardFunctionality===DATA_CARD){
            if(cardTenant){
                const updateAccess = hasRequiredAccess(allUpdatableTenants.updatableTenants,cardTenant);
                setUpdateAccess(updateAccess);
                const reviewAccess = hasRequiredAccess(allUpdatableTenants.reviewableTenants,cardTenant);
                setReviewAccess(reviewAccess);
            }
        }
    }, [allUpdatableTenants.updatableTenants,allUpdatableTenants.reviewableTenants,cardTenant])



    const handleTenant =()=>{
        if(cardType===TENANT_CARD && cardFunctionality===DATA_CARD){
            //PipeLine are implementing in next Phase
            // return <Box sx={{bgcolor:currentPipStyle.color,color:'#FFF',padding:'1px 6px',borderRadius:'100px',fontSize:'13px'}}>
            //     {currentPipStyle.text}
            // </Box>
        }
        else if(cardType===CONFIG_CARD){
            return <Typography sx={{color:'#808080',fontWeight:400,fontSize:'12px',padding:'1px 0px',lineHeight:'20px',fontFamily:'Amazon Ember',letterSpacing:'0.4px'}}>
                {cardTenant}
            </Typography>
        }
    }

    return(
        <Grid container item sx={{bgcolor:'#EFF7FE',borderBottomLeftRadius:'4px',borderBottomRightRadius:'4px',p:'0px 8px',minHeight:'33px' }} >
            <Grid item xs='auto' marginTop='7.5px'>
                {handleTenant()}
            </Grid>
            {cardFunctionality===DATA_CARD &&
                <Grid container item xs display='flex' gap='8px' py='4px'  justifyContent='flex-end' alignItems='center' >
                    <Grid item>
                        {cardType===TENANT_CARD &&
                            <IconButton
                                disabled={!updateAccess}
                                sx={!updateAccess?IconStyleDisabled:IconStyle}
                                onClick={()=> setOpenConfigModal(true)}
                            >
                                <Tooltip title='Create Config' placement='top' arrow><AddOutlined
                                    sx={!updateAccess?IconStyleDisabled:IconStyle}
                                />
                                </Tooltip>
                            </IconButton>}
                    </Grid>
                    <Grid item>
                        <IconButton
                            disabled={!updateAccess}
                            sx={!updateAccess?IconStyleDisabled:IconStyle}
                            onClick={()=> setOpenUploadModal(true)} >
                            <Tooltip title='Upload' placement='top' arrow><FileUploadOutlined
                                sx={!updateAccess?IconStyleDisabled:IconStyle}
                            /></Tooltip>
                        </IconButton>
                    </Grid>
                    <Grid item >
                        <IconButton
                            disabled={!reviewAccess}
                            sx={!reviewAccess?IconStyleDisabled:IconStyle}
                            onClick={ (e)=>openDifferencePage(e,false,cardTenant,cardConfiguration)}>
                            <Tooltip title='Differences' placement='top' arrow >
                                <DifferenceOutlined
                                    sx={!reviewAccess?IconStyleDisabled:IconStyle}
                                /></Tooltip>
                        </IconButton>
                    </Grid>
                    {/*Pipelines and favourites are implemented in next phase*/}
                    {/*<Grid item pb='3px'>               */              }
                    {/*    <IconButton sx={IconStyle}>*/}
                    {/*        <Tooltip title='Pipeline' placement='top' arrow><img src={Pipe} width={"16px"} height={"16px"}  alt={"PipeIcon"}/></Tooltip>*/}
                    {/*  (e)=>handleDifferencePage(e,false,cardTenant,cardConfiguration,DIFFERENCES_PAGE)  </IconButton>*/}
                    {/*    </Grid>*/}
                    {/*<Grid item>*/}
                    {/*    <Tooltip title='Favourite' placement='top' arrow>*/  }
                    {/*    <IconButton sx={IconStyle} onClick={handleClickFavourite}>*/}

                    {/*                {favourite?<StarOutlined sx={{width:'16px',height:'16px',color:'#FFD600'}}/>*/}
                    {/*                            :<StarBorderOutlined sx={IconStyle}/>}*/}

                    {/*    </IconButton>*/}
                    {/*    </Tooltip>*/}
                    {/*</Grid>*/}
                </Grid>
            }
        </Grid>
    )
}

export default CardActionPopUp;