import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

import {Tenants} from './tenants';
import { Configs  } from './configurations';
import {TenantsReducer} from "./reducers/tenant-reducer";

// creating store
export const ConfigureStore = () => {
    const store = createStore(
        combineReducers({
            tenants: Tenants,
            configs: Configs,
            tenantsReducer:TenantsReducer
        }),
    applyMiddleware( thunk, logger )
);

    return store;
}