import React, {useEffect, useState} from 'react';
import {Grid, IconButton, Menu, MenuItem, Table, TableBody, TableRow, Typography} from "@mui/material";
import './configTableRow.css';
import {ArrowDropDown, ArrowDropUp} from "@mui/icons-material";
import {Input} from "reactstrap";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Delete from "../../assets/Delete.png";
import Undo from "../../assets/undo-button.png";
import Redo from "../../assets/redo-button.png";


const ConfigTableObject = (props) => {
    const { k, v, path, getRows, isEditable, updateKey, onDeleteRow, undoEverything } = props;
    const [ expanded, setExpanded ] = React.useState(false);
    const [focused, setFocused] = React.useState(false)
    const [isHover, setHover] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [keyField, setKeyField] = useState(v[0]);
    const [isDeleted, setDeleted] = useState(false);
    const [isRowEdited, setIsRowEdited] = useState(false);
    const [isUndone, setIsUndone] = useState(false);
    const [undoSaved, setUndoSaved] = useState(new Map());

    const onDeleted = () => {
        setDeleted(true)
        onDeleteRow(path)
        setIsRowEdited(true)
    }

    const onUndoFunction = () => {
        let setUndoMap = new Map()
        setUndoMap.set("key", keyField)
        setUndoMap.set("deleteFlag", isDeleted)
        setUndoSaved(setUndoMap)
        undoEverything(path, setKeyField)
        setDeleted(false)
        setIsUndone(true)
        setIsRowEdited(false)
    }

    const onRedoFunction = () => {
        setIsRowEdited(true)
        setKeyField(undoSaved.get('key'))
        setDeleted(undoSaved.get('deleteFlag'))
        updateKey(path,undoSaved.get('key'))
        if(undoSaved.get('deleteFlag')) {
            onDeleteRow(path)
        }
    }

    const theme = createTheme({
        palette: {
            primary:{
                main:"#000000"
            }
        },
    });

    useEffect(() => {
        setKeyField(v[0])
        setAnchorEl(null)
        setExpanded(false)
        setFocused(false)
        setHover(false)
        setDeleted(false)
        setIsRowEdited(false)
        setIsUndone(false)
        setUndoSaved(new Map())
    },[v]);

    const onAddParamsClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const onAddParamsClose = (type) => {
        setAnchorEl(null);
        if  (type) {
            props.addRowItem(path, v[1].size, type)
        }
    }

    const onClick = () => {
        setFocused(!focused)
    }

    function onChangeKeyData(value) {
        setKeyField(value)
        updateKey(path, value)
        setIsRowEdited(true)
    }

    return(
        <Grid container id={`${path}-table-item`} key={`${path}-table-item`} justifyContent={"flex-start"} alignItems={"center"} pl={0.8}>
            <Grid container item xs={12}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)} justifyContent={"flex-start"} alignItems={"center"}>

                <Grid item xs={0.4} id={`${path}-table-item-header`} key={`${path}-table-item-header`}>
                <IconButton
                    aria-label={`config-table-expand-object-row-${path}`}
                    onClick={() => setExpanded(!expanded)}
                    id={`${path}-table-item-dropdown`}
                    key={`${path}-table-item-dropdown`}
                    className="expandableTableHeaderRowIconCellButton"
                >
                    { expanded ? <ArrowDropUp/> : <ArrowDropDown/> }
                </IconButton>
                </Grid>
                <Grid item xs={3.6} id={`${path}-table-item-header-3`}  border={'1px  red'} key={`${path}-table-item-header-3`}>
                    {isEditable && !props.isArrayRow?
                        <Input
                            aria-label={`config-table-row-key-${path}`}
                            id={`${path}-table-item-header-3`}
                            key={`${path}-table-item-header-3`}
                            className="textField"
                            value={keyField}
                            onChange={(e) => onChangeKeyData(e.target.value)}
                            style={{border: isHover ? '1.5px solid #9747FF' : '0.5px solid #979797', textDecoration: isDeleted? "line-through" : "none"}}
                        /> :
                        <Typography p={0}
                                    onClick={onClick}
                                    fontSize={"0.8rem"}
                                    fontWeight={"401"}
                                    fontFamily={'Amazon Ember'}
                                    style={focused ?
                                        {wordWrap: "break-word"} :
                                        {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}}>
                            {v[0]}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={4} id={`${path}-table-item-header-2`} key={`${path}-table-item-header-2`}  border={'1px  violet'} pl={1}>
                        <Typography p={0}
                                    onClick={onClick}
                                    fontSize={"0.9rem"}
                                    fontWeight={"401"}
                                    fontFamily = {'Amazon Ember'}
                                    style={focused?
                                        { wordWrap: "break-word" } :
                                        { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow:'hidden' }}>
                            : &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {v[1].size} props   {/*Props UI Object*/}
                        </Typography>


                </Grid>
                {isEditable ?
                    <Grid container item xs={4} border={'1px  orange'}>
                        <Grid item xs={2} alignItems={"center"} >
                            {   isRowEdited && !v[4][1]?
                                <IconButton style={{padding: 0}} key={`${path}-table-item-undo`} fontSize={"small"}
                                            onClick={() => onUndoFunction()}>
                                    <img src={Undo} width={"13px"} height={"15px"}/>
                                </IconButton>
                                : null
                            }
                            {   !isRowEdited && isUndone?
                                <IconButton style={{padding: 0}} key={`${path}-table-item-redo`} fontSize={"small"}
                                            onClick={() => onRedoFunction()}>
                                    <img src={Redo} width={"13px"} height={"15px"}/>
                                </IconButton>
                                : null
                            }
                        </Grid>
                        <Grid item xs={2}>

                        {
                            isHover ?
                                <IconButton aria-label={`config-table-delete-object-${path}`}  style={{padding:0}} key={`${path}-table-item-delete-button`} onClick={() => onDeleted()}>
                                    <img src={Delete} width={"17px"} height={"17px"} />
                                </IconButton>
                                : null
                        }
                        </Grid>
                        <Grid item xs={8}>
                        <ThemeProvider theme={theme}>
                            <IconButton
                                aria-label={`add-config-to-row-button-${path}`}
                                style={{padding: 0}}
                                id="basic-button"
                                open={Boolean(anchorEl)}
                                // aria-controls={openAddParams ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                // aria-expanded={openAddParams ? 'true' : undefined}
                                onClick={onAddParamsClick}

                            >
                                <AddCircleOutlineIcon color={"primary"}/>
                            </IconButton>
                        </ThemeProvider>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            onClose={() => onAddParamsClose()}
                            open={Boolean(anchorEl)}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem aria-label={`add-config-object-option-${path}`} onClick={() => onAddParamsClose("object")}>Object</MenuItem>
                            <MenuItem aria-label={`add-config-list-option-${path}`}  onClick={() => onAddParamsClose("list")}>List</MenuItem>
                            <MenuItem aria-label={`add-config-key-value-option-${path}`}  onClick={() => onAddParamsClose("key-value")}>Key-Value Pair</MenuItem>
                        </Menu>

                    </Grid>
                    </Grid>
                    : null
                 }
            </Grid>

            { expanded ?
                <Grid item xs ={12} id={`${path}-table-item-object`} key={`${path}-table-item-object`} pl={1.8}>
                    {
                        getRows(v[1])
                    }

                </Grid>  : null
            }
    </Grid>
    )

}

export default ConfigTableObject;