import React, {useEffect, useRef, useState} from 'react';
import {Grid, IconButton, Menu, MenuItem, Table, TableBody, TableRow, TextField, Typography} from "@mui/material";
import {ArrowDropDown, ArrowDropUp, ArrowRight} from "@mui/icons-material";
import {Input} from "reactstrap";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Delete from "../../../../assets/Delete.png";
import Undo from "../../../../assets/undo-button.png";
import Redo from "../../../../assets/redo-button.png";
import styles from "../../../../styles/TableStyles.module.css";
import {BOLD_FONT, REGULAR_FONT} from "../../../constants/TableConstants";


const ConfigTableObject = (props) => {
    const { index, row, path, getRows, isEditable, updateKey, onDeleteRow, undoEverything, expandAll, onExpandRow, onEditRow } = props;
    const [ expanded, setExpanded ] = React.useState(row[4][2]===1);
    const [focused, setFocused] = React.useState(false)
    const [isHover, setHover] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [keyField, setKeyField] = useState(row[0]);
    const [isDeleted, setDeleted] = useState(false);
    const [isRowEdited, setIsRowEdited] = useState(row[4][3]===1);
    const [isUndone, setIsUndone] = useState(false);
    const [undoSaved, setUndoSaved] = useState(new Map());
    const inputReference = useRef(null);


    const topLevelBoldFont = path.length > 1 ? REGULAR_FONT : BOLD_FONT;

    const onDeleted = () => {
        setDeleted(true)
        onDeleteRow(path)
        setIsRowEdited(true)
        onEditRow(path, 1)
    }

    const onUndoFunction = () => {
        let setUndoMap = new Map()
        setUndoMap.set("key", keyField)
        setUndoMap.set("deleteFlag", isDeleted)
        setUndoSaved(setUndoMap)
        undoEverything(path, setKeyField)
        setDeleted(false)
        setIsUndone(true)
        setIsRowEdited(false)
        onEditRow(path, 0)
    }

    const onRedoFunction = () => {
        setIsRowEdited(true)
        onEditRow(path, 1)
        setKeyField(undoSaved.get('key'))
        setDeleted(undoSaved.get('deleteFlag'))
        updateKey(path,undoSaved.get('key'))
        if(undoSaved.get('deleteFlag')) {
            onDeleteRow(path)
        }
    }

    const theme = createTheme({
        palette: {
            primary:{
                main:"#000000"
            }
        },
    });

    useEffect(() => {
        setKeyField(row[0])
        setAnchorEl(null)
        setExpanded(row[4][2]===1)
        setFocused(false)
        setHover(false)
        setDeleted(false)
        setIsRowEdited(row[4][3]===1)
        setIsUndone(false)
        setUndoSaved(new Map())
    },[row]);

    useEffect(() => {
        if(inputReference && inputReference.current && keyField==='') inputReference.current.focus();
    }, [isEditable,props.isArrayRow]);

    const onAddParamsClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const onExpand = () => {
        const value = expanded === false? 1 : 0 ;
        setExpanded(!expanded);

        onExpandRow(path, value)
    }

    const onAddParamsClose = (type) => {
        setAnchorEl(null);
        if  (type) {
            setExpanded(true);
            onExpandRow(path, 1)
            props.addRowItem(path, row[1].size, type)
        }
    }

    const onClick = () => {
        setFocused(!focused)
    }

    function onChangeKeyData(value) {
        setKeyField(value)
        updateKey(path, value)
        setIsRowEdited(true)
        onEditRow(path, 1)
    }

    return(
        <Grid container id={`${path}-table-item`} key={`${path}-table-item`} justifyContent={"flex-start"} alignItems={"center"}>
            <Grid container item xs={12}
                  style={{height: '48px',padding:0,borderBottom:'1px solid #EFEFEF'}}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)} justifyContent={"flex-start"} alignItems={"center"}>
                <Grid container item xs justifyContent={"center"} alignItems={"center"}>
                    <Grid item xs={0.5} id={`${path}-table-item-header`} key={`${path}-table-item-header`} pl={0.5}>
                        <IconButton
                            aria-label={`config-table-expand-object-row-${path}`}
                            onClick={() => onExpand()}
                            id={`${path}-table-item-dropdown`}
                            key={`${path}-table-item-dropdown`}
                            className={styles.expandableTableHeaderRowIconCellButton}
                        >
                            { expanded ? <ArrowDropDown/> : <ArrowRight/> }
                        </IconButton>
                    </Grid>
                    <Grid item xs={5} id={`${path}-table-item-header-3`} key={`${path}-table-item-header-3`} pr={2}>
                        {isEditable && !props.isArrayRow?
                            <TextField
                                fullWidth
                                size="small"
                                inputRef={inputReference}
                                aria-label={`config-table-row-key-${path}`}
                                id={`${path}-table-item-header-3`}
                                key={`${path}-table-item-header-3`}
                                className="textField"
                                value={keyField}
                                onChange={(e) => onChangeKeyData(e.target.value)}
                                style={{textDecoration: isDeleted? "line-through" : "none"}}
                                sx={{ input: {fontWeight: topLevelBoldFont}}}>
                            </TextField> :
                            <Typography p={0}
                                        onClick={onClick}
                                        fontSize={"16px"}
                                        fontWeight={expanded ? BOLD_FONT : topLevelBoldFont}
                                        fontFamily={'Amazon Ember'}
                                        style={focused ?
                                            {wordWrap: "break-word"} :
                                            {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}}>
                                {row[0]}
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={6.5} id={`${path}-table-item-header-2`} key={`${path}-table-item-header-2`}>
                        <Typography p={0}
                                    onClick={onClick}
                                    fontSize={"16px"}
                                    fontWeight={expanded ? BOLD_FONT : topLevelBoldFont}
                                    fontFamily = {'Amazon Ember'}
                                    style={focused?
                                        { wordWrap: "break-word" } :
                                        { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow:'hidden' }}>
                            : &nbsp; {row[1].size} props
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container width={'180px'} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
                    <Grid item xs={1} justifyContent={"center"} alignItems={"center"}>
                        {isEditable ? <>
                            {isRowEdited && !row[4][1] ?
                                <IconButton key={`${path}-table-item-undo`} fontSize={"small"}
                                            onClick={() => onUndoFunction()}>
                                    <img src={Undo} width={"13px"} height={"15px"}/>
                                </IconButton>
                                : null
                            }
                            {!isRowEdited && isUndone?
                                <IconButton key={`${path}-table-item-redo`} fontSize={"small"}
                                onClick={() => onRedoFunction()}>
                                <img src={Redo} width={"13px"} height={"15px"}/>
                                </IconButton>
                                : null
                            }</> : null
                        }
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2.7}>
                        { isEditable ?
                            <>
                                <ThemeProvider theme={theme}>
                                    <IconButton
                                        aria-label={`add-config-to-row-button-${path}`}
                                        id="basic-button"
                                        open={Boolean(anchorEl)}
                                        // aria-controls={openAddParams ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        // aria-expanded={openAddParams ? 'true' : undefined}
                                        onClick={onAddParamsClick}

                                    >
                                        <AddCircleOutlineIcon color={"primary"}/>
                                    </IconButton>
                                </ThemeProvider>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    onClose={() => onAddParamsClose()}
                                    open={Boolean(anchorEl)}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem aria-label={`add-config-object-option-${path}`} onClick={() => onAddParamsClose("object")}>Object</MenuItem>
                                    <MenuItem aria-label={`add-config-list-option-${path}`}  onClick={() => onAddParamsClose("list")}>List</MenuItem>
                                    <MenuItem aria-label={`add-config-key-value-option-${path}`}  onClick={() => onAddParamsClose("key-value")}>Key-Value Pair</MenuItem>
                                </Menu>
                            </> : null
                        }
                    </Grid>
                    <Grid item xs={2.5}></Grid>
                    <Grid item xs={2}>
                        { isEditable ?
                            <>
                                {
                                isHover ?
                                    <IconButton aria-label={`config-table-delete-object-${path}`} key={`${path}-table-item-delete-button`} onClick={() => onDeleted()}>
                                        <img src={Delete} width={"17px"} height={"17px"} />
                                    </IconButton>
                                    : null
                                }
                            </> : null
                        }
                    </Grid>
                    <Grid item xs={1.5}></Grid>
                </Grid>
            </Grid>

            { expanded || expandAll ?
                <Grid item xs ={12} id={`${path}-table-item-object`} key={`${path}-table-item-object`} pl={2.1}>
                    {
                        getRows(row[1])
                    }

                </Grid>  : null
            }
    </Grid>
    )

}

export default ConfigTableObject;