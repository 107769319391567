import React, {useState} from 'react';
import {Grid, Table, TableBody, TableRow, Typography} from "@mui/material";
import './configTableRow.css';
import ConfigTableObject from "./ConfigTableObject";
import ConfigTableKeyValue from "./ConfigTableKeyValue";
import ConfigTableArray from "./ConfigTableArray";

const ConfigTableRow = (props) => {
    const { k, v, path, isEditable, updateKey, updateValue, updateType, getOverridenValue, onDeleteRow, undoEverything, onRemoveOverride, selectedIcons } = props;

    const getRows= (metaMap) => {
        const comps = [];
        if (selectedIcons.length === 0) {
            metaMap.forEach((v, k) => {
                let path_value = [...path, k]
                comps.push(
                    <ConfigTableRow
                        k={k}
                        v={v}
                        path={path_value}
                        key={path_value + "-configTableRow"}
                        isEditable={isEditable}
                        updateKey={updateKey}
                        updateValue={updateValue}
                        addRowItem={props.addRowItem}
                        updateType={updateType}
                        getOverridenValue={getOverridenValue}
                        onDeleteRow={onDeleteRow}
                        undoEverything={undoEverything}
                        onRemoveOverride={onRemoveOverride}
                        selectedIcons={selectedIcons}
                    />
                )
            });
        } else {
            metaMap.forEach((v, k) => {
                if (v[3] === "" || selectedIcons.includes(v[3])) {
                    let path_value = [...path, k]
                    comps.push(
                        <ConfigTableRow
                            k={k}
                            v={v}
                            path={path_value}
                            key={path_value + "-configTableRow"}
                            isEditable={isEditable}
                            updateKey={updateKey}
                            updateValue={updateValue}
                            addRowItem={props.addRowItem}
                            updateType={updateType}
                            getOverridenValue={getOverridenValue}
                            onDeleteRow={onDeleteRow}
                            undoEverything={undoEverything}
                            onRemoveOverride={onRemoveOverride}
                            selectedIcons={selectedIcons}
                        />
                    )
                }
            });
        }
        return comps;
    }

    const getArrayRows= (metaMap) => {
        const comps = [];
        if (selectedIcons.length === 0) {
            metaMap.forEach((v, k) => {
                let path_value = [...path, k]
                comps.push(
                    <ConfigTableRow
                        k={k}
                        v={v}
                        path={path_value}
                        key={path_value + "-configTableRow"}
                        isEditable={isEditable}
                        updateKey={updateKey}
                        updateValue={updateValue}
                        isArrayRow={true}
                        addRowItem={props.addRowItem}
                        updateType={updateType}
                        getOverridenValue={getOverridenValue}
                        onDeleteRow={onDeleteRow}
                        undoEverything={undoEverything}
                        onRemoveOverride={onRemoveOverride}
                        selectedIcons={selectedIcons}
                    />
                )
            });
        } else {
            metaMap.forEach((v, k) => {
                if (v[3] === "" || selectedIcons.includes(v[3])) {
                    let path_value = [...path, k]
                    comps.push(
                        <ConfigTableRow
                            k={k}
                            v={v}
                            path={path_value}
                            key={path_value + "-configTableRow"}
                            isEditable={isEditable}
                            updateKey={updateKey}
                            updateValue={updateValue}
                            isArrayRow={true}
                            addRowItem={props.addRowItem}
                            updateType={updateType}
                            getOverridenValue={getOverridenValue}
                            onDeleteRow={onDeleteRow}
                            undoEverything={undoEverything}
                            onRemoveOverride={onRemoveOverride}
                            selectedIcons={selectedIcons}
                        />
                    )
                }
            });
        }

        return comps;

    }



    return (
            <Table
            className="mainRowDataTable"
            key={`${path}-table`}
            size="small">
                <TableBody className="mainRowDataTableBody" key={`${path}-tableBody`}>
                <TableRow key={`${path}-table-row`}>
                    {
                        v[2] === "object" ?
                            <ConfigTableObject
                                k ={k}
                                v ={v}
                                path = {path}
                                getRows = {getRows}
                                isEditable = {isEditable}
                                updateKey = {updateKey}
                                isArrayRow = {props.isArrayRow}
                                addRowItem = {props.addRowItem}
                                onDeleteRow = {onDeleteRow}
                                undoEverything={undoEverything}
                            />

                        :
                            ( v[2]==="array"?  (
                                        <ConfigTableArray
                                            k ={k}
                                            v ={v}
                                            path = {path}
                                            getRows = {getArrayRows}
                                            isEditable = {isEditable}
                                            updateKey = {updateKey}
                                            isArrayRow = {props.isArrayRow}
                                            addRowItem = {props.addRowItem}
                                            onDeleteRow = {onDeleteRow}
                                            undoEverything={undoEverything}
                                        />
                                    ) :

                                  <ConfigTableKeyValue
                                      k ={k}
                                      v ={v}
                                      path = {path}
                                      isEditable = {isEditable}
                                      updateKey = {updateKey}
                                      updateValue = {updateValue}
                                      isArrayRow = {props.isArrayRow}
                                      updateType = {updateType}
                                      getOverridenValue = {getOverridenValue}
                                      onDeleteRow = {onDeleteRow}
                                      undoEverything={undoEverything}
                                      onRemoveOverride={onRemoveOverride}
                                  />

                            )
                    }
                </TableRow>
        </TableBody>

        </Table>
    )
}
export default ConfigTableRow;