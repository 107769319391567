import {Box} from "@mui/material";
import React from "react";
const styles = {
    local:{
        background: '#000000',
        borderRadius: '4px',
        fontSize: '12px',
        height: '15px',
        width: '15px',
        color: '#FFFFFF',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }
}
const LocalIcon = () => (<Box component="span" style = {styles.local}>L</Box>);
export default LocalIcon;
