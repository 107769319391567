import React, {useEffect, useState} from 'react';
import {
    Box,
    Grid,
    TextField,
    MenuItem,
    Stack,
    Button,
    Dialog,
    DialogTitle, FormControl, InputLabel, Select, DialogActions, DialogContent, Typography
} from "@mui/material";
import {SYSTEM_NAME} from "../constants/ApiConstants";
import SwitchRightRoundedIcon from '@mui/icons-material/SwitchRightRounded';
import SwitchLeftRoundedIcon from '@mui/icons-material/SwitchLeftRounded';
import {JsonEditor} from 'jsoneditor-react';
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import 'jsoneditor-react/es/editor.min.css';
import SubmitModal from "./SubmitModal";
import styles from "../../styles/CreateConfigModalStyles.module.css";
import FormTextField from "../Shared/FormTextField";
import {checkItemInList, isValidJSONObject} from "../common/SharedFunctions";

function CreateConfigModal(props){
    const {createConfig, configsMap, configParentList, setSelectedConfiguration, tenantName, user, open, handleClose} = props;
    const [json, setJson] = useState({})
    const [configType, setConfigType] = useState("");
    const [isText, setInputType] = useState(true);
    const [isTextEditorJson, setTextJsonValidation] = useState(true);
    const [textEditorData, setTextEditorData] = useState("");
    const [configName, setConfigName] = useState("");
    const [parentConfig, setParentConfig] = useState("");
    const [versionDescription, setVersionDescription] = useState("");
    const [submitReady, setSubmitReady] = useState(false);
    const [openSubmit, setOpenSubmit] = useState(false);
    const [configNameExists, setConfigNameExists] = useState(false);
    const [configsList, setConfigsList] = useState([]);
    const [hasGlobalConfigs, setHasGlobalConfigs] = useState(false);
    const handleSubmitClose = () => setOpenSubmit(false);

    const handleNameChange = (data) => {
        if (configType !== "global"){
            setConfigName(data.replaceAll(' ', ''));
        }

    }

    const handleTextEditorChange = (data) => {
        setTextEditorData(data)
        if(isValidJSONObject(data)){
            setJson(JSON.parse(data))
            setTextJsonValidation(true);
        } else if (data === ""){
            setJson({});
            setTextJsonValidation(true);
        } else {
            setTextJsonValidation(false)
        }
    };

    const handleChangeType = (val) => {
        setConfigType(val);
    };

    const handleSwitchInput = () => {
        setInputType(current => {return !current;});
    };

    useEffect(() => {
        if(!isText){
            if (JSON.stringify(json) !== "{}") {
                setTextEditorData(JSON.stringify(json, null, 2));
            } else {
                setTextEditorData("");
            }
            setTextJsonValidation(true);
        }
    }, [json]);

    const clearEverything = () => {
        setInputType(true);
        setTextEditorData("");
        setJson({});
        setTextJsonValidation(true);
        setConfigName("");
        setParentConfig("");
        setVersionDescription("");
        setConfigType("");
        setSubmitReady(false);
        setConfigNameExists(false);
        handleClose();
    };

    const onSubmit = () => {
        console.log('JSON TO SEND: ', textEditorData)
        const jsonBody = {
            "configName" : `${configName}`,
            "configType" : `${configType}`,
            "parentConfig" : `${parentConfig}`,
            "tenantName" : `${tenantName}`,
            "system" : `${SYSTEM_NAME}`,
            "configsJson": isText ? textEditorData : JSON.stringify(json),
            "versionDescription": `${versionDescription}`,
            "user": `${user}`,
            "version":0
        }

        console.log(jsonBody)
        console.log(JSON.stringify(jsonBody))
        createConfig(tenantName, jsonBody);
        setSelectedConfiguration(configName);
        setOpenSubmit(false);
        clearEverything()
    }

    useEffect(() => {
        let globalConfigExists = false;
        let list = [];
        if (configsMap !== undefined && configsMap !== null){
            configsMap.forEach((config) => {
                if(config.configType !== "global"){
                    list.push(config.configName);
                } else {
                    globalConfigExists = true;
                }
            });
        }
        setHasGlobalConfigs(globalConfigExists);
        setConfigsList(list);
    }, [configsMap])

    useEffect(() => {
        if (configType !== "child") {
            setParentConfig("")
        }
        if (configType === "global") {
            setConfigName("global_configs")
        } else if (configName === "global_configs") {
            setConfigName("");
        }
    },[configType]);

    useEffect(() => {

        if (checkItemInList(configsList,configName) || (configType !== "global" && configName === "global_configs")) {
            setConfigNameExists(true);
        } else {
            setConfigNameExists(false);
        }

        let configsJson = isText ? textEditorData : JSON.stringify(json);
        if (configName !== "" && configType !== "" && versionDescription && isTextEditorJson && configsJson && configsJson !== "{}" && !((checkItemInList(configsList,configName) ||  (configType !== "global" && configName === "global_configs"))) ){
            setSubmitReady(true);
        } else {
            setSubmitReady(false);
        }
    },[configName, versionDescription, textEditorData,isTextEditorJson, json]);


    return(
        <Box>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={open}
                onClose={clearEverything}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                aria-label='create-config-modal'
            >
                <DialogTitle className={styles.dialog__title}>{tenantName}: Create Configuration</DialogTitle>
                <DialogContent  sx={{overflowY: "hidden"}}>
                    <Grid container className={styles.dialog__content} rowSpacing={1} columnSpacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label='Configuration Name*'
                                value={configName}
                                onChange={(e)=> handleNameChange(e.target.value)}
                                error={configNameExists}
                                helperText={configNameExists ? "Config already Exists" : ""}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Config Type*</InputLabel>
                                <Select
                                    fullWidth
                                    className={styles.environment}
                                    label="Config Type*"
                                    id="filled-select-state"
                                    aria-label='create-tenant-config-type-input'
                                    value={configType ? configType : ""}
                                    onChange={(e) => handleChangeType(e.target.value)}
                                >
                                    <MenuItem value={"child"}>Child</MenuItem>
                                    <MenuItem value={"parent"}>Parent</MenuItem>
                                    {!hasGlobalConfigs ? <MenuItem value={"global"}>Global</MenuItem>: null}
                                </Select>
                            </FormControl>
                        </Grid>
                        {configType === "child" ?
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        select
                                        aria-label='create-tenant-config-parent-name-input'
                                        key={"enabled-parentInput"}
                                        label='Parent'
                                        value={parentConfig ? parentConfig : ""}
                                        onChange={(e)=>setParentConfig(e.target.value)}>
                                        <MenuItem className={styles.none__menuitem} value={''}>None</MenuItem>
                                        {configsMap !== undefined && configsMap !== null ? configParentList() : <MenuItem value={''}></MenuItem>}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormTextField
                                        fullWidth
                                        label='Configuration Description*'
                                        value={versionDescription}
                                        setter={setVersionDescription}
                                        hasWordLimit={true}
                                        wordLimit={200}
                                    />
                                </Grid>
                            </>
                        : configType === "parent" || configType === "global" ?
                            <Grid item xs={12}>
                                <FormTextField
                                    fullWidth
                                    label='Configuration Description*'
                                    value={versionDescription}
                                    setter={setVersionDescription}
                                    hasWordLimit={true}
                                    wordLimit={200}
                                />
                            </Grid> :
                            <Grid item xs={12}></Grid>
                        }
                    </Grid>
                    <Grid className={styles.editor__box} item xs={12}>
                        <Typography className={styles.box__helper_text}>Input configuration*</Typography>
                    {isText ?
                        <Box style={{overflowY:"auto" , height : "100%"}}>
                            <TextField className={styles.editor}
                                aria-label='create-tenant-config-json-body-input'
                                multiline
                                rows={10}
                                fullWidth
                                value={textEditorData}
                                error={!isTextEditorJson}
                                helperText={!isTextEditorJson ? "Not a valid json" : ""}
                                onChange={(e) => handleTextEditorChange(e.target.value)}
                            />
                        </Box>:
                        <JsonEditor
                            mode="tree"
                            history
                            value={json}
                            onChange={setJson}
                            ace={ace}
                            theme="ace/theme/github"
                        />
                    }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container className={styles.button__row} justifyContent="space-between">
                        <Button variant="text" className={styles.switch__button} onClick={handleSwitchInput} key="switch-modal">{ (isText) ? <div>&nbsp;<b>Text</b>&nbsp;<SwitchRightRoundedIcon/>&nbsp;Editor&nbsp;</div> :  <div>&nbsp;Text&nbsp;<SwitchLeftRoundedIcon/>&nbsp;<b>Editor</b>&nbsp;</div> }</Button>
                        <Stack direction={"row"} spacing={2}>
                            <Button aria-label='cancel-create-new-config' variant="text" className={styles.button} onClick={clearEverything} key="cancel-modal" > Cancel</Button>
                            {submitReady ?
                                <Button aria-label='submit-create-new-config' variant="text" className={styles.button}
                                        onClick={onSubmit} key="submit-modal"> Submit</Button>
                            :
                                <Button disabled variant="text" className={styles.button__disabled} key="submit-modal"> Submit</Button>
                            }
                        </Stack>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default CreateConfigModal;