import React, {useEffect, useState} from 'react';
import {Grid, IconButton, Stack, Typography} from "@mui/material";
import {
    ExpandOutlined,
    FilterAltOutlined,
    KeyboardArrowDownOutlined,
    CompressOutlined,
    KeyboardArrowUpOutlined
} from "@mui/icons-material";
import styles from '../../styles/ApprovalsHistory.module.css'
import {FEDERATE_OIDC, SYSTEM_NAME} from "../constants/ApiConstants";
import {fetchAllReviews} from "../../api/services";
import {GLOBAL_CONFIGS} from "../constants";
import {approvalTimestamp, convertTimeZone, sortReviews, updateUsername} from "../common/SharedFunctions";
import {reviewStatus, statusStyle} from "../constants/ApprovalStatusConstants";


const approvalHeadingStyle={fontSize:'12px',color:'#808080',lineHeight:'19.92px'}
const approvalDataStyle={fontSize:'12px',fontFeatureSettings:`'clig' off, 'liga' off`}



const ApprovalHistory=(props) =>{
    const {user, currentTenant,currentConfig,versions,createVersionStatus,openSelectedReview}=props
    const [isApprovalsExpand,setIsApprovalsExpand]=useState(false)
    const [reviewsWithOpen,setReviewsWithOpen]=useState([])
    const [resourceIdentifierApprovals,setResourceIdentifierApprovals]=useState([])

    useEffect(() => {
        fetchReviews()
        setIsApprovalsExpand(false)
    }, [currentTenant,currentConfig,createVersionStatus]);


    async function fetchReviews() {
        let currentResourceIdentifier=''
        if(currentConfig) currentResourceIdentifier=currentTenant+'-'+currentConfig
        else currentResourceIdentifier=currentTenant+'-'+GLOBAL_CONFIGS
        let variables = {
            system: SYSTEM_NAME,
            resourceIdentifier:currentResourceIdentifier,
            fullReview:false,
            user:user
        }
        let input = {
            input: variables
        }
        await fetchAllReviews(setResourceIdentifierApprovals,input)
    }

    useEffect(() => {
        let totalReviewsWithOpen=resourceIdentifierApprovals.map((eachReview)=>({
            ...eachReview,
            open:false
        }))
        setReviewsWithOpen(totalReviewsWithOpen)
    }, [resourceIdentifierApprovals]);


    const handleAllApprovalsExpand=()=> {
        setReviewsWithOpen(resourceIdentifierApprovals.map((eachApproval)=>{
            eachApproval.open=!isApprovalsExpand
            return eachApproval
        }))
        setIsApprovalsExpand(!isApprovalsExpand)
    }
    const handleSingleApprovalExpand=(currenApprovalVersion)=>{
        const updatedResourceIdentifierApprovals=resourceIdentifierApprovals.map((eachApproval)=>{
            if(eachApproval.reviewId===currenApprovalVersion){
                eachApproval.open=!eachApproval.open
            }
            return eachApproval
        })
        setReviewsWithOpen(updatedResourceIdentifierApprovals)
    }

    return(
        <div className={styles.approvals_history}>
            <Grid className={styles.approval_history_header}>
                <Typography className={styles.approval_history_heading}>Approval History</Typography>
            </Grid>
            {reviewsWithOpen.length>0&&
                <Grid container className={styles.reviews_table_header}>
                    <Grid item xs={8.44}>
                        <Typography  className={styles.reviews_table_options}>Version</Typography>
                    </Grid>
                    <Grid item xs={1.44}>
                        <Typography className={styles.reviews_table_options} >Status</Typography>
                    </Grid>
                <Grid item xs>
                    {/* Review Filter is going to implement in next phase
                    <FilterAltOutlined sx={{width:'16px',height:'16px',ml:'18px',mb:'8px',color:'#808080'}}/>*/}
                    <IconButton sx={{width:'16px',height:'16px',ml:'42px',mb:'8px'}} onClick={handleAllApprovalsExpand}>
                        {isApprovalsExpand?<CompressOutlined sx={{width:'16px',height:'16px',color:'#808080'}}/>:
                            <ExpandOutlined sx={{width:'16px',height:'16px',color:'#808080'}}/>}
                    </IconButton>
                </Grid>
            </Grid>}
            <Grid className={styles.reviews_container}>
            {sortReviews(reviewsWithOpen,versions)?.map((eachApproval)=> {
                return<React.Fragment key={eachApproval.reviewId}>
                    <Grid  container className={styles.each_approval_row} sx={{bgcolor:eachApproval.open?'#F9F9F9':'#FFF'}}>
                        <Grid item xs={8.44}>
                            <Typography className={styles.approvals_history_version} onClick={()=>openSelectedReview(eachApproval)}>
                                {approvalTimestamp(eachApproval)}
                            </Typography>
                        </Grid>
                        <Grid item xs={1.44}>
                            <Typography sx={statusStyle[reviewStatus[eachApproval.reviewStatus]]}>{reviewStatus[eachApproval.reviewStatus]}</Typography>
                        </Grid>
                        <Grid item xs >
                            <IconButton className={styles.single_approval_expand_style} onClick={()=>handleSingleApprovalExpand(eachApproval.reviewId)}>
                                {eachApproval.open? <KeyboardArrowUpOutlined  className={styles.single_approval_icon_style}/>:
                                    <KeyboardArrowDownOutlined  className={styles.single_approval_icon_style}/>}
                            </IconButton>
                        </Grid>
                    </Grid>
                    {eachApproval.open&&
                        <Grid sx={{py:'8px',px:'15px',borderBottom:'0.5px solid #E6E6E6'}}>
                            <Grid container  sx={{justifyContent:'space-between',mb:'3px'}}>
                                <Grid item>
                                    <Stack>
                                        <Typography sx={approvalHeadingStyle}>Submitter</Typography>
                                        <Typography sx={approvalDataStyle}>{updateUsername(eachApproval.requesterId)}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Stack>
                                        <Typography sx={approvalHeadingStyle}>Submission</Typography>
                                        <Typography sx={approvalDataStyle}>{convertTimeZone(eachApproval.timestamp).slice(0,10)}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Stack>
                                        <Typography sx={approvalHeadingStyle}>Reviewer</Typography>
                                        {eachApproval.reviewers.length>0&&<Typography sx={approvalDataStyle}>{eachApproval.reviewers[0]}</Typography>}
                                    </Stack>
                                </Grid>
                                <Grid item>
                                    <Stack>
                                        <Typography sx={approvalHeadingStyle}>Reviewed</Typography>
                                        <Typography sx={approvalDataStyle}>{convertTimeZone(eachApproval?.reviewedTimestamp)?.slice(0,10)}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            {eachApproval.reviewDescription &&
                                <Grid>
                                    <Typography sx={{fontSize:'12px',color:'#808080',lineHeight:'19.92px'}}>Description:</Typography>
                                    <Typography sx={{fontSize:'12px',letterSpacing:'0.4px', wordWrap: "break-word" }}>
                                        {eachApproval.reviewDescription}
                                    </Typography>
                                </Grid>}
                        </Grid>}
                </React.Fragment>
            })}
            </Grid>
        </div>
    )
}
export default ApprovalHistory;