import {Box, Grid, MenuItem, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";

const ConfigTableKeyValueType = (props) => {
    const {isEditable, typeValue, path, updateType, setDataType, setIsRowEdited} = props

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleChangeType = (value) => {
        setDataType(value)
        updateType(path, value)
        setIsRowEdited(true)
    }

    const getDataTypeDescription = (value) => {
        if (value === 'string') return 'abc';
        if (value === 'boolean') return 'T/F' ;
        return '123';
    }


    return (
        <>
            {   isEditable?
                <>
                <Grid item xs={0.6} justifyContent={"center"} alignItems={"center"}>
                    :&ensp;&ensp;
                </Grid>
                <Grid item xs={2.2}
                      id={`${props.k}_tenantRow_stack`} justifyContent={"center"} alignItems={"center"}
                      style={{maxWidth: "min-content"}}
                >
                    <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggle}
                        direction="down"
                        id={`${props.k}_tenantRow_type`}
                        group
                        style={{fontFamily:'Amazon Ember'}}
                        data-testid={`config-table-data-type-${path}`}
                    >
                        <DropdownToggle
                            caret
                            style={{
                                height: '30px',
                                backgroundColor: '#979797',
                                color: 'white', border: '0.5px solid #979797',
                                borderRadius: '5px',
                                width: '17px',
                                padding: '0px',
                                paddingRight:'17px',
                                margin: '0px',
                                textAlign: 'center',
                                borderTopRightRadius: '0px',
                                borderBottomRightRadius: '0px',
                                fontFamily:'Amazon Ember'
                            }}
                        >
                        </DropdownToggle>
                        <Button
                            style={{
                                height: '30px',
                                backgroundColor: 'white',
                                color: '#444', border: '0.5px solid #979797',
                                borderRadius: '5px',
                                fontSize: '0.8rem',
                                borderTopLeftRadius: '0px',
                                borderBottomLeftRadius: '0px',
                                fontFamily:'Amazon Ember',
                                width: '50px'
                            }}
                            onClick={toggle}
                            aria-label={`config-table-data-type-button-${path}`}
                        >
                            {getDataTypeDescription(typeValue)}
                        </Button>
                        <DropdownMenu>
                            <DropdownItem
                                aria-label={`config-table-data-type-string-${path}`}
                                value="string"
                                onClick={(e) => handleChangeType(e.target.value)}
                                style={{
                                    fontWeight: '400',
                                    fontSize: '0.8rem',
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    backgroundColor: 'white',
                                    fontFamily:'Amazon Ember',
                                }}
                                key={props.k + "String-datatype"}
                            >
                                abc (string)
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                                aria-label={`config-table-data-type-number-${path}`}
                                value="number"
                                onClick={(e) => handleChangeType(e.target.value)}
                                style={{
                                    fontWeight: '400',
                                    fontSize: '0.8rem',
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    backgroundColor: 'white',
                                    fontFamily:'Amazon Ember'
                                }}
                                key={props.k + "Number-datatype"}
                            >
                                123 (number)
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                                aria-label={`config-table-data-type-boolean-${path}`}
                                value="boolean"
                                onClick={(e) => handleChangeType(e.target.value)}
                                style={{
                                    fontWeight: '400',
                                    fontSize: '0.8rem',
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    backgroundColor: 'white',
                                }}
                                key={props.k + "Boolean-datatype"}
                            >
                                T/F
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    {/*<CssTextField*/}
                    {/*    id={`${props.k}_tenantRow_type`}*/}
                    {/*    select*/}
                    {/*    value={typeValue}*/}
                    {/*    onChange={(e) => handleChangeType(e.target.value)}*/}
                    {/*    fullWidth*/}
                    {/*    style={{border: '0.5px solid #979797', borderRadius: '5px'}}*/}
                    {/*>*/}

                    {/*    <MenuItem style={{*/}
                    {/*        fontWeight: '400',*/}
                    {/*        fontSize: '0.8rem',*/}
                    {/*        paddingTop: '0px',*/}
                    {/*        paddingBottom: '0px',*/}
                    {/*        backgroundColor: 'white'*/}
                    {/*    }} key={path + "String-datatype"} value="string">*/}
                    {/*        abc (String)*/}
                    {/*    </MenuItem>*/}
                    {/*    <MenuItem style={{*/}
                    {/*        fontWeight: '400',*/}
                    {/*        fontSize: '0.8rem',*/}
                    {/*        paddingTop: '0px',*/}
                    {/*        paddingBottom: '0px',*/}
                    {/*        backgroundColor: 'white'*/}
                    {/*    }} key={path + "Number-datatype"} value="number">*/}
                    {/*        123 (Number)*/}
                    {/*    </MenuItem>*/}
                    {/*    <MenuItem style={{*/}
                    {/*        fontWeight: '400',*/}
                    {/*        fontSize: '0.8rem',*/}
                    {/*        paddingTop: '0px',*/}
                    {/*        paddingBottom: '0px',*/}
                    {/*        backgroundColor: 'white'*/}
                    {/*    }} key={path + "boolean-datatype"} value="boolean">*/}
                    {/*        T/F*/}
                    {/*    </MenuItem>*/}
                    {/*</CssTextField>*/}
                </Grid>
                </>
                :
                <>
                    <Grid item xs={0.5}>
                        :
                    </Grid>
                    <Box backgroundColor={'#D9D9D9'} borderRadius={'5px'} >
                    <Typography
                    p={0.5}
                    fontSize={"0.8rem"}
                    fontWeight={"400"}
                    fontFamily={'Amazon Ember'}
                    textOverflow={'ellipsis'}
                    >

                {typeValue === "number" ? "123" : typeValue === "string" ? "abc" : "T/F"}
                    </Typography>
                    </Box>
                </>
            }
        </>
    )
}
export default ConfigTableKeyValueType;