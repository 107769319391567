import React, {useState} from 'react';
import {
    Button, IconButton,
    Stack,
    Table,
    TableBody,
    TableContainer, Typography,
} from '@mui/material';
import '../../styles/table.css';
import TenantTableRow  from "./TenantTableRow";
import {Grid,Box} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import LocalIcon from "../LegendIcons/legendIcons";
import CreateConfigModal from "../Shared/CreateConfigModal";
import SubmitModal from "../Shared/SubmitModal";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Save from "../../assets/save_white.png";
import Edit from "../../assets/edit_white.png";
import Search from "../../assets/search_white.png";
import Cancel from "../../assets/cancel_white.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {CancelOutlined} from "@mui/icons-material";



export default function TenantTable(props) {
    const globalConfigs = props.config.filter(config => config['configName'] === "global_configs")
    const configValue = !globalConfigs.length ? new Map() : new Map(Object.entries(JSON.parse((globalConfigs[0])['configParams'])))
    const [config, setConfig] = useState(configValue);
    const [isEditable, setEditable] = useState(false);
    let val = new Map()
    let count =0;
    configValue.forEach((v, k) => {
        val.set(count.toString(), [k,typeof v === "boolean" ? v===true?"true":"false" : v,typeof v, 0, 0, 0])
        count+=1;
    })
    const [newConfig, setNewConfigMap] = useState(val);
    const [countNewRows, updateCountNewRows] = useState(configValue.size);
    const [createParamMap, updateCreateParamMap] = useState(val);
    const [open, setOpen] = useState(false);
    const [openSubmit, setOpenSubmit] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [update, setUpdate] = useState(null);
    const [focused, setFocused] = React.useState(false)

    const onClick = () => {
        setFocused(!focused)
    }

    const handleCloseSnack = () => {
        props.setMessage("")
    };

    React.useEffect(() => {
        setConfig(configValue)
        let val = new Map()
        let count =0;
        configValue.forEach((v, k) => {
            val.set(count.toString(), [k,typeof v === "boolean" ? v===true?"true":"false" : v,typeof v, 0, 0, 0])
            count+=1;
        })
        setNewConfigMap(val)

        setEditable(false)
        updateCountNewRows(configValue.size)
        updateCreateParamMap(new Map(JSON.parse(
            JSON.stringify(Array.from(val))
        )))


        setOpen(false)
        setOpenSubmit(false)
        setOpenCancel(false)
    }, [props.config]);

    React.useEffect(() => {
        if (update) {
            const refTarget = document.getElementById(`${update}-table-item`);
            if (refTarget) {
                refTarget.scrollIntoView({behavior: "smooth"})
            }
        }
    }, [update])


    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleSubmitClose = () => setOpenSubmit(false);

    const handleCancelClose = () => setOpenCancel(false);

    const onEdit = () => {
        setEditable(true);
    }

    const onCancel = () => {
        setOpenCancel(true)
    }

    const onSubmitEditChanges = () => {

        const mapIterator = createParamMap.values();
        let i = 0;
        let valid = true;
        let submitMap = new Map()
        while ((i < createParamMap.size) && valid) {
            let configEntry = mapIterator.next().value;
            if (configEntry[0] && configEntry[1] && !configEntry[3]) {
                if (configEntry[2]==="number") {
                    if (isNaN(configEntry[1])){
                        valid = false;
                    } else {
                        submitMap.set(configEntry[0],Number(configEntry[1]))
                    }
                } else if (configEntry[2]==="boolean") {
                    if (configEntry[1] === "true" || configEntry[1] === "false"){
                        submitMap.set(configEntry[0],configEntry[1]==="true" ? true : false)
                    } else {
                        valid = false;
                    }
                } else {
                    submitMap.set(configEntry[0],String(configEntry[1]))
                }
            }
            i++;
        }

        if (valid) {
            const jsonBody = {
                "configType": "global",
                "configsJson": JSON.stringify(Object.fromEntries(submitMap)),
                "versionDescription": "Updated Tenant Config",
                "user": props.user
            }
            let currentVersion = globalConfigs[0]["versionDescription"] != null ? globalConfigs[0]["versionDescription"] : null
            props.updateTenantConfig(props.getAWSClient, props.selectedTenant, jsonBody, "global_configs", currentVersion);
        } else {
            alert("Input datatype mismatch")
        }
        setOpenSubmit(false);
    }

    const onSubmit = () => {
        setOpenSubmit(true)
    }

    const onCancelChanges = () => {
        setEditable(false);
        let val = new Map()
        let count =0;
        configValue.forEach((v, k) => {
            val.set(count.toString(), [k,typeof v === "boolean" ? v===true?"true":"false" : v,typeof v, 0, 0, 0])
            count+=1;
        })
        setNewConfigMap(val)
        updateCountNewRows(configValue.size);
        updateCreateParamMap((new Map(JSON.parse(
            JSON.stringify(Array.from(val))
        ))));
        setOpenCancel(false)
    }

    const onTableRowValueEdit = (key, value) => {
        const valueMap = createParamMap.get(key);
        valueMap[1] = value;
        createParamMap.set(key, valueMap)
        onTableRowEditedFlag(key, 1)

    }

    const onTableRowEditedFlag = (key, value) => {
        const valueMap = createParamMap.get(key);
        valueMap[5] = value;
        updateCreateParamMap(createParamMap.set(key, valueMap))
    }

    const onTableRowKeyEdit = (key, value) =>  {
        const keyMap = createParamMap.get(key);
        keyMap[0] = value;
        createParamMap.set(key, keyMap)
        onTableRowEditedFlag(key, 1)
    }

    const setCreateParamRowType = (key, value) =>{
        const typeMap = createParamMap.get(key);
        typeMap[2] = value;

        createParamMap.set(key, typeMap)
        onTableRowEditedFlag(key, 1)

    }

    const onCreateParameter = (key, value) => {
        createParamMap.set(countNewRows.toString(),["", "", "string", 0, 1]);
        updateCountNewRows(countNewRows + 1);
        setUpdate(countNewRows.toString())
    }


    const onDeleteCreateParamRow = (key, value) => {
        const typeMap = createParamMap.get(key);
        typeMap[3] = value;
        createParamMap.set(key, typeMap)
        onTableRowEditedFlag(key, 1)

    }

    const onUndo = (key, setValue, setKey, setType, setIsDeleted, resetEditFlag) => {
        const entryMap = newConfig.get(key);
        let oldList = [entryMap[0], entryMap[1], entryMap[2], entryMap[3], entryMap[4], 2]

        createParamMap.set(key, oldList)
        resetEditFlag(2)
        setValue(entryMap[1])
        setKey(entryMap[0])
        setType(entryMap[2])
        setIsDeleted(entryMap[3])
    }

    const getRows = () =>{
        const comps = [];
        createParamMap.forEach((v, k) =>
            comps.push(<TenantTableRow
                k={k}
                v={v}
                isEditable={isEditable}
                key={k + "-" + v + "-tenantTableRow"}
                onTableRowKeyEdit={onTableRowKeyEdit}
                onTableRowValueEdit={onTableRowValueEdit}
                setCreateParamRowType={setCreateParamRowType}
                onDelete={onDeleteCreateParamRow}
                onUndo = {onUndo}
                onTableRowEditedFlag = {onTableRowEditedFlag}
    />));
        return comps;
    }

    const theme = createTheme({
        palette: {
            primary:{
                main:"#ffffff"
            }
        },
    });

    const getAlerts = () =>{
        let isSuccessful = props.message === "Success"
        if (props.message) {
            return (
                <Grid container border={ isSuccessful ? "1px solid darkgreen" : "1px solid red"}
                      bgcolor={ isSuccessful ? "#F4FFF4" : "#FFE0DF" } >
                    <Grid container item xs={0.5} style={{padding:0}} justifyContent={"flex-start"}  alignItems={"center"}>
                        {
                            isSuccessful ? <CheckCircleIcon color={"success"} fontSize={"small"}></CheckCircleIcon>
                                :
                                <CancelIcon color={"error"} fontSize={"small"}></CancelIcon>
                        }
                    </Grid>
                    <Grid container item xs={9.5} style={{padding:0}} justifyContent={"flex-start"} alignItems={"center"}>
                        {
                            isSuccessful ?
                                <Typography p={0}
                                            onClick={onClick}
                                            fontSize={"0.8rem"}
                                            fontWeight={"401"}
                                            fontFamily={'Amazon Ember'}
                                            style={focused ?
                                                {wordWrap: "break-word"} :
                                                {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}
                                            }>
                                    Changes submitted successfully</Typography> :
                                <Typography p={0}
                                            onClick={onClick}
                                            fontSize={"0.8rem"}
                                            fontWeight={"401"}
                                            fontFamily={'Amazon Ember'}
                                            style={focused ?
                                                {wordWrap: "break-word"} :
                                                {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}
                                            }> {props.message} </Typography>
                        }
                    </Grid>
                    <Grid container item xs={2} style={{padding:0}} justifyContent={"flex-end"} alignItems={"center"}>
                        <IconButton style={{padding:0}} onClick={handleCloseSnack}>
                            <CancelOutlined></CancelOutlined>
                        </IconButton>
                    </Grid>
                </Grid>)
        } else {
            return null
        }
    }

    if (config) {
        return (
            <div data-testid={'tenant-table-' + props.selectedTenant}>
            <Grid container pt={0} sx={{backgroundColor: "#595959"}}>
                {getAlerts()}
                <Grid item container justify="center"
                      alignItems="center" xs={8}
                      key={props.selectedTenant + '-grid1'}>
                    <Stack direction="row" spacing={1} paddingLeft={1}>
                        { isEditable ?
                            <Button aria-label='tenant-table-new-key-value' variant="text" style={styles.button} onClick={onCreateParameter}>
                                <AddCircleOutlineOutlinedIcon/>&nbsp;Create Key-Value
                            </Button>
                            :
                            <Button aria-label='tenant-table-create-new-config' variant="text" style={styles.button} onClick={handleOpen}><AddCircleOutlineOutlinedIcon/>&nbsp;Create Configuration</Button>
                        }
                        <Button aria-label='tenant-table-filter-by-local'  variant="text" style={styles.button}><LocalIcon key ={'button-local'}/>&nbsp;Local</Button>
                    </Stack>
                </Grid>
                <Grid item xs={4} key={props.selectedTenant + '-grid2'}>
                    <Grid container justifyContent="right" alignItems ="right">
                        <Box pr={1}>
                            <Stack direction="row" paddingRight={1} p={0.5} spacing={2}>
                                <ThemeProvider theme={theme}>
                                    <IconButton aria-label='tenant-table-search-button'   style={styles.updateButtons}><Box p={0}>
                                        <img src={Search} width={"20px"} height={"20px"} />
                                    </Box></IconButton>
                                    { isEditable ?
                                        <Stack direction="row" spacing={2}>
                                            <IconButton  aria-label='submit-tenant' style={styles.updateButtons} onClick={onSubmit}>
                                                <Box p={0}>
                                                    <img src={Save} width={"20px"} height={"20px"} />
                                                </Box>
                                                {/*<SaveOutlinedIcon color={"primary"} /> */}
                                            </IconButton>
                                            <IconButton  aria-label='cancel-tenant' style={styles.updateButtons} onClick={onCancel}>
                                                {/*<CancelOutlinedIcon color={"primary"} /> */}
                                                <Box p={0}>
                                                    <img src={Cancel} width={"20px"} height={"20px"} />
                                                </Box>
                                            </IconButton>
                                        </Stack>
                                        :   <Stack direction="row">
                                            <IconButton aria-label='edit-tenant' style={styles.updateButtons} onClick={onEdit}>
                                                <Box p={0}>
                                                    <img src={Edit} width={"20px"} height={"20px"} />
                                                </Box>
                                            </IconButton>

                                        </Stack>

                                    }
                                </ThemeProvider>

                            </Stack>
                        </Box>
                    </Grid>
                    <CreateConfigModal open={open} handleClose={handleClose} createConfig={props.createConfig} getAWSClient={props.getAWSClient} tenantName={props.selectedTenant} user={props.user}/>
                    <SubmitModal open={openCancel} handleClose ={handleCancelClose} onSubmit = {onCancelChanges} isCancel={true}/>
                    <SubmitModal open={openSubmit} handleClose ={handleSubmitClose} onSubmit = {onSubmitEditChanges}/>
                </Grid>
                <Grid item xs={12} key={props.selectedTenant + '-grid3'} sx={{backgroundColor: "#ffffff"}}>
                    <TableContainer id={'-data-table-container'}  style={{overflowX: 'visible'}}>
                        <Table id={props.selectedTenant + '-data-table'}
                               style = {{borderCollapse:"separate",borderSpacing: "0 3px"}} className="tenantContainer">
                            <TableBody id={props.selectedTenant + '-data-table-body'}>
                                {getRows()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }

}



const styles = {
    button:{
        background: '#FFFFFF',
        border: '1px solid #ECECEC',
        borderRadius: '20px',
        fontSize: '16px',
        color: '#000000',
        textAlign: 'center',
        lineHeight: '20px',
        textTransform: 'none',
        fontFamily: 'Amazon Ember',
        fontStyle: 'normal',
        fontWeight: 400,
        paddingTop:'3px',
        paddingBottom:'3px'
    },

    updateButtons:{
        padding: 0,
    }
}

