import React, {useCallback, useEffect, useState} from 'react';
import TenantPage from "./TenantPage";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useSelector} from "react-redux";
import {
    createNewTenant,
    fetchAllReviews, fetchReviewById,
    getAllTenantsData,
    reviewCanceled,
    updateConfiguration
} from "../api/services";
import VersionDifferences from "./VersionDifferences";
import ConfigStoreHomePage from "./ConfigStoreHomePage";
import {DIFFERENCES_PAGE, GLOBAL_CONFIGS, PREVIEW_CHANGES_PAGE, REVIEW_DIFFERENCES} from "../components/constants";
import {Alert, Paper, Snackbar, Stack} from "@mui/material";
import styles from "../styles/MainPageStyles.module.css";
import {CheckCircleOutline} from "@mui/icons-material";
import {diff} from "jsondiffpatch";
import {addedDiff, deletedDiff, updatedDiff,diff as diff1} from "deep-object-diff";
import {FEDERATE_OIDC, SYSTEM_NAME} from "../components/constants/ApiConstants";
import ReviewDifferences from "./ReviewDifferences";
import {
    ACTIVE,
    ALL,
    APPROVE_CONFIG,
    APPROVED_REVIEW,
    CANCELLED_REVIEW,
    STATUS_SUCCESS,
    CREATED_REVIEW,
    DISCARD_CONFIG,
    ERROR_SEVERITY, FAILED_APPROVED_REVIEW,
    FAILED_CANCELLED_REVIEW,
    FAILED_UPDATE_CONFIG, FAILED_REJECTED_REVIEW,
    INFO_SEVERITY,
    PENDING,
    PENDING_REVIEW_STATUS,
    REJECT_REVIEW,
    REJECT_REVIEW_CONFIG_STATUS,
    REJECTED_REVIEW,
    SUBMIT_CONFIG,
    SUCCESS,
    SUCCESS_SEVERITY, REVIEW_NOT_REQUIRED,
    WARNING_SEVERITY, UPDATED_CONFIG, TENANT_UPDATED, FAILED_CREATE_TENANT, CREATE_TENANT
} from "../components/constants/ApprovalStatusConstants";
import {CHILD_CONFIG_TYPE, PARENT_CONFIG_TYPE} from "../components/constants/HomePageConstants";
import {getResourceIdentifier, updateUsername} from "../components/common/SharedFunctions";

const theme = createTheme({
    typography: {
        fontFamily: 'Amazon Ember',
        letterSpacing:'0.15px'
    },
});

const MainPage =(props)=>{
    const {user, configs,tenants,fetchConfigs, getTenantConfigs, updateTenantConfig, createConfig,createVersion,loadTenants}=props
    const [openTenant,setOpenTenant]=useState(false);
    const [selectedTenant, setSelectedTenant]=useState("");
    const [selectedConfiguration,setSelectedConfiguration]=useState(null)
    const [isDifferencePageSelected,setIsDifferencePageSelected]=useState(false)
    const [allTenantsData,setAllTenantsData]=useState([])
    const [previewEditedVersion,setPreviewEditedVersion]=useState({})
    const [previewCurrentVersion,setPreviewCurrentVersion]=useState({})
    const [differencesPageType,setDifferencesPageType]=useState('')
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showFailAlert, setShowFailAlert] = useState(false);
    const [isReviewCardSelected,setIsReviewCardSelected]=useState(false)
    const [reviewData,setReviewData]=useState({})
    const [pendingVersionJsonBody,setPendingVersionJsonBody]=useState(false)
    const [pendingReviews,updatePendingReviews]=useState([])
    const [createVersionStatus,setCreateVersionStatus]=useState({status:null})
    const [isTenantPageOpened,setIsTenantPageOpened]=useState(false)
    const [tenantCardInfo,setTenantCardInfo]=useState()
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [reviewById,setReviewById]=useState({})
    const [updateConfigResponse, setUpdateConfigResponse] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage,setToastMessage]=useState('')
    const [toastSeverity, setToastSeverity] = useState("");
    const [responseType,setResponseType]=useState('')
    const [isEditTenant, setIsEditableTenant] = useState(false);
    const [tenantConfigMap, setIsTenantConfigMap] = useState(null);
    const [isEditConfig, setIsEditableConfig] = useState(false);
    const [configConfigMap, setIsConfigConfigMap] = useState(null);

    const allTenants=useSelector(({tenantsReducer})=>({
        tenants:tenantsReducer.tenants
    }))

    const onTenantSelected=(tenant)=> {
        setSelectedTenant(tenant);
        setOpenTenant(true);
    }

    const onTenantClosed=()=> {
        setSelectedTenant("");
        setSelectedConfiguration('')
        setOpenTenant(false)
        setIsDifferencePageSelected(false)
        setIsReviewCardSelected(false)
        setIsTenantPageOpened(false)
    }

    const openPreviewChangesPage=(differencesType)=>{
        setIsDifferencePageSelected(!isDifferencePageSelected)
        setDifferencesPageType(differencesType)
        setOpenTenant(false)
    }

    const closePreviewChangesPage=()=>{
        setIsDifferencePageSelected(false)
        setOpenTenant(true)
        setCreateVersionStatus({status:null})
    }

    async function fetchSingleReview(reviewId) {
        let variables = {
            reviewId:reviewId
        }
        let input = {
            input: variables
        }
        await fetchReviewById(setReviewById,input)
    }

    useEffect(()=>{
        let resourceIdentifier=reviewById?.resourceIdentifier?.split('-')
        if(resourceIdentifier && resourceIdentifier.length){
            let totalReview= {reviewId:reviewById.reviewId,reviewStatus:reviewById.reviewStatus,cardTenant:resourceIdentifier[0],cardConfiguration:resourceIdentifier.slice(1).join('-'),Submitter:reviewById.requesterId,Submission:reviewById.timestamp.slice(0,10),totalReview:reviewById}
            setReviewData(totalReview)
        }
        else {
            setReviewData({})
        }
    },[reviewById])

    const handleReviewDifferences=(reviewData,cardConfiguration)=>{
        setIsReviewCardSelected(true)
        setSelectedConfiguration(cardConfiguration)
        fetchSingleReview(reviewData.reviewId)
    }

    const openSelectedReview=(eachReview)=>{
        setOpenTenant(false)
        let resourceIdentifier=eachReview?.resourceIdentifier.split('-')
        setIsTenantPageOpened(true)
        setIsReviewCardSelected(true)
        setSelectedTenant(resourceIdentifier[0])
        if(resourceIdentifier.slice(1).join('-')!==GLOBAL_CONFIGS){
            setSelectedConfiguration(resourceIdentifier.slice(1).join('-'))
        }
        setDifferencesPageType(REVIEW_DIFFERENCES)
        fetchSingleReview(eachReview.reviewId)
    }

    const closeReviewPage=(e,isTenantPageSelected,reviewingTenant,reviewingConfig)=>{
        setIsReviewCardSelected(false)
        if(isTenantPageOpened){
            setOpenTenant(true)
            if(reviewingConfig!==GLOBAL_CONFIGS) setSelectedConfiguration(reviewingConfig)
        }
        else  setSelectedConfiguration(null)
        setIsDifferencePageSelected(false)
        setSelectedTenant(reviewingTenant)
        setReviewById({})
        setCreateVersionStatus({status:null})
    }

    const openDifferencePage=useCallback((e,tenantPageOpened,cardTenant,cardConfiguration)=>{
        e.stopPropagation()
        let currentResourceIdentifier=''
        if(tenantPageOpened){
            currentResourceIdentifier=getResourceIdentifier(selectedTenant,selectedConfiguration)
        }
        else {
            currentResourceIdentifier=cardTenant+'-'+cardConfiguration
            setSelectedTenant(cardTenant)
            if(cardConfiguration!==GLOBAL_CONFIGS) setSelectedConfiguration(cardConfiguration)
        }
        let currentPendingReview=pendingReviews.filter((eachApproval)=>eachApproval.resourceIdentifier===currentResourceIdentifier)
        if(currentPendingReview.length>0){
            let Submitter=updateUsername(currentPendingReview[0].requesterId)
            let resourceIdentifier=currentPendingReview[0].resourceIdentifier.split('-')
            let totalReview= {reviewId:currentPendingReview[0].reviewId,reviewStatus:currentPendingReview[0].reviewStatus,cardTenant:resourceIdentifier[0],cardConfiguration:resourceIdentifier.slice(1).join('-'),Submitter:Submitter,Submission:currentPendingReview[0].timestamp.slice(0,10),totalReview:currentPendingReview[0]}
            setIsReviewCardSelected(true)
            setDifferencesPageType(REVIEW_DIFFERENCES)
            fetchSingleReview(totalReview.reviewId)
        }
        else{
            setIsDifferencePageSelected(true)
            setDifferencesPageType(DIFFERENCES_PAGE)
        }
        setIsTenantPageOpened(tenantPageOpened)
        setOpenTenant(false)

    },[pendingReviews,selectedTenant,selectedConfiguration])

    const handleDifferencePage=(e,isTenantPageSelected,cardTenant,cardConfiguration,differencesType)=>{
        e.stopPropagation();
        if(isTenantPageOpened){
            setOpenTenant(true)
        }
        setSelectedConfiguration(cardConfiguration)
        setIsDifferencePageSelected(!isDifferencePageSelected)
        setSelectedTenant(cardTenant)
        setDifferencesPageType(differencesType)
        setReviewById({})
        setCreateVersionStatus({status:null})
    }

    const handleToastClose=()=> {
        if (showSuccessAlert) {
            setShowSuccessAlert(false);
        } else if (showFailAlert) {
            setShowFailAlert(false);
        }
    };
    const handlePopUpClose=()=>{
        setShowToast(false)
    }
    useEffect(() => {
        if(!showToast){
            setCreateVersionStatus({status:null})
            setResponseType('')
            setToastSeverity('')
            setToastMessage('')
            setReviewById({})

        }
        if(!openTenant && !isReviewCardSelected && !isDifferencePageSelected){
            setSelectedConfiguration('')
        }
    }, [showToast,openTenant,isReviewCardSelected,isDifferencePageSelected]);


    useEffect(   () => {
        allTenants.tenants.forEach( (tenant)=>{
            fetchData(tenant)
        })
    }, [allTenants.tenants]);

    useEffect(()=>{
        let updatedCardInfo={}
        Object.keys(allTenantsData).forEach((eachTenant)=>{
            let parentCount = 0, configCount = 0,reviewCount=0
            allTenantsData[eachTenant]?.forEach((eachConfig) => {
            if (eachConfig.configType === PARENT_CONFIG_TYPE) {
                parentCount += 1
                configCount += 1
            } else if (eachConfig.configType === CHILD_CONFIG_TYPE) {
                configCount += 1
            }
        })
            reviewCount=pendingReviews.filter((eachReview)=>eachReview.resourceIdentifier.startsWith(eachTenant)).length
             updatedCardInfo[eachTenant]={parentCount:parentCount,configCount:configCount,reviewCount:reviewCount}
        })
        setTenantCardInfo(updatedCardInfo)
    },[allTenantsData,pendingReviews])



    async function fetchData(tenant) {
        let variables = {
            tenant: `${tenant}`
        }
        let input = {
            input: variables
        }
        await getAllTenantsData(tenant,setAllTenantsData,input)
    }

    async function createTenant(tenant, variables) {
        let input = {
            input: variables
        }
        await createNewTenant(tenant, input, setCreateVersionStatus,setResponseType);
    }

    useEffect(() => {
        fetchReviews()
    }, [createVersionStatus]);

    async function fetchReviews() {
        let variables = {
            system: SYSTEM_NAME,
            fullReview:false,
            activeStatus:ACTIVE,
            user:user
        }
        let input = {
            input: variables
        }
        await fetchAllReviews(updatePendingReviews,input)
    }


    useEffect(   () => {
        switch (responseType){
            case SUBMIT_CONFIG:
                if(createVersionStatus?.reviewStatus===SUCCESS){
                    setToastMessage(CREATED_REVIEW)
                    setToastSeverity(SUCCESS_SEVERITY)
                }
                else if(createVersionStatus?.reviewStatus===REVIEW_NOT_REQUIRED && createVersionStatus?.status===STATUS_SUCCESS){
                    setToastMessage(UPDATED_CONFIG)
                    setToastSeverity(SUCCESS_SEVERITY)
                }
                else {
                    setToastMessage(FAILED_UPDATE_CONFIG)
                    setToastSeverity(ERROR_SEVERITY)
                }
                setShowToast(true)
                break
            case DISCARD_CONFIG:
                if(createVersionStatus?.status===SUCCESS){
                    setToastMessage(CANCELLED_REVIEW)
                    setToastSeverity(INFO_SEVERITY)
                }
                else {
                    setToastMessage(FAILED_CANCELLED_REVIEW)
                    setToastSeverity(ERROR_SEVERITY)
                }
                setShowToast(true)
                break
            case REJECT_REVIEW:
                if (createVersionStatus?.status===SUCCESS){
                    setToastMessage(REJECTED_REVIEW)
                    setToastSeverity(ERROR_SEVERITY)
                }
                else {
                    setToastMessage(FAILED_REJECTED_REVIEW)
                    setToastSeverity(ERROR_SEVERITY)
                }
                setShowToast(true)
                break
            case APPROVE_CONFIG:
                if(createVersionStatus?.status===SUCCESS && createVersionStatus.reviewStatus===SUCCESS && createVersionStatus.configStatus===STATUS_SUCCESS){
                    setToastMessage(APPROVED_REVIEW)
                    setToastSeverity(SUCCESS_SEVERITY)
                }
                else {
                    setToastMessage(FAILED_APPROVED_REVIEW)
                    setToastSeverity(ERROR_SEVERITY)
                }
                setShowToast(true)
                break
            case CREATE_TENANT:
                if (createVersionStatus?.status===STATUS_SUCCESS){
                    loadTenants(updateUsername(user));
                    setToastMessage(TENANT_UPDATED)
                    setToastSeverity(SUCCESS_SEVERITY)
                }
                else {
                    setToastMessage(FAILED_CREATE_TENANT)
                    setToastSeverity(ERROR_SEVERITY)
                }
                setShowToast(true)
                break
            default:
                break
        }
    }, [createVersionStatus,responseType]);

    const mainPage=()=>{
        if(openTenant) {
            return(
                <TenantPage
                    isEditTenant ={isEditTenant}
                    setIsEditableTenant ={setIsEditableTenant}
                    tenantConfigMap ={tenantConfigMap}
                    setIsTenantConfigMap = {setIsTenantConfigMap}
                    isEditConfig ={isEditConfig}
                    setIsEditableConfig ={setIsEditableConfig}
                    configConfigMap ={configConfigMap}
                    setIsConfigConfigMap = {setIsConfigConfigMap}
                    tenants={tenants}
                    tenant={selectedTenant}
                    setSelectedTenant={setSelectedTenant}
                    setSelectedConfiguration={setSelectedConfiguration}
                    onTenantClosed={onTenantClosed}
                    configs={configs}
                    selectedConfiguration={selectedConfiguration}
                    allTenantsData={allTenantsData}
                    fetchConfigs={fetchConfigs}
                    createConfig={createConfig}
                    getTenantConfigs={getTenantConfigs}
                    getAWSClient={props.getAWSClient}
                    user={user}
                    openDifferencePage={openDifferencePage}
                    setIsDifferencePageSelected={setIsDifferencePageSelected}
                    setOpenTenant={setOpenTenant}
                    setDifferencesPageType={setDifferencesPageType}
                    setPreviewEditedVersion={setPreviewEditedVersion}
                    setPreviewCurrentVersion={setPreviewCurrentVersion}
                    handleToastClose={handleToastClose}
                    setPendingVersionJsonBody={setPendingVersionJsonBody}
                    pendingReviews={pendingReviews}
                    isDifferencePageSelected={isDifferencePageSelected}
                    isReviewCardSelected={isReviewCardSelected}
                    openTenant={openTenant}
                    createVersionStatus={createVersionStatus}
                    openSelectedReview={openSelectedReview}
                    openConfigModal={openConfigModal}
                    setOpenConfigModal={setOpenConfigModal}
                    openUploadModal={openUploadModal}
                    setOpenUploadModal={setOpenUploadModal}
                    updateConfigResponse={updateConfigResponse}
                    setUpdateConfigResponse={setUpdateConfigResponse}
                    showToast={showToast}
                    toastMessage={toastMessage}
                    toastSeverity={toastSeverity}
                    handlePopUpClose={handlePopUpClose}
                    setResponseType={setResponseType}
                    setCreateVersionStatus={setCreateVersionStatus}
                />)
        }
        else if(isReviewCardSelected){
            return(
                <ReviewDifferences
                    user={user}
                    reviewData={reviewData}
                    onTenantClosed={onTenantClosed}
                    setIsDifferencePageSelected={setIsDifferencePageSelected}
                    setIsReviewCardSelected={setIsReviewCardSelected}
                    setCreateVersionStatus={setCreateVersionStatus}
                    setOpenTenant={setOpenTenant}
                    isTenantPageOpened={isTenantPageOpened}
                    closeReviewPage={closeReviewPage}
                    selectedConfiguration={selectedConfiguration}
                    setResponseType={setResponseType}
                />
            )
        }
        else if(isDifferencePageSelected){
            if(differencesPageType===DIFFERENCES_PAGE){
                return (
                    <VersionDifferences
                        user={user}
                        handleDifferencePage={handleDifferencePage}
                        onTenantClosed={onTenantClosed}
                        selectedTenant={selectedTenant}
                        selectedConfiguration={selectedConfiguration}
                        differencesPageType={differencesPageType}
                        openTenant={openTenant}
                        isTenantPageOpened={isTenantPageOpened}
                        setCreateVersionStatus={setCreateVersionStatus}
                    />)
            }
            else {
                return (
                    <VersionDifferences
                        setIsTenantConfigMap = {setIsTenantConfigMap}
                        setIsEditableTenant = {setIsEditableTenant}
                        setIsEditableConfig ={setIsEditableConfig}
                        setIsConfigConfigMap = {setIsConfigConfigMap}
                        user={user}
                        openPreviewChangesPage={openPreviewChangesPage}
                        selectedTenant={selectedTenant}
                        selectedConfiguration={selectedConfiguration}
                        differencesPageType={PREVIEW_CHANGES_PAGE}
                        closePreviewChangesPage={closePreviewChangesPage}
                        setOpenTenant={setOpenTenant}
                        previewEditedVersion={previewEditedVersion}
                        previewCurrentVersion={previewCurrentVersion}
                        pendingVersionJsonBody={pendingVersionJsonBody}
                        setIsDifferencePageSelected={setIsDifferencePageSelected}
                        setIsReviewCardSelected={setIsReviewCardSelected}
                        setCreateVersionStatus={setCreateVersionStatus}
                        setResponseType={setResponseType}
                    />)
            }
        }
        else {
            return (
                <ConfigStoreHomePage
                    user={user}
                    tenants={tenants}
                    loadTenants={loadTenants}
                    createTenant={createTenant}
                    onTenantSelected={onTenantSelected}
                    allTenantsData={allTenantsData}
                    getTenantConfigs={getTenantConfigs}
                    getAWSClient={props.getAWSClient}
                    pendingReviews={pendingReviews}
                    openDifferencePage={openDifferencePage}
                    handleReviewDifferences={handleReviewDifferences}
                    tenantCardInfo={tenantCardInfo}
                    setOpenConfigModal={setOpenConfigModal}
                    setOpenUploadModal={setOpenUploadModal}
                />)
        }
    }

    return(
        <>
            <ThemeProvider theme={theme}>
                {mainPage()}
            </ThemeProvider>
            <Snackbar
                open={showToast}
                autoHideDuration={8000}
                onClose={handlePopUpClose}
            >
                <Stack direction={'row'}>
                    {toastSeverity && <Alert severity={toastSeverity} >{toastMessage}</Alert>}
                </Stack>
            </Snackbar>
        </>
    )
}
export default MainPage;
