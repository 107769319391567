import React, {useEffect, useState} from 'react';
import {Grid, IconButton, Typography} from "@mui/material";
import ConfigTableRow from "./ConfigTableRow";
import ActionBar from "./ActionBar";
import SubmitModal from "../Shared/SubmitModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {CancelOutlined} from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import {getConfigVersion} from "../../logic/getConfigVersion";

const ConfigTable = (props) => {
    const { configs, selectedConfig, params="configParams", selectedVersion, selectedTenant, message, onEditParentConfig,
        latestConfigVersion, oldConfigVersion, setOldConfigVersion, isConfigInvalid, isLoading } = props;
    const [ selectedIcons, setSelectedIcons] = useState([])

    const [metadata, setMetadata] = React.useState(null)
    const [configExpandedMap, setConfigExpandedMap] = React.useState(null);
    const [uneditedConfigExpandedMap, setUneditedConfigExpandedMap] = React.useState(null);
    const [isEditable, setEditable] = useState(false);
    const [countOfRows, setCountRows] = useState(0)
    const [update, setUpdated] = useState(null)
    const [parentConfigMap, setParentConfigMap] = React.useState(null)
    const [globalConfigMap, setGlobalConfigMap] = React.useState(null)
    const [openSubmit, setOpenSubmit] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [configType, setConfigType] = useState(null)
    const [focused, setFocused] = React.useState(false)
    // const [oldVersion, setOldVersion] = React.useState(null)
    const [showConfigInvalid, setShowConfigInvalid] = useState(null)
    const [isLoadingConfigs, setIsLoadingConfigs] = useState(false);


    const onClick = () => {
        setFocused(!focused)
    }

    const handleCloseSnack = () => {
        props.setMessage("")
    };

    const onSetOldVersion = (value) => {
        setOldConfigVersion(value)
    }
    const refreshObjects = () => {
        const configsToDisplay = (oldConfigVersion === null || oldConfigVersion === undefined || selectedVersion === 'Current')? configs: oldConfigVersion;
        let metaJSON = JSON.parse(configsToDisplay.filter(config => config.configName === selectedConfig)[0]["metadata"])['key_sources']
        setMetadata(metaJSON)
        let configValue = JSON.parse(configsToDisplay.filter(config => config.configName === selectedConfig)[0][params])
        let expandedMap = recursiveMap(configValue, metaJSON)
        setConfigExpandedMap(expandedMap)
        setUneditedConfigExpandedMap(recursiveMap(configValue, metaJSON))
        setCountRows(expandedMap.size)
        setConfigType(JSON.parse(configsToDisplay.filter(config => config.configName === selectedConfig)[0]["metadata"])['config_type'])

        // Parent config
        let parentValue = configs.filter(config => config.configName === selectedConfig)[0]["parentConfig"]
        let parentConfigList = configs.filter(config => config.configName === parentValue)
        if (parentConfigList.length ==0) {
            setParentConfigMap(null)
        } else {
            setParentConfigMap(JSON.parse(parentConfigList[0][params]))
        }

        // Global config
        let globalConfigList = configs.filter(config => config.configName === "global_configs")
        if (globalConfigList.length ==0) {
            setGlobalConfigMap(null)
        } else {
            setGlobalConfigMap(JSON.parse(globalConfigList[0][params]))
        }
    }

    React.useEffect(() => {
        if(selectedVersion == 'Current'){
            onSetOldVersion(null)
        }
    }, [selectedConfig])

    React.useEffect(() => {
        setSelectedIcons([])
        setEditable(false)
        setFocused(false)
        const configsToDisplay = (oldConfigVersion === null || oldConfigVersion === undefined || selectedVersion === 'Current')? configs: oldConfigVersion;
        const currentConfig = configsToDisplay?(configsToDisplay.filter(config => config.configName === selectedConfig))[0]:null;
        if (currentConfig && configs) {
            refreshObjects()
        }
    }, [configs, oldConfigVersion, selectedConfig, selectedVersion]);

    React.useEffect(() => {
        setShowConfigInvalid(isConfigInvalid)
    }, [isConfigInvalid])

    React.useEffect(() => {
        if (update) {
            const refTarget = document.getElementById(`${update}-table-item`);
            if (refTarget) {
                refTarget.scrollIntoView({behavior: "smooth"})
            }
        }
    }, [update])

    React.useEffect(() => {

        async function fetchOldVersion(){
            const versionToFetch = selectedVersion.slice(1)
            await getConfigVersion(props.getAWSClient, selectedTenant, selectedConfig, versionToFetch, onSetOldVersion);
        };

        if(selectedVersion && selectedVersion !== 'Current'){
            fetchOldVersion();
        }
        else{
            onSetOldVersion(null)
        }
    }, [selectedVersion])

    React.useEffect(() => {
        setIsLoadingConfigs(isLoading)
    }, [isLoading])

    const onEdit = () => {
        setEditable(true);
    }

    const getValue = (path, configMap) => {
        if (configMap === null) {
            return null
        }
        let value = configMap
        let temp = configExpandedMap.get(path[0])
        if (path.length === 1) {
            return value[temp[0]]
        }
        temp = temp[1]
        temp = temp.get(path[1])
        for (let index = 2; index < path.length ; index ++) {
            value = value[temp[0]]
            if (value === undefined) {
                return null
            }
            temp = temp[1]
            temp = temp.get(path[index])
        }
            return value[temp[0]]
    }
    const getOverridenValue = (path) => {
        let result = getValue(path, parentConfigMap)
        if (result === null) {
            result =  getValue(path, globalConfigMap)
        }
        return result === null ? "" : result
    }

    const undoEverything = (path, keyFunc, valFunc, dataTypeFunc, typeFunc) => {
        let configEditedRow = getElementFromPath(path, configExpandedMap)
        let configUneditedRow = getElementFromPath(path, uneditedConfigExpandedMap)
        configEditedRow[0] = configUneditedRow[0]
        configEditedRow[1] = configUneditedRow[1]
        configEditedRow[2] = configUneditedRow[2]
        configEditedRow[3] = configUneditedRow[3]
        configEditedRow[4][0] = configUneditedRow[4][0]
        configEditedRow[4][1] = configUneditedRow[4][1]
        if (configEditedRow[2] == "object" || configEditedRow[2] == "array") {
            keyFunc(configUneditedRow[0])
        } else {
            keyFunc(configUneditedRow[0])
            valFunc(configUneditedRow[1])
            dataTypeFunc(configUneditedRow[2])
            typeFunc(configUneditedRow[3])
        }
    }
    const onCancel = () => {
        setOpenCancel(true)
    }

    const onSubmit = () => {
        setOpenSubmit(true)
    }

    const onCancelEditChanges = () => {
        let configValue = JSON.parse(configs.filter(config => config.configName === selectedConfig)[0][params])
        let expandedMap = recursiveMap(configValue, metadata)
        setConfigExpandedMap(expandedMap)
        setEditable(false);
        setOpenCancel(false)
    }

    const onSubmitEditChanges = () => {
        let i = 0;
        let submitMap = {}
        const mapIterator = configExpandedMap.values();
        let isValidMap = { valid: true }
        while ((i < configExpandedMap.size) && isValidMap.valid) {
            let v = mapIterator.next().value;
            if (v[3] === "PARENT" || v[3] === "GLOBAL" || v[4][0]) {
                //ignore
            } else {
                if (v[2] === "object") {
                    let tempObj = getSubmittableMap(v[1], isValidMap)
                    if (Object.keys(tempObj).length === 0) {
                        // do nothing
                    } else {
                        submitMap[v[0]] = tempObj
                    }
                } else if (v[2] === "array") {
                    let tempObj = getSubmittableList(v[1], isValidMap)
                    if (tempObj.length===0) {
                        // do nothing
                    } else {
                        submitMap[v[0]] = tempObj
                    }
                } else {
                    if (v[2]==="number") {
                        if (isNaN(v[1])){
                            isValidMap.valid = false
                        } else {
                            submitMap[v[0]] = Number(v[1])
                        }
                    } else if(v[2]==="boolean") {
                        if (v[1] === "true" || v[1] === "false"){
                            submitMap[v[0]] = v[1]==="true" ? true : false
                        } else {
                            isValidMap.valid = false
                        }
                    } else {
                        submitMap[v[0]] = String(v[1])
                    }
                }
            }
            i++;
        }
        if (!isValidMap.valid) {
            alert("Input datatype mismatch")
        } else {
            let configsJson = JSON.stringify(submitMap)
            let parentValue = configs.filter(config => config.configName === selectedConfig)[0]["parentConfig"]
            const jsonBody = {
                "configType": configType,
                "configsJson": configsJson,
                "versionDescription": "Update Version From ConfigStore UI",
                "parentConfig" : parentValue,
                "user": props.user
            }
            onEditParentConfig(false);
            props.createVersion(props.getAWSClient, selectedTenant, jsonBody, selectedConfig, latestConfigVersion);
        }
        setOpenSubmit(false)
    }


    const getSubmittableMap = (mapObject, isValidMap) => {
        let i = 0;
        let submitMap = {}
        const mapIterator = mapObject.values();
        while ((i < mapObject.size) && isValidMap.valid) {
            let v = mapIterator.next().value;

            if (v[3] === "PARENT" || v[3] === "GLOBAL" || v[4][0]) {
                //ignore
            } else {
                if (v[2] === "object") {
                    let tempObj = getSubmittableMap(v[1], isValidMap)
                    if (Object.keys(tempObj).length === 0) {
                        // do nothing
                    } else {
                        submitMap[v[0]] = tempObj
                    }

                } else if (v[2] === "array") {
                    let tempObj = getSubmittableList(v[1], isValidMap)
                    if (tempObj.length===0) {
                        // do nothing
                    } else {
                        submitMap[v[0]] = tempObj
                    }
                } else {
                    if (v[2]==="number") {
                        if (isNaN(v[1])){
                            isValidMap.valid = false
                        } else {
                            submitMap[v[0]] = Number(v[1])
                        }
                    } else if(v[2]==="boolean") {
                        if (v[1] === "true" || v[1] === "false"){
                            submitMap[v[0]] = v[1]==="true" ? true : false
                        } else {
                            isValidMap.valid = false
                        }
                    } else {
                        submitMap[v[0]] = String(v[1])
                    }
                }
            }
            i++;
        }
        return submitMap
    }

    const getSubmittableList = (listObject, isValidMap) => {
        let i = 0;
        let submitList = []
        const mapIterator = listObject.values();
        while ((i < listObject.size) && isValidMap.valid) {
            let v = mapIterator.next().value;
            if (v[3] === "PARENT" || v[3] === "GLOBAL" || v[4][0]) {
                //ignore
            } else {
                if (v[2] === "object") {
                    let tempObj = getSubmittableMap(v[1], isValidMap)
                    if (Object.keys(tempObj).length === 0) {
                        // do nothing
                    } else {
                        submitList.push(tempObj)
                    }
                } else if (v[2] === "array") {
                    let tempObj = getSubmittableList(v[1], isValidMap)
                    if (tempObj.length===0) {
                        // do nothing
                    } else {
                        submitList.push(tempObj)
                    }

                } else {
                    if (v[2]==="number") {
                        if (isNaN(v[1])){
                            isValidMap.valid = false
                        } else {
                            submitList.push(Number(v[1]))
                        }
                    } else if(v[2]==="boolean") {
                        if (v[1] === "true" || v[1] === "false"){
                            submitList.push(v[1]==="true" ? true : false)
                        } else {
                            isValidMap.valid = false
                        }
                    } else {
                        submitList.push(String(v[1]))
                    }
                }
            }
            i++;
        }
        return submitList;
    }
    const onSearch = () => {
        console.log("Search")
    }

    const onDeleteRow = (path) => {
        let temp = getElementFromPath(path, configExpandedMap)
        temp[4][0] = 1
    }


    const addObject = (path, type) => {
        let temp = configExpandedMap;
        for (let index = 0; index < path.length-1 ; index ++ ) {
            temp = temp.get(path[index])
            temp = temp[1]
        }
        let value
        switch(type) {
            case "object":
                value = ["", new Map(), "object", "LOCAL", [0,1]]
                break;
            case "list":
                value = ["", new Map(), "array", "LOCAL", [0,1]]
                break;
            default:
                value = ["", "", "string", "LOCAL", [0,1]]
        }
        temp.set(countOfRows.toString(), value)
        let element = [...path, (countOfRows-1).toString()]
        setCountRows(countOfRows+1)
        setUpdated(element)
    }

    const addRowItem = (path, count, type, isArray) => {
        let temp = getElementFromPath(path, configExpandedMap)
        temp = temp[1]
        let value;
        switch(type) {
            case "object":
                value = [ isArray? count.toString() : "" , new Map(), "object", "LOCAL", [0,1]]
                break;
            case "list":
                value = [ isArray? count.toString() : "" , new Map(), "array", "LOCAL", [0,1]]
                break;
            default:
                value = [ isArray? count.toString() : "" , "", "string", "LOCAL", [0,1]]
        }
        setUpdated([...path, count.toString()])
        temp.set(count.toString(), value)
    }


    const getRows = () => {
        const comps = [];
        if (selectedIcons.length === 0) {
            configExpandedMap.forEach((v, k) => {
                let path_value = [k]
                comps.push(
                    <ConfigTableRow
                        k={k}
                        v={v}
                        path = {path_value}
                        key={path_value + "-configTableRow"}
                        isEditable = {isEditable}
                        updateKey = {updateKey}
                        updateValue = {updateValue}
                        addRowItem = {addRowItem}
                        updateType ={updateType}
                        getOverridenValue ={getOverridenValue}
                        onDeleteRow = {onDeleteRow}
                        undoEverything={undoEverything}
                        onRemoveOverride={onRemoveOverride}
                        selectedIcons = {selectedIcons}
                    />
                )
            })
        } else {
            configExpandedMap.forEach((v, k) => {
                if (v[3] === "" || selectedIcons.includes(v[3])) {
                    let path_value = [k]
                    comps.push(
                        <ConfigTableRow
                            k={k}
                            v={v}
                            path = {path_value}
                            key={path_value + "-configTableRow"}
                            isEditable = {isEditable}
                            updateKey = {updateKey}
                            updateValue = {updateValue}
                            addRowItem = {addRowItem}
                            updateType ={updateType}
                            getOverridenValue ={getOverridenValue}
                            onDeleteRow = {onDeleteRow}
                            undoEverything={undoEverything}
                            onRemoveOverride={onRemoveOverride}
                            selectedIcons = {selectedIcons}
                        />
                    )
                }
            })
        }

        return comps;
    }

    const updateKey = (path, value) => {
        let temp = getElementFromPath(path, configExpandedMap)
        temp[0] = value
    }

    const updateType = (path, value) => {
        let temp = getElementFromPath(path, configExpandedMap)
        temp[2] = value
    }


    const updateValue = (path, value) => {
        let temp = getElementFromPath(path, configExpandedMap)
        temp[1] = value
        if (isInherited(temp[3])) {
            temp[3] = 'LOCAL_OVERRIDE'
        }
    }

    const onRemoveOverride = (path, value) => {
        let temp = getElementFromPath(path, configExpandedMap)
        temp[1] = value
        temp[3] = 'PARENT'
    }

    const getElementFromPath = (path, configMap) => {
        let temp = configMap;
        for (let index = 0; index < path.length-1 ; index ++ ) {
            temp = temp.get(path[index])
            temp = temp[1]
        }
        temp = temp.get(path[path.length-1])
        return temp
    }

    const isInherited = (text) => {
        if (text === "GLOBAL" || text === "PARENT") {
            return true
        }
        return false
    };

    const handleSubmitClose = () => setOpenSubmit(false);

    const handleCancelClose = () => setOpenCancel(false);

    const getAlerts = (newMessage) =>{
        // let isSuccessful = props.message === "Success"
        let isSuccessful = newMessage === "Success"
        // props.message
        if (newMessage) {
            return (
            <Grid aria-label='alert-box' container border={ isSuccessful ? "1px solid darkgreen" : "1px solid red"}
                  bgcolor={ isSuccessful ? "#F4FFF4" : "#FFE0DF" } >
                <Grid container item xs={0.5} style={{padding:0}} justifyContent={"flex-start"}  alignItems={"center"}>
                    {
                        isSuccessful ? <CheckCircleIcon color={"success"} fontSize={"small"}></CheckCircleIcon>
                            :
                        <CancelIcon color={"error"} fontSize={"small"}></CancelIcon>
                    }
                </Grid>
                <Grid container item xs={9.5} style={{padding:0}} justifyContent={"flex-start"} alignItems={"center"}>
                     {
                         isSuccessful ?
                             <Typography p={0}
                                         onClick={onClick}
                                         fontSize={"0.8rem"}
                                         fontWeight={"401"}
                                         fontFamily={'Amazon Ember'}
                                         style={focused ?
                                             {wordWrap: "break-word"} :
                                             {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}
                                         }>
                                 Changes submitted successfully</Typography> :
                             <Typography p={0}
                                         onClick={onClick}
                                         fontSize={"0.8rem"}
                                         fontWeight={"401"}
                                         fontFamily={'Amazon Ember'}
                                         style={focused ?
                                             {wordWrap: "break-word"} :
                                             {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}
                                         }> {newMessage} </Typography>
                    }
                </Grid>
                <Grid container item xs={2} style={{padding:0}} justifyContent={"flex-end"} alignItems={"center"}>
                    <IconButton style={{padding:0}} onClick={handleCloseSnack}>
                        <CancelOutlined></CancelOutlined>
                    </IconButton>
                </Grid>
            </Grid>)
        } else {
            return null
        }
    }

    if(showConfigInvalid && !isLoadingConfigs){
        return <div>
            {getAlerts(`The version_description attribute of the selected configuration is invalid`)}
        </div>
    }

    else if(configs) {
        return (
            <div data-testid={'config-table-' + props.selectedConfig}>
                <Grid container>
                    <ActionBar
                        isEditable={isEditable}
                        onEdit={onEdit}
                        onCancel={onCancel}
                        onSearch={onSearch}
                        addObject={addObject}
                        onSubmitChanges={onSubmit}
                        configType={configType}
                        selectedIcons = {selectedIcons}
                        setSelectedIcons = {setSelectedIcons}
                        canEdit = {selectedVersion === 'Current'}
                    />
                    <Grid item xs={12}>
                    {getAlerts(props.message)}
                    </Grid>
                    <SubmitModal open={openCancel} handleClose={handleCancelClose} onSubmit={onCancelEditChanges}
                                 isCancel={true}/>
                    <SubmitModal open={openSubmit} handleClose={handleSubmitClose} onSubmit={onSubmitEditChanges}/>
                    {
                        configExpandedMap ?
                        getRows(configExpandedMap)
                        : "No Configurations"
                    }

                </Grid>
            </div>
            )
    }
}


const recursiveMap = (configs, metaJSON) => {
    if (configs) {
        let configValue = new Map(Object.entries(configs))
        let count = 0;
        let val = new Map()
        configValue.forEach((v, k) => {
            let temp_Meta = (typeof metaJSON === "string") ? metaJSON : metaJSON[k]
            if (v == null) {
                val.set(count.toString(), [k, "", "string", temp_Meta, [0,0]])
            } else if (typeof v == "object") {
                if (!Array.isArray(v)) {
                    val.set(count.toString(), [k, recursiveMap(v, temp_Meta), typeof v, "", [0,0]])
                } else {
                    val.set(count.toString(), [k, recursiveMap(v, temp_Meta), "array", "", [0,0]])
                }
            } else {
                val.set(count.toString(), [k, typeof v === "boolean" ? v===true?"true":"false" : v, typeof v, temp_Meta, [0,0]])
            }
            count += 1;
        })
        return val
    } else {
        return ""
    }
}
export default ConfigTable;