import * as ActionTypes from "./ActionTypes";

export const tenantsLoading = () => ({
    type: ActionTypes.APPSYNC_TENANTS_LOADING
});

export const tenantsFailed = (errmess) => ({
    type: ActionTypes.APPSYNC_TENANTS_FAILED,
    payload: errmess
});

//function returns an action
export const addTenants = (tenants) => ({
    type: ActionTypes.APPSYNC_ADD_TENANTS,
    payload: tenants
});

//function returns an action
export const addUpdatableTenants = (tenants) => ({
    type: ActionTypes.APPSYNC_ADD_UPDATABLE_TENANTS,
    payload: tenants
});

//function returns an action
export const addReviewableTenants = (tenants) => ({
    type: ActionTypes.APPSYNC_ADD_REVIEWABLE_TENANTS,
    payload: tenants
});
