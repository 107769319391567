import * as ActionTypes from './ActionTypes';
import {Auth} from "@aws-amplify/auth";
const aws4fetch = require('aws4fetch');

const TENANTS = {};
const CONFIGS = {};

export const tenantsLoading = () => ({
    type: ActionTypes.TENANTS_LOADING
});

export const tenantsFailed = (errmess) => ({
    type: ActionTypes.TENANTS_FAILED,
    payload: errmess
});

//function returns an action
export const addTenants = (tenants) => ({
    type: ActionTypes.ADD_TENANTS,
    payload: tenants
});

export const configsLoading = () => ({
    type: ActionTypes.CONFIGS_LOADING
});

export const configsFailed = (errmess) => ({
    type: ActionTypes.CONFIGS_FAILED,
    payload: errmess
});

//function returns an action
export const addConfigs = (configs, message) => ({
    type: ActionTypes.ADD_CONFIGS,
    payload: configs,
    message: message
});


//// thunk: function returns an func
export const fetchTenants = (getAWSClient) => async (dispatch) => {
    try {
        const aws = await getAWSClient();
        dispatch(tenantsLoading(true));
        let endPoint = 'prod/tenants'
        let url = await getUrl() + endPoint;
        try {
            const res = await aws.fetch(url,
                {method: 'GET'})
            res.json()
                .then((json) => {
                    return json.tenants;
                })
                .then((json) => {
                    TENANTS.tenants = json;
                    dispatch(addTenants(TENANTS));
                })
                .catch((e) => dispatch(tenantsFailed(e.message)));
        } catch (e) {
            dispatch(tenantsFailed(e.message))
        }
    } catch (e) {
        console.log("fetchTenants failed", e)
        dispatch(tenantsFailed(e.message));
    }
}

//// thunk: function returns an func
export const fetchConfigs = (getAWSClient, tenantName, message, configList) => async (dispatch) => {
    try {
        const aws = await getAWSClient();
        dispatch(configsLoading(true));
        let endPoint = 'prod/tenants/' + tenantName + '/configurations'
        let url = await getUrl() + endPoint;
        if(typeof configList === 'string' ){
            configList = [configList]
        }
        configList.push('global_configs')
        const finalConfigList = Array.from(configList, item => ({'configName': item}) );
        const res = await aws.fetch(url,
            {method: 'POST', body: JSON.stringify({inheritFlag: true, "configList" : finalConfigList})})
        try {
            res.json()
                .then((json) => {
                        console.log('GET CONFIGS SUCCESSFUL');
                        return json.configsMap;
                    }
                )
                .then((json) => {
                    CONFIGS.configsMap = json;
                    dispatch(addConfigs(CONFIGS, message));
                })
                .catch((e) => dispatch(configsFailed(e.message)));
        } catch (e) {
            dispatch(configsFailed(e.message))
        }
    } catch (e) {
        console.log("fetchConfigs failed", e)
        dispatch(configsFailed(e.message));
    }
}


export const updateTenantConfig = (getAWSClient, tenantName, tenantConfigJson, configName, latestConfigVersion) => async (dispatch) => {
    try {
        const aws = await getAWSClient();

        let endPointRetrieveConfigurationAPI = 'prod/tenants/' + tenantName + '/configurations'
        let urlRetrieve = await getUrl() + endPointRetrieveConfigurationAPI;

        let endPoint = 'prod/tenants/' + tenantName + '/configurations/' + configName
        let url = await getUrl() + endPoint;

        let output = await getLatestConfig(aws, urlRetrieve, configName)
        let validRequest = latestConfigVersion === output.configsMap[0].versionDescription

        try {
            if (validRequest) {
                const res = await aws.fetch(url,
                    {method: 'PUT', body: JSON.stringify(tenantConfigJson)})
                res.json()
                    .then((json) => {
                            dispatch(fetchConfigs(getAWSClient, tenantName, json.string, [configName, output.configsMap[0].parentConfig? output.configsMap[0].parentConfig:'']));
                            console.log('PUT NEW VERSION SUCCESSFUL');
                        }
                    )
                    .catch((e) => console.log('PUT NEW VERSION UNSUCCESSFUL'));
            } else {
                dispatch(fetchConfigs(getAWSClient, tenantName, "Updating older version, refreshing configs....", [configName,  output.configsMap[0].parentConfig? output.configsMap[0].parentConfig:'']));
            }
        } catch (e) {
            dispatch(fetchConfigs(getAWSClient, tenantName, e.message, [configName, output.configsMap[0].parentConfig? output.configsMap[0].parentConfig:'']))
        }
    } catch (e) {
        console.log('updateTenantConfig Failed', e);
    }
}

export const createConfig = (getAWSClient, tenantName, tenantConfigJson, configName) => async (dispatch) => {
    try {
        const aws = await getAWSClient();
        let endPoint = 'prod/tenants/' + tenantName + '/configurations/' + configName
        let url = await getUrl() + endPoint;
        try {
            const res = await aws.fetch(url,
                {method: 'POST', body: JSON.stringify(tenantConfigJson)})
            res.json()
                .then((json) => {
                        console.log('PUT NEW CONFIG SUCCESSFUL1');
                        dispatch(fetchConfigs(getAWSClient, tenantName, json.string, [configName,  tenantConfigJson.parentConfig?tenantConfigJson.parentConfig:''] ));
                    }
                )
                .catch((e) => console.log('PUT NEW CONFIG JSON PROCESSING UNSUCCESSFUL'));
        } catch (e) {
            dispatch(getAWSClient, fetchConfigs(getAWSClient, tenantName, e.message, []))
        }
    } catch(e) {
        console.log('createConfig Failed', e);
    };
}

export const createVersion = (getAWSClient, tenantName, tenantConfigJson, configName, latestConfigVersion, parentConfig='') => async (dispatch) => {
    try {
        const aws = await getAWSClient();

        let endPointRetrieveConfigurationAPI = 'prod/tenants/' + tenantName + '/configurations'
        let urlRetrieve = await getUrl() + endPointRetrieveConfigurationAPI;

        let endPoint = 'prod/tenants/' + tenantName + '/configurations/' + configName
        let url = await getUrl() + endPoint;

        let output = await getLatestConfig(aws, urlRetrieve, configName)
        let validRequest = latestConfigVersion === output.configsMap[0].versionDescription

        try {
            if (validRequest) {
                const res = await aws.fetch(url,
                    { method: 'PUT',  body: JSON.stringify(tenantConfigJson)})
                res.json()
                    .then((json) => {
                            dispatch(fetchConfigs(getAWSClient, tenantName, json.string, [configName, parentConfig?parentConfig:(tenantConfigJson.parentConfig?tenantConfigJson.parentConfig:'')]));
                            console.log('PUT Create new version for ' + configName +'SUCCESSFUL' + " json " + json.string);
                        }
                    )
                    .catch((e) => console.log('PUT NEW VERSION JSON PROCESSING UNSUCCESSFUL'));
            } else {
                dispatch(fetchConfigs(getAWSClient, tenantName, "Updating older version, refreshing configs...."), configName, output.configsMap[0].parentConfig?output.configsMap[0].parentConfig:'');
            }
        }
        catch (e) {
            dispatch(fetchConfigs(getAWSClient, tenantName, e.message, configName, output.configsMap[0].parentConfig ));
        }
    } catch(e) {
        console.log('createVersion Failed', e);
    }
}

const getLatestConfig = async (aws, url, configName) => {
    try {
        const res = await aws.fetch(url,
            {method: 'POST', body: JSON.stringify(
                {
                        inheritFlag: false,
                        configList :
                            [
                                {
                                 configName : configName
                                }
                            ]
                        }
                )
            }
        )

        const settingsJson = await res.json();
        return settingsJson
    } catch (e) {
        alert("There was a problem retrieving configs from backend", e);
    }
}

const fetchSettings = async () => {
    try {
        const response = await fetch('./settings.json');
        const settingsJson = await response.json();
        return settingsJson
    } catch (e) {
        alert("There was a problem retrieving settings from backend", e);
    }
}
const getUrl = async () => {
    try {
        const settings = await fetchSettings()
        return settings["GFTConfigStoreService"]["APIEndPoint"]
    } catch (e) {
        alert("GetUrl failed")
    }
}
