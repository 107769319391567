import React from 'react';
import { Grid, Typography, Button, IconButton } from "@mui/material";
import DropDownMenu from './DropDownMenu';
import {Refresh, Save, Edit, Replay, Cancel} from '@mui/icons-material';
import SubmitModal from "./SubmitModal";
import {getConfig} from '../../logic/getConfig'

const MenuBar = (props) => {
    const { createVersion , onEditParentConfig, editParentConfig, onParentConfigSelected, selectedParentConfig,
        onTenantSelected, onConfigSelected, onVersionSelected, selectedVersion, selectedConfig, selectedTenant, tenants,
        configs, latestConfigVersion, setLatestConfigVersion, oldConfigVersion, getTenantConfigs, onChangeIsConfigInvalid } = props;
    const [ versions, setVersions ] = React.useState(null);
    const [prevParentConfig, setPrevParentConfig] = React.useState(null);
    const [openSubmitModal, setOpenSubmitModal] = React.useState(false);
    const [isBeingUpdated, setIsBeingUpdated] = React.useState(false);
    const [parentToDisplay, setParentToDisplay] = React.useState(false)
    const [newConfig, setNewConfig] = React.useState(null);

    const [configNames, setConfigNames] = React.useState(null);
    const [isConfigInvalid, setIsConfigInvalid] = React.useState(false)

    const config = (selectedConfig && configs !== undefined) ? configs.filter(config => config.configName === selectedConfig)[0] : null;


    const handleSelection = (value) => {
        if(value.category === 'tenants') onTenantSelected(value.item)
        else if (value.category === 'configurations') {
            onVersionSelected('Current')
            const currentConfig = configNames.filter(config => config.configName === value.item)[0]
            const parent = ( currentConfig && currentConfig.parentConfig )?currentConfig.parentConfig: '';
            onConfigSelected(value.item, parent)
            setPrevParentConfig(null)
            onEditParentConfig(false)
            setNewConfig(null)
        }
        else if (value.category === 'versions') {

        onVersionSelected(value.item)
        setPrevParentConfig(null)
        onEditParentConfig(false)
        setNewConfig(null)
    }
        else if (value.category=='parent'){
            onParentConfigSelected(value.item)
        }
    };

    const calculateVersions = (config) => {
        const currentVersion = (config && config["versionDescription"] != null) ?
            parseInt(config.versionDescription.slice(1, config.versionDescription.length)) + 1 : null
        try {
            let versionsArray = Array(currentVersion)
            setLatestConfigVersion(config["versionDescription"] != null ? config["versionDescription"] : null)
            for (let version = 0; version < currentVersion; version++) {
                if (version === 0) versionsArray[0] = "Current"
                else versionsArray[version] = `V${version}`
            }
            setVersions(versionsArray)
            setIsConfigInvalid(false)
        }
        catch(e){
            setIsConfigInvalid(true)
        }
    }


    React.useEffect(() => {
        if (selectedTenant){
            getTenantConfigs(props.getAWSClient, selectedTenant, setConfigNames);
        }
    }, [selectedTenant])

    React.useEffect(() => {
        onChangeIsConfigInvalid(isConfigInvalid)
    }, [isConfigInvalid])


    React.useEffect(() => {
        if(config != null && config !== undefined) {
            calculateVersions(config)
            onParentConfigSelected(config.parentConfig)
            const newParent = (oldConfigVersion && oldConfigVersion[0])?oldConfigVersion[0].parentConfig: selectedParentConfig;
            setParentToDisplay(newParent)
        }
    }, [ selectedConfig, configs ])

    React.useEffect(() => {
        if(versions != null) {
            onVersionSelected(versions[0])
        }
    }, [ versions ])

    React.useEffect(() => {
        const newParent = (oldConfigVersion && oldConfigVersion[0])?oldConfigVersion[0].parentConfig: selectedParentConfig;
        setParentToDisplay(newParent)
    }, [oldConfigVersion])

    React.useEffect(() => {
        setParentToDisplay(selectedParentConfig)
    }, [selectedParentConfig])

    const handleRedo = (category) => {
        if(category == 'parent'){
            onParentConfigSelected(prevParentConfig)
            setPrevParentConfig(null)
        }
    }

    const handleUndo = (category) => {
        if(category == 'parent') {
            setPrevParentConfig(selectedParentConfig)
            onParentConfigSelected(config.parentConfig)
        }
    }

    const handleOpenSubmitModal = (value) => {
        setOpenSubmitModal(value)
    }

    const handleEdit = (category) => {
        if(category == 'parent') {
            onEditParentConfig(!editParentConfig)
        }
    }

    const handleCancel = (category) => {
        handleUndo(category);
        if(category == 'parent') {
            setPrevParentConfig(null)
            onEditParentConfig(!editParentConfig)
        };
    }

    const handleSave = () => {
        handleOpenSubmitModal(true)
    }


    const onSubmitModalAccepted = () => {
        setIsBeingUpdated(true)
    }

    React.useEffect(() => {

        async function updateConfigParent(){
            await getConfig(props.getAWSClient, props.selectedTenant, selectedConfig, setNewConfig);
        }

        if(isBeingUpdated && selectedParentConfig != config.parentConfig && editParentConfig && newConfig === null){
            updateConfigParent();
        }

        if(isBeingUpdated && selectedParentConfig != config.parentConfig && editParentConfig && newConfig){

            const parentConfig = (selectedParentConfig !== 'No Parent' && selectedParentConfig !== 'EMPTY PARENT')? selectedParentConfig: '';

            const finalProduct = {
                "parentConfig": parentConfig,
                "configType": JSON.parse(config.metadata)['config_type'],
                "configsJson": newConfig,
                "versionDescription": "Update Config Parent",
                "user": props.user
            }
            console.log("HERE IS THE FINAL PRODUCT", finalProduct)
            createVersion(props.getAWSClient, props.selectedTenant, finalProduct, selectedConfig, latestConfigVersion, parentConfig)
            console.log("After submit", finalProduct)
            onEditParentConfig(false)
            handleOpenSubmitModal(false)
            setIsBeingUpdated(false)
            setNewConfig(null)
        }

        else if (isBeingUpdated && editParentConfig && newConfig){
            onEditParentConfig(false)
            handleOpenSubmitModal(false)
            setIsBeingUpdated(false)
            setNewConfig(null)
        }

    }, [selectedParentConfig, isBeingUpdated, newConfig])



    const EditButton = ({category}) => {
        return <IconButton onClick={() => handleEdit(category)}>
            <Edit />
        </IconButton>
    }

    const CancelButton = ({category}) => {
        return <IconButton onClick={() => handleCancel(category)}>
            <Cancel />
        </IconButton>
    }

    const UndoButton = ({category}) => {
        return <IconButton onClick={() => handleUndo(category)}>
            <Replay />
        </IconButton>
    }

    const RedoButton = ({category}) => {
        return <IconButton onClick={() => handleRedo(category)}>
            <Replay style={{transform: 'scaleX(-1)'}} />
        </IconButton>
    }

    const SaveButton = ({category}) => {
        return <IconButton onClick={() => handleSave(category)}>
            <Save />
        </IconButton>
    }

    const stylingTypes = {
        tenantsAndConfigs: {
            backgroundColor: "#595959",
            color: "white",
            borderRadius: 0,
            padding: 8,
            fontWeight: "bold",
            fontSize: "1rem",
            width: "100%"
        },
        versionControl: {
            backgroundColor: "white",
            color: "black",
            border: "1px solid grey",
            borderRadius: 0,
            textTransform: "none",
            margin: "15px 0 15px 0",
            width: "100%"
        }
    }

    const parentGridWidth = (isEditing, config, isCurrentConfig) => {
        if(!config) return 8;
        if(config.configType === 'parent' || !isCurrentConfig) return 9;
        if(isEditing) return 6;
        return 8;
    }


    return (
        <Grid aria-label='menu-bar' container spacing={1} mt={0} pl={1} pr={1}>
            <Grid item xs={6} >
                <Grid container>
                    <Grid item xs={3} >
                        <DropDownMenu stylingType={"tenantsAndConfigs"} handleSelection={handleSelection} category={"tenants"} items={tenants.tenants} />
                    </Grid>
                    { selectedTenant == null ? null :
                        <Grid item xs={9} className ="gridText" alignItems="center">
                            <Typography>
                                { selectedTenant }
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {/* Only show the configurations drop down if a tenant has been selected */}
            { selectedTenant != null && configNames && typeof configNames === typeof [] && configNames?.length > 0 ?
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={5}>
                            <DropDownMenu stylingType={"tenantsAndConfigs"} handleSelection={handleSelection} category={"configurations"}
                                          items={ configNames ? configNames.map(config => config.configName).filter(name => name!="global_configs") : [] } />
                        </Grid>
                        { selectedConfig != null && selectedConfig ?
                            <Grid item xs={7} className ="gridText" alignItems="center">
                                <Typography>
                                    { selectedConfig }
                                </Typography>
                            </Grid> : null }
                    </Grid>
                </Grid> : null }
                <Grid item xs={6}>
                    {selectedConfig && configs && !isConfigInvalid ?
                    <Grid container>
                        <Grid item xs={3}>
                            {editParentConfig && config && config.configType !== 'parent'?
                                <DropDownMenu
                                    aria-label='select-parent-dropdown'
                                    stylingType={"tenantsAndConfigs"}
                                    handleSelection={handleSelection}
                                    category={"parent"}
                                    items={ configNames ? configNames.filter(config =>
                                        config.configType === 'parent')
                                        .map(config => config.configName).concat('EMPTY PARENT') : [] }  />
                                :
                                <Button disabled style={stylingTypes["tenantsAndConfigs"]}>
                                    PARENT
                                </Button>
                            }
            </Grid>
                            <Grid item xs={parentGridWidth(editParentConfig, config, selectedVersion === 'Current')} className="gridText" alignItems="center">
                                <Typography>
                                    {(parentToDisplay === '' || parentToDisplay === null || parentToDisplay === undefined)?'EMPTY PARENT':parentToDisplay}
                                </Typography>
                            </Grid>
                        {config && config.configType !== 'parent' && selectedVersion === 'Current'?
                        <Grid item xs={(!editParentConfig)?1:3} className="gridText" style={{justifyContent: 'center', alignItems:"center", padding:0}}>
                            {editParentConfig &&  selectedConfig != null && selectedConfig !== undefined?
                                <>
                                    <div>
                                        {config!=null && config.parentConfig != selectedParentConfig &&
                                            (!['', null, undefined, 'No Parent', 'EMPTY PARENT'].includes(config.parentConfig) || !['', null, undefined, 'No Parent', 'EMPTY PARENT'].includes(selectedParentConfig))?
                                            <UndoButton category='parent' />:null}
                                    </div>
                                    <div>
                                        {config!=null && prevParentConfig!=null && config.parentConfig != prevParentConfig?
                                            <RedoButton category='parent' />:null}
                                    </div>

                                    <div>
                                        {config!=null?
                                            <SaveButton />
                                            :null
                                        }
                                    </div>

                                    <div>
                                        <CancelButton category='parent' />
                                    </div>
                                </>:
                                <>

                                    <div style={{textAlign:'center', marginRight: '10px'}}>
                                        <EditButton category='parent' />
                                    </div>
                                </>}
                        </Grid>:null}
                    </Grid>: null}
                </Grid>

            {/* Only show the configurations drop down if a tenant has been selected */}
            { config && selectedConfig && !isConfigInvalid && configs  && configs.filter(config => config.configName === selectedConfig)[0].versionDescription ?

                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={5}>
                            <DropDownMenu stylingType={"tenantsAndConfigs"} handleSelection={handleSelection} category={"versions"} items={ versions } />
                        </Grid>
                        { selectedVersion != null ?
                            <Grid item xs={7} className ="gridText" alignItems="center">
                                <Typography>
                                    { selectedVersion }
                                </Typography>
                            </Grid> : null }
                    </Grid>
                </Grid> : null }

            <SubmitModal open={openSubmitModal} handleClose ={() => handleOpenSubmitModal(false)} onSubmit = {onSubmitModalAccepted}/>
        </Grid>)
}

export default MenuBar