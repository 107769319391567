import * as ActionTypes from "../actions/ActionTypes";

////reducer function

export const TenantsReducer = (state = {
    isLoading: true,
    errMess: null,
    tenants: [],
    updatableTenants: [],
    reviewableTenants: []

}, action) => {

    switch (action.type) {
        case ActionTypes.APPSYNC_ADD_TENANTS:
            let sortedTenants=action?.payload?.sort((a,b)=>{
                return a.toLowerCase().localeCompare(b.toLowerCase())
            })
            return { ...state, isLoading: false, errMess: null, tenants: sortedTenants }

        case ActionTypes.APPSYNC_ADD_UPDATABLE_TENANTS:
            let sortedUpdatableTenants=action?.payload?.sort((a,b)=>{
                return a.toLowerCase().localeCompare(b.toLowerCase())
            })
            return { ...state, isLoading: false, errMess: null, updatableTenants: sortedUpdatableTenants }

        case ActionTypes.APPSYNC_ADD_REVIEWABLE_TENANTS:
            let sortedReviewableTenants=action?.payload?.sort((a,b)=>{
                return a.toLowerCase().localeCompare(b.toLowerCase())
            })
            return { ...state, isLoading: false, errMess: null, reviewableTenants: sortedReviewableTenants }

        case ActionTypes.APPSYNC_TENANTS_LOADING:
            return { ...state, isLoading: true, errMess: null, tenants: [] }

        case ActionTypes.APPSYNC_TENANTS_FAILED:
            return { ...state, isLoading: false, errMess: action.payload, tenants: [] }


        default:
            return state;
    }
}