import React, {useEffect, useState} from 'react';
import {
    Button, IconButton, Paper,
    Stack,
    Table,
    TableBody,
    TableContainer,
    Typography,
    Tooltip, TextField
} from '@mui/material';
import {SYSTEM_NAME} from "../../../constants/ApiConstants";
import '../../../../styles/table.css';
import TenantTableRow  from "./TenantTableRow";
import {Grid} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
    Add,
    CancelOutlined,
    EditOutlined,
    StarBorderOutlined,
    Search,
    CheckCircleOutlined, SearchOff
} from "@mui/icons-material";
import styles from "../../../../styles/TableStyles.module.css";
import CreateConfigModal from "../../CreateConfigModal";
import SubmitModal from "../../SubmitModal";
import {DIFFERENCES_PAGE, GLOBAL_CONFIGS, PREVIEW_CHANGES_PAGE} from "../../../constants";
import {hasRequiredAccess, isPendingState} from "../../../common/SharedFunctions";
import {useSelector} from "react-redux";


export default function TenantTable(props) {
    const {selectedVersion, oldConfigVersion,selectedTenant,createConfig, configs, updateConfig, user,onTenantSelected,setOpenTenant,setIsDifferencePageSelected,setDifferencesPageType,
        setPreviewCurrentVersion,setPreviewEditedVersion,setPendingVersionJsonBody,setOpenConfigModal,pendingReviews,setEditMode,totalVersions, setIsEditableTenant, isEditTenant,
        tenantConfigMap, setIsTenantConfigMap} = props;
    const globalConfigs = configs.filter(config => config['configName'] === "global_configs")
    const configValue = !globalConfigs.length ? new Map() : new Map(Object.entries(JSON.parse((globalConfigs[0])['configParams'])))
    const [config, setConfig] = useState(configValue);
    const [isEditable, setEditable] = useState(isEditTenant);
    let val = new Map()
    let count =0;
    configValue.forEach((v, k) => {
        val.set(count.toString(), [k,typeof v === "boolean" ? v===true?"true":"false" : v,typeof v, 0, 0, 0])
        count+=1;
    })
    const [newConfig, setNewConfigMap] = useState(val);
    const [countNewRows, updateCountNewRows] = useState(configValue.size);
    const [createParamMap, updateCreateParamMap] = useState(val);
    const [originalMap, setOriginalMap] = useState(val);
    const [open, setOpen] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [openSubmit, setOpenSubmit] = useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [update, setUpdate] = useState(null);
    const [focused, setFocused] = useState(false);
    const [updateAccess,setUpdateAccess]=useState(false);

    const allUpdatableTenants=useSelector(({tenantsReducer})=>({
        updatableTenants:tenantsReducer.updatableTenants
    }));

    useEffect(() => {
        const updateAccess = hasRequiredAccess(allUpdatableTenants.updatableTenants,selectedTenant);
        setUpdateAccess(updateAccess);
    }, [allUpdatableTenants.updatableTenants,selectedTenant])

    const onClick = () => {
        setFocused(!focused)
    }

    const handleCloseSnack = () => {
        props.setMessage("")
    };

    const handleSearch = (value) => {
        setIsSearch(value);
        if (!isSearch){
            setSearchValue("");
        }
    }

    React.useEffect(() => {
        const configsToDisplay = (!oldConfigVersion || selectedVersion === 'Current')? configs: oldConfigVersion;
        const globalConfigs = configsToDisplay.filter(config => config['configName'] === "global_configs")
        const configValue = !globalConfigs.length ? new Map() : new Map(Object.entries(JSON.parse((globalConfigs[0])['configParams'])))
        setConfig(configValue);
        let val = new Map()
        let count =0;
        configValue.forEach((v, k) => {
            val.set(count.toString(), [k,typeof v === "boolean" ? v===true?"true":"false" : v,typeof v, 0, 0, 0])
            count+=1;
        })
        setNewConfigMap(val)
        setEditable(isEditTenant)
        updateCountNewRows(configValue.size)

        if (isEditTenant === true) {
            updateCreateParamMap(tenantConfigMap)
        } else {
            updateCreateParamMap(new Map(JSON.parse(
                JSON.stringify(Array.from(val))
            )))
        }

        setOriginalMap(new Map(JSON.parse(
            JSON.stringify(Array.from(val))
        )))

        setOpen(false)
        setOpenSubmit(false)
        setOpenCancel(false)
    }, [configs, selectedVersion, oldConfigVersion]);

    React.useEffect(() => {
        if (update) {
            const refTarget = document.getElementById(`${update}-table-item`);
            if (refTarget) {
                refTarget.scrollIntoView({behavior: "smooth"})
            }
        }
    }, [update])

    React.useEffect(() => {
        setEditMode(isEditable);
    }, [isEditable])

    const handleSubmitClose = () => setOpenSubmit(false);

    const handleCancelClose = () => setOpenCancel(false);

    const onEdit = () => {
        setEditable(true);
    }

    const onCancel = () => {
        setIsEditableTenant(false)
        setIsTenantConfigMap(null)

        const rows = [...createParamMap.values()];
        const editedFlags = rows.map((row) => row[5])
        if (editedFlags.includes(1)){
            setOpenCancel(true);
        } else {
            onCancelChanges();
        }
    }

    const handlePreviewChanges=()=>{
        const mapIterator = createParamMap.values();
        let i = 0;
        let valid = true;
        let errorMessage = "";
        let submitMap = new Map()
        while ((i < createParamMap.size) && valid) {
            let configEntry = mapIterator.next().value;
            if (configEntry[0] && configEntry[1] && !configEntry[3]) {
                if (configEntry[2]==="number") {
                    if (isNaN(configEntry[1])){
                        valid = false;
                        errorMessage = "Input datatype mismatch";
                    } else {
                        submitMap.set(configEntry[0],Number(configEntry[1]))
                    }
                } else if (configEntry[2]==="boolean") {
                    if (configEntry[1] === "true" || configEntry[1] === "false"){
                        submitMap.set(configEntry[0],configEntry[1]==="true" ? true : false)
                    } else {
                        valid = false;
                        errorMessage = "Input datatype mismatch";
                    }
                } else {
                    submitMap.set(configEntry[0],String(configEntry[1]))
                }
            }
            i++;
        }

        if (submitMap.size < 1) {
            valid = false;
            errorMessage = "Global configs cannot be empty";
        }

        if (valid) {
            const jsonBody = {
                "configType": "global",
                "configName": "global_configs",
                "configsJson": JSON.stringify(Object.fromEntries(submitMap)),
                "parentConfig": "",
                "versionDescription": "Updated Tenant Config",
                "tenantName": `${selectedTenant}`,
                "system": SYSTEM_NAME,
                "user": `${user}`,
                "version":`${totalVersions}`
            }
            setPendingVersionJsonBody(jsonBody)
            setIsDifferencePageSelected(true)
            setDifferencesPageType(PREVIEW_CHANGES_PAGE)
            setOpenTenant(false)
            setPreviewEditedVersion(jsonBody)
            setPreviewCurrentVersion(globalConfigs[0])
            setIsEditableTenant(true)
            setIsTenantConfigMap(createParamMap)
        } else {
            alert(errorMessage)
        }
        setOpenSubmit(false)
    }

    const onSubmitEditChanges = () => {
        const mapIterator = createParamMap.values();
        let i = 0;
        let valid = true;
        let errorMessage = "";
        let submitMap = new Map()
        while ((i < createParamMap.size) && valid) {
            let configEntry = mapIterator.next().value;
            if (configEntry[0] && configEntry[1] && !configEntry[3]) {
                if (configEntry[2]==="number") {
                    if (isNaN(configEntry[1])){
                        valid = false;
                        errorMessage = "Input datatype mismatch";
                    } else {
                        submitMap.set(configEntry[0],Number(configEntry[1]))
                    }
                } else if (configEntry[2]==="boolean") {
                    if (configEntry[1] === "true" || configEntry[1] === "false"){
                        submitMap.set(configEntry[0],configEntry[1]==="true" ? true : false)
                    } else {
                        valid = false;
                        errorMessage = "Input datatype mismatch";
                    }
                } else {
                    submitMap.set(configEntry[0],String(configEntry[1]))
                }
            }
            i++;
        }
        if (submitMap.size < 1) {
            valid = false;
            errorMessage = "Global configs cannot be empty";
        }

        if (valid) {
            const jsonBody = {
                "configType": "global",
                "configName": "global_configs",
                "configsJson": JSON.stringify(Object.fromEntries(submitMap)),
                "parentConfig": "",
                "versionDescription": "Updated Tenant Config",
                "tenantName": `${selectedTenant}`,
                "system": SYSTEM_NAME,
                "user": `${user}`
            }
            if (configValue.size === 0) {
                createConfig(selectedTenant, jsonBody);
            } else {
                updateConfig(selectedTenant, jsonBody);
            }
        } else {
            alert(errorMessage)
        }
        setOpenSubmit(false);
    }

    const onSubmit = () => {
        setOpenSubmit(true)
    }

    const onCancelChanges = () => {
        setEditable(false);
        let val = new Map()
        let count =0;
        configValue.forEach((v, k) => {
            val.set(count.toString(), [k,typeof v === "boolean" ? v===true?"true":"false" : v,typeof v, 0, 0, 0])
            count+=1;
        })
        setNewConfigMap(val)
        updateCountNewRows(configValue.size);
        updateCreateParamMap((new Map(JSON.parse(
            JSON.stringify(Array.from(val))
        ))));
        setOpenCancel(false)
    }

    const onTableRowValueEdit = (key, value) => {
        const valueMap = createParamMap.get(key);
        valueMap[1] = value;
        createParamMap.set(key, valueMap)
        onTableRowEditedFlag(key, 1)

    }

    const onTableRowEditedFlag = (key, value) => {
        const valueMap = createParamMap.get(key);
        valueMap[5] = value;
        updateCreateParamMap(createParamMap.set(key, valueMap))
    }

    const onTableRowKeyEdit = (key, value) =>  {
        const keyMap = createParamMap.get(key);
        keyMap[0] = value;
        createParamMap.set(key, keyMap)
        onTableRowEditedFlag(key, 1)
    }

    const setCreateParamRowType = (key, value) =>{
        const typeMap = createParamMap.get(key);
        typeMap[2] = value;

        createParamMap.set(key, typeMap)
        onTableRowEditedFlag(key, 1)

    }

    const onCreateParameter = (key, value) => {
        createParamMap.set(countNewRows.toString(),["", "", "", 0, 1]);
        updateCountNewRows(countNewRows + 1);
        setUpdate(countNewRows.toString())
    }


    const onDeleteCreateParamRow = (key, value) => {
        const typeMap = createParamMap.get(key);
        // Delete if never initiated value
        if (!originalMap.get(key)){
            createParamMap.delete(key);
            updateCountNewRows(countNewRows - 1);
            setUpdate(countNewRows.toString());
        } else {
            typeMap[3] = value;
            createParamMap.set(key, typeMap)
            onTableRowEditedFlag(key, 1)
        }
    }

    const onUndo = (key, setValue, setKey, setType, setIsDeleted, resetEditFlag) => {
        const entryMap = newConfig.get(key);
        let oldList = [entryMap[0], entryMap[1], entryMap[2], entryMap[3], entryMap[4], 2]

        createParamMap.set(key, oldList)
        resetEditFlag(2)
        setValue(entryMap[1])
        setKey(entryMap[0])
        setType(entryMap[2])
        setIsDeleted(entryMap[3])
    }

    const getRows = () =>{
        const comps = [];
        if (createParamMap) {
            createParamMap.forEach((row, index) => {
                const filter = searchValue.toUpperCase();
                if (!isSearch || filter === "" || row[0].toUpperCase().indexOf(filter) > -1 || row[1].toString().toUpperCase().indexOf(filter) > -1) {
                    comps.push(<TenantTableRow
                        index={index}
                        row={row}
                        isEditable={isEditable}
                        key={index + "-" + row + "-tenantTableRow"}
                        onTableRowKeyEdit={onTableRowKeyEdit}
                        onTableRowValueEdit={onTableRowValueEdit}
                        setCreateParamRowType={setCreateParamRowType}
                        onDelete={onDeleteCreateParamRow}
                        onUndo = {onUndo}
                        onTableRowEditedFlag = {onTableRowEditedFlag}
                    />)
                }
            });
        }

        return comps;
    }

    const theme = createTheme({
        palette: {
            primary:{
                main:"#ffffff"
            }
        },
    });

    const getAlerts = () =>{
        let isSuccessful = props.message === "Success"
        if (props.message) {
            return (
                <Grid container border={ isSuccessful ? "1px solid darkgreen" : "1px solid red"}
                      bgcolor={ isSuccessful ? "#F4FFF4" : "#FFE0DF" } >
                    <Grid container item xs={0.5} style={{padding:0}} justifyContent={"flex-start"}  alignItems={"center"}>
                        {
                            isSuccessful ? <CheckCircleIcon color={"success"} fontSize={"small"}></CheckCircleIcon>
                                :
                                <CancelIcon color={"error"} fontSize={"small"}></CancelIcon>
                        }
                    </Grid>
                    <Grid container item xs={9.5} style={{padding:0}} justifyContent={"flex-start"} alignItems={"center"}>
                        {
                            isSuccessful ?
                                <Typography p={0}
                                            onClick={onClick}
                                            fontSize={"0.8rem"}
                                            fontWeight={"401"}
                                            fontFamily={'Amazon Ember'}
                                            style={focused ?
                                                {wordWrap: "break-word"} :
                                                {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}
                                            }>
                                    Changes submitted successfully</Typography> :
                                <Typography p={0}
                                            onClick={onClick}
                                            fontSize={"0.8rem"}
                                            fontWeight={"401"}
                                            fontFamily={'Amazon Ember'}
                                            style={focused ?
                                                {wordWrap: "break-word"} :
                                                {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden'}
                                            }> {props.message} </Typography>
                        }
                    </Grid>
                    <Grid container item xs={2} style={{padding:0}} justifyContent={"flex-end"} alignItems={"center"}>
                        <IconButton style={{padding:0}} onClick={handleCloseSnack}>
                            <CancelOutlined></CancelOutlined>
                        </IconButton>
                    </Grid>
                </Grid>)
        } else {
            return null
        }
    }

    if (config) {
        return (
            <div className={styles.table} data-testid={'tenant-table-' + props.selectedTenant}>
                <Grid container pt={0} sx={{backgroundColor: "white"}}>
                    {getAlerts()}
                    <Grid container direction='row' justifyContent="space-between" alignItems="center"
                          className={styles.table__header}>
                        <Grid>
                            {isEditable ?
                                <Button onClick={onCreateParameter} variant="outlined" startIcon={<Add/>}
                                        className={styles.config__button}>
                                    Create Key-Value
                                </Button>
                                :
                                <Button
                                    disabled={!updateAccess}
                                        onClick={()=>setOpenConfigModal(true)} variant="outlined" startIcon={<Add/>}
                                        className={!updateAccess?styles.config__button__disabled:styles.config__button}
                                >
                                    Create Configuration
                                </Button>
                            }
                            <Button variant="outlined" disabled className={styles.local__button}>
                                <Typography className={styles.local__icon}>L</Typography>&nbsp;Local
                            </Button>
                        </Grid>
                        {isEditable ?
                            <Stack direction="row" alignItems="center" spacing={0}>
                                {isSearch ?
                                    <TextField size="small"
                                               variant="outlined"
                                               value={searchValue}
                                               onChange={(e)=>setSearchValue(e.target.value)}
                                               inputProps={{
                                                   style: {
                                                       height: "16px",
                                                   },
                                               }}>
                                    </TextField>
                                : null
                                }
                                <Tooltip title='Search' placement='top' arrow>
                                    <IconButton onClick={()=> handleSearch(!isSearch)} size="small">
                                        {isSearch ?
                                            <SearchOff/>
                                            :
                                            <Search/>
                                        }
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Submit' placement='top' arrow>
                                    {/*<IconButton size="small" aria-label='submit-tenant' onClick={selectedTenant.startsWith('Demo')|| selectedTenant.startsWith('Prod')?handlePreviewChanges:onSubmit}>*/}
                                    <IconButton size="small" aria-label='submit-tenant' onClick={handlePreviewChanges}>
                                        <CheckCircleOutlined/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='Cancel' placement='top' arrow>
                                    <IconButton size="small" aria-label='cancel-tenant' onClick={onCancel}>
                                        <CancelOutlined/>
                                    </IconButton>
                                </Tooltip>
                                {/* //Favorites will be implemented in future release
                                <Tooltip title='Favorite' placement='top' arrow>
                                    <IconButton size="small">
                                        <StarBorderOutlined/>
                                    </IconButton>
                                </Tooltip>
                                */}
                            </Stack>
                            :
                            <Stack direction="row" alignItems="center" spacing={0}>
                                {isSearch ?
                                    <TextField size="small"
                                               variant="outlined"
                                               value={searchValue}
                                               onChange={(e)=>setSearchValue(e.target.value)}
                                               inputProps={{
                                                   style: {
                                                       height: "16px",
                                                   },
                                               }}>
                                    </TextField>
                                    : null
                                }
                                <Tooltip title='Search' placement='top' arrow>
                                    <IconButton onClick={()=> handleSearch(!isSearch)} size="small">
                                        {isSearch ?
                                            <SearchOff/>
                                            :
                                            <Search/>
                                        }
                                    </IconButton>
                                </Tooltip>
                                { !selectedVersion || selectedVersion === 'Current' ?
                                    <Tooltip title='Edit' placement='top' arrow>
                                        <IconButton size="small" onClick={onEdit} disabled={isPendingState(pendingReviews,selectedTenant,GLOBAL_CONFIGS) || !updateAccess}>
                                            <EditOutlined/>
                                        </IconButton>
                                    </Tooltip> : null
                                }
                                {/*
                                   <Tooltip title='Favorite' placement='top' arrow>
                                    <IconButton size="small">
                                        <StarBorderOutlined/>
                                    </IconButton>
                                </Tooltip>
                                */}

                            </Stack>
                        }
                    </Grid>
                    <Grid item xs={4} key={props.selectedTenant + '-grid2'}>
                        <SubmitModal open={openCancel} handleClose ={handleCancelClose} onSubmit = {onCancelChanges} isCancel={true}/>
                        <SubmitModal open={openSubmit} handleClose ={handleSubmitClose} onSubmit = {onSubmitEditChanges}/>
                    </Grid>
                    <Grid container key={props.selectedTenant + '-grid3'} >
                        <Paper sx={{ width: '100%', overflow: 'hidden'}}>
                            <TableContainer className={styles.table__rows} id={'-data-table-container'}>
                                <Table stickyHeader id={props.selectedTenant + '-data-table'}
                                       style = {{borderCollapse:"separate",borderSpacing: "0"}} className="tenantContainer">
                                    <TableBody id={props.selectedTenant + '-data-table-body'}>
                                        {getRows()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }

}

