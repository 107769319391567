// 3rd Party-Libraries import
import { TextField } from '@mui/material';

// Local import
import styles from '../../styles/FormTextFieldStyles.module.css'

const TEXTAREA_CHARACTER_LIMIT = 250;

const FormTextField = ({ label = '', multiline = false, rows = 1, value = '',
                           setter = () => { }, hasWordLimit = false, size='medium',  wordLimit=null, errorText=''}) => {

    return (
        <TextField
            variant="outlined"
            color="primary"
            label={label}
            className={`${styles.form_text_field}`}
            size={size}

            inputProps={{
                maxLength: hasWordLimit?(wordLimit??TEXTAREA_CHARACTER_LIMIT):null
            }}
            multiline={multiline}
            rows={rows}
            value={value}
            onChange={(e) => setter(e.target.value)}
            helperText={(hasWordLimit ? `${value.length}/${(wordLimit??TEXTAREA_CHARACTER_LIMIT)}` : '') + (errorText.length?(' ' + errorText) : '')}
            error = {errorText.length > 0}
        />
    );
}

export default FormTextField;