import React, { useState, useEffect } from 'react';
import {Grid, IconButton} from "@mui/material";
import styles from "../../styles/SidebarStyles.module.css";

import {DifferenceOutlined, FileUploadOutlined, HomeOutlined} from "@mui/icons-material";
import PipeIcon from "../../assets/Pipe.svg";
import {DIFFERENCES_PAGE, PREVIEW_CHANGES_PAGE} from "../constants";
import {useSelector} from "react-redux";
import {hasRequiredAccess} from "../common/SharedFunctions";

const SideBar=(props)=>{
    const {selectedTenant, onTenantClosed, differencesPageType,openDifferencePage, handleUploadOpen, editMode}=props

    const allUpdatableTenants=useSelector(({tenantsReducer})=>({
        updatableTenants:tenantsReducer.updatableTenants,
        reviewableTenants:tenantsReducer.reviewableTenants
    }))

    const [updateAccess,setUpdateAccess]=useState(false);
    const [reviewAccess,setReviewAccess]=useState(false);

    useEffect(() => {
        if(selectedTenant){
            const updateAccess = hasRequiredAccess(allUpdatableTenants.updatableTenants,selectedTenant);
            setUpdateAccess(updateAccess);
            const reviewAccess = hasRequiredAccess(allUpdatableTenants.reviewableTenants,selectedTenant);
            setReviewAccess(reviewAccess);
        }
    }, [allUpdatableTenants.updatableTenants, allUpdatableTenants.reviewableTenants,selectedTenant])

    return(
        <Grid container item direction='column' width='48px'
              pt='12.8px'
              gap='8px'

        >
            <IconButton className={styles.side__buttons}
                        onClick={onTenantClosed}
                 disabled={differencesPageType===PREVIEW_CHANGES_PAGE || editMode}
            >
                <HomeOutlined />
            </IconButton>
            <IconButton onClick={(e)=>openDifferencePage(e,true)}
                        className={differencesPageType===DIFFERENCES_PAGE?styles.differences_page__side__icons:styles.side__buttons}
                        disabled={differencesPageType===PREVIEW_CHANGES_PAGE || editMode || !reviewAccess}
            >
                <DifferenceOutlined />
            </IconButton>
            <IconButton className={styles.side__buttons}
                        onClick={handleUploadOpen}
                        disabled={differencesPageType===PREVIEW_CHANGES_PAGE || editMode || !updateAccess}>
                <FileUploadOutlined />
            </IconButton>
            {/*Pipelines are going to implement in next phase*/}
            {/*<IconButton className={styles.side__buttons}*/}
            {/*            disabled={differencesPageType===PREVIEW_CHANGES_PAGE}>*/}
            {/*    <img src={PipeIcon}/>*/}
            {/*</IconButton>*/}
        </Grid>
    )
}
export default SideBar;