import React, { useState, useEffect, useRef } from 'react';
import '../../styles/DropDownList.css'
import {ArrowDropDownOutlined, Clear} from "@mui/icons-material";
import {Divider} from "@mui/material";
import {useSelector} from "react-redux";

const dividerStyle={width:'1px',height:'30px',border:'1px solid #0070f0'}
const iconStyle={
    width:'17px',height:'17px',color:'grey'
}
const DropDownList=(props)=> {
    const {setTenantCardSelect}=props
    const [isDropdownActive, setDropdownActive] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const iconRef=useRef(null)

    const allTenants=useSelector(({tenantsReducer})=>({
        tenants:tenantsReducer.tenants
    }))
    const toggleDropdown = () => {
        setDropdownActive(!isDropdownActive);
    };

    const replaceInput = (item) => {
        setSelectedItem(item);
        setTenantCardSelect(item)
        setSearchQuery('');
        toggleDropdown();
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target) && !iconRef.current.contains(event.target)) {
            setDropdownActive(false);
        }
    };

    const handleInputClick = (e) => {
        e.stopPropagation();
        toggleDropdown();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setSelectedItem(inputValue);
        setTenantCardSelect('')
        setSearchQuery(inputValue);
        setDropdownActive(true);
    };

    const handleInputClear = () => {
        setSelectedItem('');
        setTenantCardSelect('')
        setSearchQuery('');
    };
    const filteredItems = allTenants.tenants?.filter(item => item.toLowerCase().startsWith(searchQuery.toLowerCase()));

    return (
        <div>
            <div className="input-container">
                <input
                    ref={inputRef}
                    className={"dropdown__input"}
                    type="text"
                    onClick={(e)=>handleInputClick(e)}
                    onChange={handleInputChange}
                    value={selectedItem}
                    placeholder="Search"
                    maxLength='20.5px'
                />
                <div style={{position:"absolute",top:2,right:4}} ref={iconRef}>
                    <ArrowDropDownOutlined onClick={(e)=>handleInputClick(e)} sx={{width:'24px',height:'24px',color:'#0070F0'}}/>
                </div>
                {selectedItem.length>0&&
                    <>
                        <div style={{position:"absolute",top:0,right:30}}>
                            <Divider orientation="vertical"  sx={dividerStyle} />
                        </div>
                        <div style={{position:"absolute",top:2,right:35,color:'red'}}>
                            <Clear  sx={iconStyle} onClick={handleInputClear}/>
                        </div>
                    </>
                }
                {isDropdownActive && filteredItems?.length > 0 && (
                    <div ref={dropdownRef} className="popup-dropdown">
                        <ul className="dropdown-list">
                            {filteredItems.map((item, index) => (
                                <li key={index} onClick={() => replaceInput(item)}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DropDownList;