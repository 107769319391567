import React, {useCallback, useEffect, useRef, useState} from "react";
import {Box, Divider, Grid, IconButton, MenuItem, Paper, TextField, Typography} from "@mui/material";
import {addedDiff, deletedDiff, diff as diff1, updatedDiff} from "deep-object-diff";
import {diff} from 'jsondiffpatch';

import styles from '../../styles/Differences.module.css'
import {ArrowDropDownOutlined, ArrowDropUpOutlined, KeyboardArrowUpOutlined} from "@mui/icons-material";

import * as jsondiffpatch from "jsondiffpatch";
import DeletedRowDisplay from "./DeletedRowDisplay";
import AddedRowDisplay from "./AddedRowDisplay";
import ChangedRowDisplay from "./ChangedRowDisplay";
import UnChangedRowDisplay from "./UnChangedRowDisplay";
import {currentVersionInfo, keyExists, mergeLists, nestedObj, sizeOfProp} from "../common/SharedFunctions";
import {DIFFERENCES_PAGE, PREVIEW_CHANGES_PAGE, REVIEW_DIFFERENCES} from "../constants";
import {diffBorderColor, statusColor} from "../constants/ApprovalStatusConstants";
import {ADDED, CHANGED, DELETED, UNCHANGED} from "../constants/DifferencesConstants";




const DifferencesRightDisplay=(props)=>{

    const {prevVersion,versionList,selectedVersion,handleVersion,
        differencesPageType,metaData,versionInfo,allRows,uniqueRowKey}=props
    const [isVersionChange,setIsVersionChange]=useState(false)
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
            setIsVersionChange(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const diffRowDisplay= useCallback(() => {
        return allRows.map((eachRow,index)=>{
            const {rowKey,value,isNestedJson,parentKey,level,keyMetaData,change}=eachRow
            switch (change){
                case UNCHANGED:
                    return <UnChangedRowDisplay
                        key={parentKey.join(':')+change+index}
                        rowKey={rowKey}
                        value={value}
                        isNestedJson={isNestedJson}
                        level={level}
                        keyMetaData={keyMetaData}
                        currentRowKey={change+index}
                    />
                case CHANGED:
                    return <ChangedRowDisplay
                        key={parentKey.join(':')+change+index}
                        rowKey={rowKey}
                        value={nestedObj(prevVersion,parentKey)}
                        isNestedJson={isNestedJson}
                        level={level}
                        keyMetaData={keyMetaData}
                        currentRowKey={change+index}
                        uniqueRowKey={uniqueRowKey}
                    />
                case ADDED:
                    return <AddedRowDisplay
                        key={parentKey.join(':')+change+index}
                        rowKey={rowKey}
                        value={value}
                        isNestedJson={isNestedJson}
                        level={level}
                        isCurrentVersion={false}
                        keyMetaData={keyMetaData}
                        currentRowKey={change+index}
                        uniqueRowKey={uniqueRowKey}
                    />
                case DELETED:
                    return <DeletedRowDisplay
                        key={parentKey.join(':')+change+index}
                        rowKey={rowKey}
                        value={value}
                        isNestedJson={isNestedJson}
                        level={level}
                        isCurrentVersion={false}
                        keyMetaData={keyMetaData}
                        currentRowKey={change+index}
                        uniqueRowKey={uniqueRowKey}
                    />
            }
        })
    }, [allRows,uniqueRowKey]);

    const container1 = document.getElementById('scrollContainer');
    const container2 = document.getElementById('scrollContainer2');

    if(container1 && container2){
        container1?.addEventListener('scroll', (e) => {
            container2.scrollLeft = container1.scrollLeft;
            container2.scrollTop = container1.scrollTop;
        });

        container2?.addEventListener('scroll', (e) => {
            container1.scrollLeft = container2.scrollLeft;
            container1.scrollTop = container2.scrollTop;
        });
    }


    return(

        <Grid container item xs direction='column' className={styles.differences__display__container} sx={{border:'1px solid #E6E6E6'}}>
            <Box className={styles.right__display__version__status__box}>
                <Typography className={styles.box_header_right_display} >Version</Typography>
                {differencesPageType===REVIEW_DIFFERENCES?<div style={{marginLeft:'16px',marginTop:'5px'}}>{versionInfo.date? versionInfo.date:null} &nbsp;{versionInfo.status && <span style={statusColor[versionInfo.status]}>({versionInfo.status})</span>}</div>:

                    <div ref={inputRef}  style={{marginLeft:'16px',marginTop:'5px',position:'relative'}} onClick={()=>setIsVersionChange(!isVersionChange)}>
                    {differencesPageType === DIFFERENCES_PAGE? selectedVersion : currentVersionInfo(metaData)}
                    {differencesPageType === DIFFERENCES_PAGE &&
                        <>
                            <div style={{position:"absolute",top:2,right:4}}>
                            {isVersionChange?<ArrowDropUpOutlined sx={{width:'24px',height:'24px',color:'grey'}}/>
                                :<ArrowDropDownOutlined sx={{width:'24px',height:'24px',color:'grey'}}/>}
                            </div>
                            <div ref={dropdownRef}  style={{position:"absolute",top:'125%',right:0,zIndex:100}}>
                                {isVersionChange&&
                                    <Paper className={styles.version__dropdown__list}>
                                        {
                                            versionList?.map((eachVersion)=>{
                                                return <MenuItem sx={{pl:'16px',bgcolor:selectedVersion===eachVersion?'#EFF7FE':'#fff',fontSize:'16px',width:'310px',lineHeight:'150%'}} onClick={()=>handleVersion(eachVersion)} key={eachVersion} value={eachVersion} >{eachVersion}</MenuItem>
                                            })
                                        }
                                    </Paper>}
                            </div>
                        </>
                    }
                </div>}
            </Box>
            <Divider orientation="horizontal"   sx={{bgcolor:'#E6E6E6',width:'648px',height:'1px',mt:'7px'}}/>
            <div id={'scrollContainer2'} className={styles.differences__rows__container} >
                {diffRowDisplay()}
            </div>
        </Grid>

    )
}
export default DifferencesRightDisplay;

