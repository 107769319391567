// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =
    {
        "dev": {
            "aws_appsync_graphqlEndpoint":"https://iaffhqn6u5dydktgjk67lmmv3a.appsync-api.us-west-2.amazonaws.com/graphql",
            "aws_appsync_region": "us-west-2",
            "aws_appsync_authenticationType": "API_KEY",
            "aws_appsync_apiKey":"da2-s4e2i7vvqzhnlmkijx5niiu4ku"
        },
        "alpha": {
            "aws_appsync_graphqlEndpoint":"https://iaffhqn6u5dydktgjk67lmmv3a.appsync-api.us-west-2.amazonaws.com/graphql",
            "aws_appsync_region": "us-west-2",
            "aws_appsync_authenticationType": "API_KEY",
            "aws_appsync_apiKey":"da2-s4e2i7vvqzhnlmkijx5niiu4ku"
        },
        "test":{
            "aws_appsync_graphqlEndpoint":"https://dcd6iaz5jbb2ndkjqpylzhstxm.appsync-api.us-west-2.amazonaws.com/graphql",
            "aws_appsync_region": "us-west-2",
            "aws_appsync_authenticationType": "API_KEY",
            "aws_appsync_apiKey":"da2-qpwet5ggsjajjowtgjthqdiwqa"
        },
        "beta":{
            "aws_appsync_graphqlEndpoint":"https://dcd6iaz5jbb2ndkjqpylzhstxm.appsync-api.us-west-2.amazonaws.com/graphql",
            "aws_appsync_region": "us-west-2",
            "aws_appsync_authenticationType": "API_KEY",
            "aws_appsync_apiKey":"da2-qpwet5ggsjajjowtgjthqdiwqa"
        },
        "prod":{
            "aws_appsync_graphqlEndpoint":"https://pkxzkumgpbgedac24hegntntjy.appsync-api.us-west-2.amazonaws.com/graphql",
            "aws_appsync_region": "us-west-2",
            "aws_appsync_authenticationType": "API_KEY",
            "aws_appsync_apiKey":"da2-2ubjkogmvbauthp6a4x6lbnrlq"
        },
        "production":{
            "aws_appsync_graphqlEndpoint":"https://pkxzkumgpbgedac24hegntntjy.appsync-api.us-west-2.amazonaws.com/graphql",
            "aws_appsync_region": "us-west-2",
            "aws_appsync_authenticationType": "API_KEY",
            "aws_appsync_apiKey":"da2-2ubjkogmvbauthp6a4x6lbnrlq"
        }
    };
export default awsmobile;