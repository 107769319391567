import React, { useState} from "react";
import {Grid, Stack, Typography} from "@mui/material";
import AddOutlined from "@mui/icons-material/AddOutlined";
import CreateTenantModal from "../Shared/CreateTenantModal";
import TenantsList from "./TenantsList";
import {DATA_CARD} from "../constants/HomePageConstants";
import DropDownList from "../common/DropDownList";

const titleStyle={
    fontSize: '18px',lineHeight:'160%',fontWeight:'500'
}
const iconStyle={
    width:'20px',height:'20px',color:'#808080'
}

const TenantsColumn=(props)=>{
    const {onTenantSelected, user, createTenant,allTenantsData,openDifferencePage,tenantCardInfo,setOpenConfigModal,setOpenUploadModal}=props
    const [open, setOpen] = useState(false);
    const [tenantCardSelect,setTenantCardSelect]=useState('')

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <>
            <Stack>
                <Grid container item minWidth='240px' minHeight='69px'>
                    <Grid container item width='240px'>
                        <Grid item xs>
                            <Typography sx={titleStyle}>Tenants</Typography>
                        </Grid>
                        <Grid item>
                            <AddOutlined  sx={iconStyle} onClick={handleOpen}/>
                            <CreateTenantModal
                                user={user}
                                createTenant={createTenant}
                                open={open}
                                setOpen={setOpen}
                                allTenantsData={allTenantsData}
                                handleClose={handleClose}/>
                        </Grid>
                    </Grid>
                    <Grid container item marginTop={0.8}>
                        <DropDownList
                            setTenantCardSelect={setTenantCardSelect}
                        />
                    </Grid>
                </Grid>
                <Grid mt={'9.6px'} >
                    <TenantsList
                        cardFunctionality={DATA_CARD}
                        getAWSClient={props.getAWSClient}
                        onTenantSelected={onTenantSelected}
                        tenantCardSelect={tenantCardSelect}
                        setTenantCardSelect={setTenantCardSelect}
                        tenantCardInfo={tenantCardInfo}
                        openDifferencePage={openDifferencePage}
                        setOpenConfigModal={setOpenConfigModal}
                        setOpenUploadModal={setOpenUploadModal}
                    />
                </Grid>
            </Stack>
        </>
    )
}

export default TenantsColumn;