export const APPSYNC_ADD_TENANTS = 'APPSYNC_ADD_TENANTS';             //will remove appsync variable once ApiGateway apis are removed
export const APPSYNC_TENANTS_LOADING = 'APPSYNC_TENANTS_LOADING';
export const APPSYNC_TENANTS_FAILED = 'APPSYNC_TENANTS_FAILED';

export const APPSYNC_ADD_UPDATABLE_TENANTS = 'APPSYNC_ADD_UPDATABLE_TENANTS';
export const APPSYNC_ADD_REVIEWABLE_TENANTS = 'APPSYNC_ADD_REVIEWABLE_TENANTS';


export const ADD_CONFIGS = 'ADD_CONFIGS';
export const CONFIGS_LOADING = 'CONFIGS_LOADING';
export const CONFIGS_FAILED = 'CONFIGS_FAILED';
