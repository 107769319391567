import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Box, Divider, Grid, IconButton, Stack, TableCell, TableContainer, TableRow, Typography} from "@mui/material";
import {ArrowDropDownOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined} from "@mui/icons-material";
import styles from '../../styles/Differences.module.css'
import {addedDiff, deletedDiff, updatedDiff,diff as diff1} from "deep-object-diff";
import {diff} from 'jsondiffpatch';
import UnChangedRowDisplay from "./UnChangedRowDisplay";
import AddedRowDisplay from "./AddedRowDisplay";
import DeletedRowDisplay from "./DeletedRowDisplay";
import ChangedRowDisplay from "./ChangedRowDisplay";
import {DIFFERENCES_PAGE, PREVIEW_CHANGES_PAGE} from "../constants";
import {
    checkVersionStatus, currentDiffRowFromTop, displayReviewStatus, getDiffRowItems,
    getObjectKeysList,
    keyExists,
    mergeLists,
    nestedObj,
    sizeOfProp
} from "../common/SharedFunctions";
import {diffBorderColor, statusColor} from "../constants/ApprovalStatusConstants";
import {ADDED, CHANGED, DELETED, UNCHANGED} from "../constants/DifferencesConstants";


const DifferencesLeftDisplay=(props)=>{
    const { versionInfo,uniqueRowKey,setUniqueRowKey,allRows}=props
    const [uniqueKeysList,setUniqueKeysList]=useState([])
    const [totalDiffCount,setTotalDiffCount]=useState(0)
    const [currentDiffNumber,setCurrentDiffNumber]=useState(0)

    const diffRowDisplay= useCallback(() => {
            return allRows.map((eachRow,index)=>{
                const {rowKey,value,isNestedJson,parentKey,level,keyMetaData,change}=eachRow
                switch (change){
                    case UNCHANGED:
                        return <UnChangedRowDisplay
                            key={parentKey.join(':')+change+index}
                            rowKey={rowKey}
                            value={value}
                            isNestedJson={isNestedJson}
                            level={level}
                            keyMetaData={keyMetaData}
                            currentRowKey={change+index}
                        />
                    case CHANGED:
                        return <ChangedRowDisplay
                            key={parentKey.join(':')+change+index}
                            rowKey={rowKey}
                            value={value}
                            isNestedJson={isNestedJson}
                            level={level}
                            keyMetaData={keyMetaData}
                            currentRowKey={change+index}
                            uniqueRowKey={uniqueRowKey}
                        />
                    case ADDED:
                        return <AddedRowDisplay
                            key={parentKey.join(':')+change+index}
                            rowKey={rowKey}
                            value={value}
                            isNestedJson={isNestedJson}
                            level={level}
                            isCurrentVersion={true}
                            keyMetaData={keyMetaData}
                            currentRowKey={change+index}
                            uniqueRowKey={uniqueRowKey}
                        />
                    case DELETED:
                        return <DeletedRowDisplay
                            key={parentKey.join(':')+change+index}
                            rowKey={rowKey}
                            value={value}
                            isNestedJson={isNestedJson}
                            level={level}
                            isCurrentVersion={true}
                            keyMetaData={keyMetaData}
                            currentRowKey={change+index}
                            uniqueRowKey={uniqueRowKey}
                        />
                }
            })
        }, [allRows,uniqueRowKey]);

    useEffect(() => {
        let updatedUniqueKeys=[]
        allRows.forEach((eachRow,index)=>{
            if(eachRow.change!==UNCHANGED){
                updatedUniqueKeys.push(eachRow.change+index)
            }
        })
        setTotalDiffCount(updatedUniqueKeys.length)
        setUniqueKeysList(updatedUniqueKeys)
    }, [allRows]);

    const handleNextDiffRow=()=>{
        setCurrentDiffNumber((prev)=> (prev+1)%(totalDiffCount+1))
    }

    const handlePrevDiffRow=()=>{
        setCurrentDiffNumber((prev)=> {
           if(prev===0) return totalDiffCount
            else return prev-1
        })
    }

    useEffect(()=>{
        if(currentDiffNumber===0) setUniqueRowKey('')
        else setUniqueRowKey(uniqueKeysList[currentDiffNumber-1])
    },[currentDiffNumber])

    useEffect(() => {
        if (uniqueRowKey) {
            const container1 = document.getElementById('scrollContainer');
            const container2 = document.getElementById('scrollContainer2');
            let noOfRow=currentDiffRowFromTop(uniqueRowKey)
            let pixelsToMove=(noOfRow-2)*50;
            if(container1) {
                container1.scrollLeft = pixelsToMove;
                container1.scrollTop = pixelsToMove;
            }
            if(container2){
                container2.scrollLeft = pixelsToMove;
                container2.scrollTop = pixelsToMove;
            }
        }
    }, [uniqueRowKey])

    const container1 = document.getElementById('scrollContainer');
    const container2 = document.getElementById('scrollContainer2');

    if(container1 && container2){
        container1?.addEventListener('scroll', (e) => {
            container2.scrollLeft = container1.scrollLeft;
            container2.scrollTop = container1.scrollTop;
        });

        container2?.addEventListener('scroll', (e) => {
            container1.scrollLeft = container2.scrollLeft;
            container1.scrollTop = container2.scrollTop;
        });
    }


    return(
        <Grid container item xs  direction='column' className={styles.differences__display__container} sx={{border: versionInfo?.status?diffBorderColor[versionInfo.status]:'1px solid #E6E6E6'}}>
            <Stack direction='row'>
                <Box className={styles.left__display__version__status__container} >
                    <div  className={styles.box_header}>Version</div>
                    {versionInfo  &&<div>{versionInfo.date&&versionInfo.date} &nbsp;{checkVersionStatus(versionInfo.status)&&<span style={checkVersionStatus(versionInfo?.status) && statusColor[versionInfo.status]}>{displayReviewStatus(versionInfo.status)}</span>}</div>}
                </Box>
                <Box className={styles.differences_info_box} >
                    <Grid container   direction='row'>
                        <Grid item xs className={styles.no__of__differences__container}  >
                            <div className={styles.box_header}>View Differences</div>
                            <Grid container >
                                <Grid xs item >
                                    <div style={{ textOverflow: "ellipsis", whiteSpace: "nowrap",overflow: "hidden",width:'120px'}}>{currentDiffNumber}/{totalDiffCount} Differences</div>
                                </Grid>
                                <Grid xs={'auto'} item>
                                    <IconButton className={styles.arrow_icon_button} onClick={handleNextDiffRow}>
                                        <KeyboardArrowDownOutlined className={styles.arrow_icon}/>
                                    </IconButton>
                                    <IconButton className={styles.arrow_icon_button} onClick={handlePrevDiffRow}>
                                        <KeyboardArrowUpOutlined className={styles.arrow_icon} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider orientation="vertical"  className={styles.dividerStyle} />
                        <Grid xs={'auto'} container item direction='column' className={styles.colors__info__container}  >
                            <Grid container   alignItems={'center'} direction={'row'} pt={'0'}>
                                <Grid item xs={'auto'} ><Box className={styles.circle_style} bgcolor={'#EBFAEB'} ></Box></Grid>
                                <Grid item xs>&ensp;Added</Grid>
                            </Grid>
                            <Grid container alignItems={'center'} direction={'row'}>
                                <Grid item xs={'auto'} ><Box className={styles.circle_style} border={'0.5px solid #E60000'} bgcolor={'#FFE6E6'}></Box></Grid>
                                <Grid item xs >&ensp;Deleted</Grid>
                            </Grid>
                            <Grid container alignItems={'center'} direction={'row'}>
                                <Grid item xs={'auto'} ><Box className={styles.circle_style} border={'0.5px solid #9A6632'} bgcolor={'#F9F2EC'}></Box></Grid>
                                <Grid item xs >&ensp;Changed</Grid>
                            </Grid>
                        </Grid>
                        <Divider orientation="vertical"  className={styles.dividerStyle} />
                        <Grid xs={'auto'} container item direction='column' className={styles.colors__info__container}>
                            <Grid container  alignItems={'center'} direction={'row'} pt={'0'}>
                                <Grid item xs={'auto'}><Box className={styles.circle_style} border={'0.5px solid #000'} bgcolor={'#000'}></Box></Grid>
                                <Grid item xs>&ensp;L</Grid>
                            </Grid>
                            <Grid container alignItems={'center'} direction={'row'}>
                                <Grid item xs={'auto'} ><Box className={styles.circle_style} border={'0.5px solid #9F492D'} bgcolor={'#9F492D'}></Box></Grid>
                                <Grid item xs >&ensp;O</Grid>
                            </Grid>
                            <Grid container alignItems={'center'} direction={'row'}>
                                <Grid item xs={'auto'} ><Box className={styles.circle_style} border={'0.5px solid #4700CC'} bgcolor={'#4700CC'}></Box></Grid>
                                <Grid item xs ml={'2px'}>&ensp;I</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Stack>

            <Divider orientation="horizontal"   sx={{bgcolor:'#E6E6E6',width:'648px',height:'1px',mt:'7px'}}/>
            <div id={'scrollContainer'} className={styles.differences__rows__container}>
                {diffRowDisplay()}
            </div>
        </Grid>
    )
}
export default DifferencesLeftDisplay;