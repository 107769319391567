/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const fetchReviews = /* GraphQL */ `
  query FetchReviews($input: FetchReviewsRequest!) {
    fetchReviews(input: $input) {
      reviews {
        reviewId
        reviewStatus
        reviewJson
        system
        timestamp
        requesterId
        resourceIdentifier
        reviewers
        reviewDescription
        reviewedTimestamp
        __typename
      }
      __typename
    }
  }
`;
export const fetchReviewsByUser = /* GraphQL */ `
  query FetchReviewsByUser($input: FetchReviewsByUserRequest!) {
    fetchReviewsByUser(input: $input) {
      reviewsRequestedByUser {
        reviewId
        reviewStatus
        reviewJson
        system
        timestamp
        requesterId
        resourceIdentifier
        reviewers
        reviewDescription
        __typename
      }
      reviewsToBeReviewedByUser {
        reviewId
        reviewStatus
        reviewJson
        system
        timestamp
        requesterId
        resourceIdentifier
        reviewers
        reviewDescription
        __typename
      }
      __typename
    }
  }
`;
export const fetchAccessibleReviews = /* GraphQL */ `
  query FetchAccessibleReviews($input: FetchReviewsRequest!) {
    fetchAccessibleReviews(input: $input) {
      reviews {
        reviewId
        reviewStatus
        reviewJson
        system
        timestamp
        requesterId
        resourceIdentifier
        reviewers
        reviewDescription
        reviewedTimestamp
        __typename
      }
      __typename
    }
  }
`;
export const getConfiguration = /* GraphQL */ `
  query GetConfiguration($input: GetConfigurationRequest!) {
    getConfiguration(input: $input) {
      configs
      metadata
      user
      __typename
    }
  }
`;


export const checkUserPermission = /* GraphQL */ `
  query CheckUserPermission($input: CheckSystemPermissionInput!) {
    checkSystemPermission(input: $input) {
      hasAccess
      __typename
    }
  }
`;

export const listBindleByOwner = /* GraphQL */ `
    query ListBindleByOwner($input: ListBindleByOwnerRequest!) {
        listBindleByOwner(input: $input) {
            listOfBindles {
                bindleID
                bindleName
            }
            __typename
        }
    }
`;

export const fetchReviewByReviewId = /* GraphQL */ `
  query fetchReviewByReviewId($input: FetchReviewByIdRequest!) {
    fetchReviewById(input: $input) {
      review {
        reviewId
        reviewStatus
        reviewJson
        system
        timestamp
        requesterId
        resourceIdentifier
        reviewers
        reviewDescription
        reviewedTimestamp
        __typename
    }
      __typename
    }
    }
`;

export const getListOfConfigurations = /* GraphQL */ `
  query GetListOfConfigurations($input: GetListOfConfigsRequest!) {
    getListOfConfigurations(input: $input) {
      configs {
        configName
        configType
        parentConfig
        __typename
      }
      __typename
    }
  }
`;
export const getListOfTenants = /* GraphQL */ `
  query GetListOfTenants {
    getListOfTenants {
      tenants
      __typename
    }
  }
`;

export const getListOfAccessibleTenants = /* GraphQL */ `
  query GetListOfAccessibleTenants ($input: GetListOfAccessibleTenantsRequest!) {
    getListOfAccessibleTenants (input: $input){
      tenants {
        operation
        tenantName
      }
      __typename
    }
  }
`;


export const retrieveConfigurations = /* GraphQL */ `
  query RetrieveConfigurations(
    $tenant: String!
    $configList: [RetrieveConfigListInput]
    $inheritFlag: Boolean
  ) {
    retrieveConfigurations(
      tenant: $tenant
      configList: $configList
      inheritFlag: $inheritFlag
    ) {
      configsMap {
        configName
        parentConfig
        configType
        configParams
        versionDescription
        metadata
        user
        __typename
      }
      __typename
    }
  }
`;

export const getConfigType = /* GraphQL */ `
  query GetListOfConfigurations($input: GetListOfConfigsRequest!) {
    getListOfConfigurations(input: $input) {
      configs {
        configType
        __typename
      }
      __typename
    }
  }
`;
