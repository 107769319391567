import React, {useEffect,useState} from 'react';
import {Grid, Tooltip} from "@mui/material";
import  '../../styles/cardStyles.css'
import CardTitle from "./CardTitle";
import CardData from "./CardData";
import CardActionPopUp from "./CardActionPopUp";
import {CONFIG_CARD, DATA_CARD, REVIEW_CARD} from "../constants/HomePageConstants";


const ConfigurationCard =(props)=>{
    const {cardConfiguration,cardTenant,cardFunctionality,cardData,handleDifferencePage,handleReviewDifferences}=props
    const [dataCount,setDataCount]=useState([])

    useEffect(()=>{
        if(cardFunctionality===DATA_CARD) {
            setDataCount([{'versions': parseInt(cardData?.slice(1, cardData.length))}])
        }
        else if(cardFunctionality===REVIEW_CARD){
            setDataCount([{'Submitter':cardData.Submitter},{'Submission':cardData.Submission}])
        }
    },[])

    const handleConfigSelected=()=>{
        if(cardFunctionality===REVIEW_CARD){
            handleReviewDifferences(cardData,cardConfiguration)
        }
    }

    return (
        <>
            <div className='card_wrapper'  >
                <Tooltip title={cardConfiguration?.length>25&&cardConfiguration} placement='top' arrow>
                <Grid container className='card_container' onClick={handleConfigSelected} sx={{width:'240px',height:'140px',pt:'8px',marginY:'8px',border: '1px solid var(--gftprimary-border-outlined-light, #CCE4FF)',borderRadius: '4px'}}>
                    <CardTitle title={cardConfiguration} cardData={cardData} cardFunctionality={cardFunctionality}/>
                    <CardData cardData={dataCount}/>
                    <CardActionPopUp cardTenant={cardTenant} cardConfiguration={cardConfiguration} cardType={CONFIG_CARD} cardFunctionality={cardFunctionality} handleDifferencePage={handleDifferencePage}/>
                </Grid>
                </Tooltip>
            </div>
        </>
    )
}
export default ConfigurationCard;