import React, {useEffect, useState}from 'react';
import {
    InputAdornment,
    IconButton,
    Grid,
    MenuItem,
    Typography,
    TextField, Snackbar, Stack, Alert
    , Button, Paper, Box, Autocomplete
} from "@mui/material";
import {
    HomeOutlined,
    CancelOutlined,
    DifferenceOutlined,
    FileUploadOutlined,
    CheckCircleOutline,
    ArrowDropDown,
    SaveOutlined
} from "@mui/icons-material";
import PipeIcon from "../assets/Pipe.svg";
import EditIcon from "../assets/EditBox.svg";
import EditIconLight from "../assets/EditBoxLight.svg";
import styles from "../styles/TenantPageStyles.module.css";
import CreateConfigModal from "../components/TenantPage/CreateConfigModal";
import UploadConfigModal from "../components/TenantPage/UploadConfigModal";
import TenantTable from "../components/TenantPage/TenantPageTables/TenantTable/TenantTable";
import ConfigTable from "../components/TenantPage/TenantPageTables/ConfigTable/ConfigTable";
import ApprovalHistory from "../components/Approvals/ApprovalHistory";
import SideBar from "../components/app-layout/SideBar";
import {GLOBAL_CONFIGS, PipStyle} from "../components/constants";
import {
    getConfigurations,
    createNewConfiguration,
    updateConfiguration,
    fetchReviewById,
    getConfigurationsList
} from "../api/services";
import Undo from "../assets/undo-button.png";
import Redo from "../assets/redo-button.png";
import SubmitModal from "../components/TenantPage/SubmitModal";
import {SYSTEM_NAME} from "../components/constants/ApiConstants";
import {CREATE_VERSION_STATUS} from "../components/constants/ApiConstants";
import {SUBMIT_CONFIG} from "../components/constants/ApprovalStatusConstants";

const TenantPage=(props)=>{
    const {onTenantClosed, tenant, allTenantsData,
         user,openDifferencePage,setIsDifferencePageSelected,setOpenTenant,setDifferencesPageType,
        setPreviewCurrentVersion,setPreviewEditedVersion,setSelectedConfiguration,setSelectedTenant
        ,selectedConfiguration,setPendingVersionJsonBody
       ,createVersionStatus,openConfigModal,setOpenConfigModal,openUploadModal,setOpenUploadModal,pendingReviews,openSelectedReview,setUpdateConfigResponse,
        setResponseType,setCreateVersionStatus, isEditTenant, setIsEditableTenant, tenantConfigMap, setIsTenantConfigMap,
        isEditConfig, setIsEditableConfig, configConfigMap, setIsConfigConfigMap} = props;

    const [open, setOpen] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const [openParentDropdown, setOpenParentDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [tenants, setTenants] = useState(null);
    const [currentTenant, setCurrentTenant] = useState(tenant);
    const [selectedVersion, setSelectedVersion] = useState(null);
    const [isChild, setIsChild] = useState(false);
    const [parentToDisplay, setParentToDisplay] = useState(null);
    const [currentConfig, setCurrentConfig] = useState(selectedConfiguration);
    const [configsMap, setConfigsMap] = useState(null);
    const [openSubmitModal, setOpenSubmitModal] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [message, setMessage] = useState("");
    const [selectedParentConfig, setSelectedParentConfig] = useState("");
    const [originalParentConfig, setOriginalParentConfig] = useState("");
    const [editParentConfig, setEditParentConfig] = useState(false)
    const [prevParentConfig, setPrevParentConfig] = useState(null);
    const [versions, setVersions] = useState(null);
    const [latestConfigVersion, setLatestConfigVersion] = useState(null)
    const [oldConfigVersion, setOldConfigVersion] = useState(null)
    const [isConfigInvalid, setIsConfigInvalid] = useState(true)
    const [createConfigResponse, setCreateConfigResponse] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMessage, setAlertMessage] = useState("")
    const [totalVersions,setTotalVersions]=useState(1)
    const [localConfigs,setLocalConfigs]=useState({});
    const [config_list, setConfig_List] = useState(null);

    const config = (currentConfig && configsMap !== null && configsMap !== undefined) ? configsMap.filter(config => config.configName === currentConfig)[0] : null;

    const [currentPipStyle,setCurrentPipStyle]=useState(PipStyle.Dev);

    const handleParentRedo = () => {
        setSelectedParentConfig(prevParentConfig);
        setPrevParentConfig(null);
    }

    const handleParentUndo = () => {
        setPrevParentConfig(selectedParentConfig);
        setSelectedParentConfig(originalParentConfig);
    }

    const handleParentCancel = () => {
        handleParentUndo();
        setPrevParentConfig(null);
        onEditParentConfig(!editParentConfig);
    }

    const handleOpenSubmitModal = (value) => {
        setOpenSubmitModal(value)
    }

    const getLocalConfigs = (keySources, configs) => {
        let localConfigsJson = Object.keys(configs).reduce((tempMap, key) => {
            if (keySources[key] === "LOCAL" || keySources[key] === "LOCAL_OVERRIDE") {
                tempMap[key] = configs[key];
            } else if (keySources[key] ){

            }
            return tempMap;
        }, {});
    }

    const onSubmitParent = () => {
        let submitMap = localConfigs;
        if (!localConfigs) {
            alert("Error: empty configs")
        }
        const configParams = JSON.stringify(submitMap);

        const jsonBody = {
            "configName" : `${currentConfig}`,
            "configType" : `${config.configType}`,
            "parentConfig" : `${selectedParentConfig}`,
            "tenantName" : `${currentTenant}`,
            "system" : `${SYSTEM_NAME}`,
            "configsJson": `${configParams}`,
            "versionDescription": `Update Config Parent`,
            "user": `${user}`,
            "version":totalVersions
        }
        updateConfig(currentTenant, jsonBody).then(r => setResponseType(SUBMIT_CONFIG));
        setOpenSubmitModal(false);
        handleParentCancel();
    }

    const handleClose = () => {
        setOpenConfigModal(false)
    };

    const handleUploadOpen = () => {
        setOpenUploadModal(true);
    }

    const handleUploadClose = () => {
        setOpenUploadModal(false);
    };

    const handleToastClose=()=> {
        setShowAlert(false);
        setAlertSeverity("success");
        setAlertMessage("");
        setCreateConfigResponse(null);
        setUpdateConfigResponse(null);
    };

    const handleTenantChange = (e) => {
        setSelectedConfiguration(null);
        if (e !== "" && e !== null){
            setCurrentTenant(e);
            setSelectedTenant(e);
        }
    };

    useEffect(()=>{
        setSelectedVersion(null);
        setParentToDisplay(null);
        if(selectedConfiguration){
            setCurrentConfig(selectedConfiguration);
            setSelectedConfiguration(selectedConfiguration)
        }
        else {
            setCurrentConfig(null)
            setSelectedConfiguration(null)
        }
        setConfigsMap(null);
        setVersions(null);
        if (currentTenant !== null) {
            fetchConfigsData(currentTenant,'currentTenant');
        }
    },[currentTenant]);

    useEffect(   () => {
        if (createConfigResponse){
            if (createConfigResponse.status==="Success"){
                setAlertSeverity("success");
                setAlertMessage("Configuration created Successfully");
                if (currentTenant !== null) {
                    fetchConfigsData(currentTenant,'createConfigResponse');
                }
                setCurrentConfig(selectedConfiguration);
            } else if (createConfigResponse.reviewStatus ==="SUCCESS") {
                setAlertSeverity("success");
                setAlertMessage("Review Submission Successful");
            } else {
                setAlertSeverity("error");
                setAlertMessage("Creating configuration failed");
            }
            setShowAlert(true);
        }
    }, [createConfigResponse]);

    // useEffect(   () => {
    //     console.log('updateConfigResponse')
    //     if (updateConfigResponse){
    //         if (updateConfigResponse.status==="Success"){
    //             setAlertSeverity("success");
    //             setAlertMessage("Successfully updated config");
    //             if (currentTenant !== null) {
    //                 fetchConfigsData(currentTenant,'updateConfigResponse');
    //             }
    //         } else if (updateConfigResponse.reviewStatus==="SUCCESS") {
    //             setAlertSeverity("warning");
    //             setAlertMessage("Review2 created");
    //         }
    //         else {
    //             setAlertSeverity("error");
    //             setAlertMessage("Updating config failed");
    //         }
    //         setShowAlert(true);
    //     }
    // }, [updateConfigResponse]);

    async function createConfig(tenant, variables) {
        let input = {
            input: variables
        }
        await createNewConfiguration(tenant, input, setCreateConfigResponse);
    }

    async function updateConfig(tenant, variables) {
        let input = {
            input: variables
        }
        await updateConfiguration(tenant, input, setCreateVersionStatus);
    }

    useEffect(() => {
        setTenants(Object.keys(allTenantsData).sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        }));
    }, [allTenantsData]);

    useEffect(() => {
        if(selectedVersion === 'Current'){
            setOldConfigVersion(null);
        }
        if(config !== null && config !== undefined) {
            calculateVersions(config)
            if (config.configType === 'child'){
                setIsChild(true);
            } else {
                setIsChild(false);
            }

            setSelectedParentConfig(config.parentConfig)
            const newParent = (oldConfigVersion && oldConfigVersion[0])?oldConfigVersion[0].parentConfig: selectedParentConfig;
            setParentToDisplay(newParent)
        } else if (configsMap !== null) {
            calculateVersions(configsMap.filter(config => config.configName === 'global_configs')[0]);
        }
    }, [currentConfig, configsMap]);

    //Fetches old config version from appsync
    useEffect(() => {

        async function fetchOldVersion(configName) {
            const versionToFetch = selectedVersion.slice(1)
            let variables = {
                tenant: `${currentTenant}`,
                inheritFlag: true,
                configList: {configName: `${configName}`, version: `${versionToFetch}`}
            }
            await getConfigurations(setOldConfigVersion,variables);
        }

        if(selectedVersion && selectedVersion !== 'Current'){
            fetchOldVersion(currentConfig ? currentConfig : 'global_configs');
        }
        else{
            setOldConfigVersion(null);
        }
    }, [selectedVersion]);

    useEffect(() => {
        fetchConfigsData(currentTenant)
    }, [createVersionStatus]);



    async function fetchConfigsData(tenant,from) {
        let configurationsList = await fetchConfigurationsList(tenant)
        const configListToSubmit = [{configName: "global_configs"}]
        if (selectedConfiguration) {
            configListToSubmit.push({configName: selectedConfiguration})
            const configParent = configurationsList.filter(config => config.configName === selectedConfiguration)[0].parentConfig;
            if (configParent) {
                configListToSubmit.push({configName: configParent})
            }
        }

        let variables = {
            tenant: `${tenant}`,
            inheritFlag: true,
            configList: configListToSubmit
        }
        setLoading(true);
        await getConfigurations(setConfigsMap,variables);
        setLoading(false);
    }

    async function fetchConfigurationsList(tenant) {
        let variables = {
            tenant:tenant
        }
        let input = {
            input: variables
        }
        let configurationsList = await getConfigurationsList(setConfig_List,input);
        return configurationsList;
    }

    useEffect(() => {
        if(versions != null) {
            onVersionSelected(versions[0]);
        }
    }, [versions]);

    useEffect(() => {
        const newParent = (oldConfigVersion && oldConfigVersion[0])?oldConfigVersion[0].parentConfig: selectedParentConfig;
        setParentToDisplay(newParent);
    }, [oldConfigVersion]);

    useEffect(() => {
        setParentToDisplay(selectedParentConfig);
    }, [selectedParentConfig]);


    const configList = () =>{
        const list = [];
        if (config_list!=null) {
            config_list.map(config => config.configName).filter(configType => configType !== "global_configs").forEach((config, i) => {
                list.push(<MenuItem
                    key={i}
                    value={config}
                >{config}</MenuItem>);
            });
        }

        return list;
    };

    const configParentList = () =>{
        const list = [];
        config_list.forEach((config, i) => {
            if (config.configType === "parent") {
                list.push(<MenuItem
                    key={i}
                    value={config.configName}
                >{config.configName}</MenuItem>);
            }
        });
        return list;
    };

    const onVersionSelected = (version) => {
        setSelectedVersion(version);
    };

    const onEditParentConfig = (value) => {
        setEditParentConfig(value)
        if (value) {
            setOriginalParentConfig(selectedParentConfig);
        } else {
            setOriginalParentConfig("");
        }
    }

    const handleEdit = (category) => {
        if(category == 'parent') {
            onEditParentConfig(!editParentConfig)
        }
    }

    const onSetOldConfigVersion = (value) => {
        setOldConfigVersion(value)
    }

    const handleVersionChange = (e) => {
        setSelectedVersion(e.target.value);
        setPrevParentConfig(null);
        setEditParentConfig(false);
    }

    const handleConfigChange = (value) => {
        if (value.target.value === "None") {
            setCurrentConfig(null);
            setSelectedConfiguration(null)
            let variables = {
                tenant: `${tenant}`,
                inheritFlag: true,
                configList: [{configName: "global_configs"}]
            }
            setLoading(true);
            const a = getConfigurations(setConfigsMap,variables);
            setLoading(false);
        } else {
            setCurrentConfig(value.target.value);
            setSelectedConfiguration(value.target.value)
            let configListToSubmit = [{configName: value.target.value}, {configName: "global_configs"}];
            const configParent = config_list.filter(config => config.configName === value.target.value)[0].parentConfig;
            if (configParent) {
                configListToSubmit.push({configName: configParent});
            }
            let variables = {
                tenant: `${tenant}`,
                inheritFlag: true,
                configList: configListToSubmit
            }
            setLoading(true);
            const a = getConfigurations(setConfigsMap,variables);
            setLoading(false);
            const currentConfig = configsMap.filter(config => config.configName === value.target.value)[0];
            const parent = ( currentConfig && currentConfig.parentConfig )?currentConfig.parentConfig: '';
        }

        onVersionSelected('Current');
        setPrevParentConfig(null)
        setEditParentConfig(false)
    };

    const handleParentChange= (e) => {
        setSelectedParentConfig(e.target.value);
        if (selectedParentConfig === originalParentConfig) {
            setPrevParentConfig(null);
        }
    }

    const calculateVersions = (config) => {
        const currentVersion = (config && config["versionDescription"] != null) ?
            parseInt(config.versionDescription.slice(1, config.versionDescription.length)) + 1 : null
        try {
            let versionsArray = Array(currentVersion)
            setLatestConfigVersion(config["versionDescription"] != null ? config["versionDescription"] : null)
            for (let version = 0; version < currentVersion; version++) {
                if (version === 0) versionsArray[0] = "Current"
                else versionsArray[version] = `V${version}`
            }
            setTotalVersions(currentVersion-1)
            setVersions(versionsArray)
            setIsConfigInvalid(false)
        }
        catch(e){
            setIsConfigInvalid(true)
        }
    }

    return(
        <Grid container direction='row'
              className={styles.tenant__page}>
            <SideBar
                onTenantClosed={onTenantClosed}
                openDifferencePage={openDifferencePage}
                handleUploadOpen={handleUploadOpen}
                editMode={editMode}
                selectedTenant={currentTenant}
            />
            <Grid container direction='row' className={styles.main__container}>
                <Grid container direction='column' className={styles.main__container_left}>
                    <Grid container className={styles.top__card}>
                        <Grid item className={styles.form__field_1}>
                            <Grid container spacing={0}>
                                <Typography className={styles.label__text}>
                                    Tenant
                                </Typography>
                                {/*Piplines are goint to implement in next phase*/}
                                {/*<Typography className={styles.pip} sx={{bgcolor:currentPipStyle.color}}>*/}
                                {/*    {currentPipStyle.text}*/}
                                {/*</Typography>*/}
                            </Grid>
                            <Autocomplete
                                className={styles.version__dropdown}
                                size="small"
                                disabled={editMode}
                                value={currentTenant !== null && currentTenant !== undefined ? currentTenant : ''}
                                onChange={(event, newValue) => {
                                    handleTenantChange(newValue)
                                }}
                                options={tenants ? tenants : []}
                                freeSolo
                                forcePopupIcon={true}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params}/>
                                )}
                                sx={{
                                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        border: "0"
                                    },"& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                    }
                                }}
                            >
                            </Autocomplete>
                        </Grid>
                        <Grid item className={styles.form__field_2}>
                            <Grid container sx={{height: '25px'}}>
                                <Typography className={styles.label__text}>
                                    { currentConfig ? "Version (Configuration)" : "Version (Global)"}
                                </Typography>
                            </Grid>
                            <TextField
                                size="small"
                                disabled={editMode || isConfigInvalid}
                                select
                                className={styles.version__dropdown}
                                SelectProps={{
                                    MenuProps: {
                                        sx: { height: "600px" },
                                    },
                                }}
                                sx={{
                                    "& fieldset": { border: 'none' },
                                    "& .MuiFormControl-root": {
                                        marginTop: '0',
                                    },"& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "#000000",
                                    }
                                }}
                                label={selectedVersion === null ? "Current": ''}
                                InputLabelProps={{shrink: false}}
                                value={selectedVersion ? selectedVersion : ''}
                                onChange={handleVersionChange}
                            >
                                {
                                    versions != null && versions ? versions.map( (version, i) => {
                                        return <MenuItem key={i} value={version}>{version}</MenuItem>
                                    }) : <MenuItem value={''}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                        {
                            currentConfig && currentConfig !== "global_configs" ?
                                <Grid container>
                                    <Grid item className={styles.form__field_3_expanded}>
                                        <Grid container sx={{height: '25px'}}>
                                            <Typography className={styles.label__text}>
                                                Configuration
                                            </Typography>
                                        </Grid>
                                        <TextField size="small"
                                                   disabled={editMode}
                                                   select
                                                   value={currentConfig ? currentConfig : ''}
                                                   onChange={handleConfigChange}
                                                   className={styles.config__dropdown}
                                                   SelectProps={{
                                                       MenuProps: {
                                                           sx: { height: "600px" },
                                                       },
                                                   }}
                                                   sx={{
                                                       "& fieldset": { border: 'none' },
                                                       "& .MuiFormControl-root": {
                                                           marginTop: '0',
                                                       },"& .MuiInputBase-input.Mui-disabled": {
                                                           WebkitTextFillColor: "#000000",
                                                       }
                                                   }}>
                                            <MenuItem className={styles.none__menuitem} value={"None"}>None</MenuItem>
                                            {config_list !== undefined && config_list !== null ? configList() : <MenuItem value={currentConfig ? currentConfig : ''}></MenuItem>}
                                        </TextField>
                                    </Grid>
                                    <Grid item className={styles.form__field_4_expanded}>
                                        <Grid container sx={{height: '25px'}}>
                                            <Typography className={styles.label__text}>
                                                Parent
                                            </Typography>
                                        </Grid>
                                        {!editParentConfig ?
                                            <Grid container direction="row">
                                                <Grid item xs={11}>
                                                    <TextField
                                                        size="small"
                                                        className={styles.parent__textfield}
                                                        fullWidth
                                                        disabled
                                                        value={parentToDisplay ?? ""}
                                                        label={!parentToDisplay ? 'None' : ''}
                                                        InputLabelProps={{shrink: false}}
                                                        sx={{
                                                            "& fieldset": {border: 'none'},
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: "#000000",
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    { isChild && selectedVersion === "Current" ?
                                                        <IconButton disabled={editMode} onClick={()=> onEditParentConfig(true)}>
                                                            {editMode ?
                                                                <img src={EditIconLight}/>
                                                            :
                                                                <img src={EditIcon}/>
                                                            }

                                                        </IconButton> : null
                                                    }

                                                </Grid>
                                            </Grid> :
                                            <Grid container direction="row">
                                                <Grid item xs={10} >
                                                    <TextField
                                                        size="small"
                                                        select
                                                        open={openParentDropdown}
                                                        value={selectedParentConfig ? selectedParentConfig : ""}
                                                        onChange = {(e) => handleParentChange(e)}
                                                        className={styles.config__dropdown}
                                                        SelectProps={{
                                                            MenuProps: {
                                                                sx: { height: "600px" },
                                                            },
                                                        }}
                                                        sx={{
                                                            "& fieldset": { border: 'none' },
                                                            "& .MuiFormControl-root": {
                                                                marginTop: '0',
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem className={styles.none__menuitem} value={""}>None</MenuItem>
                                                        {configsMap !== undefined && configsMap !== null ? configParentList() : <MenuItem value={currentConfig ? currentConfig : ''}></MenuItem>}
                                                    </TextField>
                                                </Grid>
                                                <Grid container item xs={2}>
                                                    <Grid item xs={3}>
                                                        {config !== null && config.parentConfig !== undefined && originalParentConfig !== selectedParentConfig ?
                                                            <IconButton onClick={()=>handleParentUndo()} className={styles.select__adornment}>
                                                                <img src={Undo} width={"13px"} height={"15px"}/>
                                                            </IconButton> : null
                                                        }
                                                        {config != null && prevParentConfig && prevParentConfig !== selectedParentConfig ?
                                                            <IconButton onClick={()=>handleParentRedo()} className={styles.select__adornment}>
                                                                <img src={Redo} width={"13px"} height={"15px"}/>
                                                            </IconButton> : null
                                                        }
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {originalParentConfig !== selectedParentConfig ?
                                                            <IconButton onClick={() => handleOpenSubmitModal(true)} className={styles.select__adornment}>
                                                                <SaveOutlined fontSize="small"></SaveOutlined>
                                                            </IconButton> : null
                                                        }
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <IconButton onClick={()=>handleParentCancel()} className={styles.select__adornment}>
                                                            <CancelOutlined fontSize="small"></CancelOutlined>
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                :
                                <Grid container>
                                    <Grid item className={styles.form__field_3}>
                                        <TextField
                                            size="small"
                                            disabled={editMode}
                                            fullWidth
                                            select
                                            label={"Configuration"}
                                            value={''}
                                            InputLabelProps={{shrink: false}}
                                            onChange={handleConfigChange}
                                            className={styles.config__dropdown_disabled}
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: { height: "600px" },
                                                },
                                            }}
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                                "& .MuiFormControl-root": {
                                                    marginTop: '0',
                                                },
                                            }}
                                        >
                                            {config_list !== undefined && config_list !== null ? configList() : <MenuItem value={currentConfig ? currentConfig : ''}></MenuItem>}
                                        </TextField>
                                    </Grid>
                                    <Grid item className={styles.form__field_4}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            disabled
                                            label={"Parent"}
                                            value={''}
                                            variant="outlined"
                                            className={styles.parent__disabled}
                                            sx={{"& fieldset": {border: 'none'}}}
                                        />
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
                    {
                        (!currentConfig || currentConfig === "global_configs") && configsMap ?
                            ( currentTenant != null?
                                <TenantTable
                                    tenantConfigMap = {tenantConfigMap}
                                    setIsTenantConfigMap = {setIsTenantConfigMap}
                                    isEditTenant = {isEditTenant}
                                    setIsEditableTenant = {setIsEditableTenant}
                                    configs={configsMap}
                                    updateConfig={updateConfig}
                                    selectedConfig={currentConfig}
                                    selectedTenant={currentTenant}
                                    createConfig={createConfig}
                                    selectedVersion={selectedVersion}
                                    oldConfigVersion = {oldConfigVersion}
                                    message={message}
                                    setEditMode={setEditMode}
                                    setMessage={setMessage}
                                    user={user}
                                    getAWSClient={props.getAWSClient}
                                    setOpenTenant={setOpenTenant}
                                    setIsDifferencePageSelected={setIsDifferencePageSelected}
                                    setDifferencesPageType={setDifferencesPageType}
                                    setPreviewCurrentVersion={setPreviewCurrentVersion}
                                    setPreviewEditedVersion={setPreviewEditedVersion}
                                    setPendingVersionJsonBody={setPendingVersionJsonBody}
                                    setOpenConfigModal={setOpenConfigModal}
                                    pendingReviews={pendingReviews}
                                    totalVersions={totalVersions}
                                /> : null):
                            <ConfigTable
                                isEditConfig ={isEditConfig}
                                setIsEditableConfig ={setIsEditableConfig}
                                configConfigMap ={configConfigMap}
                                setIsConfigConfigMap = {setIsConfigConfigMap}
                                message={message}
                                selectedVersion={selectedVersion}
                                configs={configsMap}
                                selectedConfig={currentConfig}
                                selectedTenant={currentTenant}
                                updateConfig={updateConfig}
                                setMessage={setMessage}
                                editParentConfig={editParentConfig}
                                onEditParentConfig={onEditParentConfig}
                                user={user}
                                latestConfigVersion = {latestConfigVersion}
                                oldConfigVersion = {oldConfigVersion}
                                setOldConfigVersion = {onSetOldConfigVersion}
                                isConfigInvalid={isConfigInvalid}
                                isLoading={loading}
                                getAWSClient={props.getAWSClient}
                                setOpenTenant={setOpenTenant}
                                setEditMode={setEditMode}
                                setIsDifferencePageSelected={setIsDifferencePageSelected}
                                setDifferencesPageType={setDifferencesPageType}
                                setPreviewCurrentVersion={setPreviewCurrentVersion}
                                setPreviewEditedVersion={setPreviewEditedVersion}
                                setPendingVersionJsonBody={setPendingVersionJsonBody}
                                pendingReviews={pendingReviews}
                                totalVersions={totalVersions}
                                setLocalConfigs={setLocalConfigs}
                            />
                    }
                </Grid>
                <Grid container className={styles.versions__container} >
                    <ApprovalHistory  currentTenant={currentTenant} currentConfig={currentConfig}
                                       createVersionStatus={createVersionStatus}
                                      openSelectedReview={openSelectedReview} versions={versions}
                                      user={user}
                    />
                </Grid>
            </Grid>

            <CreateConfigModal
                open={openConfigModal}
                handleClose={handleClose}
                createConfig={createConfig}
                tenantName={currentTenant}
                configsMap={config_list}
                configParentList={configParentList}
                setSelectedConfiguration={setSelectedConfiguration}
                user={user}/>
            <UploadConfigModal
                tenant={tenant}
                open={openUploadModal}
                handleClose={handleUploadClose}
                createConfig={createConfig}
                updateConfig={updateConfig}
                setResponseType={setResponseType}
                configsMap={config_list}
                configParentList={configParentList}
                setSelectedConfiguration={setSelectedConfiguration}
                setCurrentConfig={setCurrentConfig}
                tenantName={currentTenant}
                currentConfig={currentConfig}
                user={user}/>
            <SubmitModal open={openSubmitModal} handleClose ={() => handleOpenSubmitModal(false)} onSubmit = {onSubmitParent}/>
            <Snackbar
                open={showAlert}
                autoHideDuration={8000}
                onClose={handleToastClose}
            >
                <Stack direction={'row'}>
                    <Alert severity={alertSeverity} >{alertMessage}</Alert>
                </Stack>
            </Snackbar>
        </Grid>
    )
}
export default TenantPage;