import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import { Provider } from "react-redux";
import { ConfigureStore } from "./redux/configureStore";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Layout from "./components/app-layout/Layout";
import TestRoutingPage from "./screens/TestRoutingPage";
import {getAWSClient} from "./authentication";
// Redux store
const store = ConfigureStore();

/* 
    To build the bundle for Config Store uncommment the block of code below 
    and comment out the "Test Config Store Locally" block (including the root declaration)
*/

let root = ReactDOM.createRoot(
  document.getElementById("microfrontend-component-id")
);

export default {
  init: ({ username, getAWSClient }) => {
    root.render(
      <Provider store={store}>
        <Router basename="/config-store">
          <Routes>
            <Route exact path="/testing" element={<TestRoutingPage />} />
            <Route
              exact
              path="/"
              element={
                <Layout>
                  <App username={username} getAWSClient={getAWSClient} />
                </Layout>
              }
            />
          </Routes>
        </Router>
      </Provider>
    );
  },
};

/* 
    To test Config Store Locally, uncomment this block and give your username to username variable
    for and comment out the code above for bulding the bundle (including the root declaration)
*/

// const root = ReactDOM.createRoot(document.getElementById("root"));
// let username = "yourusername"; //Give Your username here
// root.render(
//   <Provider store={store}>
//     <Router basename="/config-store">
//       <Routes>
//         <Route exact path="/testing" element={<TestRoutingPage />} />
//         <Route
//           exact
//           path="/"
//           element={
//             <Layout>
//               <App username={username} getAWSClient={getAWSClient} />
//             </Layout>
//           }
//         />
//       </Routes>
//     </Router>
//   </Provider>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
