import React from 'react';
import {Grid} from "@mui/material";
import TenantsColumn from "../components/ConfigStoreHomePage/TenantsColumn";
import RecentlyViewed from "../components/ConfigStoreHomePage/RecentlyViewed";
import Favourites from "../components/ConfigStoreHomePage/Favourites";
import Reviews from "../components/ConfigStoreHomePage/Reviews";
import KeyFeatures from "../components/ConfigStoreHomePage/KeyFeatures";

const ConfigStoreHomePage=(props)=>{
    const {tenants,onTenantSelected,allTenantsData,createTenant,user,pendingReviews,handleReviewDifferences, setOpenConfigModal, setOpenUploadModal,openDifferencePage,tenantCardInfo}=props
    return (
        <Grid container justify-content='space-evenly' sx={{height: '93vh'}}
              alignItems='flex-start' pl='32px' pt='28px' gap='16px' width='1440px' spacing={1}>
            <Grid container item xs={2.05} >
                <TenantsColumn
                    user={user}
                    tenants={tenants}
                    getAWSClient={props.getAWSClient}
                    onTenantSelected={onTenantSelected}
                    allTenantsData={allTenantsData}
                    tenantCardInfo={tenantCardInfo}
                    createTenant={createTenant}
                    setOpenConfigModal={setOpenConfigModal}
                    setOpenUploadModal={setOpenUploadModal}
                    openDifferencePage={openDifferencePage}
                />
            </Grid>
            {/*Recently Viewed and Favourites are implementing in next phase*/}
            {/*<Grid container item xs={2.05}>*/}
            {/*    <RecentlyViewed*/}
            {/*        getTenantConfigs={getTenantConfigs}*/}
            {/*        getAWSClient={props.getAWSClient}*/}
            {/*        onTenantSelected={onTenantSelected}*/}
            {/*        allTenantsData={allTenantsData}*/}
            {/*        handleDifferencePage={handleDifferencePage}*/}
            {/*    />*/}
            {/*</Grid>*/}
            {/*<Grid container item xs={2.05}>*/}
            {/*    <Favourites*/}
            {/*        onTenantSelected={onTenantSelected}*/}
            {/*        getAWSClient={props.getAWSClient}*/}
            {/*        getTenantConfigs={getTenantConfigs}*/}
            {/*        allTenantsData={allTenantsData}*/}
            {/*        handleDifferencePage={handleDifferencePage}*/}
            {/*    />*/}
            {/*</Grid>*/}
            <Grid container mr={'510px'} item xs={2}>
                <Reviews
                    handleReviewDifferences={handleReviewDifferences}
                    pendingReviews={pendingReviews}
                />
            </Grid>
            <Grid container item ml='30px' direction='column' minWidth='305px' xs minHeight='90vh'
                  mr='30px'>
                <KeyFeatures/>
            </Grid>
        </Grid>
    )
}

export default ConfigStoreHomePage;