import React from 'react';
import {Typography, Modal, Box, Stack, Button} from "@mui/material";

function SubmitModal(props){

    return(
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-label='modal-submit-modal'
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styles.modal} >{
                props.isCancel?
                    <Typography aria-label='modal-submit-modal-cancel-title' id="modal-modal-title" variant="h6" component="h2" style={{height: "10%"}}>
                        Are you sure you want to cancel all changes ? ?
                    </Typography>
                    :
                    <Typography aria-label='modal-submit-modal-submit-title'  id="modal-modal-title" variant="h6" component="h2" style={{height: "10%"}}>
                        Are you sure you want to submit changes ?
                    </Typography>
            }
                <Stack direction="row" spacing={1} justifyContent="center" pt={1}>
                    <Button variant="text" style={styles.button} onClick={props.onSubmit}>Yes</Button>
                    <Button variant="text" style={{...styles.buttonSecond, ...styles.button}} onClick={props.handleClose} >No</Button>
                </Stack>
            </Box>
        </Modal>
    )
}

const styles = {
    button:{
        backgroundColor: '#E5E5E5',
        borderRadius: '20px',
        fontSize: '12px',
        color: '#000000',
        textAlign: 'center',
        lineHeight: '20px',
        textTransform: 'none',
        fontWeight: 'bold'
    },
    buttonSecond:{
        border: '1px solid black',
    },
    modal : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '1px solid #000',
        bgcolor : 'white',
        boxShadow: 24,
        p: 2
    }
}

export default SubmitModal;