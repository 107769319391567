import React, {useEffect, useState} from 'react';
import {
    Box,
    Grid,
    TextField,
    MenuItem,
    Stack,
    Button,
    Dialog,
    DialogTitle, FormControl, InputLabel, Select, DialogActions, DialogContent, Autocomplete, IconButton, Typography
} from "@mui/material";
import {SYSTEM_NAME} from "../constants/ApiConstants";
import styles from "../../styles/CreateConfigModalStyles.module.css";
import FormTextField from "../Shared/FormTextField";
import {AddOutlined, FileUploadOutlined} from "@mui/icons-material";
import {JsonEditor} from "jsoneditor-react";
import ace from "brace";
import SwitchRightRoundedIcon from "@mui/icons-material/SwitchRightRounded";
import SwitchLeftRoundedIcon from "@mui/icons-material/SwitchLeftRounded";
import SubmitModal from "./SubmitModal";
import {getConfigurations} from "../../api/services";
import {CREATE_CONFIG, SUBMIT_CONFIG} from "../constants/ApprovalStatusConstants";
import {isValidJSONObject} from "../common/SharedFunctions";

function UploadConfigModal(props){
    const {createConfig, updateConfig, setResponseType, configsMap, currentConfig, configParentList, setSelectedConfiguration, tenantName, user, open, handleClose, setCurrentConfig, tenant} = props;
    const [json, setJson] = useState({})
    const [configurationsMap, setConfigurationsMap] = useState(null);
    const [configType, setConfigType] = useState("");
    const [uploadType, setUploadType] = useState("");
    const [isText, setInputType] = useState(false);
    const [isTextEditorJson, setTextJsonValidation] = useState(true);
    const [configName, setConfigName] = useState("");
    const [configsList, setConfigsList] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [isNewConfig, setIsNewConfig] = useState(false);
    const [configNameExists, setConfigNameExists] = useState(false);
    const [parentConfig, setParentConfig] = useState("");
    const [versionDescription, setVersionDescription] = useState("");
    const [submitReady, setSubmitReady] = useState(false);
    const [openSubmit, setOpenSubmit] = useState(false);
    const [uploadReady, setUploadReady] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [rawData, setRawData] = useState("");
    const handleSubmitClose = () => setOpenSubmit(false);

    const handleNameChange = (data) => {
        setConfigName(data.replaceAll(' ', ''));
    }

    const handleConfigChange = (val) => {
        if (val === "" || val === null) {
            console.log("Do nothing")
        } else {
            if (val === "+ Create New Configuration") {
                setIsNewConfig(true);
            } else {
                let variables = {
                    tenant: `${tenant}`,
                    inheritFlag: false,
                    configList: [{configName: val}]
                }
                const value = getConfigurations(setConfigurationsMap,variables);
                setConfigName(val);
                if (configsMap) {
                    let selectedConfig = configsMap.filter((config) => config.configName === val)[0];
                    setVersionDescription("Updating Config from Upload");
                    setConfigType(selectedConfig.configType);
                    setParentConfig(selectedConfig.parentConfig);
                }
            }
        }
    }

    const handleTextEditorChange = (data) => {
        setRawData(data);
        if(isValidJSONObject(data)){
            setJson(JSON.parse(data))
            setTextJsonValidation(true);
        } else if (data === ""){
            setJson({});
            setTextJsonValidation(true);
        } else {
            setTextJsonValidation(false)
        }
    };

    const handleChangeType = (val) => {
        setConfigType(val);
    };

    const handleChangeUploadType = (val) => {
        setUploadType(val);
    }

    const handleSwitchInput = () => {
        setInputType(current => {return !current;});
    };

    useEffect(() => {
        if(!isText){
            if (JSON.stringify(json) !== "{}") {
                setRawData(JSON.stringify(json, null, 2));
            } else {
                setRawData("");
            }
            setTextJsonValidation(true);
        }
    }, [json]);

    const handleUpload = (e) => {
        setFileUploaded(false);
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            setRawData(e.target.result);
            try {
                let resultJson = JSON.parse(e.target.result);

                //If Incremental is selected, then append uploaded json to existing config json, only selecting LOCAL and OVERRIDE config values
                if (uploadType === "Incremental") {
                    let existingConfigs = configurationsMap.filter((config) => config.configName === configName)[0];
                    if (existingConfigs !== undefined) {
                        let existingConfigsJson = JSON.parse(existingConfigs.configParams);
                        resultJson = mergeObjects(existingConfigsJson, resultJson)
                        // resultJson = {...existingConfigsJson,...resultJson};
                        setRawData(JSON.stringify(resultJson));
                    }
                }
                setJson(resultJson);
                setTextJsonValidation(true);
                setInputType(false);
            } catch(e) {
                console.log(e)
                setTextJsonValidation(false);
                setInputType(true);
            }
            setFileUploaded(true);
        };
    }

    function mergeObjects(obj1, obj2) {
        for (const key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key]) &&
                    typeof obj1[key] === 'object' && !Array.isArray(obj1[key])) {
                    // Both values are objects, recursively merge them
                    obj1[key] = mergeObjects(obj1[key], obj2[key]);
                } else {
                    // Values are either primitives or arrays, merge them directly
                    obj1[key] = obj2[key];
                }
            }
        }
        return obj1;
    }

    const clearEverything = () => {
        setConfigurationsMap(null)
        setInputType(false);
        setJson({});
        setRawData("");
        setTextJsonValidation(true);
        setIsNewConfig(false);
        setConfigName("");
        setParentConfig("");
        setVersionDescription("");
        setConfigType("");
        setUploadType("");
        setConfigNameExists(false);
        setUploadReady(false);
        setFileUploaded(false);
        setSubmitReady(false);
        handleClose();
    };

    const onSubmit = () => {
        const jsonBody = {
            "configName" : `${configName}`,
            "configType" : `${configType}`,
            "parentConfig" : parentConfig ? `${parentConfig}`: "",
            "tenantName" : `${tenantName}`,
            "system" : `${SYSTEM_NAME}`,
            "configsJson": isText ? rawData : JSON.stringify(json),
            "versionDescription": `${versionDescription}`,
            "user": `${user}`,
        }
        if (isNewConfig) {
            jsonBody.version = 1;
            createConfig(tenantName, jsonBody).then(r => setResponseType(CREATE_CONFIG));
        } else {
            let existingConfigs = configurationsMap.filter((config) => config.configName === configName)[0];

            const currentVersion = (existingConfigs && existingConfigs["versionDescription"] != null) ?
                parseInt(existingConfigs.versionDescription.slice(1, existingConfigs.versionDescription.length)) + 1 : null
            jsonBody.version = currentVersion-1;
            updateConfig(tenantName, jsonBody).then(r => setResponseType(SUBMIT_CONFIG));
        }
        setCurrentConfig(configName)
        setSelectedConfiguration(configName);
        setOpenSubmit(false);
        clearEverything();
    }

    useEffect(() => {
        if(currentConfig) {
            let variables = {
                tenant: `${tenant}`,
                inheritFlag: false,
                configList: [{configName: currentConfig}]
            }
            const value = getConfigurations(setConfigurationsMap,variables);
            setConfigName(currentConfig);
            setIsNewConfig(false);
            if (configsMap) {
                let selectedConfig = configsMap.filter((config) => config.configName === currentConfig)[0];
                setVersionDescription("Updating Config from Upload");
                if (selectedConfig) {
                    setConfigType(selectedConfig.configType);
                    setParentConfig(selectedConfig.parentConfig);
                }
            }
        }
    }, [currentConfig])

    useEffect (() => {
        setConfigName("");
        setVersionDescription("");
        setConfigType("");
        setParentConfig("");
    }, [isNewConfig])

    useEffect(() => {
        if (configsList.includes(configName) || configName === "global_configs") {
            setConfigNameExists(true);
        } else {
            setConfigNameExists(false);
        }

        if (isNewConfig) {
            if (configName !== null && configName !== "" && versionDescription !== "" && configType !== "") {
                // Have to check again because setState won't update values until next render
                if (configsList.includes(configName) || configName === "global_configs") {
                    setUploadReady(false);
                } else {
                    setUploadReady(true);
                }
            } else {
                setUploadReady(false);
            }
        } else {
            if (configName !== null && configName !== "" && uploadType !== "") {
                setUploadReady(true);
            } else {
                setUploadReady(false)
            }
        }
    }, [configName, configType, uploadType, versionDescription, parentConfig])

    useEffect(() => {
        let configsJson = isText ? rawData : JSON.stringify(json);
        if (configsJson && configsJson !== "{}") {
            if (configName != null && configName !== "" && configType !== "" && versionDescription !== "" && isTextEditorJson) {
                setSubmitReady(true);
            } else {
                setSubmitReady(false);
            }
        } else {
            setSubmitReady(false);
        }
    }, [json, rawData, configName, configType, uploadType, versionDescription, parentConfig])

    useEffect(() => {
        if (configType !== "child") {
            setParentConfig("")
        }
    }, [configType])

    useEffect(() => {
        let list = [];
        list.push("+ Create New Configuration");
        if (configsMap !== undefined && configsMap !== null){
            configsMap.map(config => config.configName).filter(configType => configType !== "global_configs").forEach((config) => {
                list.push(config);
            });
        }

        setConfigsList(list);
    }, [configsMap])


    return (
        <Box>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={open}
                onClose={clearEverything}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                aria-label='create-config-modal'
            >
                <DialogTitle className={styles.dialog__title}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {tenantName}: Upload Configuration
                        { fileUploaded ?
                            <Button component="label" className={styles.upload__button}>
                                <FileUploadOutlined/>&nbsp;Reload
                                <input
                                    styles={{display:"none"}}
                                    type="file"
                                    hidden
                                    onChange={handleUpload}
                                    name="[jsonFile]"
                                />
                            </Button> : null
                        }

                    </Grid>

                </DialogTitle>
                <DialogContent  sx={{overflowY: "hidden"}}>
                    { !isNewConfig ?
                        <Grid container className={styles.dialog__content} rowSpacing={1} columnSpacing={1}>
                            <Grid item xs={6}>
                                <Autocomplete
                                    value={configName !== "" ? configName : null}
                                    onChange={(event, newValue) => {
                                        handleConfigChange(newValue)
                                    }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    options={configsList}
                                    renderInput={(params) => (
                                        <TextField className={styles.autocomplete__options} {...params} label="Configuration*" />
                                    )}
                                >
                                </Autocomplete>
                            </Grid>
                            <Grid item xs={6}>
                                { configName !== "" ?
                                    <FormControl fullWidth>
                                        <InputLabel>Upload Type*</InputLabel>
                                        <Select
                                            fullWidth
                                            className={styles.environment}
                                            label="Upload Type*"
                                            id="filled-select-state"
                                            aria-label='create-tenant-config-type-input'
                                            value={uploadType ? uploadType : ""}
                                            onChange={(e) => handleChangeUploadType(e.target.value)}
                                        >
                                            <MenuItem value={"Incremental"}>Incremental</MenuItem>
                                            <MenuItem value={"Full"}>Full</MenuItem>
                                        </Select>
                                    </FormControl> : null
                                }
                            </Grid>
                        </Grid>
                    :
                        <Grid container className={styles.dialog__content} rowSpacing={1} columnSpacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label='New Configuration Name*'
                                    value={configName ? configName : ""}
                                    onChange={(e)=> handleNameChange(e.target.value)}
                                    error={configNameExists}
                                    helperText={configNameExists ? "Config already Exists" : ""}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Config Type*</InputLabel>
                                    <Select
                                        fullWidth
                                        className={styles.environment}
                                        label="Configuration Type*"
                                        id="filled-select-state"
                                        aria-label='create-tenant-config-type-input'
                                        value={configType ? configType : ""}
                                        onChange={(e) => handleChangeType(e.target.value)}
                                    >
                                        <MenuItem value={"child"}>Child</MenuItem>
                                        <MenuItem value={"parent"}>Parent</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {configType === "child" ?
                                <>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            select
                                            aria-label='create-tenant-config-parent-name-input'
                                            key={"enabled-parentInput"}
                                            label='Parent Name'
                                            value={parentConfig ? parentConfig : ""}
                                            onChange={(e)=>setParentConfig(e.target.value)}>
                                            <MenuItem className={styles.none__menuitem} value={''}>None</MenuItem>
                                            {configsMap !== undefined && configsMap !== null ? configParentList() : <MenuItem value={''}></MenuItem>}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormTextField
                                            fullWidth
                                            label='Configuration Description*'
                                            value={versionDescription ? versionDescription: ""}
                                            setter={setVersionDescription}
                                            hasWordLimit={true}
                                            wordLimit={200}
                                        />
                                    </Grid>
                                </>
                                : configType === "parent" ?
                                    <Grid item xs={12}>
                                        <FormTextField
                                            fullWidth
                                            label='Configuration Description*'
                                            value={versionDescription}
                                            setter={setVersionDescription}
                                            hasWordLimit={true}
                                            wordLimit={200}
                                        />
                                    </Grid> :
                                    <Grid item xs={12}></Grid>
                            }
                        </Grid>
                    }
                    <Typography mt={2} className={styles.box__helper_text}>Upload configuration*</Typography>
                    {!fileUploaded ?
                        <Grid className={styles.upload__box} container item xs={12} alignItems="center"
                              justifyContent="center" sx={{border: '1px solid #D1D1D1'}}>
                            { uploadReady ?
                                <Button component="label" className={styles.upload__button}>
                                    <FileUploadOutlined/>&nbsp;Upload
                                    <input
                                        styles={{display:"none"}}
                                        type="file"
                                        hidden
                                        onChange={handleUpload}
                                        name="[jsonFile]"
                                    />
                                </Button> :
                                <Button disabled className={styles.upload__button_disabled}>
                                    <FileUploadOutlined/>&nbsp;Upload
                                </Button>
                            }
                        </Grid>
                    :
                        <Grid className={styles.editor__box} item xs={12} mt={2}>

                            {isText ?
                                <Box style={{overflowY: "auto", height: "100%"}}>
                                    <TextField className={styles.editor}
                                               aria-label='create-tenant-config-json-body-input'
                                               multiline
                                               rows={10}
                                               value={rawData}
                                               error={!isTextEditorJson}
                                               helperText={!isTextEditorJson ? "Not a valid json" : ""}
                                               fullWidth
                                               onChange={(e) => handleTextEditorChange(e.target.value)}
                                    />
                                </Box> :
                                <JsonEditor mode="tree"
                                            history
                                            value={json}
                                            onChange={setJson}
                                            ace={ace}
                                            theme="ace/theme/github"
                                />
                            }
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Grid container className={styles.button__row} justifyContent="space-between">
                        {fileUploaded ?
                            <Button variant="text" className={styles.switch__button} onClick={handleSwitchInput} key="switch-modal">{ (isText) ? <div>&nbsp;<b>Text</b>&nbsp;<SwitchRightRoundedIcon/>&nbsp;Editor&nbsp;</div> :  <div>&nbsp;Text&nbsp;<SwitchLeftRoundedIcon/>&nbsp;<b>Editor</b>&nbsp;</div> }</Button>
                        :
                            <Button disabled variant="text" className={styles.switch__button_disabled}>{ (isText) ? <div>&nbsp;<b>Text</b>&nbsp;<SwitchRightRoundedIcon/>&nbsp;Editor&nbsp;</div> :  <div>&nbsp;Text&nbsp;<SwitchLeftRoundedIcon/>&nbsp;<b>Editor</b>&nbsp;</div> }</Button>

                        }
                       <Stack direction={"row"} spacing={2}>
                            <Button aria-label='cancel-create-new-config' variant="text" className={styles.button} onClick={clearEverything} key="cancel-modal" > Cancel</Button>
                            { submitReady ?
                                <Button aria-label='submit-create-new-config' variant="text" className={styles.button} onClick={onSubmit} key="submit-modal"> Submit</Button>
                            :
                                <Button disabled variant="text" className={styles.button__disabled} key="submit-modal-disabled"> Submit</Button>
                            }

                        </Stack>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default UploadConfigModal;