import React, {useEffect, useState} from "react";
import {Box, Grid, IconButton, Typography} from "@mui/material";
import styles from "../../styles/Differences.module.css";
import {ArrowDropDownOutlined} from "@mui/icons-material";
import {DiffRowColor, metaData} from "../constants";
import {paddingLeftDiffRow} from "../common/SharedFunctions";

const DeletedRowDisplay = (props) => {
    const {rowKey, value, isNestedJson, level,isCurrentVersion,keyMetaData='LOCAL',currentRowKey,uniqueRowKey}=props
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded)
    };

    const valueType=()=>{return(<Box className={styles.value_type}>{isNaN(value) ? (typeof value==="boolean"? 'T/F' : 'abc') : (typeof value==="boolean"? 'T/F' : 'abc')}</Box>)}
    const nestedVersionArrow=()=>{
        return(<Grid sx={{ width: '26px' }} pl={'0'}><IconButton className={styles.down_arrow_icon_button}><ArrowDropDownOutlined className={styles.down_arrow_icon} /></IconButton></Grid>)
    }


    return (
        <React.Fragment key={rowKey}>
            {!isCurrentVersion?
                <Grid id={`${currentRowKey}-row-diff-item-prev-display`} key={rowKey} item container className={styles.display_row_container} sx={{pl: paddingLeftDiffRow(level,isNestedJson),bgcolor:uniqueRowKey? uniqueRowKey===currentRowKey?DiffRowColor['deletedRow']:'#FFF':DiffRowColor['deletedRow'],fontWeight:isNestedJson?700:400,color:isNestedJson?metaData['LOCAL']:metaData[keyMetaData]}}>
                    {isNestedJson && nestedVersionArrow()}
                    <Grid className={styles.key_in_row_container} onClick={()=>handleExpandClick()} >
                        <Typography style={expanded? { wordWrap: "break-word",marginTop:'12px',marginBottom:'12px' } : { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow:'hidden' }}  >
                            {rowKey}
                        </Typography>
                    </Grid>
                    &ensp;:
                    {!isNestedJson && valueType()}
                    <Grid sx={{ pl: !isNestedJson ? '18px' : '78px',width:`${250-level * 26 }px`,color:isNestedJson?metaData['LOCAL']:metaData[keyMetaData] }} onClick={()=>handleExpandClick(value)} >
                        <Typography p={1}  sx={{fontWeight:isNestedJson?700:400}} style={expanded? { wordWrap: "break-word" } : { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow:'hidden' }}>
                            {typeof value==='boolean'?value?.toString():value}
                        </Typography>
                    </Grid>
                </Grid>:
                <Grid id={`${currentRowKey}-row-diff-item`} item container  sx={{maxWidth:'646px',height:'50px',bgcolor:uniqueRowKey? uniqueRowKey===currentRowKey?DiffRowColor['deletedRow']:'#FFF':DiffRowColor['deletedRow'],border:'1px solid #E6E6E6'}}  ></Grid>}
        </React.Fragment>
    );
};
export default DeletedRowDisplay;