import React, {useState} from 'react';
import {Typography, Modal, Box, Grid, TextField, MenuItem, Stack, Button, IconButton} from "@mui/material";
import SwitchRightRoundedIcon from '@mui/icons-material/SwitchRightRounded';
import SwitchLeftRoundedIcon from '@mui/icons-material/SwitchLeftRounded';
import {JsonEditor} from 'jsoneditor-react';
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import 'jsoneditor-react/es/editor.min.css';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Input} from "reactstrap";
import SubmitModal from "./SubmitModal";


function CreateConfigModal(props){
    const [json, setJson] = useState({})
    const [configType, setConfigType] = useState('child');
    const [isText, setInputType] = useState(true);
    const [isTextEditorJson, setTextJsonValidation] = useState(true);
    const [textEditorData, setTextEditorData] = useState("");
    const [configName, setConfigName] = useState("");
    const [parentConfig, setParentConfig] = useState("");
    const [versionDescription, setVersionDescription] = useState("");
    const [openSubmit, setOpenSubmit] = useState(false);
    const handleSubmitClose = () => setOpenSubmit(false);


    const theme = createTheme({
        palette: {
            secondary: {
                main: "#ffffff",
            },
            primary:{
                main:"#595959"
            }

        },
    });


    const handleTextEditorChange = (data) => {

        try {
            JSON.parse(data);
            setTextEditorData(data)
            setTextJsonValidation(true);
        } catch(e) {
            setTextJsonValidation(false)
        }
    };



    const handleChangeType = (val) => {
        setConfigType(val);
    };

    const configTypes = [
        {
            value: 'child',
            label: 'Child',
        },
        {
            value: 'global',
            label: 'Global',
        },
        {
            value: 'parent',
            label: 'Parent',
        }
    ];

    const handleSwitchInput = () => {
        setInputType(current => {return !current;});
    };

    const clearEverything = () => {
        setInputType(true);
        setTextEditorData("");
        setTextJsonValidation(true);
        setConfigName("");
        setParentConfig("");
        setVersionDescription("");
        setConfigType("child");
        props.handleClose();
    };

    function updateValue(setFunc, value) {
        setFunc(value)
    }

    const onSubmit = () => {
        console.log('JSON TO SEND: ', textEditorData)
        const jsonBody = {
            "configType": configType,
            "parentConfig" : parentConfig,
            "configsJson": isText ? textEditorData : JSON.stringify(json),
            "versionDescription": versionDescription,
            "user": props.user
        }


        props.createConfig(props.getAWSClient, props.tenantName, jsonBody, configName);
        clearEverything()
    }

    const verifySubmitClick = () => {
        let configsJson = isText ? textEditorData : JSON.stringify(json);
        if (!configName) {
            alert("Empty Config name ")
        }
        else if (!versionDescription) {
            alert("Empty Version Description")
        }
        else if (!configsJson || configsJson === "{}") {
            alert("Empty Configs value")
            // configsJson was empty string, false, 0, null, undefined, ...
        } else if (!isTextEditorJson) {
            alert("is Not Valid Json")
        } else {
            setOpenSubmit(true);
        }
    }

    React.useEffect(() => {
        if (configType != "child") {
            setParentConfig("")
        }
    }, [configType])

    return(
        <Box>
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            aria-label='create-config-modal'
        >
            <Box sx={styles.modal} >
                <Grid container bgcolor={"black"}>
                    <Grid item xs={8}>
                        <Typography id="modal-modal-title" variant="h6" component="h2"  color={"white"} p={1} fontFamily={"Amazon Ember"}>
                            CREATE CONFIG
                        </Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <ThemeProvider theme={theme}>
                            <Box display="flex" justifyContent="flex-end" height={"100%"}>
                                <IconButton color={"secondary"} onClick={props.handleClose}>
                                    <CancelOutlinedIcon/>
                                </IconButton>
                            </Box>
                        </ThemeProvider>
                    </Grid>
                </Grid>
                <Box pl={0.7} pr={0.7} pt={2} style={{overflowY:"auto" , height : "25%"}}>
                <Grid container border={"1px solid black"} justifyContent={"center"} alignItems={"center"} >
                    <Grid item xs={6} p={1.5}>
                        <Stack>
                            <Box sx={{border : "1px solid black" }}>
                                <Input
                                    aria-label='create-tenant-config-name-input'
                                    placeholder="CONFIGURATION NAME"
                                    style = {{borderRadius:'0px', fontFamily:"Amazon Ember"}}
                                    onChange={(e) => updateValue(setConfigName, e.target.value)}
                                />
                            </Box>
                        </Stack>
                        </Grid>
                    <Grid item xs={6} pr={1.5}>
                        <Box sx={{border : "1px solid black" }}>
                            { configType === "child" ?

                                <Input
                                    aria-label='create-tenant-config-parent-name-input'
                                    placeholder="PARENT NAME"

                                    key={"enabled-parentInput"}

                                    style = {{borderRadius:'0px', fontFamily:"Amazon Ember"}}

                                    onChange={(e) => updateValue(setParentConfig,   e.target.value)}

                                />  :
                                <Input
                                    key={"disabled-parentInput"}
                                    disabled
                                    style = {{borderRadius:'0px', fontFamily:"Amazon Ember"}}
                                />
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={6} pl={1.5} pr={1.5} pb={1.5}>
                        <Box sx={{border : "1px solid black" }}>
                            <TextField
                                id="filled-select-state"
                                aria-label='create-tenant-config-type-input'
                                select
                                fullWidth
                                label="Config type"
                                value={configType}
                                onChange={(e) => handleChangeType(e.target.value)}
                                variant="filled"
                                size="small"
                            >
                                {configTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={6} pr={1.5} pb={1.5}>
                        <Box sx={{border : "1px solid black" }}>
                            <TextField fullWidth id="filled-basic" label="Version descr." variant="filled" size="small"
                                       aria-label='create-tenant-config-version-description-input'
                                       inputProps={{
                                           style: {
                                               backgroundColor: 'white'
                                           },
                                       }}
                                       onChange={(e) => updateValue(setVersionDescription, e.target.value)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                </Box>
                <Box style={{height : "61%"}} pl={0.7} pr={0.7}>
                    {isText ?
                        <Box sx={{border : isTextEditorJson ? "1.5px solid green" : "1.5px solid red"}} style={{overflowY:"auto" , height : "100%"}}>
                            <TextField
                                aria-label='create-tenant-config-json-body-input'
                                multiline
                                rows={15}
                                fullWidth
                                onChange={(e) => handleTextEditorChange(e.target.value)}
                            />
                        </Box>:
                        <Box sx={{border :"1px solid black"}} style={{overflowY:"auto" , height : "100%"}}>
                            <JsonEditor
                                mode="tree"
                                history
                                value={json}
                                onChange={setJson}
                                ace={ace}
                                theme="ace/theme/github"
                            />
                        </Box>
                    }

                </Box>
                <Grid container pt={0.7} pl={0.7} pr={0.7} pb={0} justifyContent={"space-between"}>
                        <Button variant="text" style={styles.button} onClick={handleSwitchInput} key="switch-modal">{ (isText) ? <div><SwitchRightRoundedIcon/>&nbsp;Editor</div> :  <div><SwitchLeftRoundedIcon/>&emsp;&emsp;Text</div> }</Button>
                    <Stack direction={"row"} spacing={2}>
                        <Button aria-label='submit-create-new-config' variant="text" style={styles.button} onClick={verifySubmitClick} key="submit-modal"> Submit</Button>
                        <Button aria-label='cancel-create-new-config' variant="text" style={styles.button} onClick={clearEverything} key="cancel-modal" sx={{border: '1px solid black'}}> Cancel</Button>
                    </Stack>
                </Grid>
            </Box>
        </Modal>
            <SubmitModal open={openSubmit} handleClose ={handleSubmitClose} onSubmit = {onSubmit}/>

        </Box>
    )
}

const styles = {
    button:{
        background: '#ECECEC',
        // border: '1px solid #ECECEC',
        borderRadius: '20px',
        fontSize: '12px',
        color: '#000000',
        textAlign: 'center',
        lineHeight: '10px',
        textTransform: 'none',
        fontWeight: 'bold',
        fontFamily: 'Amazon Ember',
        paddingTop:'1px',
        paddingBottom:'1px'
    },
    modal : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        height: '90%',
        // border: '1px solid white',
        bgcolor : 'white',
        boxShadow: 24,
        p: 0
    }
}

export default CreateConfigModal;